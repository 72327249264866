import React from 'react';
import { IQuestData } from '../../../../../game-server/src/modules/quest/Quest.interface';
import { Box, Flex, Image } from '@chakra-ui/react';
import { ProgressBar } from '../../layout/UI/ProgressBar';
import { usePlayerField } from '../../../hooks/hooks';
import { checkRequirements } from './QuestFunctions';
import { AiOutlinePushpin, AiFillLock } from 'react-icons/ai';
import { socket } from '../../../services/socket.service';
import { formatNumberToString } from '../../../utils/helperFunctions';
import QuestTooltip from './QuestTooltip';

export default function AchievementEntry({ quest }: { quest: IQuestData }) {
	const skills = usePlayerField('skills');
	const settings = usePlayerField('settings');
	const questsCompleted = usePlayerField('questsCompleted');
	const questProgress = usePlayerField('questProgress');
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);
	const metRequirements = checkRequirements(quest, skills, questIdsCompleted);
	const step = quest.questSteps[0];

	const pinnedQuest = settings.quest.pin;

	const completed = questIdsCompleted.find((completedQuest) => completedQuest === quest.id);
	const inProgress = questProgress.filter((questProgress) => questProgress.id === quest.id);

	function pinQuest(quest: number, e?: React.MouseEvent) {
		if (e) e.stopPropagation();
		socket.emit('settings:update', {
			value: settings.quest.pin === quest ? 0 : quest,
			path: 'quest.pin',
		});
	}

	const currentProgress = inProgress[0]?.current ?? 0;

	let progressString = formatNumberToString(currentProgress) + '/' + formatNumberToString(step.targetAmount);
	if (
		currentProgress === step.targetAmount ||
		questsCompleted.find((completedQuest) => completedQuest.id === quest.id)
	) {
		progressString = 'Complete';
	}

	function renderPin() {
		const pinned = quest.id === pinnedQuest;
		return (
			<Box
				position='absolute'
				fontSize={20}
				right='10px'
				top='10px'
				cursor='pointer'
				onClick={(e) => pinQuest(quest.id, e)}
				color={pinned ? 'white' : 'gray'}
				_hover={{ color: 'white' }}
				zIndex='1000'
			>
				<AiOutlinePushpin />
			</Box>
		);
	}

	let extraCSS = '';
	if (completed) {
		extraCSS = 'quest-listing-completed';
	} else if (!metRequirements) {
		extraCSS = 'quest-listing-requirement-unmet';
	} else if (inProgress.length > 0) {
		extraCSS = 'quest-listing-in-progress';
	}

	return (
		<Flex
			position='relative'
			className={`quest-step ${extraCSS}`}
			fontSize='14px'
			padding='10px'
			justifyContent='center'
		>
			<Flex height='60px' alignItems='center'>
				{quest.questIcon ? (
					<Box className={`achievement-icon ${quest.name}`} width='80px' height='60px' overflow='hidden'>
						<Image position='relative' width='56px' height='56px' margin='5px 12px' src={quest.questIcon} />
					</Box>
				) : (
					<span>{quest.name}</span>
				)}
			</Flex>
			<Flex
				position='absolute'
				justifyContent='center'
				alignItems='center'
				height='20px'
				width='80%'
				marginTop='2px'
				bottom='10px'
			>
				<ProgressBar
					position='absolute'
					top='0'
					theme='quest'
					value={currentProgress}
					max={step.targetAmount}
					height='100%'
					maxWidth='300px'
					width='100%'
				/>
				<Box position='relative'>{progressString}</Box>
			</Flex>
			<Flex height='66%' width='66%' alignItems='center' justifyContent='center' position='absolute'>
				{extraCSS === 'quest-listing-requirement-unmet' ? <AiFillLock size='100%'></AiFillLock> : ''}
			</Flex>
			<QuestTooltip quest={quest} step={step} isAchievement={true} />
			{extraCSS === 'quest-listing-requirement-unmet' || extraCSS === 'quest-listing-completed'
				? ''
				: renderPin()}
		</Flex>
	);
}

import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React from 'react';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { IDialogProps } from './MainItemDialog';

function TabDialog(props: IDialogProps) {
	const tabCount = usePlayerField('bonusInventoryTabs') + 2;

	function handleTabGlobalSelection(tab: number) {
		socket.emit('inventory:globalTab', {
			inventoryItemID: [props.item.id],
			tab,
		});
		props.onClose();
	}

	const tabButtons: React.ReactElement[] = [];
	for (let i = 0; i < tabCount; i++) {
		const tabText = i === 0 ? 'Default Tab' : 'Tab ' + i;
		tabButtons.push(
			<IdlescapeButton
				key={i}
				variant='green'
				className='item-dialogue-button idlescape-button'
				onClick={() => handleTabGlobalSelection(i)}
				width='80%'
				order={i === 0 ? 1 : undefined}
			>
				{tabText}
			</IdlescapeButton>
		);
	}
	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Tab Selection</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					You will be moving {props.name}s to a tab, which tab would you like to move it to? This will tab ALL
					items of the same type.
					<Flex flexDirection='column' alignItems='center' gap='5px' padding='5px'>
						{tabButtons}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

export default TabDialog;

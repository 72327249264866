import { IdlescapeContainer } from '@idlescape/ui';
import { usePlayerField } from '../../../hooks/hooks';
import { leagueList } from '../../../utils/leagueList';
import { questList } from '../../../utils/questList';
import { talentList } from '../../../utils/talentList';
import './Talents.css';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { TSkillName } from '../../../../../game-server/src/Atypes/Skills';
import { TalentBox } from './TalentBox';

export const TalentsSummary = () => {
	const settings = usePlayerField('settings');
	const selectedTalents = settings.miscellaneous.selectedTalents;
	const skills = usePlayerField('skills');
	const questsCompleted = usePlayerField('questsCompleted');

	const league = usePlayerField('league');
	const leagueData = leagueList[league];
	if (!leagueData?.rules?.quests) {
		return <>You do not have access to mastery talents in this league or season.</>;
	}

	const questPoints = Object.values(questsCompleted).reduce((acc, cur) => {
		return acc + (questList[cur.id]?.questRewards?.questPoints ?? 0);
	}, 0);
	const achievementPoints = Object.values(questsCompleted).reduce((acc, cur) => {
		return acc + (questList[cur.id]?.questRewards?.achievementPoints ?? 0);
	}, 0);

	const stockpile = usePlayerField('stockpile');
	const hasRespecToken = stockpile.find((item) => item.itemID === itemsIds.talent_respec_token) !== undefined;

	function getQuestAllowance() {
		return Math.floor(questPoints / 5);
	}

	function getAchievementAllowance() {
		return Math.floor(achievementPoints / 10);
	}

	function getMasteryAllowance() {
		let sum = 0;
		for (const keyName of Object.keys(skills)) {
			if (keyName === 'total') {
				continue;
			}
			const skill = skills[keyName as TSkillName];
			if (!skill.masteryLevel) {
				continue;
			}
			// 1 mastery = 1 worth, 5 mastery = 2 worth, 10 mastery = 3 worth, etc.
			const levelWorth = Math.floor(skill.masteryLevel / 5) + 1;
			sum += levelWorth;
		}
		return Math.floor(sum);
	}

	function getTalentAllowance() {
		const questAllowance = getQuestAllowance();
		const achievementAllowance = getAchievementAllowance();
		const masteryAllowance = getMasteryAllowance();
		return Math.floor(questAllowance + achievementAllowance + masteryAllowance);
	}
	function getTalentSpent() {
		const worth = selectedTalents.reduce((acc, cur) => {
			const talent = talentList[cur];
			if (!talent) {
				return acc;
			}
			return acc + talent.talentCost;
		}, 0);
		return worth;
	}

	function renderSelectedTalents() {
		return (
			<div className='talent-list'>
				{selectedTalents.length > 0 ? (
					<div className='talent-grid'>
						{selectedTalents.map((talentID) => {
							const talent = talentList[talentID];
							if (!talent) {
								return null;
							}
							return <TalentBox key={`talent-${talent.id}`} talent={talent} selected={true} />;
						})}
					</div>
				) : (
					<div>No talents selected.</div>
				)}
			</div>
		);
	}

	return (
		<div className='talent-container'>
			<IdlescapeContainer variant='dark'>
				<div>
					Talents cannot be edited while in combat!
					<br />
					Mastery Talent Points: {getTalentSpent()} / {getTalentAllowance()}
					<br />
					From Quests: {getQuestAllowance()} ({questPoints} Quest Points / 5, rounded down)
					<br />
					From Achievements: {getAchievementAllowance()} ({achievementPoints} Achievement Points / 10, rounded
					down)
					<br />
					From Mastery: {getMasteryAllowance()} (1 point at mastery 1, then 1 point more every 5 mastery,
					rounded down)
					<hr />
					{hasRespecToken
						? 'Use the Respec Token in your stockpile to reset your talents.' // opted to not make this a button to make it harder to accidentally use
						: 'You need a Respec Token to reset your talents.'}
				</div>
			</IdlescapeContainer>
			<IdlescapeContainer variant='dark'>{renderSelectedTalents()}</IdlescapeContainer>
		</div>
	);
};

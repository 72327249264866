import React, { useEffect } from 'react';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import { IMarketListing } from '../../../../../game-server/src/repositories/MarketListing.repository';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import { IdlescapeButton, IdlescapeNumberInput, IdlescapeWrappingTooltip } from '@idlescape/ui';
import {
	Box,
	Flex,
	Img,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
} from '@chakra-ui/react';
import { usePlayerField } from '../../../hooks/hooks';
import { compareItems } from '../../../utils/itemFunctions';
import { marketplaceItemAtom } from '../../../atoms/marketplaceItemAtom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { coinIcon } from '../../../utils/itemHelperFunctions';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { MAXIMUM_MARKET_PRICE } from '../../../utils/constantsCollection';
import { mobileTooltipViewAtom } from '../../../atoms/mobileTooltipViewAtom';
import useIsMobile from '../../../hooks/useIsMobile';
import ItemTooltip from '../../game/Tooltips/ItemTooltip';
import { marketplaceTabAtom } from '../../../atoms/marketplaceTabAtom';

export const SellMarketplaceDialogue = ({
	itemProp,
	onClose,
	listingProp,
	offerProp,
}:
	| {
			itemProp?: undefined;
			onClose: () => void;
			listingProp: IMarketListing;
			offerProp?: IMarketListing;
	  }
	| {
			itemProp: IItem;
			onClose: () => void;
			listingProp?: IMarketListing;
			offerProp?: IMarketListing;
	  }) => {
	const [itemToBuy, setItemToBuy] = useRecoilState(marketplaceItemAtom);
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const setMarketTab = useSetRecoilState(marketplaceTabAtom);
	const [mobileView, setMobileView] = useRecoilState(mobileTooltipViewAtom);
	const isMobile = useIsMobile();
	const item = getItem();
	const [listing, setListing] = React.useState<IMarketListing | undefined>(listingProp ?? undefined);
	const itemID = item?.itemID ?? listing?.item.itemID ?? 0;
	const itemData = itemList[itemID];
	const offerPrice = offerProp?.price;
	const offerAmount = offerProp?.item.stackSize ?? 1;

	const [amountToSell, setAmountToSell] = React.useState(listing?.item.stackSize ?? 1);
	const [price, setPrice] = React.useState(offerPrice ?? listing?.price ?? 0);

	const [lowestPrice, setLowestPrice] = React.useState(0);
	//Block if below vendor price
	const preventListing = price < (itemData.value ?? 0) || amountToSell <= 0;

	const maxItemAmount = maxAmount();

	useEffect(() => {
		socket.on('marketplace:listing:item:price', (data) => {
			if (!data?.itemID) return;
			const lowestPrice = data.lowestPrice;

			if (lowestPrice && (data.itemID === item?.itemID || data.itemID === listing?.item.itemID)) {
				setLowestPrice(lowestPrice);
			} else {
				setLowestPrice(0);
			}
		});
		if (item) socket.emit('marketplace:listing:item:price', item.itemID);
		else if (listing) socket.emit('marketplace:listing:item:price', listing.item.itemID);

		socket.on('marketplace:listing:player:send', (listing) => {
			setListing(listing);
			if (offerProp) {
				setAmountToSell(Math.min(offerAmount, listing.item.stackSize));
			} else {
				setAmountToSell(listing.item.stackSize);
				setPrice(listing.price);
			}
		});
		if (!listingProp && item) socket.emit('marketplace:listing:player:get', item.id);

		return () => {
			socket.off('marketplace:listing:item:price');
			socket.off('marketplace:listing:player:send');
		};
	}, []);

	function getItem() {
		if (itemProp) return itemProp;
		const stockpile = usePlayerField('stockpile');
		return stockpile.find((i) => compareItems(i, listingProp?.item));
	}

	function handleSellMax() {
		setAmountToSell(maxItemAmount);
	}

	function handleOfferMax() {
		let max = maxItemAmount;
		if (offerProp) {
			max = Math.min(offerAmount, max);
		}
		setAmountToSell(max);
	}

	function maxAmount() {
		return (item ? item.stackSize : 0) + (listing ? listing.item.stackSize : 0);
	}

	function handleSellItem() {
		if (preventListing) return;
		if (item && !listing) {
			socket.emit('marketplace:listing:create', {
				amount: amountToSell,
				price: price,
				dbID: item.id,
				refreshItemId: itemToBuy, //Refresh the current view, not the item you sold
			});
		} else if (listing && (amountToSell !== listing.item.stackSize || price !== listing.price)) {
			socket.emit('marketplace:listing:update', {
				listingId: listing.listingId,
				amount: amountToSell,
				price: price,
				dbID: item?.id,
				refreshItemId: itemToBuy,
			});
		}
		onClose();
	}

	function handleCancelItem() {
		if (!listing) return;
		socket.emit('marketplace:listing:remove', {
			listingId: listing.listingId,
			refreshItemId: itemToBuy,
		});
		onClose();
	}

	const valueSlider = (
		<Flex flexWrap='wrap' gap='4' justifyContent='center' alignItems='center'>
			<Slider
				onChange={setAmountToSell}
				min={1}
				value={amountToSell}
				max={maxItemAmount}
				step={1}
				aria-labelledby='continuous-slider'
				focusThumbOnChange={false}
				flex='1 0 150px'
			>
				<SliderTrack>
					<SliderFilledTrack />
				</SliderTrack>
				<SliderThumb />
			</Slider>
			<IdlescapeNumberInput
				value={amountToSell}
				setValue={setAmountToSell}
				max={maxItemAmount}
				width='130px'
				onKeyUp={(e) => {
					if (e.key === 'Enter') handleSellItem();
				}}
			/>
			<Flex flex='1 0 100px' flexWrap={'wrap'} justifyContent='center'>
				<IdlescapeButton
					variant='green'
					size='large'
					color='secondary'
					onClick={handleSellMax}
					flex='1 0 100px'
					width='100%'
					maxWidth='200px'
				>
					Sell Max
				</IdlescapeButton>
				{offerProp && maxItemAmount > offerAmount && (
					<IdlescapeButton
						variant='blue'
						size='large'
						color='secondary'
						onClick={handleOfferMax}
						flex='1 0 100px'
						width='100%'
						maxWidth='200px'
					>
						Offer Max
					</IdlescapeButton>
				)}
			</Flex>
		</Flex>
	);

	const goToListing = (
		<IdlescapeWrappingTooltip content='Open marketplace listing'>
			<Box position='absolute' top='60px' right='18px'>
				<Img
					src='images/ui/marketplace_icon.png'
					alt='Open marketplace listing'
					className='share-marketplace-listing-button'
					filter='brightness(1.2) drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
					onClick={() => {
						setActiveTab('marketplace');
						setMarketTab('buy');
						setItemToBuy(itemID);
					}}
				></Img>
			</Box>
		</IdlescapeWrappingTooltip>
	);

	return (
		<Modal isOpen={true} onClose={onClose} size='lg'>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog anchor-sell-item-dialog'>
				{isMobile && item && (
					<Flex justifyContent='center' width='100%' top='0.5rem' margin='-13px 0'>
						<IdlescapeButton variant='blue' onClick={() => setMobileView(false)}>
							Details
						</IdlescapeButton>
						<IdlescapeButton variant='green' onClick={() => setMobileView(true)}>
							Sell
						</IdlescapeButton>
					</Flex>
				)}
				<ModalHeader>
					{listing
						? 'Update your listing'
						: 'Sell ' +
						  (item ? item.name + (item.augmentations ? ' +' + item.augmentations : '') : 'an item')}
				</ModalHeader>
				<ModalCloseButton />
				{goToListing}
				{isMobile && !mobileView && item ? (
					<Flex justifyContent='center'>
						<ItemTooltip item={item} embed={true} />
					</Flex>
				) : (
					<>
						<ModalBody>
							<Text margin='0'>How many do you want to sell?</Text>
							{valueSlider}
							<Flex flexDirection='column' alignItems='center'>
								<Text fontSize='lg'>Price per item</Text>
								<Flex flexWrap='wrap' justifyContent='space-between' width='100%' columnGap='20px'>
									<div id='lowest-price'>
										Lowest price on market:{' '}
										{lowestPrice.toLocaleString('en-US', { useGrouping: true })} {coinIcon(10)}
									</div>
									{itemData.value && (
										<div id='lowest-price-npc'>
											Item sells to NPCs for:{' '}
											{itemData.value.toLocaleString('en-US', {
												useGrouping: true,
											})}{' '}
											{coinIcon(10)}
										</div>
									)}
								</Flex>
							</Flex>
							<InputGroup marginY='1'>
								<IdlescapeNumberInput
									value={price}
									setValue={setPrice}
									max={MAXIMUM_MARKET_PRICE}
									onKeyUp={(e) => {
										if (e.key === 'Enter') handleSellItem();
									}}
									className='anchor-sell-price-input'
								/>
								<InputRightElement right='25px'>{coinIcon(16)}</InputRightElement>
							</InputGroup>
							<Text margin='0'>
								You will receive:{' '}
								{Math.floor(amountToSell * price * 0.95).toLocaleString('en-US', { useGrouping: true })}{' '}
								{coinIcon(16)}
								<br />
								After the 5% fee of:{' '}
								{Math.ceil(amountToSell * price * 0.05).toLocaleString('en-US', {
									useGrouping: true,
								})}{' '}
								{coinIcon(16)}
							</Text>
						</ModalBody>
						<ModalFooter gap='5px' justifyContent='center' flexWrap='wrap' maxWidth='100%'>
							{listing && (
								<IdlescapeButton
									variant='red'
									size='medium'
									color='secondary'
									onClick={handleCancelItem}
								>
									Cancel Listing
								</IdlescapeButton>
							)}
							<IdlescapeButton
								variant={preventListing ? 'disabled' : 'green'}
								size='medium'
								color='secondary'
								onClick={handleSellItem}
								className='anchor-sell-confirm-button'
							>
								{listing ? 'Update Listing' : 'Create Listing'}
							</IdlescapeButton>
						</ModalFooter>
						<Text fontSize='sm' color='gray.400' textAlign='center' marginTop='0'>
							Inputs support short notation, e.g. 1.5k = 1500.
						</Text>
					</>
				)}
			</ModalContent>
		</Modal>
	);
};

import { usePlayerAffixStrength, usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import { cookingRecipeAtom } from '../../../../atoms/cookingRecipeAtom';
import { useRecoilValue } from 'recoil';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import {
	getNonBurnFormula,
	getCookingQuality,
	getLadleHaste,
	getPotionInfo,
	qualityCostCurve,
	getItemIDFromTag,
	getCookingDurationFormula,
	getFoodInfo,
	cookingTimeExpMulti,
} from '../../../../utils/cookingFunctions';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { cookingList } from '../../../../utils/cookingList';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import { infernoSpeed } from '../../../../utils/helperFunctions';
import { dwarvenElvenBonus, dwarvenElvenHaste } from '../../../../utils/itemFunctions';
import { itemList } from '../../../../utils/itemList';
import {
	COOKING_EXP_PER_DIFFICULTY,
	COOKING_HEAT_PER_SIZE,
	FISHOIL_PER_SIZE,
	NORMAL_POTION_IDS,
	POTION_DEFAULT_AMOUNT,
} from '../../../../utils/constantsCollection';
import { TIngredientTag } from '../../../../../../game-server/src/modules/skills/cooking/Cooking.interface';

export default function CookingInformation(quality: number, isAlchemy = false, vialPotion?: IItem, buffItem?: number) {
	const cookingRecipe = useRecoilValue(cookingRecipeAtom);
	const stockpile = usePlayerField('stockpile');
	const equipment = usePlayerField('equipment');
	const skills = usePlayerField('skills');
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');
	const affixAlchemyQualityBonus = usePlayerAffixStrength('cooking.alchemy_quality_bonus');
	const affixAlchemyQualityChance = usePlayerAffixStrength('cooking.alchemy_quality_bonus_chance');
	const affixFoodQualityBonus = usePlayerAffixStrength('cooking.food_quality_bonus');
	const affixFoodQualityChance = usePlayerAffixStrength('cooking.food_quality_bonus_chance');
	const affixDoubleChance = usePlayerAffixStrength('cooking.chance_to_double');
	const affixPreserveChance = usePlayerAffixStrength('cooking.chance_to_preserve');
	const heatReductionAffix = usePlayerAffixStrength('cooking.heat_cost_reduction');
	const affixMiracle = usePlayerAffixStrength('cooking.miracle_chance');

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, 'cooking');
	const deepFryStrength = usePlayerEnchantmentStrength(enchantmentsIds.deep_fry, 'cooking');
	const infernoStrength = usePlayerEnchantmentStrength(enchantmentsIds.inferno, 'cooking');
	const masterChefStrength = usePlayerEnchantmentStrength(enchantmentsIds.master_chef, 'cooking');
	const overlyWellDoneStrength = usePlayerEnchantmentStrength(enchantmentsIds.overly_well_done, 'cooking');
	const cookingStrength = usePlayerEnchantmentStrength(enchantmentsIds.cooking, 'cooking');
	const pyromancyStrength = usePlayerEnchantmentStrength(enchantmentsIds.pyromancy, 'cooking');
	const dwarvenManufacturingStrength = usePlayerEnchantmentStrength(enchantmentsIds.dwarven_manufacturing, 'cooking');
	const elvenLogisticsStrength = usePlayerEnchantmentStrength(enchantmentsIds.elven_logistics, 'cooking');
	const scholarStrength = usePlayerEnchantmentStrength(enchantmentsIds.scholar, 'cooking');
	const wealthStrength = usePlayerEnchantmentStrength(enchantmentsIds.wealth, 'cooking');
	const scholarWealth = wealthStrength ? 'wealth' : scholarStrength ? 'scholar' : undefined;
	const consistentChef = usePlayerEnchantmentStrength(enchantmentsIds.consistent_chef, 'cooking');

	const cookingLevel = skills.cooking.level + skills.cooking.masteryLevel + skillEquipmentStats.cooking;
	const potionInfo = getPotionInfo(vialPotion?.itemID);
	const foodInfo = getFoodInfo(cookingRecipe);
	const previewItem: TPartialItemWithItemID<IItem> = { itemID: 0 };

	let difficulty = 0;
	let size = 0;
	let valid = Infinity;

	// Quality and potion amount
	let affixBonus = affixFoodQualityBonus;
	if (vialPotion) {
		affixBonus = affixAlchemyQualityBonus;
	}
	const cookingQuality =
		getCookingQuality(cookingLevel, affixBonus, isAlchemy) +
		(isAlchemy ? affixAlchemyQualityChance : affixFoodQualityChance);
	const potionAmount = Math.ceil(POTION_DEFAULT_AMOUNT + cookingQuality);
	// Food quality
	const foodAugmentation = cookingQuality + quality * foodInfo.perSlider;
	const augmentationChance = (isAlchemy ? cookingQuality : foodAugmentation) % 1;
	const foodQuality = Math.floor(foodAugmentation) + (consistentChef && augmentationChance > 0 ? 1 : 0);

	// Find all ingredients and add up difficulty, size and number of cooks
	const ingredients: { id: number; amount: number }[] = [];
	if (cookingRecipe) {
		const cookingData = cookingList[cookingRecipe];
		let qualityAmount = foodInfo.initialCost + qualityCostCurve(quality);
		if (isAlchemy) {
			qualityAmount = potionInfo.upgradeCost[quality];
			// Buff item size
			const amount = quality - potionInfo.tier;
			difficulty += (cookingData.difficulty ?? 0) * amount;
			size += (cookingData.size ?? 0) * amount;
			const owned = stockpile.find((item) => item.itemID === cookingRecipe)?.stackSize ?? 0;
			if (valid > owned / amount) {
				valid = owned / amount;
			}
			ingredients.push({ id: cookingRecipe, amount });
		}
		if (cookingData?.recipeTags) {
			for (const [tag, count] of Object.entries(cookingData.recipeTags)) {
				const itemID = getItemIDFromTag(tag as TIngredientTag);
				if (itemID) {
					const ingredient = cookingList[itemID];
					let amount = qualityAmount;
					amount *= count;
					size += (ingredient?.size ?? 0) * amount;
					difficulty += (ingredient?.difficulty ?? 0) * amount;
					ingredients.push({ id: itemID, amount });
					const owned = stockpile.find((item) => item.itemID === itemID)?.stackSize ?? 0;
					if (valid > owned / amount) {
						valid = owned / amount;
					}
				}
			}
		}
		if (vialPotion) {
			// Potion size
			difficulty += cookingList[vialPotion.itemID]?.difficulty ?? 0;
			size += cookingList[vialPotion.itemID]?.size ?? 0;
			const owned = stockpile.find((item) => item.id === vialPotion.id)?.stackSize ?? 0;
			if (valid > owned / potionAmount) {
				valid = owned / potionAmount;
			}
		} else if (buffItem) {
			// Buff item size for cooking
			difficulty += cookingList[buffItem]?.difficulty ?? 0;
			size += cookingList[buffItem]?.size ?? 0;
			const owned = stockpile.find((item) => item.itemID === buffItem)?.stackSize ?? 0;
			if (valid > owned) {
				valid = owned;
			}
		}
	}

	valid = Math.floor(valid);
	if (valid === Infinity) {
		valid = 0;
	}

	// Fish oil
	const fish = ingredients.find((ing) => ing.id === itemsIds.filleted_fish);
	const fishOil = (fish?.amount ?? 0) * FISHOIL_PER_SIZE;

	// Heat
	const heat = Math.ceil(
		size *
			COOKING_HEAT_PER_SIZE *
			(1 - heatReductionAffix) *
			(1 - pyromancyStrength) *
			dwarvenElvenBonus(dwarvenManufacturingStrength, elvenLogisticsStrength)
	);
	ingredients.push({ id: itemsIds.heat, amount: heat });

	// Duration stuff
	const timeExpMulti = cookingTimeExpMulti(cookingRecipe);
	const ladleHaste = getLadleHaste(equipment);
	const infSpeed = 1 + infernoSpeed(infernoStrength);
	const logisticsStrength = dwarvenElvenHaste(dwarvenManufacturingStrength, elvenLogisticsStrength);
	const duration =
		((isAlchemy ? 1 : timeExpMulti) * getCookingDurationFormula(size)) /
		(1 + hasteStrength + ladleHaste + deepFryStrength) /
		(1 + logisticsStrength);

	// Difficulty and Burn chance
	let chance = getNonBurnFormula(difficulty, cookingLevel);
	chance += cookingStrength;
	chance += (masterChefStrength * cookingLevel) / 100;
	chance -= overlyWellDoneStrength;
	chance = Math.max(0, Math.min(1, chance));
	chance += (1 - chance) * affixMiracle;

	// Experience
	let experience = (isAlchemy ? 1 : timeExpMulti) * difficulty * COOKING_EXP_PER_DIFFICULTY;
	if (scholarWealth === 'scholar') {
		experience *= 1 + scholarStrength;
	}
	experience = Math.floor(experience);

	// Create item preview
	if (vialPotion) {
		const nextPotion = cookingList[vialPotion.itemID]?.nextPotion;
		if (nextPotion) {
			previewItem.itemID = NORMAL_POTION_IDS[quality];
			previewItem.stackSize = Math.floor(potionAmount);
		}
	} else if (!isAlchemy) {
		previewItem.itemID = cookingRecipe;
		previewItem.augmentations = foodQuality;
	}
	const recipeBuff = isAlchemy
		? cookingList[cookingRecipe]?.alchemyEnchantment
		: cookingList[buffItem ?? 0]?.cookingEnchantment;
	previewItem.enchantmentID = recipeBuff;

	// Check if wealth or scholar
	const itemData = itemList[previewItem.itemID];
	if (itemData) {
		if (wealthStrength) {
			previewItem.stackSize = itemData.value ?? 0;
			previewItem.itemID = itemsIds.gold;
			delete previewItem.augmentations;
			delete previewItem.enchantmentID;
		} else if (scholarStrength) {
			previewItem.itemID = 0;
			delete previewItem.augmentations;
			delete previewItem.enchantmentID;
		}
	}

	return {
		difficulty,
		chance,
		heat,
		experience,
		actions: valid,
		duration,
		quality: foodQuality,
		higherQuality: augmentationChance,
		qualityPerSlider: isAlchemy ? 1 : foodInfo.perSlider,
		ingredients,
		previewItem,
		totalTime: (duration * valid) / infSpeed,
		scholarWealth,
		fishOil,
		consistentChef,
	};
}

export const talentsIds = {
	gathering_master: 1,
	gathering_grandmaster: 2,
	dwarven_student: 3,
	elven_student: 4,
	shrimp_student: 5,
	hamster_hunter: 6,
	melter_smelter: 7,
	fresh_forager: 8,
	mining_prodigy: 9,
	foraging_prodigy: 10,
	fishing_prodigy: 11,
	mining_grandmaster: 12,
	foraging_grandmaster: 13,
	fishing_grandmaster: 14,
	precision: 1000,
	bound_anger: 1001,
	infernal_knowledge: 1002,
	mercenary_guild_trainer: 1003,
	mercenary_guild_paymaster: 1004,
	lucky_looter: 1005,
	critical_weakpoint: 1006,
	careful_criticals: 1007,
	unbound_rage: 1008,
	unadorned_agility: 1009,
	arsonist: 1010,
	giants_blood: 1011,
	featherweight_fighter: 1012,
	ninelives: 1013,
	coldblooded: 1014,
	goblin_hunter: 1015,
	demon_hunter: 1016,
	undead_hunter: 1017,
	goblin_slayer: 1018,
	demon_slayer: 1019,
	undead_slayer: 1020,
	featherweight_champion: 1021,
	weredruid: 1022,
	wereelf: 1023,
	loaded_dice: 1024,
	human_hunter: 1025,
	human_slayer: 1026,
	elf_hunter: 1027,
	elf_slayer: 1028,
	dwarf_hunter: 1029,
	dwarf_slayer: 1030,
	giant_hunter: 1031,
	giant_slayer: 1032,
	golem_hunter: 1033,
	golem_slayer: 1034,
	beast_hunter: 1035,
	beast_slayer: 1036,
	shrimp_hunter: 1037,
	shrimp_slayer: 1038,
	chaotic_hunter: 1039,
	chaotic_slayer: 1040,
	corrupted_hunter: 1041,
	corrupted_slayer: 1042,
	woodlike_hunter: 1043,
	woodlike_slayer: 1044,
	silent_fury: 1045,
	arrow_expenditure: 1046,
	elven_exaltation: 1047,
	piercing_ammo_specialization: 1048,
	serrated_ammo_specialization: 1049,
	heavy_ammo_specialization: 1050,
	mark_ii: 1051,
	ammunition_overload: 1052,
	ammunition_overoverload: 1053,
	poisoner: 1054,
	druidism: 1055,
	runecrafting_master: 2000,
	augmentation_afficionado: 2001,
	novice_crafter: 2002,
	apprentice_crafter: 2003,
	journeyman_crafter: 2004,
	master_crafter: 2005,
	grandmaster_crafter: 2006,
	runecrafting_grandmaster: 2007,
	slag_refinement: 2008,
	seed_sifter: 2009,
	pungent_runes: 2010,
	smithing_master: 2011,
	cooking_master: 2012,
	transformation_talent: 2013,
	enchanting_master: 2014,
	bulk_cooking: 2015,
	overheating: 2016,
	obsidian_forgery: 2017,
	dwarven_refinement: 2018,
	molten_metal: 2019,
	pyromania: 2020,
	greenest_thumb: 2021,
	totemic_wisdom: 2022,
	mysterious_harvest: 2023,
	trapper: 2024,
	farm_blitz: 2025,
	sympathetic_growth: 2026,
	crop_rotation: 2027,
	tree_hugger: 2028,
	heart_of_the_forest: 2029,
	farming_master: 2030,
	mysterious_nutrients: 2031,
	research_ready: 2032,
	mad_scientist: 2033,
	lingering_enlightenment: 2034,
	spiraling_out_of_control: 2035,
	affix_affinity: 2036,
	fantastic_fiber_finder: 2037,
	talisman_talent: 2038,
	essence_concentration_expert: 2039,
	essence_concentration_master: 2040,
	consistent_chef: 2041
}

/* eslint-disable turbo/no-undeclared-env-vars */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { Flex, Stack, Heading, Input, Button, Image, Checkbox } from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { clientType } from 'idlescape-globals';
import { openGameUrl } from '../../GameUrls';
import { steamworksClient } from '../../services/Steamworks';
/* import * as steamworks from 'steamworks.js'; */

// TODO: Add actual Idlescape styling
// Most of this functionality was lifted from the landing page's LoginComponent.tsx
export const Login = () => {
	const [loginUsername, setLoginUsername] = useState('');
	const [loginPassword, setLoginPassword] = useState('');
	const [registerConfirmPassword, setRegisterConfirmPassword] = useState('');
	const [login2FA, setLogin2FA] = useState('');
	const [loginErrors, setLoginErrors] = useState<{
		username?: string;
		password?: string;
		password2?: string;
		'2fa'?: string;
		server?: string;
		account?: string;
	}>({});
	const [agreeToPolicy, setAgreeToPolicy] = useState(false);
	const [over13, setOver13] = useState(false);
	const navigate = useNavigate();
	// The only valid client types are 'web', 'steam', and 'app', and 'web' will just redirect to the website
	const currentClient = clientType || 'web';

	const [view, setView] = useState<'guest-login' | 'login' | 'register'>('register');
	function changeView(newView: 'guest-login' | 'login' | 'register') {
		setLoginErrors({});
		setView(newView);
	}

	/* useEffect(() => {
		try {
			const steamworks = require('steamworks.js');
			const steamClient = steamworks.init(2692420);
			console.log(steamClient);
			console.log(steamClient.localplayer.getSteamId());
			console.log(steamClient.localplayer.getName());
		} catch (error) {
			console.error('Failed to initialize Steamworks:', error);
		}
	}, []); */

	// Check if they're already logged in, if so, navigate to the characters page
	if (localStorage.jwtToken) {
		openGameUrl('characters', { navigate });
	}

	function renderSteamLogin() {
		if (currentClient === 'steam') {
			return (
				<Heading fontSize={{ base: 'xs', md: 'sm' }} color={'gray.300'} alignSelf='center'>
					<a href={generateSteamLoginLink()}>Authenticate Steam</a>
				</Heading>
			);
		}
		return '';
	}

	function generateSteamLoginLink() {
		// const returnUrl = `${window.location.origin}/login`;
		// const returnUrl = 'idlescape://login';
		const returnUrl = window.location.href;
		/* console.log('Return url:', returnUrl); */
		/* console.log('Encoded:', encodeURIComponent(returnUrl)); */
		const url = `https://steamcommunity.com/openid/login?openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.mode=checkid_setup&openid.ns=http://specs.openid.net/auth/2.0&openid.realm=${encodeURIComponent(
			window.location.origin
		)}&openid.return_to=${encodeURIComponent(returnUrl)}`;
		return url;
	}

	// Not using authActions.ts because it's ~21 months old and doesn't appear to be used anywhere
	// I'm just copying the functionality from the landing instead and slightly modifying it to fit here

	function login() {
		const loginData = { username: loginUsername, password: loginPassword, '2fa': login2FA };

		axios
			.post(`${process.env.REACT_APP_PUBLIC_AUTH_SERVER_URL}/api/auth/login`, loginData, {
				withCredentials: true,
			})
			.then((res) => {
				const { token } = res.data;
				if (token) {
					localStorage.setItem('jwtToken', token);
					// Send them to the /characters page
					openGameUrl('characters', { navigate });
				}
			})
			.catch((err) => {
				console.log('Error!', err, err?.response?.data);
				if (err?.response?.data) {
					setLoginErrors(err.response.data);
				} else {
					setLoginErrors({ server: 'Game server is possibly down' });
				}
			});
	}

	function register() {
		const registerData = {
			username: loginUsername,
			email: '',
			password: loginPassword,
			password2: registerConfirmPassword,
		};

		// registration sometimes goes to landing page, might be browser only?
		axios
			.post(`${process.env.REACT_APP_PUBLIC_AUTH_SERVER_URL}/api/auth/register`, registerData)
			.then((res) => {
				console.log('Registered!', res);
				const { token } = res.data;
				if (token) {
					localStorage.setItem('jwtToken', token);
					setTimeout(() => {
						openGameUrl('characters', { navigate });
					}, 1000);
				}
			})
			.catch((err) => {
				console.log('Error!', err, err?.response?.data);
				if (err?.response?.data) {
					setLoginErrors(err.response.data);
				} else {
					setLoginErrors({ server: 'Game server is possibly down' });
				}
			});
	}

	function guestLogin() {
		const registerData = { agreeToPolicy, over13 };

		axios
			.post(`${process.env.REACT_APP_PUBLIC_AUTH_SERVER_URL}/api/auth/guest-login`, registerData, {
				withCredentials: true,
			})
			.then((res) => {
				console.log('Guest Registered!', res);
				const { token } = res.data;
				if (token) {
					localStorage.setItem('jwtToken', token);
					setTimeout(() => {
						openGameUrl('characters', { navigate });
					}, 1000);
				}
			})
			.catch((err) => {
				console.log('Error!', err, err?.response?.data);
				if (err?.response?.data) {
					setLoginErrors(err.response.data);
				} else {
					setLoginErrors({ server: 'Game server is possibly down' });
				}
			});
	}

	// TODO: All steam functions require the query that comes back from the steam login

	function steamRegister() {
		const registerData = {
			agreeToPolicy,
			over13,
			username: loginUsername,
			query: window.location.search,
		};
		console.log('Steam Register!', registerData, process.env);

		axios
			.post(`${process.env.REACT_APP_PUBLIC_AUTH_SERVER_URL}/api/auth/steam-register/`, registerData, {
				withCredentials: true,
			})
			.then((res) => {
				console.log('Steam Registered!', res);
				const { token } = res.data;
				if (token) {
					localStorage.setItem('jwtToken', token);
					setTimeout(() => {
						openGameUrl('characters', { navigate });
					}, 1000);
				}
			})
			.catch((err) => {
				console.log('Error!', err, err?.response?.data);
				if (err?.response?.data) {
					setLoginErrors(err.response.data);
				} else {
					setLoginErrors({ server: 'Game server is possibly down' });
				}
			});
	}

	function steamConnect() {
		const registerData = {
			username: loginUsername,
			password: loginPassword,
			'2fa': login2FA,
			query: window.location.search,
		};
		console.log('Steam Connect!', registerData);
		axios
			.post(`${process.env.REACT_APP_PUBLIC_AUTH_SERVER_URL}/api/auth/steam-connect`, registerData, {
				withCredentials: true,
			})
			.then((res) => {
				console.log('Steam Connect!', res);
				const { token } = res.data;
				if (token) {
					localStorage.setItem('jwtToken', token);
					setTimeout(() => {
						openGameUrl('characters', { navigate });
					}, 1000);
				}
			})
			.catch((err) => {
				console.log('Error!', err, err?.response?.data);
				if (err?.response?.data) {
					setLoginErrors(err.response.data);
				} else {
					setLoginErrors({ server: 'Game server is possibly down' });
				}
			});
	}

	// useEffect to only call steamLogin on first render
	useEffect(() => {
		if (currentClient === 'steam') {
			console.log('Client is Steam, attempting to login');

			steamLogin();
		}
	}, []);

	async function steamLogin() {
		/* const steamworksClient = await steamworks.init(2692420); */
		const steamId = steamworksClient.localplayer.getSteamId();
		console.log('Steam ID:', steamId);
		console.log('Steam name:', steamworksClient.localplayer.getName());
		console.log(steamworksClient.apps.isAppInstalled(2692420));
		console.log(steamworksClient.apps.appOwner());
		console.log(steamworksClient.apps.appBuildId());
		console.log(steamworksClient.apps.currentGameLanguage());

		try {
			const authTicket = await steamworksClient.auth.getAuthTicketForWebApi('idlescape-steam-client');
			const authTicketHex = authTicket.getBytes().toString('hex');
			const loginData = { authTicketHex, steamId: steamId.steamId64.toString() };
			console.log('Auth Ticket:', authTicket, authTicketHex);
			/* const sessionTicket = await steamworksClient.auth.getSessionTicketWithSteamId(steamId.steamId64);

			const sessionTicketHex = sessionTicket.getBytes().toString('hex');

			console.log(
				'Session Ticket:',
				sessionTicket,
				sessionTicket.getBytes(),
				sessionTicket.getBytes().toString('hex')
			);

			const loginData = {
				steamId: steamId.steamId64.toString(),
				sessionTicket: sessionTicketHex,
			}; */

			axios
				.post(`${process.env.REACT_APP_PUBLIC_AUTH_SERVER_URL}/api/auth/steam-login`, loginData, {
					withCredentials: true,
				})
				.then((res) => {
					console.log('Steam login request successful', res.data);
					const { token } = res.data;
					if (token) {
						localStorage.setItem('jwtToken', token);
						console.log('Token set', token);
						authTicket.cancel();
						console.log('Session Ticket cancelled');
						// Send them to the /characters page
						openGameUrl('characters', { navigate });
					}
				})
				.catch((err) => {
					console.log('Error!', err, err?.response?.data);
					if (err?.response?.data) {
						setLoginErrors(err.response.data);
					} else {
						setLoginErrors({ server: 'Game server is possibly down' });
					}
				});

			//console.log('Session Ticket:', sessionTicket.getBytes(), sessionTicket);

			//sessionTicket.cancel();
		} catch (error) {
			console.error('Failed to get session ticket:', error);
		}

		/* steamworksClient.auth
			.getSessionTicketWithSteamId(steamId.steamId64)
			.then((ticket) => {
				console.log('Ticket:', ticket.getBytes());
			})
			.catch((err) => {
				console.log('Error!', err);
			}); */
		/* console.log('Session Ticket:', sessionTicket);

		const sessionTicketHex = sessionTicket;

		const loginData = {
			steamId: steamId.steamId64,
			sessionTicket: sessionTicketHex,
		};

		console.log('loginData', loginData); */

		/* axios
			.post(`${process.env.REACT_APP_PUBLIC_AUTH_SERVER_URL}/api/auth/steam-login`, loginData, {
				withCredentials: true,
			})
			.then((res) => {
				console.log('Steam login request successful', res.data);
				const { token } = res.data;
				if (token) {
					localStorage.setItem('jwtToken', token);
					console.log('Token set', token);
					sessionTicket.cancel();
					console.log('Session Ticket cancelled');
					// Send them to the /characters page
					openGameUrl('characters', { navigate });
				}
			}); */
	}

	function steamLoginOld() {
		const loginData = { query: window.location.search, '2fa': login2FA };

		axios
			.post(`${process.env.REACT_APP_PUBLIC_AUTH_SERVER_URL}/api/auth/steam-login`, loginData, {
				withCredentials: true,
			})
			.then((res) => {
				const { token } = res.data;
				if (token) {
					localStorage.setItem('jwtToken', token);
					// Send them to the /characters page
					openGameUrl('characters', { navigate });
				}
			})
			.catch((err) => {
				console.log('Error!', err, err?.response?.data);
				if (err?.response?.data) {
					setLoginErrors(err.response.data);
				} else {
					setLoginErrors({ server: 'Game server is possibly down' });
				}
			});
	}

	function renderErrors() {
		// This is so weird, but it lets us show multiple errors at once
		return (
			<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '2xl' }} alignSelf='center'>
				{loginErrors.username && <p>{loginErrors.username}</p>}
				{loginErrors.password && <p>{loginErrors.password}</p>}
				{loginErrors.password2 && <p>{loginErrors.password2}</p>}
				{loginErrors['2fa'] && <p>{loginErrors['2fa']}</p>}
				{loginErrors.server && <p>{loginErrors.server}</p>}
				{loginErrors.account && <p>{loginErrors.account}</p>}
			</Heading>
		);
	}

	// If this is a web client, tell them to go to the website
	if (currentClient === 'web') {
		console.log(7, 'web');
		return (
			<Flex w={'full'} h={'100vh'} justifyContent={'center'} alignItems={'center'} bg={'gray.800'}>
				<Stack spacing={4} w={'full'} maxW={'md'} bg={'gray.700'} rounded={'xl'} boxShadow={'lg'} p={6} my={12}>
					<Image
						src='images/logo_2.0.png'
						alt='Idlescape Logo'
						sizes='(max-width: 600px) 75vw, (max-width: 900px) 50vw, 25vw'
						width={'75%'}
						height={'auto'}
						alignSelf='center'
					/>
					<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }} alignSelf='center'>
						Register or Login on the Idlescape website
					</Heading>
					<Heading fontSize={{ base: 'xs', md: 'sm' }} color={'gray.300'} alignSelf='center'>
						For more functionality please visit the{' '}
						<a href='https://idlescape.com' target='_blank' rel='noreferrer'>
							Idlescape website
						</a>
					</Heading>
				</Stack>
			</Flex>
		);
	}

	// Register is the default view
	if (view === 'register') {
		// If this is a steam client, give them a login with steam button or a connect account button
		const hasQuery = window.location.search.length > 0;
		if (currentClient === 'steam') {
			return (
				<Flex w={'full'} h={'100vh'} justifyContent={'center'} alignItems={'center'} bg={'gray.800'}>
					<Stack
						spacing={4}
						w={'full'}
						maxW={'md'}
						bg={'gray.700'}
						rounded={'xl'}
						boxShadow={'lg'}
						p={6}
						my={12}
					>
						<Image
							src='/images/logo_2.0.png'
							alt='Idlescape Logo'
							sizes='(max-width: 600px) 75vw, (max-width: 900px) 50vw, 25vw'
							width={'75%'}
							height={'auto'}
							alignSelf='center'
						/>
						<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }} alignSelf='center'>
							Steam - Registration
						</Heading>
						<Heading fontSize={{ base: 'xs', md: 'sm' }} color={'gray.300'} alignSelf='center'>
							For more functionality please visit the{' '}
							<a href='https://idlescape.com' target='_blank' rel='noreferrer'>
								Idlescape website
							</a>
						</Heading>
						{renderSteamLogin()}
						{renderErrors()}
						<Checkbox
							colorScheme={'blue'}
							isChecked={agreeToPolicy}
							onChange={() => setAgreeToPolicy(!agreeToPolicy)}
							size={'lg'}
							marginLeft={'10px'}
							marginTop={'10px'}
						>
							I agree to Idlescape&apos;s{' '}
							<a href='https://idlescape.com/privacy-policy' target='_blank' rel='noreferrer'>
								Privacy Policy
							</a>
						</Checkbox>
						<Checkbox
							colorScheme={'blue'}
							isChecked={over13}
							onChange={() => setOver13(!over13)}
							size={'lg'}
							marginLeft={'10px'}
							marginTop={'10px'}
						>
							I am over 13 years old
						</Checkbox>
						<Input
							placeholder='Game Account Name'
							variant='filled'
							_hover={{
								bg: 'gray.900',
							}}
							_focus={{
								bg: 'gray.900',
							}}
							onChange={(e) => setLoginUsername(e.target.value)}
						/>
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={steamRegister}
								isDisabled={!agreeToPolicy || !over13 || !hasQuery}
							>
								Play with Steam
							</Button>
						</Stack>
						{
							<Stack spacing={6}>
								<Button
									bg={'blue.400'}
									color={'white'}
									_hover={{
										bg: 'blue.500',
									}}
									onClick={() => changeView('guest-login')}
								>
									Login to Existing Steam Account
								</Button>
							</Stack>
						}
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={() => changeView('login')}
							>
								Connect Existing Account
							</Button>
						</Stack>
					</Stack>
				</Flex>
			);
		}
		// If this is an app client, ask them to register or play as guest
		if (currentClient === 'app') {
			return (
				<Flex w={'full'} h={'100vh'} justifyContent={'center'} alignItems={'center'} bg={'gray.800'}>
					<Stack
						spacing={4}
						w={'full'}
						maxW={'md'}
						bg={'gray.700'}
						rounded={'xl'}
						boxShadow={'lg'}
						p={6}
						my={12}
					>
						<Image
							src='/images/logo_2.0.png'
							alt='Idlescape Logo'
							sizes='(max-width: 600px) 75vw, (max-width: 900px) 50vw, 25vw'
							width={'75%'}
							height={'auto'}
							alignSelf='center'
						/>
						<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }} alignSelf='center'>
							App - Registration
						</Heading>
						<Heading fontSize={{ base: 'xs', md: 'sm' }} color={'gray.300'} alignSelf='center'>
							For more functionality please visit the{' '}
							<a href='https://idlescape.com' target='_blank' rel='noreferrer'>
								Idlescape website
							</a>
						</Heading>
						{renderErrors()}
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={() => changeView('login')}
							>
								Go to Login
							</Button>
						</Stack>
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={() => changeView('guest-login')}
							>
								Play as Guest
							</Button>
						</Stack>
						<Input
							placeholder='Account Name'
							variant='filled'
							_hover={{
								bg: 'gray.900',
							}}
							_focus={{
								bg: 'gray.900',
							}}
							onChange={(e) => setLoginUsername(e.target.value)}
						/>
						<Input
							placeholder='Password'
							type='password'
							variant='filled'
							_hover={{
								bg: 'gray.900',
							}}
							_focus={{
								bg: 'gray.900',
							}}
							onChange={(e) => setLoginPassword(e.target.value)}
						/>
						<Input
							placeholder='Confirm Password'
							type='password'
							variant='filled'
							_hover={{
								bg: 'gray.900',
							}}
							_focus={{
								bg: 'gray.900',
							}}
							onChange={(e) => setRegisterConfirmPassword(e.target.value)}
						/>
						<Checkbox
							colorScheme={'blue'}
							isChecked={agreeToPolicy}
							onChange={() => setAgreeToPolicy(!agreeToPolicy)}
							size={'lg'}
							marginLeft={'10px'}
							marginTop={'10px'}
						>
							I agree to Idlescape&apos;s{' '}
							<a href='https://idlescape.com/privacy-policy' target='_blank' rel='noreferrer'>
								Privacy Policy
							</a>
						</Checkbox>
						<Checkbox
							colorScheme={'blue'}
							isChecked={over13}
							onChange={() => setOver13(!over13)}
							size={'lg'}
							marginLeft={'10px'}
							marginTop={'10px'}
						>
							I am over 13 years old
						</Checkbox>
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={register}
								isDisabled={!agreeToPolicy || !over13}
							>
								Register Account
							</Button>
						</Stack>
					</Stack>
				</Flex>
			);
		}
	} else if (view === 'login') {
		let firstHeaderText = 'Log In to Account';
		if (currentClient === 'steam') {
			firstHeaderText = 'Connect Steam to Account';
		}
		let loginText = 'Log In';
		if (currentClient === 'steam') {
			loginText = 'Connect Account';
		}
		const hasQuery = window.location.search.length > 0;
		const disableConnect = clientType === 'steam' && !hasQuery;
		if (disableConnect) {
			loginText = 'Authenticate Steam First';
		}
		return (
			<Flex w={'full'} h={'100vh'} justifyContent={'center'} alignItems={'center'} bg={'gray.800'}>
				<Stack spacing={4} w={'full'} maxW={'md'} bg={'gray.700'} rounded={'xl'} boxShadow={'lg'} p={6} my={12}>
					<Image
						src='/images/logo_2.0.png'
						alt='Idlescape Logo'
						sizes='(max-width: 600px) 75vw, (max-width: 900px) 50vw, 25vw'
						width={'75%'}
						height={'auto'}
						alignSelf='center'
					/>
					<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }} alignSelf='center'>
						{firstHeaderText}
					</Heading>
					<Heading fontSize={{ base: 'xs', md: 'sm' }} color={'gray.300'} alignSelf='center'>
						For more functionality please visit the{' '}
						<a href='https://idlescape.com' target='_blank' rel='noreferrer'>
							Idlescape website
						</a>
					</Heading>

					{renderSteamLogin()}
					{renderErrors()}
					<Stack spacing={6}>
						<Button
							bg={'blue.400'}
							color={'white'}
							_hover={{
								bg: 'blue.500',
							}}
							onClick={() => changeView('register')}
						>
							Back to Registration
						</Button>
					</Stack>
					{currentClient !== 'steam' ? (
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={() => changeView('guest-login')}
							>
								Play as Guest
							</Button>
						</Stack>
					) : (
						''
					)}
					<Input
						placeholder='Account Name'
						variant='filled'
						_hover={{
							bg: 'gray.900',
						}}
						_focus={{
							bg: 'gray.900',
						}}
						onChange={(e) => setLoginUsername(e.target.value)}
					/>
					<Input
						placeholder='Password'
						type='password'
						variant='filled'
						_hover={{
							bg: 'gray.900',
						}}
						_focus={{
							bg: 'gray.900',
						}}
						onChange={(e) => setLoginPassword(e.target.value)}
					/>
					<Input
						placeholder='2FA If Enabled'
						variant='filled'
						_hover={{
							bg: 'gray.900',
						}}
						_focus={{
							bg: 'gray.900',
						}}
						onChange={(e) => setLogin2FA(e.target.value)}
					/>
					<Stack spacing={6}>
						<Button
							bg={'blue.400'}
							color={'white'}
							_hover={{
								bg: 'blue.500',
							}}
							onClick={currentClient === 'steam' ? steamConnect : login}
							isDisabled={disableConnect}
						>
							{loginText}
						</Button>
					</Stack>
				</Stack>
			</Flex>
		);
	} else if (view === 'guest-login') {
		// If this is the steam client then guest-login is actually trying to login to an existing steam account
		if (currentClient === 'steam') {
			const hasQuery = window.location.search.length > 0;
			return (
				<Flex w={'full'} h={'100vh'} justifyContent={'center'} alignItems={'center'} bg={'gray.800'}>
					<Stack
						spacing={4}
						w={'full'}
						maxW={'md'}
						bg={'gray.700'}
						rounded={'xl'}
						boxShadow={'lg'}
						p={6}
						my={12}
					>
						<Image
							src='/images/logo_2.0.png'
							alt='Idlescape Logo'
							sizes='(max-width: 600px) 75vw, (max-width: 900px) 50vw, 25vw'
							width={'75%'}
							height={'auto'}
							alignSelf='center'
						/>
						<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }} alignSelf='center'>
							Steam - Login
						</Heading>
						<Heading fontSize={{ base: 'xs', md: 'sm' }} color={'gray.300'} alignSelf='center'>
							For more functionality please visit the{' '}
							<a href='https://idlescape.com' target='_blank' rel='noreferrer'>
								Idlescape website
							</a>
						</Heading>
						{renderSteamLogin()}
						{renderErrors()}
						<Input
							placeholder='2FA If Enabled'
							variant='filled'
							_hover={{
								bg: 'gray.900',
							}}
							_focus={{
								bg: 'gray.900',
							}}
							onChange={(e) => setLogin2FA(e.target.value)}
						/>
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={steamLogin}
								isDisabled={!hasQuery}
							>
								{hasQuery ? 'Login to Steam Account' : 'Authenticate Steam First'}
							</Button>
						</Stack>
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={() => changeView('login')}
							>
								Back to Login
							</Button>
						</Stack>
					</Stack>
				</Flex>
			);
		}
		// If this is an app client, prompt them to play as guest or give them register/login buttons
		if (currentClient === 'app') {
			return (
				<Flex w={'full'} h={'100vh'} justifyContent={'center'} alignItems={'center'} bg={'gray.800'}>
					<Stack
						spacing={4}
						w={'full'}
						maxW={'md'}
						bg={'gray.700'}
						rounded={'xl'}
						boxShadow={'lg'}
						p={6}
						my={12}
					>
						<Image
							src='/images/logo_2.0.png'
							alt='Idlescape Logo'
							sizes='(max-width: 600px) 75vw, (max-width: 900px) 50vw, 25vw'
							width={'75%'}
							height={'auto'}
							alignSelf='center'
						/>
						<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }} alignSelf='center'>
							App - Play as Guest
						</Heading>
						<Heading fontSize={{ base: 'xs', md: 'sm' }} color={'gray.300'} alignSelf='center'>
							For more functionality please visit the{' '}
							<a href='https://idlescape.com' target='_blank' rel='noreferrer'>
								Idlescape website
							</a>
						</Heading>
						{renderErrors()}
						<Checkbox
							colorScheme={'blue'}
							isChecked={agreeToPolicy}
							onChange={() => setAgreeToPolicy(!agreeToPolicy)}
							size={'lg'}
							marginLeft={'10px'}
							marginTop={'10px'}
						>
							I agree to Idlescape&apos;s{' '}
							<a href='https://idlescape.com/privacy-policy' target='_blank' rel='noreferrer'>
								Privacy Policy
							</a>
						</Checkbox>
						<Checkbox
							colorScheme={'blue'}
							isChecked={over13}
							onChange={() => setOver13(!over13)}
							size={'lg'}
							marginLeft={'10px'}
							marginTop={'10px'}
						>
							I am over 13 years old
						</Checkbox>
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={guestLogin}
								isDisabled={!agreeToPolicy || !over13}
							>
								Start Guest Account
							</Button>
						</Stack>
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={() => changeView('login')}
							>
								Back to Login
							</Button>
						</Stack>
						<Stack spacing={6}>
							<Button
								bg={'blue.400'}
								color={'white'}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={() => changeView('register')}
							>
								Back to Registration
							</Button>
						</Stack>
					</Stack>
				</Flex>
			);
		}
	}
	return <>Error!</>;
};

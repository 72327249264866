import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { Route, HashRouter as Router, Routes, Navigate, useNavigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.css';
import { logoutUser, setCurrentUser } from './actions/authActions';
import EmailRegistered from './components/auth/EmailRegistered';
import EmailRegisteredFail from './components/auth/EmailRegisteredFail';
import { PaymentSuccessful } from './components/auth/PaymentSuccessful';
import { Game } from './components/game/Game';
import { CharactersMenu } from './components/character-menu/CharactersMenu';
import { ProtectedRoute } from './components/private-route/PrivateRoute';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { PaymentFailed } from './components/auth/PaymentFailed';
import accordionTheme from './chakra-themes/AccordionTheme';
import modalTheme from './chakra-themes/ModalTheme';
import tabsTheme from './chakra-themes/TabsTheme';
import DOMPurify from 'dompurify';
import { ICharacterJWTData } from '../../game-server/src/modules/session/SessionStarter';
import { Login } from './components/login/Login';
import { initializeExperienceTables } from './utils/experienceFunctions';
import { gameUrls, openGameUrl } from './GameUrls';

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GTM_ID ?? '',
};
TagManager.initialize(tagManagerArgs);
// Check for token to keep user logged in
/** Do we even need to check expiry here?
 * Players that just logged in won't have a localStorage.jwtToken until {@link ProtectedRoute} */
if (localStorage.jwtToken) {
	// Set auth token header auth
	try {
		const token = localStorage.jwtToken;
		setAuthToken(token);
		// Decode token and get user info and exp
		const decoded: ICharacterJWTData = jwtDecode(token); // ! TS - Placeholder
		// Set user and isAuthenticated
		store.dispatch(setCurrentUser(decoded));
		// Check for expired token
		const currentTime = Date.now() / 1000; // to get in milliseconds
		if (decoded.exp < currentTime) {
			// Logout user
			logoutUser(store.dispatch);

			// Redirect to login
			openGameUrl('login');
		}
	} catch (e) {
		// Logout user
		logoutUser(store.dispatch);

		// Redirect to login
		openGameUrl('login');
	}
}

const config = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
};
const theme = extendTheme({
	config,
	styles: {
		global: {
			'html, body': {
				color: 'white',
			},
			'span, div': {
				fontWeight: 'unset',
			},
		},
	},
	fonts: {
		heading: 'Exo 2',
		body: 'Exo 2',
	},
	components: {
		Accordion: accordionTheme,
		Modal: modalTheme,
		Tabs: tabsTheme,
	},
});

const App = () => {
	// Create cache
	/* const navigate = useNavigate(); */
	initializeExperienceTables();

	useEffect(() => {
		// https://github.com/cure53/DOMPurify/issues/317#issuecomment-670816926
		DOMPurify.addHook('afterSanitizeAttributes', function (node) {
			if ('target' in node) {
				node.setAttribute('target', '_blank');
				node.setAttribute('rel', 'noopener');
			}
		});
	}, []);

	/* if (clientType === 'steam' && !localStorage.jwtToken) {
		navigate('/login');
	} */

	return (
		<ChakraProvider resetCSS={false} theme={theme}>
			<RecoilRoot>
				<Provider store={store}>
					<Router>
						<div className='App'>
							<div>Pitaisi nakya {window.location.href}</div>
							<Routes>
								<Route
									path={gameUrls.game}
									element={
										<ProtectedRoute>
											<Game></Game>
										</ProtectedRoute>
									}
								/>
								<Route
									path={gameUrls.characters}
									element={
										<ProtectedRoute>
											<CharactersMenu></CharactersMenu>
										</ProtectedRoute>
									}
								/>
								<Route path={gameUrls.login} element={<Login />} />
								<Route path='/game' element={<Navigate to={gameUrls.game} replace={true} />} />
								<Route
									path='/recover-password'
									element={<Navigate to={gameUrls.recoverPassword} replace={true} />}
								/>

								<Route path={gameUrls.verificationComplete} element={<EmailRegistered />} />
								<Route path={gameUrls.verificationFailed} element={<EmailRegisteredFail />} />
								<Route
									path={gameUrls.paymentSuccess}
									element={<PaymentSuccessful isSubscription={false}></PaymentSuccessful>}
								/>
								<Route
									path={gameUrls.subscriptionSuccess}
									element={<PaymentSuccessful isSubscription={true}></PaymentSuccessful>}
								/>
								<Route path={gameUrls.paymentFail} element={<PaymentFailed></PaymentFailed>} />
							</Routes>
						</div>
					</Router>
				</Provider>
			</RecoilRoot>
		</ChakraProvider>
	);
};

export default App;

import React, { useEffect, useState } from 'react';
import {
	Box,
	Image,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { socket } from '../../../services/socket.service';
import { leagueList } from '../../../utils/leagueList';
import './Inspect.css';
import { chatIconList } from '../../../utils/chatIconList';
import { IdlescapeButton, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { CombatAvatar } from '../../game/Combat/CombatAvatar2';
import { TSkills } from '../../../../../game-server/src/Atypes/Skills';
import { tabs } from '../../../utils/NavigationTabsList';
import { TCosmetics, TEquipmentPartials } from '../../../../../game-server/src/modules/player/PlayerTypes';

function Inspect() {
	const [inspectData, setInspectData] = useState<{
		inspectData: {
			equipment: TEquipmentPartials;
			levels: Partial<TSkills>;
			cosmetics: TCosmetics;
			icons: number[];
			hiscores: string[]; // TODO: hiscores
			items: { itemID: number; itemName: string; itemAug: number }[];
		};
		username: string;
		league: number;
		achievementPoints: number;
	}>({
		inspectData: {
			equipment: {},
			levels: {},
			cosmetics: {},
			icons: [-1],
			hiscores: [''],
			items: [],
		},
		username: '',
		league: 0,
		achievementPoints: 0,
	});
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		socket.on('inspect:player', (data) => {
			setInspectData(data);
			onOpen();
		});
		return () => {
			socket.off('inspect:player');
		};
	});

	const icons: React.ReactElement[] = [];
	let count = 0;
	for (const icon of inspectData.inspectData.icons) {
		if (count++ > 3) break; // only show 3 icons
		const iconData = chatIconList[icon];
		if (iconData) {
			icons.push(
				<IdlescapeWrappingTooltip key={icon} content={iconData.name}>
					<img className='inspect-achievement-icon' src={iconData.source} alt={iconData.name} />
				</IdlescapeWrappingTooltip>
			);
		}
	}

	const totalLevel = inspectData.inspectData.levels.total?.level ?? 0;
	const totalMastery = inspectData.inspectData.levels.total?.masteryLevel ?? 0;

	const levels = Object.entries(inspectData.inspectData.levels).map(([skill, skillData]) => {
		if (skill === 'total') return;
		const imageSource = tabs[skill].icon;
		const image = <img className='header-league-icon' src={imageSource} alt={skill} />;
		let masteryLevel: React.ReactElement | null = null;
		if (skillData.masteryLevel) {
			masteryLevel = <span className='lightblue-text'> + {skillData.masteryLevel}</span>;
		}
		const key = `${skill}-${skillData.level}-${skillData.masteryLevel}`;
		return (
			<div key={key} className='inspect-level'>
				{image}
				{skillData.level}
				{masteryLevel}
			</div>
		);
	});

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent maxWidth='600px'>
				<ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
					<div className='inspect-chat-icon-container'>{icons}</div>
					<div className='tooltip-title'>{inspectData.username}</div>
					<Image
						className='inspect-league-icon'
						src={leagueList[inspectData.league]?.icon}
						alt={leagueList[inspectData.league]?.name}
						width='60px'
						height='60px'
					/>
				</ModalHeader>
				<ModalBody
					display='flex'
					columnGap='35px'
					alignItems='end'
					justifyContent='center'
					flexWrap='wrap'
					padding='0'
					className='inspect-container'
				>
					<Box width='200px' marginLeft='10px'>
						<CombatAvatar
							equipment={inspectData.inspectData.equipment}
							cosmetics={inspectData.inspectData.cosmetics}
						/>
					</Box>
					<div className='inspect-levels'>
						<div className='inspect-level'>
							<img className='header-league-icon' src='/images/total_level.png' alt='Total Level' />
							{totalLevel}
						</div>
						<div className='inspect-level lightblue-text'>
							<img
								className='header-league-icon'
								src='/images/total_level_mastery_icon.png'
								alt='Total Mastery Level'
							/>
							{totalMastery}
						</div>
						{levels}
					</div>
					{/* <div className='inspect-achievement-points-container'>
						<span>Achievement Points: {inspectData.achievementPoints}</span>
					</div> */}
				</ModalBody>
				<ModalFooter display='flex' justifyContent='center'>
					<IdlescapeButton size='large' variant='red' onClick={onClose}>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default Inspect;

import { Flex, Grid, Heading, Image, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeFrameBox, IdlescapeNumberInput } from '@idlescape/ui';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { AiFillCaretLeft } from 'react-icons/ai';
import { IItemData } from '../../../../../game-server/src/modules/items/items.interface';
import { IShrineBucket, IShrineBuffBucket } from '../../../../../game-server/src/modules/shrine/Shrine.interface';
import { useGlobalField, usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import {
	MATERIAL_VENDOR_COST_MULTIPLIER,
	SHRINE_MINIMUM_DONATION_VALUE,
	SHRINE_TIME_PER_LEVEL,
} from '../../../utils/constantsCollection';
import { formatNumberToString, getTimeString } from '../../../utils/helperFunctions';
import { itemList } from '../../../utils/itemList';
import { leagueList } from '../../../utils/leagueList';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { Buff } from '../../layout/StatusBar/Buff';
import ItemTooltip from '../Tooltips/ItemTooltip';
import ShrineBucketHighscore from './ShrineBucketHighscore';
import { BucketIcon, BucketProgress, getBucketInfo, getShrineGod, getThemeIcon } from './ShrineUtils';

export default function ShrineBucket(props: { bucket: IShrineBucket; onBack: () => void }) {
	const madnessTheme = useGlobalField('madnessTheme');

	const [donationValue, setDonationValue] = useState(SHRINE_MINIMUM_DONATION_VALUE);
	const [selectedMaterial, setSelectedMaterial] = useState<IItemData['id']>(itemsIds.gold);
	const selectedMaterialData = itemList[selectedMaterial];

	const materialValue = selectedMaterialData.value ? MATERIAL_VENDOR_COST_MULTIPLIER * selectedMaterialData.value : 1;
	const totalValue = donationValue * materialValue;

	const stockpile = usePlayerField('stockpile');

	const differenceToNextLevel = props.bucket.costOfNextLevel - props.bucket.progressToNextLevel;
	const valueToNextLevel = Math.max(differenceToNextLevel, SHRINE_MINIMUM_DONATION_VALUE);
	const amountToNextLevel = Math.ceil(valueToNextLevel / materialValue);
	const selectedMaterialItem = stockpile.find((item) => item.itemID === selectedMaterial);
	const stackSize = selectedMaterialItem?.stackSize ?? 0;
	const enoughForMilestone = amountToNextLevel <= stackSize;

	function donate() {
		socket.emit('shrine:donate', {
			type: props.bucket.type,
			bucket: props.bucket.id,
			amount: donationValue,
			material: selectedMaterial,
		});
	}

	function completeMilestone() {
		setDonationValue(Math.min(amountToNextLevel, stackSize));
	}

	function renderBonus(bucket: IShrineBucket) {
		if (bucket.type === 'madnessStrength') {
			return (
				<>
					<Text textAlign='center'>{getBucketInfo(bucket, false)}</Text>
					<Text textAlign='center'>
						The Madness Strength altar influences the strength of all madness buffs that activate. Its level
						is randomly reduced about every 24 hours, but isn&apos;t reset with the rest of the shrine.
					</Text>
				</>
			);
		}
		if (bucket.type === 'madnessBuff') {
			return (
				<>
					<Text textAlign='center'>{getBucketInfo(bucket, false)}</Text>
					<Text textAlign='center'>
						The Madness Buff altar activates one random from the current theme instantly when a milestone is
						completed. Its level is randomly reduced about every 24 hours, but isn&apos;t reset with the
						rest of the shrine.
					</Text>
					<Text textAlign='center'>
						Current theme: {capitalize(madnessTheme)}{' '}
						<Image src={getThemeIcon(madnessTheme)} alt={capitalize(madnessTheme)} width='30px' />
					</Text>
				</>
			);
		}

		return (
			<Grid gridTemplateColumns='3fr 1fr' textAlign='center'>
				<Text>Unlocked</Text>
				<Text>Next</Text>
				{getBucketInfo(bucket, false)}
				{bucket.type === 'buff' && nextBuff(bucket as IShrineBuffBucket)}
				{bucket.type === 'time' && <Text margin='0'>+{getTimeString(SHRINE_TIME_PER_LEVEL / 1000)}</Text>}
			</Grid>
		);
	}

	function nextBuff(bucket: IShrineBuffBucket) {
		const next = bucket.buffLevels[bucket.buffLevels.length - 1];
		const nextIndex = bucket.buffs.findIndex((buff) => buff === next);
		return (
			<Flex justifyContent='center' gridColumn='2 / 3' gridRow={`${nextIndex + 2} / ${nextIndex + 3}`}>
				<Buff enchantmentID={next} enchantmentStrength={1} source='shrine-next' />
			</Flex>
		);
	}

	function renderMaterials(bucket: IShrineBuffBucket) {
		const leagueData = leagueList[bucket.leagueId];
		// Materials can only be used in IM leagues
		if (leagueData.rules.marketplace) return null;
		return (
			<>
				<Grid gridTemplateColumns='repeat(auto-fit, 140px)' width='100%' justifyContent='center'>
					{[itemsIds.gold, ...bucket.materials].map((material) => {
						const materialItem = stockpile.find((item) => item.itemID === material);
						const materialData = itemList[material];
						return (
							<IdlescapeFrameBox
								as={Flex}
								key={material}
								alignItems='center'
								gap='5px'
								borderRadius='5px'
								padding='5px 10px'
								margin='5px'
								justifyContent='space-between'
								cursor='pointer'
								variant={material === selectedMaterial ? 'dark' : 'primary'}
								transform={material === selectedMaterial ? 'scale(1.05)' : undefined}
								onClick={() => setSelectedMaterial(material)}
							>
								<Image
									src={materialData.itemIcon ?? materialData.itemImage}
									width={'40px'}
									height={'40px'}
									objectFit='contain'
									display='block'
								/>
								<Text>{formatNumberToString(materialItem?.stackSize ?? 0)}</Text>
								<Image
									src='/images/ui/inventory_icon.png'
									alt='inventory'
									line-height='40px'
									justifySelf='right'
									width='16px'
									height='16px'
									display='inline-block'
									verticalAlign='middle'
								/>
								<ItemTooltip item={{ itemID: material }} />
							</IdlescapeFrameBox>
						);
					})}
				</Grid>
				<Text>Materials other than gold, contribute 10x their vendor value to the shrine.</Text>
			</>
		);
	}

	return (
		<Grid
			gridTemplateColumns='repeat(auto-fill, minmax(min(400px, 100%), 1fr))'
			columnGap='150px'
			alignItems='center'
		>
			<IdlescapeContainer position='relative'>
				<Grid gridTemplateColumns='1fr 1fr'>
					<IdlescapeButton onClick={props.onBack} variant='blue' size='small'>
						<AiFillCaretLeft />
						Back
					</IdlescapeButton>
					<BucketIcon bucket={props.bucket} size={40} justifySelf='right' />
					<Heading textAlign='center' size='lg' gridColumn='span 2'>
						Altar of {getShrineGod(props.bucket.id)}
					</Heading>
				</Grid>
				<Text textAlign='center'>
					{props.bucket.progressToNextLevel.toLocaleString('en-us')} /{' '}
					{props.bucket.costOfNextLevel.toLocaleString('en-us')}
				</Text>
				<BucketProgress bucket={props.bucket} height='20px' />
				{renderBonus(props.bucket)}
			</IdlescapeContainer>
			<IdlescapeContainer as={Flex} flexDirection='column' alignItems='center' height='fit-content'>
				<Heading size='lg'>Make a donation</Heading>
				{props.bucket.type === 'buff' && renderMaterials(props.bucket as IShrineBuffBucket)}
				<Flex alignItems='center'>
					<IdlescapeNumberInput
						value={donationValue}
						setValue={setDonationValue}
						onKeyUp={(e) => {
							if (e.key === 'Enter') donate();
						}}
					/>
					<IdlescapeButton onClick={completeMilestone} variant='green' size='large' flex='0 0 200px'>
						{enoughForMilestone ? 'Complete milestone' : 'Donate all'}
					</IdlescapeButton>
				</Flex>
				{selectedMaterialData.value !== undefined && 'Total Value: ' + totalValue.toLocaleString('en-us')}
				{totalValue < SHRINE_MINIMUM_DONATION_VALUE && (
					<Text color='red' margin='0'>
						The mininum donation value is {SHRINE_MINIMUM_DONATION_VALUE}
					</Text>
				)}
				{donationValue > stackSize && (
					<Text color='red' margin='0'>
						You do not have enough {selectedMaterialData.name}
					</Text>
				)}
				<IdlescapeButton
					onClick={donate}
					variant={
						totalValue >= SHRINE_MINIMUM_DONATION_VALUE && donationValue <= stackSize
							? 'purple'
							: 'disabled'
					}
				>
					Donate
				</IdlescapeButton>
			</IdlescapeContainer>
			<ShrineBucketHighscore bucketId={props.bucket.id} />
		</Grid>
	);
}

import { Flex, Box, Text } from '@chakra-ui/react';
import { IdlescapeFrameBox, IdlescapeContainer } from '@idlescape/ui';
import {
	IQuestCompleted,
	IQuestProgress,
	TQuestDifficulty,
} from '../../../../../game-server/src/modules/quest/Quest.interface';
import { questPointIcon } from '../../../utils/itemHelperFunctions';
import { questList } from '../../../utils/questList';
import useIsMobile from '../../../hooks/useIsMobile';

interface QuestSummaryProps {
	isAchievements: boolean;
	questProgress: IQuestProgress[];
	questsCompleted: IQuestCompleted[];
}

export default function QuestSummary({ isAchievements, questProgress, questsCompleted }: QuestSummaryProps) {
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);

	const isMobile = useIsMobile();

	const totalQuests = Object.values(questList).reduce((acc, quest) => {
		if (quest.achievement === isAchievements) {
			acc++;
		}
		return acc;
	}, 0);

	// Filter questIds by whether they are achievements or not
	const filteredCompleted = questIdsCompleted.filter((questId) => {
		const quest = questList[questId];
		if (isAchievements) {
			return quest?.achievement;
		} else {
			return quest?.achievement === undefined;
		}
	});
	// Same for questProgress
	const filteredProgress = questProgress.filter((questId) => {
		const quest = questList[questId.id];
		if (isAchievements) {
			return quest?.achievement;
		} else {
			return quest?.achievement === undefined;
		}
	});

	let questPoints = 0;
	let achievementPoints = 0;
	Object.values(questList).forEach((quest) => {
		if (questIdsCompleted.includes(quest.id)) {
			questPoints += quest?.questRewards?.questPoints ?? 0;
			achievementPoints += quest?.questRewards?.achievementPoints ?? 0;
		}
	});

	function getSumCompletedByDifficulty(difficulty: TQuestDifficulty) {
		let sum = 0;
		Object.values(questList).forEach((quest) => {
			if (questIdsCompleted.includes(quest.id)) {
				if (quest.difficulty === difficulty) {
					sum += 1;
				}
			}
		});
		return sum;
	}

	const targetName = isAchievements ? 'Achievement' : 'Quest';

	const totalEasy = getSumCompletedByDifficulty('Easy');
	const totalMedium = getSumCompletedByDifficulty('Medium');
	const totalHard = getSumCompletedByDifficulty('Hard');
	const totalElite = getSumCompletedByDifficulty('Elite');
	const totalMaster = getSumCompletedByDifficulty('Master');

	if (isAchievements) {
		return (
			<Flex justifyContent='center' alignItems='center' gap='10px'>
				<h1 style={{ textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black' }}>
					Achievements ({filteredCompleted.length} / {totalQuests})
				</h1>
				<br></br>
				<h2 style={{ textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black' }}>
					Points: {achievementPoints}
				</h2>
			</Flex>
		);
	}

	return (
		<Box textAlign='center'>
			<IdlescapeContainer>
				<Flex alignItems='center' height='150px'>
					<IdlescapeFrameBox padding='10px' margin='5px' _hover={{}}>
						{questPointIcon(100)}
					</IdlescapeFrameBox>
					<Text fontSize={isMobile ? '35px' : '50px'}>{targetName} Summary</Text>
				</Flex>
			</IdlescapeContainer>
			<IdlescapeContainer fontSize='28px'>
				<p>
					Total {targetName} Points: {questPoints}
					<br />
					Completed: {filteredCompleted.length}
					<br />
					In Progress: {filteredProgress.length}
				</p>
				<p>
					Easy Completed: {totalEasy}
					<br />
					Medium Completed: {totalMedium}
					<br />
					Hard Completed: {totalHard}
					<br />
					Elite Completed: {totalElite}
					<br />
					Master Completed: {totalMaster}
				</p>
			</IdlescapeContainer>
		</Box>
	);
}

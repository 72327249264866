// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { TForgeDataListClient } from '../../../game-server/src/modules/skills/smithing/Smithing.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const forges: TForgeDataListClient = {
	1: {
		forgeID: 1,
		forgeName: 'City Forge',
		image: '/images/smithing/city_forge.png',
		backgroundImage: '/images/smithing/city_forge_with_background.png',
		forgeLevelRequired: 1,
		forgeXPMult: 1,
		forgeHeatCost: 1,
		forgeMaterialCost: 1,
		forgeSpeedMult: 1,
		forgeBonusBars: 0,
		forgeIntensityHeatCostMult: 1.5,
		forgeIntensityMaterialCostMult: 1.1,
		forgeIntensitySpeedMult: 0.9,
		forgeIntensityBonusBars: 1.1,
		forgeMaxIntensity: 3,
		refiningStrengthMult: 4,
		refiningTable: [
			{ id: itemsIds.black_opal, chance: 0.01 }, // Black Opal
			{ id: itemsIds.diamond, chance: 0.05 }, // Diamond
			{ id: itemsIds.ruby, chance: 0.15 }, // Ruby
			{ id: itemsIds.emerald, chance: 0.3 }, // Emerald
			{ id: itemsIds.sapphire, chance: 0.45 }, // Sapphire
			// { id: itemsIds.iron_ore, chance: 0.9, minAmount: 100, maxAmount: 250 }, // Iron Ore
			{ id: itemsIds.silver, chance: 0.75, minAmount: 50, maxAmount: 100 }, // Silver
			// { id: itemsIds.mithril_ore, chance: 0.4, minAmount: 100, maxAmount: 175 }, // Mithril Ore
			{ id: itemsIds.metal_slag, chance: 0.95, minAmount: 100, maxAmount: 750 },
			{ id: itemsIds.inert_heat_stone, chance: 0.03, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.infernal_dust, chance: 0.0, minAmount: 1, maxAmount: 3 },
		],
	},
	2: {
		forgeID: 2,
		forgeName: 'Dwarven Forge',
		image: '/images/smithing/dwarven_forge.png',
		backgroundImage: '/images/smithing/dwarven_forge_with_background.png',
		forgeLevelRequired: 40,
		forgeXPMult: 1.5,
		forgeHeatCost: 1,
		forgeMaterialCost: 1.0,
		forgeSpeedMult: 1.5,
		forgeBonusBars: 0.5,
		forgeIntensityHeatCostMult: 1.2,
		forgeIntensityMaterialCostMult: 1.1,
		forgeIntensitySpeedMult: 1.1,
		forgeIntensityBonusBars: 1.25,
		forgeMaxIntensity: 6,
		refiningStrengthMult: 4,
		refiningTable: [
			{ id: itemsIds.black_opal, chance: 0.005 }, // Black Opal
			{ id: itemsIds.diamond, chance: 0.045 }, // Diamond
			{ id: itemsIds.ruby, chance: 0.12 }, // Ruby
			{ id: itemsIds.emerald, chance: 0.2, minAmount: 1, maxAmount: 2 }, // Emerald
			{ id: itemsIds.sapphire, chance: 0.3, minAmount: 1, maxAmount: 3 }, // Sapphire
			// { id: itemsIds.runite_ore, chance: 0.45, minAmount: 25, maxAmount: 100 }, // Runite Ore
			// { id: itemsIds.adamantite_ore, chance: 0.50, minAmount: 50, maxAmount: 150 }, // Adamantite Ore
			// { id: itemsIds.mithril_ore, chance: 0.58, minAmount: 75, maxAmount: 200 }, // Mithril Ore
			// { id: itemsIds.iron_ore, chance: 0.95, minAmount: 100, maxAmount: 400 }, // Iron Ore
			{ id: itemsIds.molten_ashes, chance: -2 / 666 }, // Molten Ashes
			{ id: itemsIds.inert_heat_stone, chance: 0.03, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.metal_slag, chance: 0.8, minAmount: 100, maxAmount: 1500 },
		],
	},
	3: {
		forgeID: 3,
		forgeName: 'Volcanic Forge',
		image: '/images/smithing/volcanic_forge.png',
		backgroundImage: '/images/smithing/volcanic_forge_with_background.png',
		forgeLevelRequired: 80,
		forgeXPMult: 1,
		forgeHeatCost: 3,
		forgeMaterialCost: 1.5,
		forgeSpeedMult: 1,
		forgeBonusBars: 1,
		forgeIntensityHeatCostMult: 1.8,
		forgeIntensityMaterialCostMult: 1.3,
		forgeIntensitySpeedMult: 1.25,
		forgeIntensityBonusBars: 1.5,
		forgeMaxIntensity: 9,
		refiningStrengthMult: 3,
		refiningTable: [
			{ id: itemsIds.black_opal, chance: 0.01 }, // Black Opal
			{ id: itemsIds.diamond, chance: 0.05 }, // Diamond
			{ id: itemsIds.ruby, chance: 0.15 }, // Ruby
			{ id: itemsIds.emerald, chance: 0.3 }, // Emerald
			{ id: itemsIds.sapphire, chance: 0.45 }, // Sapphire
			{ id: itemsIds.core_fragment, chance: -13 / 666, minAmount: 1, maxAmount: 5 }, // Core Fragment
			{ id: itemsIds.inert_heat_stone, chance: 0.03, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.metal_slag, chance: 0.5, minAmount: 200, maxAmount: 2500 },
		],
	},
};

// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { ILoot } from '../../../game-server/src/modules/items/LootInterface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import { leaguesIds } from '../utils/lookup-dictionaries/lookupLeagueList';
import { locationsIds } from '../utils/lookup-dictionaries/lookupLocationList';

export const locationsLootTable: Record<number, { loot?: ILoot[]; nodes?: Record<string, ILoot[]> }> = {
	[locationsIds.clay_pit]: {
		loot: [
			{ id: itemsIds.clay, frequency: 40, minAmount: 1 },
			{ id: itemsIds.copper_ore, frequency: 20, minAmount: 1 },
			{ id: itemsIds.tin_ore, frequency: 20, minAmount: 1 },
			{ id: itemsIds.stone, frequency: 10, minAmount: 1 },
			{ id: itemsIds.sand, frequency: 10, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.fat_nightcrawler_bait, frequency: 1, minAmount: 1 },
		],
	},
	[locationsIds.city_outskirts_mine]: {
		loot: [
			{ id: itemsIds.copper_ore, frequency: 30, minAmount: 1 },
			{ id: itemsIds.tin_ore, frequency: 30, minAmount: 1 },
			{ id: itemsIds.clay, frequency: 10, minAmount: 1, maxAmount: 3 },
			{ id: itemsIds.sand, frequency: 10, minAmount: 1, maxAmount: 3 },
			{ id: itemsIds.stone, frequency: 5, minAmount: 1, maxAmount: 3 },
			{ id: itemsIds.iron_ore, frequency: 5, minAmount: 1 },
			{ id: itemsIds.rune_slate, frequency: 5, minAmount: 1 },
			{ id: itemsIds.coal, frequency: 5, minAmount: 1 },
			{ id: itemsIds.fat_nightcrawler_bait, frequency: 1, minAmount: 1 },
		],
	},
	[locationsIds.village_quarry]: {
		loot: [
			{ id: itemsIds.stone, frequency: 25, minAmount: 1, maxAmount: 4 },
			{ id: itemsIds.iron_ore, frequency: 50, minAmount: 1 },
			{ id: itemsIds.coal, frequency: 25, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.fat_nightcrawler_bait, frequency: 1, minAmount: 1 },
		],
	},
	[locationsIds.desert_mine]: {
		loot: [
			{ id: itemsIds.sand, frequency: 20, minAmount: 1, maxAmount: 10 },
			{ id: itemsIds.mithril_ore, frequency: 20, minAmount: 1 },
			{ id: itemsIds.silver, frequency: 20, minAmount: 1 },
			{ id: itemsIds.coal, frequency: 10, minAmount: 1 },
			{ id: itemsIds.salt, frequency: 10, minAmount: 1 },
			{ id: itemsIds.rune_slate, frequency: 9, minAmount: 1 },
			{ id: itemsIds.iron_ore, frequency: 9, minAmount: 1 },
			{ id: itemsIds.gold_ore, frequency: 5, minAmount: 1 },
			{ id: itemsIds.clay, frequency: 3, minAmount: 1, maxAmount: 2 },
		],
	},
	[locationsIds.underground_mine]: {
		loot: [
			{ id: itemsIds.mithril_ore, frequency: 30, minAmount: 1 },
			{ id: itemsIds.gold_ore, frequency: 17.5, minAmount: 1 },
			{ id: itemsIds.silver, frequency: 12.5, minAmount: 1 },
			{ id: itemsIds.rune_slate, frequency: 15, minAmount: 1 },
			{ id: itemsIds.salt, frequency: 10, minAmount: 1, maxAmount: 3 },
			{ id: itemsIds.stone, frequency: 5, minAmount: 1, maxAmount: 3 },
			{ id: itemsIds.iron_ore, frequency: 5, minAmount: 1 },
			{ id: itemsIds.coal, frequency: 5, minAmount: 1 },
		],
	},
	[locationsIds.hidden_mine]: {
		loot: [
			{ id: itemsIds.adamantite_ore, frequency: 35, minAmount: 1 },
			{ id: itemsIds.silver, frequency: 20, minAmount: 1 },
			{ id: itemsIds.stone, frequency: 10, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.gold_ore, frequency: 10, minAmount: 1 },
			{ id: itemsIds.mithril_ore, frequency: 10, minAmount: 1 },
			{ id: itemsIds.runite_ore, frequency: 5, minAmount: 1 },
			{ id: itemsIds.rune_slate, frequency: 10, minAmount: 1 },
		],
	},
	[locationsIds.volcano_mine]: {
		loot: [
			{ id: itemsIds.silver, frequency: 20, minAmount: 1 },
			{ id: itemsIds.mithril_ore, frequency: 20, minAmount: 1 },
			{ id: itemsIds.adamantite_ore, frequency: 20, minAmount: 1 },
			{ id: itemsIds.runite_ore, frequency: 20, minAmount: 1 },
			{ id: itemsIds.rune_slate, frequency: 10, minAmount: 1 },
			{ id: itemsIds.gold_ore, frequency: 10, minAmount: 1 },
		],
	},
	[locationsIds.slate_spires]: {
		loot: [
			{ id: itemsIds.rune_slate, frequency: 50, minAmount: 5, maxAmount: 15 },
			{ id: itemsIds.stone, frequency: 10, minAmount: 2, maxAmount: 4 },
			{ id: itemsIds.sand, frequency: 10, minAmount: 2, maxAmount: 4 },
		],
	},
	[locationsIds.deep_pit]: {
		loot: [
			{ id: itemsIds.runite_ore, frequency: 30, minAmount: 1 },
			{ id: itemsIds.stone, frequency: 5, minAmount: 1 },
			{ id: itemsIds.clay, frequency: 5, minAmount: 1 },
			{ id: itemsIds.rune_slate, frequency: 25, minAmount: 1, maxAmount: 3 },
			{ id: itemsIds.gold_ore, frequency: 15, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.stygian_ore, frequency: 15, minAmount: 1 },
			{ id: itemsIds.void_stone, frequency: 5, minAmount: 1 },
		],
	},
	[locationsIds.grasslands]: {
		nodes: {
			'The Grasslands': [
				{ id: itemsIds.carrot_seed, frequency: 1.5, minAmount: 1 },
				{ id: itemsIds.honey, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.carrot, frequency: 1, minAmount: 1 },
				{ id: itemsIds.potato, frequency: 1, minAmount: 1 },
				{ id: itemsIds.potato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.wildberry, frequency: 10, minAmount: 1 },
				{ id: itemsIds.wildberry_bush_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.branch, frequency: 10, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.25, minAmount: 2, maxAmount: 4 },
			],
			'A Tree': [
				{ id: itemsIds.honey, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.branch, frequency: 10, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.25, minAmount: 2, maxAmount: 4 },
			],
			'A Large Tree': [
				{ id: itemsIds.honey, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.branch, frequency: 10, minAmount: 2, maxAmount: 3 },
				{ id: itemsIds.log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.25, minAmount: 2, maxAmount: 4 },
			],
			'Grassy Vegetables': [
				{ id: itemsIds.carrot_seed, frequency: 1, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.carrot, frequency: 10, minAmount: 1 },
				{ id: itemsIds.potato, frequency: 10, minAmount: 1 },
				{ id: itemsIds.potato_seed, frequency: 0.25, minAmount: 1 },
			],
			'Wildberry Bushes': [
				{ id: itemsIds.wildberry, frequency: 10, minAmount: 1 },
				{ id: itemsIds.wildberry_bush_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.5, minAmount: 2, maxAmount: 4 },
			],
			'Grassy Seeds': [
				{ id: itemsIds.carrot_seed, frequency: 0.25, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.potato_seed, frequency: 0.25, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.wildberry_bush_seed, frequency: 0.25, minAmount: 1, maxAmount: 2 },
			],
		},
	},
	[locationsIds.verdant_valley]: {
		nodes: {
			'The Verdant Valley': [
				{ id: itemsIds.honey, frequency: 1, minAmount: 1 },
				{ id: itemsIds.carrot, frequency: 2, minAmount: 1 },
				{ id: itemsIds.carrot_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.tomato, frequency: 2, minAmount: 1 },
				{ id: itemsIds.tomato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.wheat, frequency: 2, minAmount: 1 },
				{ id: itemsIds.wheat_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.branch, frequency: 10, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.oak_log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.25, minAmount: 2, maxAmount: 4 },
				{ id: itemsIds.watermelon, frequency: 1.5, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.pineapple, frequency: 3, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.fourleaf_clover, frequency: 0.25, minAmount: 1, maxAmount: 1 },
				{ id: itemsIds.onion, frequency: 4, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.mana_berry, frequency: 1, minAmount: 1, maxAmount: 1 },
			],
			'A Tree': [
				{ id: itemsIds.honey, frequency: 1, minAmount: 1 },
				{ id: itemsIds.branch, frequency: 10, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.tree_seed, frequency: 0.0625, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.25, minAmount: 2, maxAmount: 4 },
			],
			'Oak Tree': [
				{ id: itemsIds.honey, frequency: 1, minAmount: 1 },
				{ id: itemsIds.branch, frequency: 8, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.oak_log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.tree_seed, frequency: 0.0625, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.25, minAmount: 2, maxAmount: 4 },
			],
			'Copse of Verdant Trees': [
				{ id: itemsIds.honey, frequency: 1, minAmount: 2 },
				{ id: itemsIds.branch, frequency: 8, minAmount: 2, maxAmount: 3 },
				{ id: itemsIds.log, frequency: 10, minAmount: 2 },
				{ id: itemsIds.oak_log, frequency: 10, minAmount: 1 },
			],
			'Verdant Vegetables': [
				{ id: itemsIds.carrot, frequency: 8, minAmount: 1 },
				{ id: itemsIds.carrot_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.tomato, frequency: 8, minAmount: 1 },
				{ id: itemsIds.tomato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.5, minAmount: 2, maxAmount: 4 },
				{ id: itemsIds.watermelon, frequency: 1.5, minAmount: 1, maxAmount: 5 },
			],
			'Wild Wheat': [
				{ id: itemsIds.wheat, frequency: 8, minAmount: 1 },
				{ id: itemsIds.wheat_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.5, minAmount: 2, maxAmount: 4 },
			],
			'Verdant Seeds': [
				{ id: itemsIds.tree_seed, frequency: 0.0625, minAmount: 1 },
				{ id: itemsIds.carrot_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.tomato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.wheat_seed, frequency: 0.25, minAmount: 1 },
			],
			'Verdant Reagents': [
				{ id: itemsIds.watermelon, frequency: 1.5, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.pineapple, frequency: 3, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.fourleaf_clover, frequency: 0.25, minAmount: 1, maxAmount: 1 },
				{ id: itemsIds.onion, frequency: 4, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.mana_berry, frequency: 1, minAmount: 1, maxAmount: 1 },
			],
		},
	},
	[locationsIds.fungal_grotto]: {
		nodes: {
			'The Fungal Grotto': [
				{ id: itemsIds.mushroom, frequency: 1, minAmount: 1 },
				{ id: itemsIds.mushroom_spore, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.potato, frequency: 1, minAmount: 1 },
				{ id: itemsIds.potato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.carrot, frequency: 1, minAmount: 1 },
				{ id: itemsIds.carrot_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.branch, frequency: 5, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.oak_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.willow_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.banana_tree_seed, frequency: 0.06, minAmount: 1 },
				{ id: itemsIds.apple_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.25, minAmount: 2, maxAmount: 4 },
				{ id: itemsIds.glow_spore_shroom, frequency: 3, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.ghost_shrooms, frequency: 3, minAmount: 1, maxAmount: 5 },
			],
			'Lots of Fungi': [
				{ id: itemsIds.mushroom, frequency: 4, minAmount: 1 },
				{ id: itemsIds.mushroom_spore, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.125, minAmount: 2, maxAmount: 4 },
				{ id: itemsIds.glow_spore_shroom, frequency: 3, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.ghost_shrooms, frequency: 3, minAmount: 1, maxAmount: 5 },
			],
			"Carrots and 'Taters": [
				{ id: itemsIds.potato, frequency: 8, minAmount: 1 },
				{ id: itemsIds.potato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.carrot, frequency: 8, minAmount: 1 },
				{ id: itemsIds.carrot_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.125, minAmount: 2, maxAmount: 4 },
			],
			'A Seedy Place': [
				{ id: itemsIds.mushroom_spore, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.potato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.carrot_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.oak_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.willow_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.banana_tree_seed, frequency: 0.06, minAmount: 1 },
				{ id: itemsIds.apple_tree_seed, frequency: 0.125, minAmount: 1 },
			],
			'Lone Swamp Tree': [
				{ id: itemsIds.branch, frequency: 5, minAmount: 2, maxAmount: 3 },
				{ id: itemsIds.log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.oak_log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.willow_log, frequency: 10, minAmount: 1 },
			],
		},
	},
	[locationsIds.the_tangle]: {
		nodes: {
			'The Tangle': [
				{ id: itemsIds.chili_pepper, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.chili_pepper_seed, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.apple, frequency: 1, minAmount: 1 },
				{ id: itemsIds.apple_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.banana, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.banana_tree_seed, frequency: 0.06, minAmount: 1 },
				{ id: itemsIds.sugarcane_seed, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.branch, frequency: 5, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.oak_log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.willow_log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.maple_log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.25, minAmount: 2, maxAmount: 4 },
				{ id: itemsIds.tangle_root, frequency: 4, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.thorny_root, frequency: 4, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.thorny_vine, frequency: 4, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.yellow_pepper, frequency: 2, minAmount: 1 },
			],
			'Sweet Tooth': [
				{ id: itemsIds.apple, frequency: 1, minAmount: 1 },
				{ id: itemsIds.apple_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.banana, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.banana_tree_seed, frequency: 0.06, minAmount: 1 },
				{ id: itemsIds.sugarcane_seed, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.125, minAmount: 2, maxAmount: 4 },
			],
			'All Tangled Up': [
				{ id: itemsIds.branch, frequency: 5, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.oak_log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.willow_log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.maple_log, frequency: 10, minAmount: 1 },
			],
			'A Little Chili': [
				{ id: itemsIds.yellow_pepper, frequency: 1, minAmount: 1 },
				{ id: itemsIds.chili_pepper, frequency: 4, minAmount: 1 },
				{ id: itemsIds.chili_pepper_seed, frequency: 0.25, minAmount: 1 },
			],
			'Tangle of Seeds': [
				{ id: itemsIds.chili_pepper_seed, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.apple_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.banana_tree_seed, frequency: 0.06, minAmount: 1 },
				{ id: itemsIds.sugarcane_seed, frequency: 0.15, minAmount: 1 },
			],
			'Tangled Reagents': [
				{ id: itemsIds.tangle_root, frequency: 4, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.thorny_root, frequency: 4, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.thorny_vine, frequency: 4, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.yellow_pepper, frequency: 2, minAmount: 1, maxAmount: 5 },
			],
		},
	},
	[locationsIds.misty_marsh]: {
		nodes: {
			'The Misty Marsh': [
				{ id: itemsIds.potato, frequency: 1, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.potato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.pumpkin, frequency: 1, minAmount: 1 },
				{ id: itemsIds.pumpkin_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.rice, frequency: 1, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.rice_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.sugarcane_seed, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.oak_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.willow_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.oak_log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.willow_log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.glowbug_bait, frequency: 0.25, minAmount: 2, maxAmount: 4 },
				{ id: itemsIds.onion, frequency: 1, minAmount: 1, maxAmount: 5 },
			],
			'Misty Trees': [
				{ id: itemsIds.oak_log, frequency: 10, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.willow_log, frequency: 10, minAmount: 1, maxAmount: 3 },
			],
			'Misty Plants': [
				{ id: itemsIds.potato, frequency: 1, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.pumpkin, frequency: 1, minAmount: 1 },
				{ id: itemsIds.rice, frequency: 1, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.onion, frequency: 1, minAmount: 1, maxAmount: 5 },
			],
			'Misty Seeds': [
				{ id: itemsIds.potato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.pumpkin_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.rice_seed, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.sugarcane_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.oak_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.willow_tree_seed, frequency: 0.125, minAmount: 1 },
			],
		},
	},
	[locationsIds.frozen_tundra]: {
		nodes: {
			'The Frozen Tundra': [
				{ id: itemsIds.chili_pepper, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.chili_pepper_seed, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.wildberry, frequency: 1, minAmount: 1 },
				{ id: itemsIds.wildberry_bush_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.wheat, frequency: 1, minAmount: 1 },
				{ id: itemsIds.wheat_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.peppercorn_seed, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.maple_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.maple_log, frequency: 1, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.snow, frequency: 0.001, minAmount: 100, maxAmount: 200 },
			],
			'Thawed Oasis': [
				{ id: itemsIds.chili_pepper, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.yellow_pepper, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.chili_pepper_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.wildberry_bush_seed, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.wheat, frequency: 1, minAmount: 1 },
				{ id: itemsIds.wheat_seed, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.peppercorn_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.maple_tree_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.maple_log, frequency: 2, minAmount: 1, maxAmount: 5 },
			],
			'Squirrel Cache': [
				{ id: itemsIds.chili_pepper_seed, frequency: 0.8, minAmount: 1 },
				{ id: itemsIds.wildberry_bush_seed, frequency: 1, minAmount: 1 },
				{ id: itemsIds.wheat_seed, frequency: 1, minAmount: 1 },
				{ id: itemsIds.peppercorn_seed, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.maple_tree_seed, frequency: 0.5, minAmount: 1 },
			],
			'Frozen Reagents': [
				{ id: itemsIds.frost_flowers, frequency: 1, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.yellow_pepper, frequency: 0.5, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.black_berry, frequency: 1, minAmount: 1, maxAmount: 3 },
			],
		},
	},
	[locationsIds.haunted_woods]: {
		nodes: {
			'The Haunted Woods': [
				{ id: itemsIds.mushroom, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.mushroom_spore, frequency: 0.06, minAmount: 1 },
				{ id: itemsIds.pumpkin, frequency: 1, minAmount: 1 },
				{ id: itemsIds.pumpkin_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.peppercorn_seed, frequency: 0.06, minAmount: 1 },
				{ id: itemsIds.willow_tree_seed, frequency: 0.0625, minAmount: 1 },
				{ id: itemsIds.maple_tree_seed, frequency: 0.0625, minAmount: 1 },
				{ id: itemsIds.yew_tree_seed, frequency: 0.0625, minAmount: 1 },
				{ id: itemsIds.willow_log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.maple_log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.yew_log, frequency: 5, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.mana_berry, frequency: 0.25, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.thorny_root, frequency: 0.5, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.thorny_vine, frequency: 0.5, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.mandrake, frequency: 0.5, minAmount: 1, maxAmount: 3 },
			],
			"Woods' Bounty": [
				{ id: itemsIds.mushroom, frequency: 0.5, minAmount: 1 },
				{ id: itemsIds.mushroom_spore, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.pumpkin, frequency: 1, minAmount: 1 },
				{ id: itemsIds.pumpkin_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.peppercorn_seed, frequency: 0.15, minAmount: 1 },
			],
			'Gnarled Trees': [
				{ id: itemsIds.willow_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.maple_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.yew_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.willow_log, frequency: 5, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.maple_log, frequency: 5, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.yew_log, frequency: 10, minAmount: 1, maxAmount: 2 },
			],
			'Haunted Seeds': [
				{ id: itemsIds.willow_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.maple_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.yew_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.pumpkin_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.peppercorn_seed, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.mushroom_spore, frequency: 0.15, minAmount: 1 },
			],
			'Haunted Reagents': [
				{ id: itemsIds.mana_berry, frequency: 0.5, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.thorny_root, frequency: 1, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.thorny_vine, frequency: 1, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.mandrake, frequency: 1, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.black_berry, frequency: 1, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.fairy_dust, frequency: 1, minAmount: 1, maxAmount: 3 },
			],
		},
	},
	[locationsIds.living_forest]: {
		nodes: {
			'The Living Forest': [
				{ id: itemsIds.tomato, frequency: 1, minAmount: 1 },
				{ id: itemsIds.tomato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.apple_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.apple, frequency: 5, minAmount: 1 },
				{ id: itemsIds.banana_tree_seed, frequency: 0.1, minAmount: 1 },
				{ id: itemsIds.banana, frequency: 0.5, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.yew_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.willow_log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.maple_log, frequency: 10, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.yew_log, frequency: 5, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.mana_berry, frequency: 0.5, minAmount: 1, maxAmount: 4 },
				{ id: itemsIds.fairy_dust, frequency: 1, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.lotus_flower, frequency: 1, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.durian, frequency: 0.5, minAmount: 1, maxAmount: 5 },
			],
			'Healthy Living': [
				{ id: itemsIds.tomato, frequency: 1, minAmount: 1 },
				{ id: itemsIds.tomato_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.apple_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.apple, frequency: 1, minAmount: 1 },
				{ id: itemsIds.banana_tree_seed, frequency: 0.15, minAmount: 1 },
				{ id: itemsIds.banana, frequency: 0.25, minAmount: 1, maxAmount: 4 },
			],
			'The Young Trees': [
				{ id: itemsIds.yew_tree_seed, frequency: 0.125, minAmount: 1 },
				{ id: itemsIds.willow_log, frequency: 5, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.maple_log, frequency: 5, minAmount: 1, maxAmount: 2 },
				{ id: itemsIds.yew_log, frequency: 5, minAmount: 1, maxAmount: 2 },
			],
			'The Elder Tree': [
				{ id: itemsIds.yew_log, frequency: 20, minAmount: 1, maxAmount: 3 },
				{ id: itemsIds.elder_log, frequency: 10, minAmount: 1 },
				{ id: itemsIds.elder_tree_seed, frequency: 0.125, minAmount: 1 },
			],
			'Living Reagents': [
				{ id: itemsIds.fairy_dust, frequency: 1, minAmount: 1, maxAmount: 6 },
				{ id: itemsIds.lotus_flower, frequency: 0.5, minAmount: 1, maxAmount: 4 },
				{ id: itemsIds.durian, frequency: 0.25, minAmount: 1, maxAmount: 4 },
			],
			'Living Seeds': [
				{ id: itemsIds.elder_tree_seed, frequency: 0.25, minAmount: 1 },
				{ id: itemsIds.yew_tree_seed, frequency: 1, minAmount: 1 },
				{ id: itemsIds.apple_tree_seed, frequency: 1, minAmount: 1 },
				{ id: itemsIds.tomato_seed, frequency: 3, minAmount: 1 },
			],
		},
	},
	[locationsIds.shallow_pond]: {
		nodes: {
			'The Shallow Pond': [
				{ id: itemsIds.raw_shrimp, frequency: 100, maxFrequency: 150 },
				{ id: itemsIds.raw_anchovy, frequency: 50, maxFrequency: 100 },
				{ id: itemsIds.raw_magnetic_minnow, frequency: -10, maxFrequency: 18 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 15 },
				{ id: itemsIds.aqueous_grass, frequency: 2, maxFrequency: 40, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.aqueous_grass, frequency: -100, maxFrequency: 40, minAmount: 1, maxAmount: 10 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 9 },
			],
			'Oops, All Shrimp!': [{ id: itemsIds.raw_shrimp, frequency: 100 }],
			'Small Schools of Fish': [
				{ id: itemsIds.raw_anchovy, frequency: 25, maxFrequency: 75 },
				{ id: itemsIds.raw_magnetic_minnow, frequency: -5, maxFrequency: 22 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 3 },
				{ id: itemsIds.aqueous_grass, frequency: 2, maxFrequency: 10, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 9 },
			],
			'Drifting Around': [
				{ id: itemsIds.raw_anchovy, frequency: 15, maxFrequency: 50 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 5 },
				{ id: itemsIds.aqueous_grass, frequency: 2, maxFrequency: 10, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 25 },
			],
			'Shallow Flora': [
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 5 },
				{ id: itemsIds.aqueous_grass, frequency: 1, maxFrequency: 25, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.water_weeds, frequency: -5, maxFrequency: 45, minAmount: 1, maxAmount: 3 },
			],
		},
	},
	[locationsIds.lazy_river]: {
		nodes: {
			'The Lazy River': [
				{ id: itemsIds.raw_trout, frequency: 100, maxFrequency: 175 },
				{ id: itemsIds.raw_salmon, frequency: 50, maxFrequency: 100 },
				{ id: itemsIds.raw_tigerfish, frequency: -50, maxFrequency: 8 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 2 },
				{ id: itemsIds.aqueous_grass, frequency: 1, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.water_weeds, frequency: -5, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.aqueous_grass, frequency: -100, maxFrequency: 25, minAmount: 1, maxAmount: 10 },
				{ id: itemsIds.water_weeds, frequency: -100, maxFrequency: 25, minAmount: 1, maxAmount: 10 },
				{ id: itemsIds.raw_magnetic_minnow, frequency: 1, maxFrequency: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 15 },
				{ id: itemsIds.sturdy_driftwood, frequency: -5, maxFrequency: 5 },
			],
			'Migrating Schools': [
				{ id: itemsIds.raw_trout, frequency: 15, maxFrequency: 25 },
				{ id: itemsIds.raw_salmon, frequency: 50, maxFrequency: 75 },
				{ id: itemsIds.raw_tigerfish, frequency: -15, maxFrequency: 12 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 3 },
				{ id: itemsIds.aqueous_grass, frequency: 1, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.water_weeds, frequency: -5, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.raw_magnetic_minnow, frequency: 5, maxFrequency: 12 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 25 },
				{ id: itemsIds.sturdy_driftwood, frequency: -5, maxFrequency: 15 },
			],
			'ANGY bois': [
				{ id: itemsIds.raw_trout, frequency: 3, maxFrequency: 9 },
				{ id: itemsIds.raw_salmon, frequency: 6, maxFrequency: 16 },
				{ id: itemsIds.raw_tigerfish, frequency: 9, maxFrequency: 21 },
				{ id: itemsIds.rotten_driftwood, frequency: -1, maxFrequency: 20 },
				{ id: itemsIds.sturdy_driftwood, frequency: -1, maxFrequency: 30 },
				{ id: itemsIds.aquamarine, frequency: -50, maxFrequency: 1 },
			],
			'Riverbed Flora': [
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 5 },
				{ id: itemsIds.aqueous_grass, frequency: 1, maxFrequency: 10, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.water_weeds, frequency: -5, maxFrequency: 30, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.river_vines, frequency: -10, maxFrequency: 10, minAmount: 1, maxAmount: 3 },
			],
		},
	},
	[locationsIds.still_lake]: {
		nodes: {
			'The Still Lake': [
				{ id: itemsIds.raw_lobster, frequency: 100, maxFrequency: 150 },
				{ id: itemsIds.raw_crab, frequency: 10, maxFrequency: 50 },
				{ id: itemsIds.raw_slippery_eel, frequency: -25, maxFrequency: 12 },
				{ id: itemsIds.raw_tigerfish, frequency: -25, maxFrequency: 12 },
				{ id: itemsIds.raw_magnetic_minnow, frequency: 1, maxFrequency: 10 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 2 },
				{ id: itemsIds.water_weeds, frequency: 5, maxFrequency: 10, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.river_vines, frequency: -10, maxFrequency: 10, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: -10, maxFrequency: 10 },
				{ id: itemsIds.sturdy_driftwood, frequency: -10, maxFrequency: 10 },
			],
			'Big Catch!': [
				{ id: itemsIds.raw_lobster, frequency: 45, maxFrequency: 90 },
				{ id: itemsIds.raw_ancient_lobster, frequency: -35, maxFrequency: 15 },
				{ id: itemsIds.raw_slippery_eel, frequency: 5, maxFrequency: 12 },
				{ id: itemsIds.raw_tigerfish, frequency: -10, maxFrequency: 8 },
				{ id: itemsIds.raw_crab, frequency: 15, maxFrequency: 75 },
				{ id: itemsIds.aquamarine, frequency: -50, maxFrequency: 1 },
			],
			"Yedyod's Cove": [
				{ id: itemsIds.raw_ancient_lobster, frequency: 25, maxFrequency: 75 },
				{ id: itemsIds.raw_slippery_eel, frequency: 5, maxFrequency: 12 },
				{ id: itemsIds.raw_crab, frequency: 3, maxFrequency: 15 },
				{ id: itemsIds.raw_tigerfish, frequency: 10, maxFrequency: 30 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 5 },
				{ id: itemsIds.aqueous_grass, frequency: 1, maxFrequency: 5, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.water_weeds, frequency: -5, maxFrequency: 25, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.river_vines, frequency: -10, maxFrequency: 25, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 10 },
				{ id: itemsIds.sturdy_driftwood, frequency: 1, maxFrequency: 30 },
				{ id: itemsIds.aquamarine, frequency: -100, maxFrequency: 4 },
			],
			'Cage Match!': [
				{ id: itemsIds.raw_lobster, frequency: 50, maxFrequency: 100 },
				{ id: itemsIds.raw_crab, frequency: 25, maxFrequency: 100 },
				{ id: itemsIds.raw_ancient_lobster, frequency: -25, maxFrequency: 25 },
			],
			'Lake Flora': [
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 5 },
				{ id: itemsIds.aqueous_grass, frequency: 1, maxFrequency: 10, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.water_weeds, frequency: 1, maxFrequency: 25, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.river_vines, frequency: -5, maxFrequency: 25, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.violet_ribbons, frequency: -10, maxFrequency: 10, minAmount: 1, maxAmount: 3 },
			],
		},
	},
	[locationsIds.open_ocean]: {
		nodes: {
			'The Open Ocean': [
				{ id: itemsIds.raw_tuna, frequency: 100, maxFrequency: 150 },
				{ id: itemsIds.raw_shark, frequency: 50, maxFrequency: 100 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: 10, maxFrequency: 50 },
				{ id: itemsIds.raw_great_white_shark, frequency: -33, maxFrequency: 5 },
				{ id: itemsIds.raw_huge_tuna, frequency: -50, maxFrequency: 45 },
				{ id: itemsIds.raw_megalodon, frequency: -75, maxFrequency: 10 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 3 },
				{ id: itemsIds.river_vines, frequency: -10, maxFrequency: 25, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.violet_ribbons, frequency: -25, maxFrequency: 25, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 20 },
				{ id: itemsIds.sturdy_driftwood, frequency: 1, maxFrequency: 30 },
				{ id: itemsIds.aquamarine, frequency: -50, maxFrequency: 0.5 },
			],
			'Sharks Ate All The Tuna': [
				{ id: itemsIds.raw_tuna, frequency: -35, maxFrequency: 5 },
				{ id: itemsIds.raw_shark, frequency: 75, maxFrequency: 100 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: 15, maxFrequency: 33 },
				{ id: itemsIds.raw_megalodon, frequency: -50, maxFrequency: 20 },
				{ id: itemsIds.raw_great_white_shark, frequency: 10, maxFrequency: 12 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 8 },
				{ id: itemsIds.river_vines, frequency: -10, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.violet_ribbons, frequency: -25, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 15 },
				{ id: itemsIds.sturdy_driftwood, frequency: 1, maxFrequency: 25 },
			],
			'Almost Jurassic': [
				{ id: itemsIds.raw_great_white_shark, frequency: 1, maxFrequency: 8 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: 1, maxFrequency: 15 },
				{ id: itemsIds.raw_huge_tuna, frequency: 50, maxFrequency: 60 },
				{ id: itemsIds.raw_megalodon, frequency: 5, maxFrequency: 35 },
				{ id: itemsIds.raw_tentacle_meat, frequency: -100, maxFrequency: 10 },
				{ id: itemsIds.aquamarine, frequency: -75, maxFrequency: 1 },
			],
			'Is that a tentacle?': [
				{ id: itemsIds.raw_great_white_shark, frequency: 25, maxFrequency: 40 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: 5, maxFrequency: 45 },
				{ id: itemsIds.raw_megalodon, frequency: 15, maxFrequency: 25 },
				{ id: itemsIds.raw_tentacle_meat, frequency: -50, maxFrequency: 150 },
				{ id: itemsIds.aquamarine, frequency: -100, maxFrequency: 2 },
				{ id: itemsIds.mystical_driftwood, frequency: -15, maxFrequency: 5 },
			],
			'Carefree Day': [
				{ id: itemsIds.raw_tuna, frequency: 100, maxFrequency: 200 },
				{ id: itemsIds.raw_huge_tuna, frequency: -25, maxFrequency: 150 },
				{ id: itemsIds.seaweed, frequency: 3, maxFrequency: 8 },
				{ id: itemsIds.river_vines, frequency: -10, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.violet_ribbons, frequency: -25, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 3, maxFrequency: 25 },
				{ id: itemsIds.sturdy_driftwood, frequency: 3, maxFrequency: 30 },
				{ id: itemsIds.aquamarine, frequency: -30, maxFrequency: 2 },
				{ id: itemsIds.mystical_driftwood, frequency: -15, maxFrequency: 10 },
			],
			'Shipwreck Bay': [
				{ id: itemsIds.seaweed, frequency: 3, maxFrequency: 8 },
				{ id: itemsIds.river_vines, frequency: -10, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.violet_ribbons, frequency: -25, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 15, maxFrequency: 25 },
				{ id: itemsIds.sturdy_driftwood, frequency: 5, maxFrequency: 40 },
				{ id: itemsIds.aquamarine, frequency: -30, maxFrequency: 1.25 },
				{ id: itemsIds.mystical_driftwood, frequency: -1, maxFrequency: 15 },
				{ id: itemsIds.raw_megalodon, frequency: -25, maxFrequency: 50 },
				{ id: itemsIds.raw_great_white_shark, frequency: 3, maxFrequency: 40 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: 5, maxFrequency: 100 },
				{ id: itemsIds.raw_shark, frequency: 25, maxFrequency: 125 },
			],
			"Playtester's Paradise": [
				{ id: itemsIds.aquamarine, frequency: -30, maxFrequency: 1.5 },
				{ id: itemsIds.raw_megalodon, frequency: 5, maxFrequency: 50 },
				{ id: itemsIds.raw_great_white_shark, frequency: 5, maxFrequency: 12 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: 5, maxFrequency: 10 },
				{ id: itemsIds.mystical_driftwood, frequency: -1, maxFrequency: 20 },
				{ id: itemsIds.violet_ribbons, frequency: -5, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.white_reeds, frequency: -10, maxFrequency: 10, minAmount: 1, maxAmount: 3 },
			],
			'Ghost Ship': [
				{ id: itemsIds.sturdy_driftwood, frequency: 5, maxFrequency: 25 },
				{ id: itemsIds.mystical_driftwood, frequency: -1, maxFrequency: 50 },
				{ id: itemsIds.aquamarine, frequency: -30, maxFrequency: 2.5 },
				{ id: itemsIds.raw_megalodon, frequency: -25, maxFrequency: 50 },
				{ id: itemsIds.raw_great_white_shark, frequency: 3, maxFrequency: 40 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: 5, maxFrequency: 75 },
				{ id: itemsIds.raw_shark, frequency: 25, maxFrequency: 50 },
			],
			'Ocean Flora': [
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 5 },
				{ id: itemsIds.aqueous_grass, frequency: 1, maxFrequency: 5, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.water_weeds, frequency: 1, maxFrequency: 5, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.river_vines, frequency: 1, maxFrequency: 20, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.violet_ribbons, frequency: -5, maxFrequency: 20, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.white_reeds, frequency: -10, maxFrequency: 10, minAmount: 1, maxAmount: 3 },
			],
		},
	},
	[locationsIds.stormy_seas]: {
		nodes: {
			'The Stormy Seas': [
				{ id: itemsIds.raw_tuna, frequency: 25, maxFrequency: 50 },
				{ id: itemsIds.raw_shark, frequency: 50, maxFrequency: 75 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: 15, maxFrequency: 50 },
				{ id: itemsIds.raw_great_white_shark, frequency: -10, maxFrequency: 5 },
				{ id: itemsIds.raw_huge_tuna, frequency: -25, maxFrequency: 25 },
				{ id: itemsIds.raw_megalodon, frequency: -50, maxFrequency: 5 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 5 },
				{ id: itemsIds.violet_ribbons, frequency: 1, maxFrequency: 10, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.white_reeds, frequency: -5, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 20 },
				{ id: itemsIds.sturdy_driftwood, frequency: 1, maxFrequency: 20 },
				{ id: itemsIds.aquamarine, frequency: -45, maxFrequency: 3 },
				{ id: itemsIds.mystical_driftwood, frequency: -35, maxFrequency: 15 },
			],
			'Eye of the Storm': [
				{ id: itemsIds.seaweed, frequency: 3, maxFrequency: 15 },
				{ id: itemsIds.violet_ribbons, frequency: 1, maxFrequency: 9, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.white_reeds, frequency: -5, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.rotten_driftwood, frequency: 15, maxFrequency: 25 },
				{ id: itemsIds.sturdy_driftwood, frequency: 5, maxFrequency: 40 },
				{ id: itemsIds.aquamarine, frequency: -30, maxFrequency: 2 },
				{ id: itemsIds.raw_megalodon, frequency: -25, maxFrequency: 10 },
				{ id: itemsIds.raw_great_white_shark, frequency: -20, maxFrequency: 5 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: -15, maxFrequency: 10 },
				{ id: itemsIds.raw_shark, frequency: 10, maxFrequency: 25 },
				{ id: itemsIds.raw_tuna, frequency: 25, maxFrequency: 50 },
				{ id: itemsIds.raw_huge_tuna, frequency: 5, maxFrequency: 15 },
			],
			'Endless Vortex': [
				{ id: itemsIds.raw_megalodon, frequency: 15, maxFrequency: 100 },
				{ id: itemsIds.raw_tentacle_meat, frequency: 50, maxFrequency: 250 },
				{ id: itemsIds.aquamarine, frequency: -100, maxFrequency: 5 },
				{ id: itemsIds.mystical_driftwood, frequency: -1, maxFrequency: 25 },
			],
			"INFO's Stand": [
				{ id: itemsIds.raw_tentacle_meat, frequency: 25, maxFrequency: 150 },
				{ id: itemsIds.aquamarine, frequency: -150, maxFrequency: 100 },
				{ id: itemsIds.mystical_driftwood, frequency: -120, maxFrequency: 100 },
			],
			"World's End": [
				{ id: itemsIds.raw_tuna, frequency: 1, maxFrequency: 33 },
				{ id: itemsIds.raw_shark, frequency: 1, maxFrequency: 40 },
				{ id: itemsIds.raw_hammerhead_shark, frequency: 15, maxFrequency: 65 },
				{ id: itemsIds.raw_great_white_shark, frequency: -25, maxFrequency: 12 },
				{ id: itemsIds.raw_huge_tuna, frequency: -33, maxFrequency: 35 },
				{ id: itemsIds.raw_megalodon, frequency: -50, maxFrequency: 15 },
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 2 },
				{ id: itemsIds.violet_ribbons, frequency: 1, maxFrequency: 9, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.white_reeds, frequency: 1, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.ancient_kelp, frequency: 1, maxFrequency: 25 },
				{ id: itemsIds.rotten_driftwood, frequency: 1, maxFrequency: 3 },
				{ id: itemsIds.sturdy_driftwood, frequency: 1, maxFrequency: 3 },
				{ id: itemsIds.aquamarine, frequency: -45, maxFrequency: 4 },
				{ id: itemsIds.mystical_driftwood, frequency: -40, maxFrequency: 20 },
			],
			'Deep Sea Flora': [
				{ id: itemsIds.seaweed, frequency: 1, maxFrequency: 5 },
				{ id: itemsIds.aqueous_grass, frequency: 1, maxFrequency: 3, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.water_weeds, frequency: 1, maxFrequency: 6, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.river_vines, frequency: 1, maxFrequency: 9, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.violet_ribbons, frequency: 1, maxFrequency: 15, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.white_reeds, frequency: -15, maxFrequency: 25, minAmount: 1, maxAmount: 5 },
				{ id: itemsIds.ancient_kelp, frequency: -20, maxFrequency: 40, minAmount: 1, maxAmount: 3 },
			],
		},
	},
	
};

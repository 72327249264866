// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { TEnchantmentListClient } from '../../../game-server/src/modules/enchantment/enchantment.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const enchantmentsList: TEnchantmentListClient = {
	0: {
		id: 0,
		name: 'Unknown',
		strengthPerLevel: 0,
		relatedSkills: ['total'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/misc/unknown_buff.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) => `This isn't an enchantment. What are you doing?`,
		// endclient
	}, // no enchantment
	1: {
		id: 1,
		name: 'Embers',
		strengthPerLevel: 0.1,
		relatedSkills: ['foraging'],
		scrollID: itemsIds.scroll_of_embers,
		// client
		buffIcon: '/images/magic/buffs/embers_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to additionally gain the heat from a burnable when gathering it without burning the item.`,
		// endclient
	}, // log burning
	2: {
		id: 2,
		name: 'Prospecting',
		strengthPerLevel: 0.5,
		relatedSkills: ['mining'],
		scrollID: itemsIds.scroll_of_the_prospector,
		// client
		buffIcon: '/images/magic/buffs/prospector_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increase your chance to find gems or silver while mining by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Has a 10% chance to consume an earth rune on success. Attempts to pull the rune from your stockpile then ammo pouch if none found.`,
		// endclient
	}, // gem chance
	3: {
		id: 3,
		name: 'Scholar',
		strengthPerLevel: 0.2,
		relatedSkills: ['gathering', 'production'],
		ignoredByMadness: true,
		scrollID: itemsIds.scroll_of_the_scholar,
		// client
		buffIcon: '/images/magic/buffs/scholar_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases experience gained from gathering and production by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% but destroys the item in process. Does not affect Crafting or stack with Wealth. Only active for mastery experience, when a skill is at level 99.`,
		// endclient
	}, // exp without resources
	4: {
		id: 4,
		name: 'Gathering',
		strengthPerLevel: 0.1,
		relatedSkills: ['gathering', 'mining', 'foraging', 'fishing'],
		scrollID: itemsIds.scroll_of_gathering,
		// client
		buffIcon: '/images/magic/buffs/gathering_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to find an extra resource while gathering. There is a 15% chance that a nature rune is consumed when it procs. Attempts to pull the rune from your stockpile then ammo pouch if none found.`,
		// endclient
	}, // double resources
	5: {
		id: 5,
		name: 'Superheating',
		strengthPerLevel: 0.01,
		relatedSkills: ['mining'],
		scrollID: itemsIds.scroll_of_superheating,
		// client
		buffIcon: '/images/magic/buffs/superheated_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Items with higher heat costs to process have an increased chance to be gathered. Has a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to consume a fire rune every mining action. Attempts to pull the rune from your stockpile then ammo pouch if none found.`,
		// endclient
	}, // smelt when mining
	6: {
		id: 6,
		name: 'Pyromancy',
		strengthPerLevel: 0.05,
		strengthCap: 0.8,
		relatedSkills: ['production', 'cooking', 'smithing'],
		scrollID: itemsIds.scroll_of_pyromancy,
		// client
		buffIcon: '/images/magic/buffs/pyromancy.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Use ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% less heat when cooking and smithing. Caps at 80% reduction.`,
		// endclient
	}, // less heat used
	7: {
		id: 7,
		name: 'Wealth',
		strengthPerLevel: 0.2,
		relatedSkills: ['production', 'gathering'],
		ignoredByMadness: true,
		scrollID: itemsIds.scroll_of_wealth,
		// client
		buffIcon: '/images/magic/buffs/wealth_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Automatically sells gathering and production items for ${Math.round(
				(1 + enchantmentStrength * strengthPerLevel) * 100
			)}% of their vendor price.
                        Does not affect Crafting or stack with Scholar.`,
		// endclient
	}, // auto sell for more
	8: {
		id: 8,
		name: 'Haste',
		strengthPerLevel: 0.04,
		strengthCap: 1.0,
		relatedSkills: ['skilling'],
		scrollID: itemsIds.scroll_of_haste,
		// client
		buffIcon: '/images/magic/buffs/haste_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases non-combat action speed by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Caps at halving action time. Stacks with Empowered Haste.`,
		// endclient
	}, // gathering speed
	9: {
		id: 9,
		name: 'Naturalist',
		strengthPerLevel: 0.2,
		relatedSkills: ['gathering', 'mining', 'foraging', 'fishing'],
		scrollID: itemsIds.scroll_of_naturalist,
		// client
		buffIcon: '/images/magic/buffs/naturalist_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increase your chance to gain chests as well as convert standard chests to their greater variants while gathering by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%.`,
		// endclient
	}, // chest chance
	10: {
		id: 10,
		name: 'Acrobatics',
		strengthPerLevel: 0.03,
		relatedSkills: ['combat', 'attack'],
		scrollID: itemsIds.scroll_of_acrobatics,
		// client
		buffIcon: '/images/magic/buffs/patience_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Has a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to speed up attack speed by 33%.`,
		// endclient
	}, // acrobatics, speed up attacks
	11: {
		id: 11,
		name: 'Critical Strike',
		strengthPerLevel: 0.025,
		relatedSkills: ['attack'],
		statBuffs: [
			{ path: 'offensiveCritical.chance', additivePerLevel: 0.025, multiplicativePerLevel: 0 },
			{ path: 'offensiveCritical.damageMultiplier', additivePerLevel: 0.025, multiplicativePerLevel: 0 },
		],
		scrollID: itemsIds.scroll_of_critical_strike,
		// client
		buffIcon: '/images/magic/buffs/critical_strike_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases critical chance and damage multiplier by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(1)}%.`,
		// endclient
	}, // critical strike
	12: {
		id: 12,
		name: 'Poisoning',
		strengthPerLevel: 1,
		relatedSkills: ['attack'],
		scrollID: itemsIds.scroll_of_poisoning,
		isDebuff: true,
		// client
		buffIcon: '/images/misc/unknown_buff.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Apply poison for ${enchantmentStrength * strengthPerLevel} damage per second.`,
		// endclient
	}, // poisoning, apply poison
	13: {
		id: 13,
		name: 'Accuracy',
		strengthPerLevel: 0.025,
		relatedSkills: ['attack'],
		scrollID: itemsIds.scroll_of_accuracy,
		statBuffs: [
			{ path: 'offensiveAccuracyAffinityRating.Melee', additivePerLevel: 0, multiplicativePerLevel: 0.025 },
			{ path: 'offensiveAccuracyAffinityRating.Magic', additivePerLevel: 0, multiplicativePerLevel: 0.025 },
			{ path: 'offensiveAccuracyAffinityRating.Range', additivePerLevel: 0, multiplicativePerLevel: 0.025 },
		],
		// client
		buffIcon: '/images/magic/buffs/accuracy_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases accuracy for all three combat styles by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% multiplicatively.`,
		// endclient
	}, // increased hit accuracy
	14: {
		id: 14,
		name: 'Deflect',
		strengthPerLevel: 0.1,
		strengthCap: 0.5,
		relatedSkills: ['defense'],
		scrollID: itemsIds.scroll_of_deflect,
		// client
		buffIcon: '/images/magic/buffs/force_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases damage by and reflects ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% of damage back onto the attacker. Caps at 50%.`,
		// endclient
	}, // deflect on hit
	15: {
		id: 15,
		name: 'Force',
		strengthPerLevel: 0.01,
		strengthCap: 1,
		relatedSkills: ['strength'],
		scrollID: itemsIds.scroll_of_force,
		// client
		buffIcon: '/images/magic/buffs/force_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% of your protection as strength. Caps at 100%.`,
		// endclient
	}, // defenses to offensive shield
	16: {
		id: 16,
		name: 'Healing',
		strengthPerLevel: 0.2,
		relatedSkills: ['constitution'],
		scrollID: itemsIds.scroll_of_healing,
		// client
		buffIcon: '/images/magic/buffs/healing_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Passive heal per tick increased by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Increases health received from consumables by ${Math.round(
				enchantmentStrength * strengthPerLevel * 0.5 * 100
			)}%.`,
		// endclient
	}, // boost health regen
	17: {
		id: 17,
		name: 'Weakening',
		strengthPerLevel: 1,
		relatedSkills: ['total'],
		ignoredByMadness: true,
		scrollID: itemsIds.scroll_of_weakening,
		// client
		buffIcon: '/images/magic/buffs/weakening_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases the level requirements of the item by ${Math.round(enchantmentStrength * strengthPerLevel)}.`,
		// endclient
	}, // decrease item level req
	18: {
		id: 18,
		name: 'Reinforcement',
		strengthPerLevel: 12,
		relatedSkills: ['defense', 'range'],
		scrollID: itemsIds.scroll_of_reinforcement,
		statBuffs: [{ path: 'armor.agility', additivePerLevel: 12, multiplicativePerLevel: 0 }],
		// client
		buffIcon: '/images/magic/buffs/reinforcement_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases agility by ${Math.round(enchantmentStrength * strengthPerLevel)}.`,
		// endclient
	}, // decrease enemy accuracy
	19: {
		id: 19,
		name: 'Protection',
		strengthPerLevel: 10,
		relatedSkills: ['defense', 'strength'],
		scrollID: itemsIds.scroll_of_protection,
		statBuffs: [{ path: 'armor.protection', additivePerLevel: 10, multiplicativePerLevel: 0 }],
		// client
		buffIcon: '/images/magic/buffs/protection_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases protection by ${Math.round(enchantmentStrength * strengthPerLevel)}.`,
		// endclient
	}, // reduce damage taken
	20: {
		id: 20,
		name: 'Parry',
		strengthPerLevel: 0.01,
		strengthCap: 0.5,
		relatedSkills: ['combat', 'defense', 'attack'],
		scrollID: itemsIds.scroll_of_parry,
		// client
		buffIcon: '/images/magic/buffs/recklessness_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to reduce damage taken by 75% and cast your next ability. Caps at 50%.`,
		// endclient
	}, // parry
	21: {
		id: 21,
		name: 'False Equilibrium',
		strengthPerLevel: 0.015,
		strengthCap: 0.8,
		relatedSkills: ['attack'],
		scrollID: itemsIds.scroll_of_false_equilibrium,
		// client
		buffIcon: '/images/magic/buffs/recklessness_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases maximum damage and decreases minimum damage by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(1)}%. Caps at 80%.`,
		// endclient
	}, // False Equilibrium, increases max and decreases min damage
	22: {
		id: 22,
		name: 'Efficiency',
		strengthPerLevel: 0.01,
		relatedSkills: ['production'],
		scrollID: itemsIds.scroll_of_efficiency,
		// client
		buffIcon: '/images/magic/buffs/efficiency_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to produce an extra item during cooking, smithing, scrollcrafting, and weaving fibers.`,
		// endclient
	}, // efficiency, chance to produce extra item
	23: {
		id: 23,
		name: 'Fishing',
		strengthPerLevel: 0.025,
		relatedSkills: ['fishing'],
		scrollID: itemsIds.scroll_of_fishing,
		// client
		buffIcon: '/images/magic/buffs/fishing.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases fish catch chance by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(1)}%.`,
		// endclient
	}, // fishing, increased catch rate
	24: {
		id: 24,
		name: 'Cooking',
		strengthPerLevel: 0.04,
		relatedSkills: ['cooking'],
		scrollID: itemsIds.scroll_of_cooking,
		// client
		buffIcon: '/images/magic/buffs/cooking_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decrease chance to burn food while cooking by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%.`,
		// endclient
	}, // cooking, reduced burn rate
	25: {
		id: 25,
		name: 'Crafting',
		strengthPerLevel: 0.02,
		relatedSkills: ['crafting'],
		scrollID: itemsIds.scroll_of_crafting,
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/crafting_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			// `Each crafted item costs ${Math.round(enchantmentStrength * strengthPerLevel * 100)}% less ingredients`,
			`Replaced by a quest talent reward. Give us ideas for its replacement!`,
		// endclient
	}, // crafting, reduced cost
	26: {
		id: 26,
		name: 'Refining',
		strengthPerLevel: 2,
		relatedSkills: ['smithing'],
		scrollID: itemsIds.scroll_of_refining,
		// client
		buffIcon: '/images/magic/buffs/refining_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a mysterious chance to find gems and other rare materials while smithing. Each forge is designed to
			extract different materials. The rarest of materials requires the hottest of forges and highest tier
			ores to be smelted.`,
		// endclient
	}, // refining, forge specific items while smithing
	27: {
		id: 27,
		name: 'Runecrafting',
		strengthPerLevel: 0.05,
		strengthCap: 0.8,
		relatedSkills: ['runecrafting'],
		scrollID: itemsIds.scroll_of_runecrafting,
		// client
		buffIcon: '/images/magic/buffs/runecrafting_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Reduces essence used in Runecrafting by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Caps at 80%.`,
		// endclient
	}, // runecrafting, reduces essence usage
	28: {
		id: 28,
		name: 'Chances',
		strengthPerLevel: 0.01,
		relatedSkills: ['enchanting'],
		scrollID: itemsIds.scroll_of_chances,
		// client
		buffIcon: '/images/magic/buffs/chances-icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Multiplies the chance to transform an item by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(
				0
			)}%. If an item has a 10% chance to transform and you have a 10% bonus transform chance, it becomes a 11% chance to transform.`,
		// endclient
	}, // chances, increased chance of augmentation
	29: {
		id: 29,
		name: 'Shrimp Lord',
		strengthPerLevel: 0.05,
		relatedSkills: ['cooking'],
		scrollID: itemsIds.scroll_of_the_shrimp_lord,
		// client
		buffIcon: '/images/magic/buffs/shrimlord_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance per cooking action to gain one Raw Shrimp.`,
		// endclient
	}, // Shrimp Lord, chance to get shrimp while cooking
	30: {
		id: 30,
		name: 'Enlightenment',
		strengthPerLevel: 1,
		relatedSkills: ['total', 'skilling'],
		scrollID: itemsIds.scroll_of_enlightenment,
		// client
		buffIcon: '/images/magic/buffs/enlightenment_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain ${Math.round(
				enchantmentStrength * strengthPerLevel
			)} more essence per action where essence is acquired.`,
		// endclient
	}, // enlightment, gain more essence
	31: {
		id: 31,
		name: 'Prolonging',
		strengthPerLevel: 0.25,
		relatedSkills: ['total'],
		scrollID: itemsIds.scroll_of_prolonging,
		ignoreProlonging: true, // otherwise it will loop forever
		// client
		buffIcon: '/images/magic/buffs/prolonging_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				(1 - 1 / (enchantmentStrength * strengthPerLevel + 1)) * 100
			)}% chance to not consume a buff stack (resulting in ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% more uses from a buff on average).`,
		// endclient
	}, // boosting, more actions
	32: {
		id: 32,
		name: 'Treasure Hunter',
		strengthPerLevel: 1,
		relatedSkills: ['combat'],
		scrollID: itemsIds.scroll_of_the_treasure_hunter,
		// client
		buffIcon: '/images/magic/buffs/treasurehunter_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your chance of finding items from slain enemies by ${Math.round(enchantmentStrength * 3)}%.`,
		// endclient
	}, // treasure hunter, rare item from combat
	33: {
		id: 33,
		name: 'Inferno',
		strengthPerLevel: 0.04,
		strengthCap: 0.8,
		relatedSkills: ['production', 'smithing', 'cooking'],
		scrollID: itemsIds.scroll_of_inferno,
		// client
		buffIcon: '/images/magic/buffs/inferno_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance per smithing/cooking action to complete another smithing/cooking action.
			Caps at 80% strength and 5 consecutive procs.`,
		// endclient
	}, // inferno, smelt ore, costs heat
	34: {
		id: 34,
		name: 'Destructive Testing',
		strengthPerLevel: 5,
		relatedSkills: ['mining'],
		scrollID: itemsIds.scroll_of_destructive_testing,
		// client
		buffIcon: '/images/magic/buffs/destructivetesting_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Sand, Stone and Clay mined will be destroyed and provide ${Math.round(
				enchantmentStrength * strengthPerLevel
			)} mining experience per piece. Scholar rolls first and does not stack.`,
		// endclient
	}, // destructive testing
	35: {
		id: 35,
		name: 'Nature',
		strengthPerLevel: 1,
		relatedSkills: ['foraging'],
		scrollID: itemsIds.scroll_of_nature,
		// client
		buffIcon: '/images/magic/buffs/nature_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Access hidden tree nodes and greatly increase your chance to find a tree related node while Foraging.`,
		// endclient
	}, // nature, bonus logs
	36: {
		id: 36,
		name: 'Root Digging',
		strengthPerLevel: 0.5,
		relatedSkills: ['foraging'],
		scrollID: itemsIds.scroll_of_root_digging,
		// client
		buffIcon: '/images/magic/buffs/rootdigging_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a chance to find gems while foraging. Stronger enchantments are stronger chances. Has a 10% chance to consume an earth rune on success. Attempts to pull the rune from your stockpile then ammo pouch if none found.`,
		// endclient
	}, // root digging, gems from wc
	37: {
		id: 37,
		name: 'Patience',
		strengthPerLevel: 3,
		relatedSkills: ['attack'],
		scrollID: itemsIds.scroll_of_patience,
		// client
		buffIcon: '/images/magic/buffs/patience_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			'Ability cast speed increases damage dealt with slower abilities getting a larger bonus damage. Bonus is capped at either 50% faster or slower than your base speed. For example, if your base speed is 3s, then the cap is 1.5s or 4.5s when calculating bonus damage. Slower base attack speeds result in higher potential damage bonuses. Always increases damage dealt; it will never decrease your damage.',
		// endclient
	}, // patience, increases damage by attack speed
	38: {
		id: 38,
		name: 'Overhealing',
		strengthPerLevel: 0.01,
		relatedSkills: ['combat', 'constitution'],
		scrollID: itemsIds.scroll_of_overhealing,
		// client
		buffIcon: '/images/magic/buffs/overhealing_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Heal for ${Math.round(enchantmentStrength * strengthPerLevel * 100)}% of overkill damage on a target.`,
		// endclient
	}, // overhealing, returns overkill damage as health
	39: {
		id: 39,
		name: 'Farming',
		strengthPerLevel: 0.02,
		relatedSkills: ['farming'],
		scrollID: itemsIds.scroll_of_farming,
		// client
		buffIcon: '/images/magic/buffs/farming_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to get double the yield when harvesting.`,
		// endclient
	}, // double yield when harvesting."
	40: {
		id: 40,
		name: 'Gold Digger',
		strengthPerLevel: 2000,
		relatedSkills: ['farming'],
		scrollID: itemsIds.scroll_of_the_gold_digger,
		// client
		buffIcon: '/images/magic/buffs/golddigger_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a fixed 10% chance per harvested plant to get ${Math.round(
				enchantmentStrength * strengthPerLevel
			)} Gold Coins.`,
		// endclient
	}, // gold digger, chance to get gold when farming
	41: {
		id: 41,
		name: 'Fertilizing',
		strengthPerLevel: 0.06,
		relatedSkills: ['farming'],
		scrollID: itemsIds.scroll_of_fertilizing,
		// client
		buffIcon: '/images/magic/buffs/fertilizing_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increase minimum harvest yield by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	}, // fertilizing, gain the effect of fertilizer
	42: {
		id: 42,
		name: 'Cultivation',
		strengthPerLevel: 0.02,
		relatedSkills: ['farming'],
		scrollID: itemsIds.scroll_of_cultivation,
		// client
		buffIcon: '/images/magic/buffs/cultivation_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to get a seed back when harvesting.`,
		// endclient
	}, // chance to get seed back
	43: {
		id: 43,
		name: 'Corruption',
		strengthPerLevel: 40,
		relatedSkills: ['constitution'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/cultivation_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Consumes ichor received in combat to heal for ${strengthPerLevel * enchantmentStrength} health.`,
		// endclient
	}, // Corruption, eats ichor
	44: {
		id: 44,
		name: 'Fortitude',
		strengthPerLevel: 10,
		scrollID: itemsIds.scroll_of_fortitude,
		relatedSkills: ['constitution'],
		statBuffs: [{ path: 'armor.stamina', additivePerLevel: 10, multiplicativePerLevel: 0 }],
		// client
		buffIcon: '/images/magic/buffs/overhealing_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases stamina by ${strengthPerLevel * enchantmentStrength}.`,
		// endclient
	}, // Fortitude, increases max hp
	45: {
		id: 45,
		name: 'Aspect of Flame',
		strengthPerLevel: 0.05,
		relatedSkills: ['magic'],
		damageConversion: 'Fire',
		// client
		buffIcon: '/images/magic/buffs/aspect_of_fire.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Converts a part of the damage from basic auto attacks to fire damage. Adds a ${(
				1 +
				enchantmentStrength * strengthPerLevel
			).toFixed(2)}x fire affinity to all basic auto attacks.`,
		// endclient
	}, // Aspect of Flame, swaps auto attack to fire
	46: {
		id: 46,
		name: 'Aspect of Lightning',
		strengthPerLevel: 0.05,
		relatedSkills: ['strength'],
		damageConversion: 'Lightning',
		// client
		buffIcon: '/images/magic/buffs/aspect_of_air.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Converts a part of the damage from basic auto attacks to lightning damage. Adds a ${(
				1 +
				enchantmentStrength * strengthPerLevel
			).toFixed(2)}x lightning affinity to all basic auto attacks.`,
		// endclient
	}, // Aspect of Lightning, swaps auto attack to lightning
	47: {
		id: 47,
		name: 'Aspect of Chaos',
		strengthPerLevel: 0.05,
		relatedSkills: ['strength', 'magic'],
		damageConversion: 'Chaos',
		// client
		buffIcon: '/images/magic/buffs/aspect_of_chaos.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Converts a part of the damage from basic auto attacks to chaos damage. Adds a ${(
				1 +
				enchantmentStrength * strengthPerLevel
			).toFixed(2)}x chaos affinity to all basic auto attacks.`,
		// endclient
	}, // Aspect of Chaos, swaps auto attack to chaos,
	48: {
		id: 48,
		name: 'Fishing Magnetism',
		strengthPerLevel: 2,
		relatedSkills: ['fishing'],
		scrollID: itemsIds.scroll_of_fishing_magnetism,
		// client
		buffIcon: '/images/magic/buffs/fishingmagnetism_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases bonus rarity by ${
				enchantmentStrength * strengthPerLevel
			} and decreases both bait and reel power by the same amount. Increases chance to find known rare nodes.`,
		// endclient
	}, // fishing bonus rarity, increases bonus, decreases bait and reel
	49: {
		id: 49,
		name: 'Pungent Bait',
		strengthPerLevel: 3,
		relatedSkills: ['fishing'],
		scrollID: itemsIds.scroll_of_pungent_bait,
		// client
		buffIcon: '/images/magic/buffs/pungentbait_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases bait power by ${enchantmentStrength * strengthPerLevel}.`,
		// endclient
	}, // fishing bait power buff
	50: {
		id: 50,
		name: 'Reinforced Line',
		strengthPerLevel: 3,
		scrollID: itemsIds.scroll_of_reinforced_line,
		relatedSkills: ['fishing'],
		// client
		buffIcon: '/images/magic/buffs/reinforcedline_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases reel power by ${enchantmentStrength * strengthPerLevel}.`,
		// endclient
	}, // fishing reel power buff
	51: {
		id: 51,
		name: 'Bait Preservation',
		strengthPerLevel: 0.04,
		scrollID: itemsIds.scroll_of_bait_preservation,
		relatedSkills: ['fishing'],
		// client
		buffIcon: '/images/magic/buffs/preservation_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases chance to preserve bait by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	}, // chance to keep bait from being consumed
	52: {
		id: 52,
		name: 'Oil Spill',
		strengthPerLevel: 0.075,
		scrollID: itemsIds.scroll_of_oil_spill,
		relatedSkills: ['fishing'],
		// client
		buffIcon: '/images/magic/buffs/oilspill_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to try and extract fish oil from a fish based on the fish's size. There is a chance that fish oil may not be created if the fish is too small.`,
		// endclient
	}, // chance to roll for fish oil
	53: {
		id: 53,
		name: 'Deadliest Catch',
		strengthPerLevel: 0.05,
		scrollID: itemsIds.scroll_of_deadliest_catch,
		relatedSkills: ['fishing'],
		// client
		buffIcon: '/images/magic/buffs/deadliestcatch_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases bonuses from using bait by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% but decreases bait preservation chance by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Increases chance to find known rare resources.`,
		// endclient
	}, // enhances fishing bait but makes it more likely to be consumed
	54: {
		id: 54,
		name: 'Master Chef',
		strengthPerLevel: 0.04,
		scrollID: itemsIds.scroll_of_the_master_chef,
		relatedSkills: ['cooking'],
		// client
		buffIcon: '/images/magic/buffs/masterchef_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases chance to burn food while cooking by ${(enchantmentStrength * strengthPerLevel * 1).toFixed(
				2
			)}% of your effective cooking level.`,
		// endclient
	}, // decreases burn chance by cooking level
	55: {
		id: 55,
		name: 'Herbalist',
		strengthPerLevel: 1,
		scrollID: itemsIds.scroll_of_the_herbalist,
		relatedSkills: ['foraging'],
		// client
		buffIcon: '/images/magic/buffs/herbalist.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Access hidden plant nodes and greatly increase your chance to find a plant related node while Foraging.`,
		// endclient
	}, // herbalist, plants are more likely to be found
	56: {
		id: 56,
		name: 'Seed Harvesting',
		strengthPerLevel: 1,
		scrollID: itemsIds.scroll_of_seed_harvesting,
		relatedSkills: ['foraging'],
		// client
		buffIcon: '/images/magic/buffs/seed_harvesting.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Access hidden seed nodes and greatly increase your chance to find a seed related node while Foraging.`,
		// endclient
	}, // seed harvesting, more seeds are more likely to be found
	57: {
		id: 57,
		name: 'Metallurgy',
		strengthPerLevel: 0.5,
		scrollID: itemsIds.scroll_of_metallurgy,
		relatedSkills: ['smithing'],
		// client
		buffIcon: '/images/magic/buffs/metallurgy.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases the forge's max heat intensity by ${(enchantmentStrength * strengthPerLevel).toFixed(
				1
			)}. (Rounded down)`,
		// endclient
	}, // metallurgy, increase forge's max heat intensity
	58: {
		id: 58,
		name: 'Pure Metals',
		strengthPerLevel: 0.02,
		strengthCap: 0.8,
		scrollID: itemsIds.scroll_of_pure_metals,
		relatedSkills: ['smithing'],
		// client
		buffIcon: '/images/magic/buffs/metallurgy.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Reduces the amount of materials required to smelt a bar by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Caps at 80%.`,
		// endclient
	}, //
	59: {
		id: 59,
		name: 'Essence Concentration',
		strengthPerLevel: 1,
		scrollID: itemsIds.scroll_of_essence_concentration,
		relatedSkills: ['runecrafting'],
		// client
		buffIcon: '/images/magic/buffs/essence_concentration.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases the number of runes crafted per action by ${
				enchantmentStrength * strengthPerLevel
			} as well as increases essence cost by ${Math.round((enchantmentStrength * strengthPerLevel * 100) / 4)}%.`,
		// endclient
	}, //
	60: {
		id: 60,
		name: 'Endless Ammo',
		strengthPerLevel: 0.2,
		scrollID: itemsIds.scroll_of_endless_ammo,
		relatedSkills: ['attack', 'strength', 'range', 'magic'],
		// client
		buffIcon: '/images/magic/buffs/endless_ammo.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				(1 - 1 / (enchantmentStrength * strengthPerLevel + 1)) * 100
			)}% chance to not consume ammo (resulting in ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% more uses from each ammo piece on average). Works on ranged projectiles,
			melee whetstones, and magic charms.`,
		// endclient
	}, //
	61: {
		id: 61,
		name: 'Rune Reserves',
		strengthPerLevel: 0.5,
		scrollID: itemsIds.scroll_of_rune_reserves,
		relatedSkills: ['magic'],
		// client
		buffIcon: '/images/magic/buffs/rune_reserves.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				(1 - 1 / (enchantmentStrength * strengthPerLevel + 1)) * 100
			)}% chance to not consume runes when using abilities (resulting in ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% more ability uses for the same rune cost).`,
		// endclient
	}, //
	62: {
		id: 62,
		name: 'Fiber Finder',
		strengthPerLevel: 0.25,
		scrollID: itemsIds.scroll_of_the_fiber_finder,
		relatedSkills: ['fishing'],
		// client
		buffIcon: '/images/magic/buffs/fiber_finder.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases chance to find aquatic fibers by ${enchantmentStrength * strengthPerLevel * 100}%.`,
		// endclient
	}, //
	63: {
		id: 63,
		name: 'Quiver',
		strengthPerLevel: 0.05,
		relatedSkills: ['range'],
		// client
		buffIcon: '/images/magic/buffs/endless_ammo.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				(1 - 1 / (enchantmentStrength * strengthPerLevel + 1)) * 100
			)}% chance to not consume ranged ammo (resulting in ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% more uses from each ammo piece on average).`,
		// endclient
	}, //
	64: {
		id: 64,
		name: 'Alacrity',
		strengthPerLevel: 15,
		relatedSkills: ['defense'],
		statBuffs: [
			{ path: 'armor.agility', additivePerLevel: 15, multiplicativePerLevel: 0 },
			{ path: 'offensiveAccuracyAffinityRating.Melee', additivePerLevel: -15, multiplicativePerLevel: 0 },
			{ path: 'offensiveAccuracyAffinityRating.Magic', additivePerLevel: -15, multiplicativePerLevel: 0 },
			{ path: 'offensiveAccuracyAffinityRating.Range', additivePerLevel: -15, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/alacrity.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases agility by ${Math.round(
				enchantmentStrength * strengthPerLevel
			)} at the expense of the same amount of accuracy in all combat styles.`,
		// endclient
	}, // massively increase agility, decrease accuracy
	65: {
		id: 65,
		name: 'Hold the Line',
		strengthPerLevel: 15,
		relatedSkills: ['defense'],
		statBuffs: [
			{ path: 'armor.protection', additivePerLevel: 15, multiplicativePerLevel: 0 },
			{ path: 'armor.resistance', additivePerLevel: 15, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Melee', additivePerLevel: -0.05, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Magic', additivePerLevel: -0.05, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Range', additivePerLevel: -0.05, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/hold_the_line.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases defenses by ${Math.round(
				enchantmentStrength * strengthPerLevel
			)}, but lowers damage affinity in all styles by ${Math.round(enchantmentStrength * 0.05 * 100)}%.`,
		// endclient
	}, // massively increase protection, decrease damage
	66: {
		id: 66,
		name: 'Taxing',
		strengthPerLevel: 0.25,
		strengthCap: 1,
		relatedSkills: ['combat'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/taxing.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to increase junk found in combat, but lowers combat experience gain by the same amount. At 100% strength your chance to roll junk is doubled, i.e. 15% to get junk is now 30%. Does not affect the quantity of junk looted when it is rolled, just the chance that it will be initially selected. Caps at 100%.`,
		// endclient
	}, // gives gold in exchange for xp and stats
	67: {
		id: 67,
		name: 'Archaeology',
		strengthPerLevel: 1,
		scrollID: itemsIds.scroll_of_archaeology,
		relatedSkills: ['gathering', 'mining', 'foraging', 'fishing'],
		// client
		buffIcon: '/images/magic/buffs/archaeology.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives access to a secondary drop table while gathering with rewards and chances heavily scaling on enchantment strength. The best finds are only found in the hardest to reach zones!`,
		// endclient
	}, // archaeology
	68: {
		id: 68,
		name: 'Empowered Prospecting',
		strengthPerLevel: 0.5,
		relatedSkills: ['mining'],
		// client
		buffIcon: '/images/magic/buffs/prospector_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increase your chance to find gems or silver while mining by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Rolls before Prospecting and does not stack with it; does not require an earth rune.`,
		// endclient
	}, // smelt when mining
	69: {
		id: 69,
		name: 'Empowered Gathering',
		strengthPerLevel: 0.1,
		relatedSkills: ['gathering', 'mining', 'foraging', 'fishing'],
		// client
		buffIcon: '/images/magic/buffs/gathering_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to find an extra resource while gathering. Rolls before Gathering and does not stack with it; does not require a nature rune.`,
		// endclient
	}, // double resources
	70: {
		id: 70,
		name: 'Aspect of Ice',
		strengthPerLevel: 0.05,
		relatedSkills: ['magic'],
		damageConversion: 'Ice',
		// client
		buffIcon: '/images/magic/buffs/aspect_of_water.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Converts a part of the damage from basic auto attacks to ice damage. Adds a ${(
				1 +
				enchantmentStrength * strengthPerLevel
			).toFixed(2)}x ice affinity to all basic auto attacks.`,
		// endclient
	}, // Aspect of Ice, swaps auto attack to ice
	71: {
		id: 71,
		name: 'Soul Wisdom',
		strengthPerLevel: 0.06,
		relatedSkills: ['total'],
		ignoredByMadness: true,
		scrollID: itemsIds.scroll_of_the_soul_wisdom,
		// client
		buffIcon: '/images/magic/buffs/embers_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`ONLY APPLIES TO THE ITEM THIS ENCHANTMENT IS ON. Increases soulbound item XP gain by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%.`,
		// endclient
	}, // Bonus XP for soulbound items, obsolete
	72: {
		id: 72,
		name: 'Overly Well Done',
		strengthPerLevel: 0.2,
		scrollID: itemsIds.scroll_of_overly_well_done,
		relatedSkills: ['cooking'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/masterchef_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases chance to burn food and reduces burn XP penalty (caps at no penalty) while cooking by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%.`,
		// endclient
	}, // increases burn chance by cooking level
	73: {
		id: 73,
		name: 'Loot Goblin',
		strengthPerLevel: 2,
		relatedSkills: ['combat'],
		// client
		buffIcon: '/images/magic/buffs/phroglin.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gains a chance to encounter a Loot Goblin in combat on average ${
				enchantmentStrength * strengthPerLevel
			} times a day.`,
		// endclient
	}, // loot goblin!
	74: {
		id: 74,
		name: 'Recklessness',
		strengthPerLevel: 0.02,
		relatedSkills: ['attack'],
		scrollID: itemsIds.scroll_of_recklessness,
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Melee', additivePerLevel: 0.02, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Magic', additivePerLevel: 0.02, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Range', additivePerLevel: 0.02, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Melee', additivePerLevel: -0.02, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Magic', additivePerLevel: -0.02, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Range', additivePerLevel: -0.02, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/recklessness_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Additively increases offensive magic, range, and melee affinity by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(0)}% but reduces the respective defensive affinities by the same amount.`,
		// endclient
	}, // recklessness, increases offensive affinity but reduces defensive affinity
	75: {
		id: 75,
		name: 'Elemental Stabilization',
		strengthPerLevel: 0.02,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/elemental_stabilization.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Neutralizes negative affinities by ${
				enchantmentStrength * strengthPerLevel * 100
			}% but will never exceed a neutral state. Does NOT apply to accuracy.`,
		// endclient
	}, // elemental stabilization, reduces all positive affinities and increases negative affinities by the same amount
	76: {
		id: 76,
		name: 'Empowered Haste',
		strengthPerLevel: 0.02,
		strengthCap: 1.0,
		relatedSkills: ['skilling'],
		// client
		buffIcon: '/images/magic/buffs/haste_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases non-combat action speed by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Caps at halving action time. Stacks with Haste.`,
		// endclient
	}, // gathering speed
	77: {
		id: 77,
		name: "Researcher's Boon",
		strengthPerLevel: 0.1,
		strengthCap: 1.0,
		relatedSkills: ['skilling'],
		// client
		buffIcon: '/images/magic/buffs/researchers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases shard generation rate by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	78: {
		id: 78,
		name: "Gatherer's Boon",
		strengthPerLevel: 0.1,
		strengthCap: 1.0,
		relatedSkills: ['gathering', 'mining', 'foraging', 'fishing'],
		// client
		buffIcon: '/images/magic/buffs/gatherers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases min and max gathering ranges by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. If the range is 1, it is instead a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to gather an extra resource.`,
		// endclient
	},
	79: {
		id: 79,
		name: "Adventurer's Boon",
		strengthPerLevel: 0.1,
		strengthCap: 1.0,
		relatedSkills: ['skilling'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to produce an additional skilling shard.`,
		// endclient
	},
	80: {
		id: 80,
		name: 'Bag of Holding',
		strengthPerLevel: 1 / 3,
		strengthCap: 1.0,
		relatedSkills: ['gathering', 'mining', 'foraging', 'fishing'],
		// client
		buffIcon: '/images/magic/buffs/bag_of_holding.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to force the max quantity of a resource while gathering.`,
		// endclient
	},
	81: {
		id: 81,
		name: 'Engraving',
		strengthPerLevel: 0.02,
		strengthCap: 0.8,
		scrollID: itemsIds.scroll_of_engraving,
		relatedSkills: ['runecrafting'],
		// client
		buffIcon: '/images/magic/buffs/engraving.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to not consume rune slates while Runecrafting. Caps at 80%.`,
		// endclient
	},
	82: {
		id: 82,
		name: 'Supercooling',
		strengthPerLevel: 0.01,
		scrollID: itemsIds.scroll_of_supercooling,
		relatedSkills: ['mining'],
		// client
		buffIcon: '/images/magic/buffs/supercooling.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Items with lower heat costs to process have an increased chance to be gathered. Has a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to consume a water rune every mining action. Attempts to pull the rune from your stockpile then ammo pouch if none found.`,
		// endclient
	},
	83: {
		id: 83,
		name: 'Dwarven Engineering',
		strengthPerLevel: 0.002,
		strengthCap: 0.8,
		scrollID: itemsIds.scroll_of_dwarven_engineering,
		relatedSkills: ['mining'],
		// client
		buffIcon: '/images/magic/buffs/dwarven_engineering.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${(enchantmentStrength * strengthPerLevel * 100).toFixed(
				1
			)}% chance to massively boost amount gathered.`,
		// endclient
	},
	84: {
		id: 84,
		name: 'Dwarven Manufacturing',
		strengthPerLevel: 1,
		relatedSkills: ['production'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/dwarven_manufacturing.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases production speed by 20% and increases resource cost by 25%. For cooking this resource cost modification only applies to heat.`,
		// endclient
	},
	85: {
		id: 85,
		name: 'Elven Logistics',
		strengthPerLevel: 1,
		relatedSkills: ['production'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/elven_logistics.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases production speed by 25% and decreases resource cost by 20%. For cooking this resource cost modification only applies to heat.`,
		// endclient
	},
	86: {
		id: 86,
		name: 'Resistance',
		strengthPerLevel: 10,
		relatedSkills: ['defense', 'magic'],
		scrollID: itemsIds.scroll_of_resistance,
		statBuffs: [{ path: 'armor.resistance', additivePerLevel: 10, multiplicativePerLevel: 0 }],
		// client
		buffIcon: '/images/magic/buffs/resistance.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases resistance by ${Math.round(enchantmentStrength * strengthPerLevel)}.`,
		// endclient
	},
	87: {
		id: 87,
		name: 'Convergence',
		strengthPerLevel: 0.01,
		strengthCap: 1,
		relatedSkills: ['magic'],
		scrollID: itemsIds.scroll_of_convergence,
		// client
		buffIcon: '/images/magic/buffs/convergence.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% of your resistance as intellect. Caps at 100%`,
		// endclient
	}, // defenses to offensive shield
	88: {
		id: 88,
		name: 'Finesse',
		strengthPerLevel: 0.01,
		strengthCap: 1,
		relatedSkills: ['range'],
		scrollID: itemsIds.scroll_of_finesse,
		// client
		buffIcon: '/images/magic/buffs/finesse.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% of your agility as dexterity. Caps at 100%`,
		// endclient
	}, // defenses to offensive shield
	89: {
		id: 89,
		name: 'Crucible',
		strengthPerLevel: 0.02,
		relatedSkills: ['smithing'],
		scrollID: itemsIds.scroll_of_crucible,
		// client
		buffIcon: '/images/magic/buffs/crucible.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases input and output of materials in Smithing by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Heat cost is instead increased by ${Math.round(enchantmentStrength * strengthPerLevel * 1.5 * 100)}%.`,
		// endclient
	}, // smithing in/out increase
	90: {
		id: 90,
		name: 'Forge Enhancement',
		strengthPerLevel: 0.01,
		strengthCap: 0.3,
		relatedSkills: ['smithing'],
		scrollID: itemsIds.scroll_of_forge_enhancement,
		// client
		buffIcon: '/images/magic/buffs/forge_enhancements.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases positive forge effects by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Caps at 30%.`,
		// endclient
	}, // better forge bonuses
	91: {
		id: 91,
		name: 'Forge Maintenance',
		strengthPerLevel: 0.01,
		strengthCap: 0.3,
		relatedSkills: ['smithing'],
		scrollID: itemsIds.scroll_of_forge_maintenance,
		// client
		buffIcon: '/images/magic/buffs/forge_maintenance.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases negative forge effects by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Caps at 30%.`,
		// endclient
	}, // less forge maluses
	92: {
		id: 92,
		name: 'Fibrous Fabrication',
		strengthPerLevel: 0.02,
		strengthCap: 0.8,
		relatedSkills: ['runecrafting'],
		scrollID: itemsIds.scroll_of_fibrous_fabrication,
		// client
		buffIcon: '/images/magic/buffs/fibrous_fabrication.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to preserve fibers while weaving. Caps at 80%`,
		// endclient
	}, // chance to save fibers
	93: {
		id: 93,
		name: 'Key Hunter',
		strengthPerLevel: 0.005,
		relatedSkills: ['combat'],
		ignoredByMadness: true,
		scrollID: itemsIds.scroll_of_finesse, // TODO replace
		// client
		buffIcon: '/images/magic/buffs/force_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`${(enchantmentStrength * strengthPerLevel * 100).toFixed(
				1
			)}% chance to convert a key piece into its fully crafted counterpart.`,
		// endclient
	}, // chance to instant-craft keys // TODO implement me
	94: {
		id: 94,
		name: 'Soul Trade',
		strengthPerLevel: 0.03,
		relatedSkills: ['total'],
		ignoredByMadness: true,
		scrollID: itemsIds.scroll_of_finesse, // TODO replace
		// client
		buffIcon: '/images/magic/buffs/force_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases skill XP gained by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% but increases item XP gain by the same amount.`,
		// endclient
	}, // chance to instant-craft keys // TODO implement me
	95: {
		id: 95,
		name: 'Deep Fry',
		strengthPerLevel: 0.04,
		strengthCap: 1.0,
		relatedSkills: ['cooking'],
		// client
		buffIcon: '/images/magic/buffs/deep_fry.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases cooking speed by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Caps at halving action time. Stacks with Haste.`,
		// endclient
	}, // increases cooking speed
	96: {
		id: 96,
		name: 'Leftovers',
		strengthPerLevel: 0.01,
		relatedSkills: ['cooking'],
		scrollID: itemsIds.scroll_of_leftovers,
		// client
		buffIcon: '/images/magic/buffs/leftovers.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance to create a random buff food while cooking or brewing. Amount increases with the number of ingredients used.`,
		// endclient
	}, // Questionable food with random buff
	97: {
		id: 97,
		name: 'Blitzing',
		strengthPerLevel: 0.0005,
		relatedSkills: ['cooking'],
		scrollID: itemsIds.scroll_of_blitzing,
		// client
		buffIcon: '/images/magic/buffs/blitzing.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain a chance to prepare another ingredient by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(
				2
			)}% per effective cooking level.`,
		// endclient
	},
	98: {
		id: 98,
		name: 'Swift Agony',
		strengthPerLevel: 1,
		relatedSkills: ['magic'],
		// client
		buffIcon: '/images/magic/buffs/haste_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Deal the entire damage over time effect instantly to enemies but with a 50% reduction in total damage which can be negated by the Living Death set bonus.`,
		// endclient
	},
	99: {
		id: 99,
		name: 'Duster',
		strengthPerLevel: 0.01,
		relatedSkills: ['enchanting'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/duster.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your chance to successfully research an item by ${Math.round(
				strengthPerLevel * enchantmentStrength * 100
			).toFixed(0)}%.`,
		// endclient
	},
	100: {
		id: 100,
		name: 'Scrapper',
		strengthPerLevel: 0.1,
		relatedSkills: ['enchanting'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/scrapper.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases your chance to successfully research an item by ${Math.round(
				strengthPerLevel * enchantmentStrength * 100
			).toFixed(0)}%.`,
		// endclient
	},
	101: {
		id: 101,
		name: 'Immunity',
		strengthPerLevel: 0.1,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/immunity.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${Math.round(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% chance to halve debuff stacks taken.`,
		// endclient
	},
	102: {
		id: 102,
		name: 'Voidblessed',
		strengthPerLevel: 0.1,
		relatedSkills: ['attack'],
		damageEnhancement: 'Chaos',
		// client
		buffIcon: '/images/magic/buffs/aspect_of_chaos.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases Chaos scaling of abilities by ${(1 + enchantmentStrength * strengthPerLevel).toFixed(2)}x.`,
		// endclient
	},

	// TALENTS
	500: {
		id: 500,
		name: 'Crafting Talent',
		strengthPerLevel: 0.02,
		strengthCap: 0.2,
		relatedSkills: ['crafting'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/crafting_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Each crafted item costs ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% less ingredients. Caps at 20%.`,
		// endclient
	}, // crafting, reduced cost, for talent ONLY
	501: {
		id: 501,
		name: 'Dwarven Student',
		strengthPerLevel: 1,
		relatedSkills: ['mining'],
		statBuffs: [
			{ path: 'mining', additivePerLevel: 30, multiplicativePerLevel: 0 },
			{ path: 'foraging', additivePerLevel: -10, multiplicativePerLevel: 0 },
			{ path: 'fishing', additivePerLevel: -10, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/mining.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your mining level by ${enchantmentStrength * 30} but decreases foraging and fishing levels by ${
				enchantmentStrength * 10
			}.`,
		// endclient
	},
	502: {
		id: 502,
		name: 'Elven Student',
		strengthPerLevel: 1,
		relatedSkills: ['foraging'],
		statBuffs: [
			{ path: 'mining', additivePerLevel: -10, multiplicativePerLevel: 0 },
			{ path: 'foraging', additivePerLevel: 30, multiplicativePerLevel: 0 },
			{ path: 'fishing', additivePerLevel: 30, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/foraging.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your foraging level by ${enchantmentStrength * 30} but decreases mining and fishing levels by ${
				enchantmentStrength * 10
			}.`,
		// endclient
	},
	503: {
		id: 503,
		name: 'Shrimp Student',
		strengthPerLevel: 1,
		relatedSkills: ['fishing'],
		statBuffs: [
			{ path: 'mining', additivePerLevel: -10, multiplicativePerLevel: 0 },
			{ path: 'foraging', additivePerLevel: -10, multiplicativePerLevel: 0 },
			{ path: 'fishing', additivePerLevel: 30, multiplicativePerLevel: 0 },
			{ path: 'fishingBaitPower', additivePerLevel: 30, multiplicativePerLevel: 0 },
			{ path: 'fishingRarityPower', additivePerLevel: 30, multiplicativePerLevel: 0 },
			{ path: 'fishingReelPower', additivePerLevel: 30, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/fishing_tool.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases all fishing stats by ${enchantmentStrength * 30} but decreases foraging and mining levels by ${
				enchantmentStrength * 10
			}.`,
		// endclient
	},
	504: {
		id: 504,
		name: 'Hamster Hunter',
		strengthPerLevel: 0.05,
		relatedSkills: ['mining'],
		// client
		buffIcon: '/images/magic/buffs/hamster.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your speed in the Underground Mines by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}%.`,
		// endclient
	},
	505: {
		id: 505,
		name: 'Melter Smelter',
		strengthPerLevel: 0.005,
		relatedSkills: ['mining'],
		// client
		buffIcon: '/images/magic/buffs/superheated_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${(strengthPerLevel * enchantmentStrength * 100).toFixed(1)}% chance to instantly smelt an ore.`,
		// endclient
	},
	506: {
		id: 506,
		name: 'Fresh Forager',
		strengthPerLevel: 0.005,
		relatedSkills: ['foraging'],
		// client
		buffIcon: '/images/magic/buffs/fresh_forager.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				1
			)}% chance to instantly prepare an ingredient.`,
		// endclient
	},
	507: {
		id: 507,
		name: 'Mining Prodigy',
		strengthPerLevel: 10,
		relatedSkills: ['mining'],
		statBuffs: [{ path: 'mining', additivePerLevel: 10, multiplicativePerLevel: 0 }],
		// client
		buffIcon: '/images/magic/buffs/mining.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases mining level by ${(strengthPerLevel * enchantmentStrength).toFixed(0)}.`,
		// endclient
	},
	508: {
		id: 508,
		name: 'Foraging Prodigy',
		strengthPerLevel: 10,
		relatedSkills: ['foraging'],
		statBuffs: [{ path: 'foraging', additivePerLevel: 10, multiplicativePerLevel: 0 }],
		// client
		buffIcon: '/images/magic/buffs/foraging.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases foraging level by ${(strengthPerLevel * enchantmentStrength).toFixed(0)}.`,
		// endclient
	},
	509: {
		id: 509,
		name: 'Fishing Prodigy',
		strengthPerLevel: 10,
		relatedSkills: ['fishing'],
		statBuffs: [
			{ path: 'fishing', additivePerLevel: 10, multiplicativePerLevel: 0 },
			{ path: 'fishingBaitPower', additivePerLevel: 10, multiplicativePerLevel: 0 },
			{ path: 'fishingRarityPower', additivePerLevel: 10, multiplicativePerLevel: 0 },
			{ path: 'fishingReelPower', additivePerLevel: 10, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/fishing_tool.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases fishing stats by ${(strengthPerLevel * enchantmentStrength).toFixed(0)}.`,
		// endclient
	},
	510: {
		id: 510,
		name: 'Mining Grandmaster',
		strengthPerLevel: 0.05,
		relatedSkills: ['mining'],
		statBuffs: [{ path: 'mining', additivePerLevel: 0, multiplicativePerLevel: 0.05 }],
		// client
		buffIcon: '/images/magic/buffs/mining.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases mining level from boosts and equipment by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%.`,
		// endclient
	},
	511: {
		id: 511,
		name: 'Foraging Grandmaster',
		strengthPerLevel: 0.05,
		relatedSkills: ['foraging'],
		statBuffs: [{ path: 'foraging', additivePerLevel: 0, multiplicativePerLevel: 0.05 }],
		// client
		buffIcon: '/images/magic/buffs/foraging.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases foraging level from boosts and equipment by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%.`,
		// endclient
	},
	512: {
		id: 512,
		name: 'Fishing Grandmaster',
		strengthPerLevel: 0.05,
		relatedSkills: ['fishing'],
		statBuffs: [
			{ path: 'fishing', additivePerLevel: 0, multiplicativePerLevel: 0.05 },
			{ path: 'fishingBaitPower', additivePerLevel: 0, multiplicativePerLevel: 0.05 },
			{ path: 'fishingRarityPower', additivePerLevel: 0, multiplicativePerLevel: 0.05 },
			{ path: 'fishingReelPower', additivePerLevel: 0, multiplicativePerLevel: 0.05 },
		],
		// client
		buffIcon: '/images/magic/buffs/fishing_tool.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases fishing stats from boosts and equipment by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%.`,
		// endclient
	},
	513: {
		id: 513,
		name: 'Bound Anger',
		strengthPerLevel: 0.05,
		relatedSkills: ['strength'],
		// client
		buffIcon: '/images/magic/buffs/bound_anger.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Deal up to ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% more damage when your health drops below 33%, scaling with your missing health. Stacks with other similar effects.`,
		// endclient
	},
	514: {
		id: 514,
		name: 'Lucky Looter',
		strengthPerLevel: 0.005,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/lucky_looter.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${(strengthPerLevel * enchantmentStrength * 100).toFixed(1)}% chance to double combat junk drops.`,
		// endclient
	},
	515: {
		id: 515,
		name: 'Critical Weakpoint',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/critical_strike_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% chance to apply vulnerability to a hit target.`,
		// endclient
	},
	516: {
		id: 516,
		name: 'Careful Criticals',
		strengthPerLevel: 0.25,
		relatedSkills: ['attack'],
		ignoredByMadness: true,
		statBuffs: [
			{ path: 'offensiveCritical.chance', additivePerLevel: 0, multiplicativePerLevel: -0.25 },
			{ path: 'offensiveCritical.damageMultiplier', additivePerLevel: 0, multiplicativePerLevel: 0.5 },
		],
		// client
		buffIcon: '/images/magic/buffs/critical_strike_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases critical hit chance by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% but increases critical hit damage by twice as much.`,
		// endclient
	},
	517: {
		id: 517,
		name: 'Unbound Rage',
		strengthPerLevel: 0.05,
		relatedSkills: ['strength'],
		ignoredByMadness: true,
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Melee', additivePerLevel: -0.05, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Magic', additivePerLevel: -0.05, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Range', additivePerLevel: -0.05, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/bound_anger.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Lowers your Defensive Affinities for Melee, Magic, and Range by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%. Deal up to ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% more damage when your health drops below 33%, scaling with your missing health. Stacks with other similar effects.`,
		// endclient
	},
	518: {
		id: 518,
		name: 'Arsonist',
		strengthPerLevel: 0.25,
		relatedSkills: ['attack'],
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Fire', additivePerLevel: 0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Fire', additivePerLevel: 0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Melee', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Magic', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Range', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/pyromancy.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases offensive and defensive fire affinities by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%, but decreases your defensive affinities to melee, range, and magic by the same amount.`,
		// endclient
	},
	519: {
		id: 519,
		name: "Giant's Blood",
		strengthPerLevel: 0.1,
		relatedSkills: ['constitution'],
		statBuffs: [
			{ path: 'weapon.strength', additivePerLevel: 0, multiplicativePerLevel: 0.1 },
			{ path: 'armor.stamina', additivePerLevel: 0, multiplicativePerLevel: 0.1 },
			{ path: 'armor.agility', additivePerLevel: 0, multiplicativePerLevel: -0.1 },
		],
		// client
		buffIcon: '/images/magic/buffs/heart.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases stamina and strength by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}%, but decreases agility and consumable healing by the same amount.`,
		// endclient
	},
	520: {
		id: 520,
		name: 'Cold-Blooded',
		strengthPerLevel: 0.25,
		relatedSkills: ['magic'],
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Ice', additivePerLevel: 0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Ice', additivePerLevel: 0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Melee', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Magic', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Range', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/heart.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases offensive and defensive ice affinities by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%, but decreases your defensive affinities to melee, range, and magic by the same amount.`,
		// endclient
	},
	521: {
		id: 521,
		name: 'Molten Metal',
		strengthPerLevel: 1,
		relatedSkills: ['smithing'],
		// client
		buffIcon: '/images/magic/buffs/crucible.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your max heat by ${(strengthPerLevel * enchantmentStrength).toFixed(0)} at the Volcanic Forge.`,
		// endclient
	},
	522: {
		id: 522,
		name: 'Slag Refinement',
		strengthPerLevel: 0.025,
		relatedSkills: ['smithing'],
		// client
		buffIcon: '/images/magic/buffs/crucible.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				1
			)}% chance to re-roll refining if Metal Slag is rolled.`,
		// endclient
	},
	523: {
		id: 523,
		name: 'Seed Sifter',
		strengthPerLevel: 0.01,
		relatedSkills: ['cooking'],
		// client
		buffIcon: '/images/magic/buffs/fresh_forager.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% chance to produce a seed when preparing a vegetable.`,
		// endclient
	},
	524: {
		id: 524,
		name: 'Goblin Slayer',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when attacking Goblins.`,
		// endclient
	},
	525: {
		id: 525,
		name: 'Exorcist',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Demons.`,
		// endclient
	},
	526: {
		id: 526,
		name: 'Vigilante',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Humans.`,
		// endclient
	},
	527: {
		id: 527,
		name: 'Purifier',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting the Corrupted.`,
		// endclient
	},
	528: {
		id: 528,
		name: 'Big Game Hunter',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Beasts.`,
		// endclient
	},
	529: {
		id: 529,
		name: 'Crusader',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Undead.`,
		// endclient
	},
	530: {
		id: 530,
		name: 'Elf Exterminator',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Elves.`,
		// endclient
	},
	531: {
		id: 531,
		name: 'Giant Slayer',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Giant enemies.`,
		// endclient
	},
	532: {
		id: 532,
		name: 'Order from Chaos',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Chaotic enemies.`,
		// endclient
	},
	533: {
		id: 533,
		name: 'Shrimpbane',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Shrimp.`,
		// endclient
	},
	534: {
		id: 534,
		name: 'Arboreal Annihilator',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Woodlike enemies.`,
		// endclient
	},
	535: {
		id: 535,
		name: 'Technophobe',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Golems or other artificial enemies.`,
		// endclient
	},
	536: {
		id: 536,
		name: 'Luddite',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		// client
		buffIcon: '/images/magic/buffs/adventurers_boon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage and accuracy by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% when fighting Dwarven enemies.`,
		// endclient
	},
	537: {
		id: 537,
		name: 'Greenest Thumb',
		strengthPerLevel: 0.05,
		relatedSkills: ['farming'],
		// client
		buffIcon: '/images/magic/buffs/cultivation_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases growth speed by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(0)}%.`,
		// endclient
	},
	538: {
		id: 538,
		name: 'Totemic Wisdom',
		strengthPerLevel: 0.05,
		strengthCap: 0.95,
		relatedSkills: ['farming'],
		// client
		buffIcon: '/images/magic/buffs/cultivation_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Farming totems give their full effect at ${(1 - strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% of their maximum age. Caps at full effect at 5% age.`,
		// endclient
	},
	539: {
		id: 539,
		name: 'Mysterious Harvest',
		strengthPerLevel: 0.01,
		relatedSkills: ['farming'],
		// client
		buffIcon: '/images/magic/buffs/mysterious_harvest.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases maximum harvest roll for all plants by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% for every Mysterious Seed with a unique size.`,
		// endclient
	},
	540: {
		id: 540,
		name: 'Trapper',
		strengthPerLevel: 0.05,
		relatedSkills: ['farming'],
		// client
		buffIcon: '/images/magic/buffs/trapper.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% chance to recover a trap when harvesting.`,
		// endclient
	},
	541: {
		id: 541,
		name: 'Farm Blitz',
		strengthPerLevel: 1,
		relatedSkills: ['farming'],
		// client
		buffIcon: '/images/magic/buffs/cultivation_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives up to ${(strengthPerLevel * enchantmentStrength).toFixed(
				0
			)} Blitzing 3 stacks every time a 1x1 seed is harvested.`,
		// endclient
	},
	542: {
		id: 542,
		name: 'Sympathetic Growth',
		strengthPerLevel: 0.01,
		relatedSkills: ['farming'],
		// client
		buffIcon: '/images/magic/buffs/cultivation_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases minimum harvest roll by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% for a plot for each duplicate plot type in your field.`,
		// endclient
	},
	543: {
		id: 543,
		name: 'Crop Rotation',
		strengthPerLevel: 0.05,
		relatedSkills: ['farming'],
		// client
		buffIcon: '/images/magic/buffs/cultivation_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases growth speed of your field by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% if there are no duplicate plot types.`,
		// endclient
	},
	544: {
		id: 544,
		name: 'Tree Hugger',
		strengthPerLevel: 0.05,
		relatedSkills: ['farming'],
		// client
		buffIcon: '/images/magic/buffs/nature_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases harvest from trees by ${(strengthPerLevel * enchantmentStrength * 100).toFixed(0)}%.`,
		// endclient
	},
	545: {
		id: 545,
		name: 'Heart of the Forest',
		strengthPerLevel: 1,
		relatedSkills: ['farming'],
		// client
		buffIcon: '/images/magic/buffs/nature_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases the augmentation level of all Heart of the Oak drops in your field by ${(
				strengthPerLevel * enchantmentStrength
			).toFixed(0)}.`,
		// endclient
	},
	546: {
		id: 546,
		name: 'Unadorned Agility',
		strengthPerLevel: 25,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/nimble.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Each empty primary armor slot (helmet, torso, legs) additively increases agility by ${(
				strengthPerLevel * enchantmentStrength
			).toFixed(0)}. If all three are empty then agility is increased by an additional ${(
				strengthPerLevel * enchantmentStrength
			).toFixed(0)}.`,
		// endclient
	},
	547: {
		id: 547,
		name: 'Featherweight Fighter',
		strengthPerLevel: 1,
		relatedSkills: ['defense', 'attack'],
		ignoredByMadness: true,
		statBuffs: [{ path: 'armor.agility', additivePerLevel: 0, multiplicativePerLevel: 0.1 }],
		// client
		buffIcon: '/images/magic/buffs/finesse.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Your attacks come out 5% faster and your agility is increased by 10%, but your minimum and maximum damage multipliers are decreased by a flat 15%.`,
		// endclient
	},
	548: {
		id: 548,
		name: 'Nine-Lives',
		strengthPerLevel: 1,
		relatedSkills: ['defense', 'constitution'],
		// client
		buffIcon: '/images/magic/buffs/heart.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives you a flat 0.9% chance to survive a lethal hit with 1 hp left. This chance is increased by 0.09% for every 0.9% of health you had before the hit, up to a maximum of 9% at full health.`,
		// endclient
	},
	549: {
		id: 549,
		name: 'Featherweight Champion',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack', 'strength', 'magic', 'range'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/finesse.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				0
			)}% of your agility is converted into strength, dexterity, and intellect. Only works if agility is positive.`,
		// endclient
	},
	550: {
		id: 550,
		name: 'Augmentation Afficionado',
		strengthPerLevel: 0.025,
		relatedSkills: ['enchanting'],
		// client
		buffIcon: '/images/magic/buffs/chances-icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives an additive ${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				1
			)}% chance to augment an item.`,
		// endclient
	},
	551: {
		id: 551,
		name: 'Research Ready',
		strengthPerLevel: 0.025,
		relatedSkills: ['enchanting'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/duster.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your chance to successfully research an item by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(1)}%.`,
		// endclient
	},
	552: {
		id: 552,
		name: 'Mad Scientist',
		strengthPerLevel: 0.025,
		relatedSkills: ['enchanting'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/scrapper.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases your chance to successfully research an item by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(1)}%.`,
		// endclient
	},
	553: {
		id: 553,
		name: 'Lingering Enlightenment',
		strengthPerLevel: 1,
		relatedSkills: ['enchanting'],
		// client
		buffIcon: '/images/magic/buffs/enlightenment_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Grants 200 stacks of Enlightenment when consuming a talisman, but halves the amount of essence gained when consuming talismans.`,
		// endclient
	},
	554: {
		id: 554,
		name: 'Spiraling out of Control',
		strengthPerLevel: 0.0025,
		relatedSkills: ['mining', 'runecrafting'],
		// client
		buffIcon: '/images/magic/buffs/enlightenment_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`${(strengthPerLevel * enchantmentStrength * 100).toFixed(
				2
			)}% faster action speed in Slate Spires for each Runecrafting mastery level.`,
		// endclient
	},
	555: {
		id: 555,
		name: 'Affix Affinity',
		strengthPerLevel: 1,
		relatedSkills: ['runecrafting'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/chances-icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Re-rolls your affixes an additional time for free if a re-roll attempt results in all affixes being worse than the prior roll.`,
		// endclient
	},
	556: {
		id: 556,
		name: 'Talisman Talent',
		strengthPerLevel: 0.2,
		relatedSkills: ['runecrafting'],
		// client
		buffIcon: '/images/magic/buffs/runecrafting_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases the passive effect of consumed talismans by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%.`,
		// endclient
	},
	557: {
		id: 557,
		name: 'Essence Concentration Expert',
		strengthPerLevel: 0.1,
		relatedSkills: ['runecrafting'],
		// client
		buffIcon: '/images/magic/buffs/essence_concentration.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Reduces the negative impact of Essence Concentration by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%.`,
		// endclient
	},
	558: {
		id: 558,
		name: 'Silent Fury',
		strengthPerLevel: 0.05,
		relatedSkills: ['constitution'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/silent_fury.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`When determining your current health for effects that change in potency, such as bonus damage at low health, your health will be considered ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(
				0
			)}% lower, making the effect become more potent at higher health. This only affects checks where lower health is better.`,
		// endclient
	},
	559: {
		id: 559,
		name: 'Arrow Expenditure',
		strengthPerLevel: 1,
		relatedSkills: ['range'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/endless_ammo.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Ranged attacks that do not consume ammunition will now do so and use the increased damage and accuracy from them.`,
		// endclient
	},
	560: {
		id: 560,
		name: 'Ammunition Overload',
		strengthPerLevel: 0.05,
		relatedSkills: ['attack'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/endless_ammo.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases your chance to preserve ammunition by a flat ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%, but increases the ammo stat buffs by ${(
				(strengthPerLevel * enchantmentStrength * 100) /
				5
			).toFixed(0)}%.`,
		// endclient
	},
	561: {
		id: 561,
		name: 'Poisoner',
		strengthPerLevel: 0.25,
		relatedSkills: ['magic'],
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Poison', additivePerLevel: 0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Poison', additivePerLevel: 0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Melee', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Magic', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Range', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/swift_agony.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases offensive and defensive poison affinities by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%, but decreases your defensive affinities to melee, range, and magic by the same amount.`,
		// endclient
	},
	562: {
		id: 562,
		name: 'Druidism',
		strengthPerLevel: 0.25,
		relatedSkills: ['magic'],
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Nature', additivePerLevel: 0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Nature', additivePerLevel: 0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Melee', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Magic', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Range', additivePerLevel: -0.25, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/magic/buffs/nature_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases offensive and defensive poison affinities by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%, but decreases your defensive affinities to melee, range, and magic by the same amount.`,
		// endclient
	},
	563: {
		id: 563,
		name: 'Consistent Chef',
		strengthPerLevel: 1,
		relatedSkills: ['cooking'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/masterchef_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			'Always cook the higher quality food, ignoring the cooking chance to increase quality.',
		// endclient
	},

	// TALENTS END
	// mostly monster effects
	1000: {
		id: 1000,
		name: 'Impenetrable Defense',
		strengthPerLevel: 1,
		relatedSkills: ['defense'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/impenetrabledefence_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) => 'All damage taken is reduced to 1.',
		// endclient
	}, // Impenetrable defense, reduces all incoming damage to 1

	// mostly food effects
	2000: {
		id: 2000,
		name: 'Nimble',
		strengthPerLevel: 0.1,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/nimble.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Multiplicatively increases your defense rolls when being attacked by ${(
				strengthPerLevel *
				enchantmentStrength *
				100
			).toFixed(0)}%, improving your chances to dodge an incoming attack.`,
		// endclient
	}, // nimble
	2001: {
		id: 2001,
		name: 'Demon Skin',
		strengthPerLevel: 9,
		setRequirements: [
			{ count: 3, strength: 2 },
			{ count: 4, strength: 4 },
			{ count: 5, strength: 6 },
		],
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/demon_skin.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`All damage taken is reduced by ${enchantmentStrength * strengthPerLevel} or ${
				enchantmentStrength * 2
			}%, whichever decreases the damage more, to a minimum of 1.`,
		// endclient
	}, // demon skin, reduces damage taken
	2002: {
		id: 2002,
		name: 'Intuition',
		strengthPerLevel: 0.05,
		relatedSkills: ['total'],
		// client
		buffIcon: '/images/magic/buffs/xp_increased.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gain ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% increased experience while doing most things.`,
		// endclient
	}, // intuition, increases exp gain

	5000: {
		id: 5000,
		name: 'schfftph fzzz',
		strengthPerLevel: 0.0005,
		relatedSkills: ['total'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/shrimlord_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) => `fzzglglfzzglgl kakfzz`,
		// endclient
	},

	7000: {
		id: 7000,
		name: 'Christmas Spirit',
		strengthPerLevel: 1,
		relatedSkills: ['total'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/christmas/snow.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Merry Christmas! Santa Claus has buffed the server with Christmas Spirit!`,
		// endclient
	},
	7001: {
		id: 7001,
		name: 'Easter',
		strengthPerLevel: 1,
		relatedSkills: ['total'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/cooking/34573r_3gg.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) => `Happy Easter! Code: iSawItFirst`,
		// endclient
	},

	8000: {
		id: 8000,
		name: 'Weakness',
		strengthPerLevel: 0.1,
		relatedSkills: ['attack'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/buffs/weakening_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases max and min hits in combat by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	8001: {
		id: 8001,
		name: 'Corrosion',
		strengthPerLevel: 0.1,
		relatedSkills: ['defense'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/buffs/protection_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases effectiveness of your defenses by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	8002: {
		id: 8002,
		name: 'Sick',
		strengthPerLevel: 0.1,
		relatedSkills: ['constitution'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/buffs/prolonging_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases healing in combat by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	8003: {
		id: 8003,
		name: 'Blind',
		strengthPerLevel: 0.1,
		relatedSkills: ['attack'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/buffs/accuracy_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases accuracy in combat by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	8004: {
		id: 8004,
		name: 'Stunned',
		strengthPerLevel: 0.1,
		relatedSkills: ['combat', 'attack'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/buffs/patience_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases attack speed in combat by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	8005: {
		id: 8005,
		name: 'Resurrection Sickness',
		strengthPerLevel: 0.33,
		relatedSkills: ['constitution', 'attack'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/debuffs/resurrection_sickness.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`You're feeling weak after missing your appointment with death. Most stats and healing reduced by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%.`,
		// endclient
	},
	8006: {
		id: 8006,
		name: 'Overheating',
		strengthPerLevel: 0.1,
		relatedSkills: ['constitution', 'magic'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		hideStacks: true,
		buffIcon: '/images/magic/debuffs/overheating.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`This zone is dangerous, you're overheating! Damage done reduced and damage taken increased by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. This effect can be mitigated by the Flame Resistance buff.`,
		// endclient
	},
	8007: {
		id: 8007,
		name: 'Flame Resistance',
		strengthPerLevel: 0.1,
		relatedSkills: ['constitution', 'magic'],
		stackMult: 2,
		// client
		buffIcon: '/images/magic/buffs/flame_resistance.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Grants some resistance to intense heat; counteracts up to ${enchantmentStrength} levels of overheating. May be consumed multiple times per attack!`,
		// endclient
	},
	8008: {
		id: 8008,
		name: 'Soul Stealing',
		strengthPerLevel: 0.01,
		relatedSkills: ['attack', 'constitution'],
		// client
		buffIcon: '/images/magic/buffs/overhealing_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Heal for ${enchantmentStrength * strengthPerLevel * 100}% of damage dealt.`,
		// endclient
	},
	8009: {
		id: 8009,
		name: "Nature's Sanctuary",
		strengthPerLevel: 20,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/natures_sanctuary.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases resistance by ${enchantmentStrength * strengthPerLevel} and protection by ${
				(enchantmentStrength * strengthPerLevel) / 2
			}.`,
		// endclient
	},
	8010: {
		id: 8010,
		name: 'Regal Might',
		strengthPerLevel: 50,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/impenetrabledefence_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases protection by ${enchantmentStrength * strengthPerLevel} and resistance by ${
				(enchantmentStrength * strengthPerLevel) / 2
			}.`,
		// endclient
	},
	8011: {
		id: 8011,
		name: 'Wide Open',
		strengthPerLevel: 20,
		relatedSkills: ['defense'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/debuffs/resurrection_sickness.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases protection and resistance by ${enchantmentStrength * strengthPerLevel}%.`,
		// endclient
	},
	8012: {
		id: 8012,
		name: 'Guarded',
		strengthPerLevel: 20,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/protection_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases protection and resistance by ${enchantmentStrength * strengthPerLevel}%.`,
		// endclient
	},
	8013: {
		id: 8013,
		name: 'Taunting',
		strengthPerLevel: 0.2,
		relatedSkills: ['constitution'],
		// client
		buffIcon: '/images/magic/buffs/taunting.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your threat by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	}, // threat
	8014: {
		id: 8014,
		name: 'Camouflage',
		strengthPerLevel: 0.1,
		relatedSkills: ['constitution'],
		// client
		buffIcon: '/images/magic/buffs/reinforcement_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases your threat by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	}, // reduced threat
	8015: {
		id: 8015,
		name: 'Hard to Hit',
		strengthPerLevel: 0.1,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/reinforcement_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your agility by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% or by 20, whichever is higher.`,
		// endclient
	}, // increased agility
	8016: {
		id: 8016,
		name: 'Ice Armor',
		strengthPerLevel: 10,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/ice_armor.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your protection and resistance by ${Math.round(
				enchantmentStrength * strengthPerLevel
			)} and reduces agility by the same amount.`,
		// endclient
	}, // ice protection and affinity
	8017: {
		id: 8017,
		name: 'Rooted',
		strengthPerLevel: 0.1,
		relatedSkills: ['defense'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/debuffs/resurrection_sickness.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases agility by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% or by 20, whichever is higher.`,
		// endclient
	}, // increased agility
	8018: {
		id: 8018,
		name: 'Tank Taunting',
		strengthPerLevel: 2,
		relatedSkills: ['constitution', 'defense'],
		// client
		buffIcon: '/images/magic/buffs/impenetrabledefence_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Quadruples your threat and gives you +100 protection and resistance.`,
		// endclient
	}, // threat
	8019: {
		id: 8019,
		name: 'Cleanse',
		strengthPerLevel: 0.1,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/immunity.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Counteracts up to ${enchantmentStrength} levels of Corrosion.`,
		// endclient
	},
	8020: {
		id: 8020,
		name: 'Skirmishing Stance',
		strengthPerLevel: 15,
		relatedSkills: ['defense', 'range'],
		// client
		buffIcon: '/images/magic/buffs/nimble.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases agility but decreases dexterity by ${enchantmentStrength * strengthPerLevel}.`,
		// endclient
	},
	8021: {
		id: 8021,
		name: 'Sniping Stance',
		strengthPerLevel: 25,
		relatedSkills: ['range'],
		// client
		buffIcon: '/images/magic/buffs/accuracy_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases agility but increases dexterity by ${enchantmentStrength * strengthPerLevel}.`,
		// endclient
	},
	8022: {
		id: 8022,
		name: 'Enraged',
		strengthPerLevel: 0.15,
		relatedSkills: ['combat'],
		// client
		buffIcon: '/images/magic/buffs/bound_anger.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases almost all combat stats by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	8023: {
		id: 8023,
		name: 'Warded',
		strengthPerLevel: 15,
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/protection_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases protection and resistance by ${Math.round(
				enchantmentStrength * strengthPerLevel
			)} but decreases intellect by the same amount.`,
		// endclient
	},
	8024: {
		id: 8024,
		name: 'Pending Resurrection',
		strengthPerLevel: 1,
		relatedSkills: ['combat', 'constitution'],
		ignoreProlonging: true,
		// client
		buffIcon: '/images/magic/buffs/resurrection.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`When hit with a killing blow you will resurrect with half HP and Resurrection Sickness. Does not stack with other Resurrection types.`,
		// endclient
	},
	8025: {
		id: 8025,
		name: 'Enhanced Stun',
		strengthPerLevel: 0.99,
		relatedSkills: ['attack'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/debuffs/resurrection_sickness.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Reduces enemy accuracy by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	8026: {
		id: 8026,
		name: 'Enhanced Silence',
		strengthPerLevel: 1,
		relatedSkills: ['combat', 'attack'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/debuffs/resurrection_sickness.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% to replace the next casted attack with an auto-attack while still putting the original attack on cooldown.`,
		// endclient
	},
	8027: {
		id: 8027,
		name: 'Enhanced Vulnerability',
		strengthPerLevel: 0.1,
		relatedSkills: ['constitution'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/debuffs/resurrection_sickness.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases damage taken by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	8028: {
		id: 8028,
		name: 'Marked',
		strengthPerLevel: 0.5,
		relatedSkills: ['constitution'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/debuffs/resurrection_sickness.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases your generated threat by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	8029: {
		id: 8029,
		name: 'Transcendant Vitality',
		strengthPerLevel: 0.01,
		relatedSkills: ['constitution'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/debuffs/resurrection_sickness.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) => `An impossible strengthening of vitality.`,
		// endclient
	},
	8030: {
		id: 8030,
		name: 'Unbreakable Will',
		strengthPerLevel: 0.01,
		relatedSkills: ['constitution'],
		ignoreProlonging: true,
		isDebuff: true,
		// client
		buffIcon: '/images/magic/debuffs/resurrection_sickness.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) => `An impossible strengthening of willpower.`,
		// endclient
	},

	// DOT stuff, DEPRECATED
	9000: {
		id: 9000,
		name: 'Regeneration',
		strengthPerLevel: 1,
		relatedSkills: ['constitution'],
		ignoreProlonging: true,
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/overhealing_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Regenerate ${enchantmentStrength * strengthPerLevel} health per second.`,
		// endclient
	},

	9001: {
		id: 9001,
		name: 'Poisoned',
		strengthPerLevel: 1,
		relatedSkills: ['constitution'],
		ignoreProlonging: true,
		isDebuff: true,
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/overhealing_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Lose ${enchantmentStrength * strengthPerLevel} health per second.`,
		// endclient
	},

	9500: {
		id: 9500,
		name: 'Danger Zone',
		strengthPerLevel: 0.5,
		relatedSkills: ['gathering', 'mining', 'foraging', 'fishing'],
		// client
		buffIcon: '/images/magic/buffs/demon_skin.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases the chance of a dangerous encounter by ${
				enchantmentStrength * strengthPerLevel * 100
			}%. Reduces dangerous gathering delay by 10% and during dangerous gathering encounters, reduces damage taken and increases damage done by the same amount.`,
		// endclient
	},
	9501: {
		id: 9501,
		name: 'Calming Aura',
		strengthPerLevel: 0.5,
		relatedSkills: ['gathering', 'mining', 'foraging', 'fishing'],
		// client
		buffIcon: '/images/magic/buffs/heart.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Decreases the chance of a dangerous encounter by ${
				enchantmentStrength * strengthPerLevel * 100
			}%. Reduces dangerous gathering delay by 20% and during dangerous gathering encounters, reduces damage taken and increases damage done by the same amount`,
		// endclient
	},

	// SHRIMP BONUS USES DEMONSKIN, ID 2001
	// SET BONUSES
	10001: {
		id: 10001,
		name: 'Master Fisherman',
		strengthPerLevel: 0.15,
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 4, strength: 0 },
		],
		relatedSkills: ['fishing'],
		statBuffs: [
			{ path: 'fishing', additivePerLevel: 0, multiplicativePerLevel: 0.15 },
			{ path: 'fishingBaitPower', additivePerLevel: 0, multiplicativePerLevel: 0.15 },
			{ path: 'fishingRarityPower', additivePerLevel: 0, multiplicativePerLevel: 0.15 },
			{ path: 'fishingReelPower', additivePerLevel: 0, multiplicativePerLevel: 0.15 },
		],
		// client
		buffIcon: '/images/fishing/equipment/bucket_hat.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases equipment based fishing stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Does not affect bait. Reduces damage taken and increases damage done by 25% in dangerous gathering encounters.`,
		// endclient
	},
	10002: {
		id: 10002,
		name: 'Legendary Angler',
		strengthPerLevel: 0.3,
		setRequirements: [{ count: 4, strength: 1 }],
		relatedSkills: ['fishing'],
		statBuffs: [
			{ path: 'fishing', additivePerLevel: 0, multiplicativePerLevel: 0.3 },
			{ path: 'fishingBaitPower', additivePerLevel: 0, multiplicativePerLevel: 0.3 },
			{ path: 'fishingRarityPower', additivePerLevel: 0, multiplicativePerLevel: 0.3 },
			{ path: 'fishingReelPower', additivePerLevel: 0, multiplicativePerLevel: 0.3 },
		],
		// client
		buffIcon: '/images/fishing/Master_Tacklebox.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Overrides Master Fisherman. Increases equipment based fishing stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Does not affect bait. Reduces damage taken and increases damage done by 50% in dangerous gathering encounters.`,
		// endclient
	},
	10003: {
		id: 10003,
		name: 'Jolly',
		strengthPerLevel: 1,
		relatedSkills: ['total'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/magic/buffs/fishing.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) => `Ho ho ho! Christmas 2021 Set Bonus!`,
		// endclient
	},
	10004: {
		id: 10004,
		name: "Phoenix's Flame",
		strengthPerLevel: 0.25,
		relatedSkills: ['combat', 'constitution'],
		setRequirements: [{ count: 1, strength: 1 }],
		// client
		buffIcon: '/images/magic/buffs/pyromancy.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Gives a ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}% chance for a fatal blow to be rendered non-fatal; if this happens the Resurrection Sickness debuff will be applied. Does not stack with other Resurrection types.`,
		// endclient
	},
	10005: {
		id: 10005,
		name: 'Master Forager',
		strengthPerLevel: 0.2,
		relatedSkills: ['foraging'],
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 4, strength: 0 },
		],
		statBuffs: [{ path: 'foraging', additivePerLevel: 0, multiplicativePerLevel: 0.2 }],
		// client
		buffIcon: '/images/foraging/Foraging_hat.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases equipment based foraging stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 25% in dangerous gathering encounters.`,
		// endclient
	},
	10006: {
		id: 10006,
		name: 'Legendary Lumberjack',
		strengthPerLevel: 0.4,
		setRequirements: [{ count: 4, strength: 1 }],
		relatedSkills: ['foraging'],
		statBuffs: [{ path: 'foraging', additivePerLevel: 0, multiplicativePerLevel: 0.4 }],
		// client
		buffIcon: '/images/foraging/grovekeeper.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Overrides Master Forager. Increases equipment based foraging stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 50% in dangerous gathering encounters.`,
		// endclient
	},
	10007: {
		id: 10007,
		name: 'Master Miner',
		strengthPerLevel: 0.2,
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 4, strength: 0 },
		],
		relatedSkills: ['mining'],
		statBuffs: [{ path: 'mining', additivePerLevel: 0, multiplicativePerLevel: 0.2 }],
		// client
		buffIcon: '/images/fishing/equipment/bucket_hat.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases equipment based mining stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 25% in dangerous gathering encounters.`,
		// endclient
	},
	10008: {
		id: 10008,
		name: 'Legendary Rockbreaker',
		strengthPerLevel: 0.4,
		setRequirements: [{ count: 4, strength: 1 }],
		relatedSkills: ['mining'],
		statBuffs: [{ path: 'mining', additivePerLevel: 0, multiplicativePerLevel: 0.4 }],
		// client
		buffIcon: '/images/mining/dwarven_pickaxe.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Overrides Master Miner. Increases equipment based mining stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 50% in dangerous gathering encounters.`,
		// endclient
	},
	10009: {
		id: 10009,
		name: 'Protective Plates',
		strengthPerLevel: 0.075,
		setRequirements: [
			{ count: 2, strength: 1 },
			{ count: 3, strength: 2 },
			{ count: 4, strength: 3 },
		],
		relatedSkills: ['defense'],
		statBuffs: [{ path: 'armor.protection', additivePerLevel: 0, multiplicativePerLevel: 0.075 }],
		// client
		buffIcon: '/images/magic/buffs/protection_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases protection by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	10010: {
		id: 10010,
		name: "Ranger's Grace",
		strengthPerLevel: 0.1,
		setRequirements: [
			{ count: 2, strength: 1 },
			{ count: 4, strength: 2 },
		],
		relatedSkills: ['defense', 'range'],
		statBuffs: [{ path: 'armor.agility', additivePerLevel: 0, multiplicativePerLevel: 0.1 }],
		// client
		buffIcon: '/images/magic/buffs/nimble.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases agility by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	10011: {
		id: 10011,
		name: 'Magical Barriers',
		strengthPerLevel: 0.1,
		setRequirements: [
			{ count: 2, strength: 1 },
			{ count: 4, strength: 2 },
		],
		relatedSkills: ['defense', 'magic'],
		statBuffs: [{ path: 'armor.resistance', additivePerLevel: 0, multiplicativePerLevel: 0.1 }],
		// client
		buffIcon: '/images/magic/buffs/resistance.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases resistance by ${Math.round(enchantmentStrength * strengthPerLevel * 100)}%.`,
		// endclient
	},
	10012: {
		id: 10012,
		name: 'Dextrous Shot',
		strengthPerLevel: 0.025,
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 4, strength: 2 },
		],
		relatedSkills: ['range'],
		statBuffs: [{ path: 'weapon.dexterity', additivePerLevel: 0, multiplicativePerLevel: 0.025 }],
		// client
		buffIcon: '/images/magic/buffs/accuracy_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases dexterity by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(1)}% and gives a ${(
				enchantmentStrength *
				strengthPerLevel *
				2 *
				100
			).toFixed(1)}% to not consume ammo when casting a Range ability.`,
		// endclient
	},
	10013: {
		id: 10013,
		name: "Wizard's Wisdom",
		strengthPerLevel: 0.025,
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 4, strength: 2 },
		],
		relatedSkills: ['magic'],
		statBuffs: [{ path: 'weapon.intellect', additivePerLevel: 0, multiplicativePerLevel: 0.025 }],
		// client
		buffIcon: '/images/magic/buffs/convergence.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases intellect by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(1)}% and gives a ${(
				enchantmentStrength *
				strengthPerLevel *
				2 *
				100
			).toFixed(1)}% to not consume ammo when casting a Magic ability.`,
		// endclient
	},
	10014: {
		id: 10014,
		name: "Elder's Elegance",
		strengthPerLevel: 0.15,
		setRequirements: [
			{ count: 4, strength: 2 },
			{ count: 5, strength: 3 },
		],
		relatedSkills: ['magic', 'range', 'defense'],
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Nature', additivePerLevel: 0, multiplicativePerLevel: 0.05 },
			{ path: 'offensiveDamageAffinity.Nature', additivePerLevel: 0, multiplicativePerLevel: 0.05 },
			{ path: 'offensiveAccuracyAffinityRating.Range', additivePerLevel: 0, multiplicativePerLevel: 0.15 },
			{ path: 'offensiveAccuracyAffinityRating.Magic', additivePerLevel: 0, multiplicativePerLevel: 0.15 },
			{ path: 'offensiveAccuracyAffinityRating.Range', additivePerLevel: 0, multiplicativePerLevel: 0.15 },
			{ path: 'armor.agility', additivePerLevel: 0, multiplicativePerLevel: 0.15 },
		],
		// client
		buffIcon: '/images/magic/buffs/alacrity.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases magic accuracy, range accuracy, and agility by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(0)}%. Increases nature offensive and defensive affinities by ${(
				enchantmentStrength *
				0.05 *
				100
			).toFixed(0)}%.`,
		// endclient
	},
	10015: {
		id: 10015,
		name: "Valley's Vigor",
		strengthPerLevel: 0.15,
		setRequirements: [{ count: 2, strength: 1 }],
		relatedSkills: ['constitution', 'defense'],
		statBuffs: [
			{ path: 'armor.stamina', additivePerLevel: 0, multiplicativePerLevel: 0.15 },
			{ path: 'defensiveDamageAffinity.Ice', additivePerLevel: 0, multiplicativePerLevel: 0.05 },
			{ path: 'defensiveDamageAffinity.Fire', additivePerLevel: 0, multiplicativePerLevel: 0.05 },
			{ path: 'defensiveDamageAffinity.Nature', additivePerLevel: 0, multiplicativePerLevel: 0.05 },
		],
		// client
		buffIcon: '/images/magic/buffs/impenetrabledefence_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases stamina by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(
				0
			)}%. Increases ice, fire, and nature defensive affinities by ${(enchantmentStrength * 0.05 * 100).toFixed(
				0
			)}%. Increases health gained from food by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(0)}%.`,
		// endclient
	},
	10016: {
		id: 10016,
		name: 'Nigh-Invincibility',
		strengthPerLevel: 0.1,
		setRequirements: [{ count: 6, strength: 1 }],
		relatedSkills: ['constitution', 'defense'],
		statBuffs: [
			{ path: 'armor.stamina', additivePerLevel: 0, multiplicativePerLevel: 0.1 },
			{ path: 'armor.resistance', additivePerLevel: 0, multiplicativePerLevel: 0.1 },
			{ path: 'armor.protection', additivePerLevel: 0, multiplicativePerLevel: 0.1 },
		],
		// client
		buffIcon: '/images/magic/buffs/impenetrabledefence_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases protection, resistance, and stamina by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Enhances parries to negate all damage when you parry. Increases threat by 25%. Increases strength of self de/buffs by 2. Increases strength of team de/buffs by 1. Increases enemy de/buffs stacks by 25%.`,
		// endclient
	},
	10017: {
		id: 10017,
		name: 'Bestial Fury',
		strengthPerLevel: 0.1,
		setRequirements: [
			{ count: 2, strength: 1 },
			{ count: 3, strength: 2 },
		],
		relatedSkills: ['combat', 'defense', 'strength'],
		statBuffs: [{ path: 'armor.agility', additivePerLevel: 0, multiplicativePerLevel: 0.1 }],
		// client
		buffIcon: '/images/magic/buffs/patience_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases agility by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(0)}%. Gives a ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(0)}% chance to decrease attack delay by 25%.`,
		// endclient
	},
	10018: {
		id: 10018,
		name: "Gargoyle's Gift",
		strengthPerLevel: 0.03,
		setRequirements: [
			{ count: 2, strength: 1 },
			{ count: 3, strength: 2 },
			{ count: 4, strength: 3 },
			{ count: 5, strength: 4 },
			{ count: 6, strength: 5 },
			{ count: 7, strength: 6 },
		],
		relatedSkills: ['strength'],
		statBuffs: [
			{ path: 'weapon.strength', additivePerLevel: 0, multiplicativePerLevel: 0.03 },
			{ path: 'offensiveDamageAffinity.Melee', additivePerLevel: 0, multiplicativePerLevel: 0.02 },
		],
		// client
		buffIcon: '/images/combat/equipment/gargoyle_glaive_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases strength by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(
				0
			)}% and melee affinity by ${(enchantmentStrength * 0.02 * 100).toFixed(0)}%.`,
		// endclient
	},
	10019: {
		id: 10019,
		name: "Nature's Blessing",
		strengthPerLevel: 0.25,
		setRequirements: [{ count: 3, strength: 1 }],
		relatedSkills: ['magic', 'constitution'],
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Nature', additivePerLevel: 0, multiplicativePerLevel: 0.25 },
			{ path: 'offensiveDamageAffinity.Nature', additivePerLevel: 0, multiplicativePerLevel: 0.25 },
		],
		// client
		buffIcon: '/images/jewellery/ancient_nature_talisman.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases defensive and offensive nature affinities by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(
				0
			)}%. Increases strength of all de/buffs by 2. Increases healing given to allies by 200%. Gives a 25% chance on healing an ally to also grant 3 stack of the Healing buff at a strength of 1; will not apply if the buff ability requires runes and not enough are supplied.`,
		// endclient
	},
	10020: {
		id: 10020,
		name: 'Living Death',
		strengthPerLevel: 0.33,
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 5, strength: 2 },
		],
		relatedSkills: ['magic'],
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Poison', additivePerLevel: 0, multiplicativePerLevel: 0.33 },
			{ path: 'offensiveDamageAffinity.Poison', additivePerLevel: 0, multiplicativePerLevel: 0.33 },
		],
		// client
		buffIcon: '/images/combat/equipment/magic/necro_cowl.PNG',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases offensive and defensive Poison affinities by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(0)}%. Increases de/buffs given to enemies by ${
				enchantmentStrength * 2
			}. Increases damage dealt by DOTs by ${(enchantmentStrength * 150).toFixed(
				0
			)}%. Decreases delay between DOT ticks by ${(enchantmentStrength * 25).toFixed(
				0
			)}%. Counteracts the damage malus from Swift Agony based on missing health percentage; the malus is reduced by 1% for every 1% of health missing starting at 75% health and completely negated at 25% health remaining.`,
		// endclient
	},
	10021: {
		id: 10021,
		name: 'Royal Reverence',
		strengthPerLevel: 0.025,
		setRequirements: [
			{ count: 2, strength: 4 },
			{ count: 3, strength: 6 },
		],
		relatedSkills: ['range', 'defense'],
		statBuffs: [{ path: 'armor.agility', additivePerLevel: 0, multiplicativePerLevel: 0.05 }],
		// client
		buffIcon: '/images/combat/equipment/kings_crown.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases agility by ${(enchantmentStrength * strengthPerLevel * 100).toFixed(0)}% and gives a ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(
				0
			)}% chance to gain a stack of Acrobatics 5 (chance to increase attack speed) on dodge. If this is from a set bonus, for every missing percent of missing health under
			50% hp, your critical damage is additively increased by 1% and critical chance is additively increased by 0.5%.`,
		// endclient
	},
	10022: {
		id: 10022,
		name: 'Infernal Strength',
		strengthPerLevel: 0.1,
		setRequirements: [{ count: 3, strength: 1 }],
		relatedSkills: ['strength'],
		statBuffs: [
			{ path: 'weapon.strength', additivePerLevel: 0, multiplicativePerLevel: 0.1 },
			{ path: 'offensiveDamageAffinity.Fire', additivePerLevel: 0, multiplicativePerLevel: 0.1 },
			{ path: 'defensiveDamageAffinity.Fire', additivePerLevel: 0, multiplicativePerLevel: 0.1 },
		],
		// client
		buffIcon: '/images/combat/equipment/infernal_lance_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases strength and both defensive and offensive Fire affinities by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(
				0
			)}%. Increases max hit AND threat by 1% per 1% of health missing, starting at 20% of your maximum HP. Your AOE attacks ignore damage loss from hitting multiple targets.`,
		// endclient
	},
	10023: {
		id: 10023,
		name: 'Master Smith',
		strengthPerLevel: 0.2,
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 4, strength: 0 },
		],
		relatedSkills: ['smithing'],
		statBuffs: [{ path: 'smithing', additivePerLevel: 0, multiplicativePerLevel: 0.2 }],
		// client
		buffIcon: '/images/combat/equipment/crest_of_chaos_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases equipment based smithing stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 25% in dangerous gathering encounters.`,
		// endclient
	},
	10024: {
		id: 10024,
		name: 'Legendary Smith',
		strengthPerLevel: 0.4,
		setRequirements: [{ count: 4, strength: 1 }],
		relatedSkills: ['smithing'],
		statBuffs: [{ path: 'smithing', additivePerLevel: 0, multiplicativePerLevel: 0.4 }],
		// client
		buffIcon: '/images/smithing/dwarven_tongs.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Overrides Master Smith. Increases equipment based smithing stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 50% in dangerous gathering encounters.`,
		// endclient
	},
	10025: {
		id: 10025,
		name: 'Master Chef (Set)',
		strengthPerLevel: 0.2,
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 4, strength: 0 },
		],
		relatedSkills: ['cooking'],
		statBuffs: [{ path: 'cooking', additivePerLevel: 0, multiplicativePerLevel: 0.2 }],
		// client
		buffIcon: '/images/cooking/equipment/cooking_hat.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases equipment based cooking stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 25% in dangerous gathering encounters.`,
		// endclient
	},
	10026: {
		id: 10026,
		name: 'Legendary Chef',
		strengthPerLevel: 0.4,
		setRequirements: [{ count: 4, strength: 1 }],
		relatedSkills: ['cooking'],
		statBuffs: [{ path: 'cooking', additivePerLevel: 0, multiplicativePerLevel: 0.4 }],
		// client
		buffIcon: '/images/cooking/golden_spoon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Overrides Master Chef. Increases equipment based cooking stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 50% in dangerous gathering encounters.`,
		// endclient
	},
	// Master Runecrafter and Legendary Runecrafter for Runecrafting
	10027: {
		id: 10027,
		name: 'Master Runecrafter',
		strengthPerLevel: 0.2,
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 4, strength: 0 },
		],
		relatedSkills: ['runecrafting'],
		statBuffs: [{ path: 'runecrafting', additivePerLevel: 0, multiplicativePerLevel: 0.2 }],
		// client
		buffIcon: '/images/fishing/equipment/bucket_hat.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases equipment based runecrafting stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 25% in dangerous gathering encounters.`,
		// endclient
	},
	10028: {
		id: 10028,
		name: 'Legendary Runecrafter',
		strengthPerLevel: 0.4,
		setRequirements: [{ count: 4, strength: 1 }],
		relatedSkills: ['runecrafting'],
		statBuffs: [{ path: 'runecrafting', additivePerLevel: 0, multiplicativePerLevel: 0.4 }],
		// client
		buffIcon: '/images/runecrafting/chisel_2.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Overrides Master Runecrafter. Increases equipment based runecrafting stats by ${Math.round(
				enchantmentStrength * strengthPerLevel * 100
			)}%. Reduces damage taken and increases damage done by 50% in dangerous gathering encounters.`,
		// endclient
	},
	// The basic shrimp set offensive bonus
	// The shrimp set is comprised of helm, torso, legs, gloves, mainhand, and offhand
	// Intention of these set bonuses: flexible and allowing multiple styles with one armor set
	// Should be generally weaker in raw statsthan the specialized sets, i.e. infernal, necro, royal elven
	// But makes up for it in their flexibility and hybrid effects
	10029: {
		id: 10029,
		name: "Shrimp God's Hunger",
		strengthPerLevel: 0.06,
		setRequirements: [
			{ count: 4, strength: 4 }, // the 4 armor pieces
			{ count: 5, strength: 6 }, // 4 armor + 1 weapon
			{ count: 6, strength: 8 }, // 4 armor + main/offhand
		],
		relatedSkills: ['attack'],
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Poison', additivePerLevel: 0.06, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Chaos', additivePerLevel: 0.06, multiplicativePerLevel: 0 },
		],
		// client
		buffIcon: '/images/misc/shrimp_eye_sleep.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases offensive Poison and Chaos affinities by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(0)}%. DOTs deal an additive +${(enchantmentStrength * strengthPerLevel * 100 * 2).toFixed(
				0
			)}% damage.`,
		// endclient
	},
	// Can get both the range effect and either melee or magic
	10030: {
		id: 10030,
		name: 'Expanse of the Void',
		strengthPerLevel: 0.06,
		setRequirements: [{ count: 5, strength: 4 }],
		relatedSkills: ['magic'],
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Magic', additivePerLevel: 0.06, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Poison', additivePerLevel: 0.03, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Chaos', additivePerLevel: 0.03, multiplicativePerLevel: 0 },
			{ path: 'weapon.intellect', additivePerLevel: 0, multiplicativePerLevel: 0.06 },
		],
		// client
		buffIcon: '/images/combat/equipment/shrimp_staff_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases offensive Magic affinity and intellect by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(0)}%. Increases offensive Poison and Chaos affinities by ${(
				(enchantmentStrength * strengthPerLevel * 100) /
				2
			).toFixed(
				0
			)}%. Gives a flat 33% chance on DOT application to apply stun (causes enemy to miss), vulnerability (increases damage taken), or weakness (decreases damage done) for 1 stack at strength 3.`,
		// endclient
	},
	// Shrimp Stinger is offhand
	10031: {
		id: 10031,
		name: 'Call of the Void',
		strengthPerLevel: 0.06,
		setRequirements: [{ count: 5, strength: 4 }],
		relatedSkills: ['range'],
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Range', additivePerLevel: 0.06, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Poison', additivePerLevel: 0.03, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Chaos', additivePerLevel: 0.03, multiplicativePerLevel: 0 },
			{ path: 'weapon.dexterity', additivePerLevel: 0, multiplicativePerLevel: 0.06 },
			{ path: 'armor.agility', additivePerLevel: 0, multiplicativePerLevel: 0.06 },
		],
		// client
		buffIcon: '/images/combat/equipment/shrimp_dagger_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases offensive Range affinity, agility, and dexterity by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(0)}%. Increases offensive Poison and Chaos affinities by ${(
				(enchantmentStrength * strengthPerLevel * 100) /
				2
			).toFixed(0)}%. Gives a flat 10% chance per hit to increase the max targets of an ability by 1.`,
		// endclient
	},
	10032: {
		id: 10032,
		name: 'Desolation of the Void',
		strengthPerLevel: 0.06,
		setRequirements: [{ count: 5, strength: 4 }],
		relatedSkills: ['strength'],
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Melee', additivePerLevel: 0.06, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Poison', additivePerLevel: 0.03, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Chaos', additivePerLevel: 0.03, multiplicativePerLevel: 0 },
			{ path: 'weapon.strength', additivePerLevel: 0, multiplicativePerLevel: 0.06 },
		],
		// client
		buffIcon: '/images/combat/equipment/shrimp_whip_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases offensive Melee affinity and strength by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(0)}%. Increases offensive Poison and Chaos affinities by ${(
				(enchantmentStrength * strengthPerLevel * 100) /
				2
			).toFixed(
				0
			)}%. Gives a flat 20% chance on hit to apply stun (causes enemy to miss), vulnerability (increases damage taken), or weakness (decreases damage done) for 1 stack at strength 3.`,
		// endclient
	},
	// The shield is offhand and contributes most of the tank bonuses
	// Remember, they already have demon skin
	// They also can pair with with the scepter
	10033: {
		id: 10033,
		name: "Shrimp God's Shell",
		strengthPerLevel: 0.05,
		setRequirements: [{ count: 5, strength: 15 }],
		relatedSkills: ['strength'],
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Melee', additivePerLevel: 0.05, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Magic', additivePerLevel: 0.05, multiplicativePerLevel: 0 },
			{ path: 'defensiveDamageAffinity.Range', additivePerLevel: 0.05, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Melee', additivePerLevel: -0.025, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Magic', additivePerLevel: -0.025, multiplicativePerLevel: 0 },
			{ path: 'offensiveDamageAffinity.Range', additivePerLevel: -0.025, multiplicativePerLevel: 0 },
			{ path: 'armor.stamina', additivePerLevel: 0, multiplicativePerLevel: 0.015 },
			{ path: 'armor.resistance', additivePerLevel: 0, multiplicativePerLevel: 0.015 },
			{ path: 'armor.protection', additivePerLevel: 0, multiplicativePerLevel: 0.015 },
		],
		// client
		buffIcon: '/images/combat/equipment/shrimp_shield_icon.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Increases defensive Melee, Magic, and Range affinities by ${(
				enchantmentStrength *
				strengthPerLevel *
				100
			).toFixed(0)}%, but decreases offensive Melee, Magic, and Range affinities by ${(
				(enchantmentStrength * strengthPerLevel * 100) /
				2
			).toFixed(0)}%. Increases stamina, protection, and resistance by ${(
				enchantmentStrength *
				0.015 *
				100
			).toFixed(
				0
			)}%. Additionally applies the secondary bonuses from Nigh-Invincibility: enhanced parries, increased threat by 25%, increased strength of self de/buffs by 2, increased strength of team de/buffs by 1, and increased enemy de/buffs stacks by 25%.`,
		// endclient
	},
	10034: {
		id: 10034,
		name: 'Shrimp Skin',
		strengthPerLevel: 0.05,
		setRequirements: [
			{ count: 3, strength: 1 },
			{ count: 4, strength: 2 },
			{ count: 5, strength: 3 },
		],
		relatedSkills: ['defense'],
		// client
		buffIcon: '/images/magic/buffs/demon_skin.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) =>
			`Enhances Demon Skin, giving it 1.5x strength in damage calculations. Gives a ${(
				(enchantmentStrength * strengthPerLevel * 100) /
				2
			).toFixed(0)}% chance to deflect the damage negated by demon skin.`,
		// endclient
	},
	99999999: {
		id: 99999999,
		name: 'Affix',
		strengthPerLevel: 1,
		relatedSkills: ['total'],
		ignoredByMadness: true,
		// client
		buffIcon: '/images/misc/unknown_buff.png',
		getTooltip: (enchantmentStrength, strengthPerLevel) => `This is an Affix`,
		// endclient
	},
};

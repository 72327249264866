// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { TProductionRecipeListClient } from '../../../game-server/src/modules/crafting-augmenting/crafting-augmenting.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const productionRecipeList: TProductionRecipeListClient = {
	[itemsIds.fertilizer]: {
		crafting: [
			{ recipe: { [itemsIds.burnt_food]: 10, [itemsIds.sand]: 10 } },
			{ recipe: { [itemsIds.burnt_fish]: 10, [itemsIds.sand]: 10 } },
			{ recipe: { [itemsIds.sand]: 10, [itemsIds.ashes]: 10 } },
			{ recipe: { [itemsIds.clay]: 10, [itemsIds.sand]: 10 } },
			{ recipe: { [itemsIds.sand]: 5, [itemsIds.bone]: 10 } },
			{ recipe: { [itemsIds.clay]: 5, [itemsIds.bone]: 10 } },
			{ recipe: { [itemsIds.sand]: 5, [itemsIds.bone_plate]: 1 }, multiplier: 3 },
			{ recipe: { [itemsIds.clay]: 5, [itemsIds.bone_plate]: 1 }, multiplier: 3 },
		],
	},
	[itemsIds.mysterious_seed]: {
		crafting: [
			{ recipe: { [itemsIds.sageberry_bush_seed]: 1, [itemsIds.ichor]: 100 } },
			{ recipe: { [itemsIds.sageberry_bush_seed]: 1, [itemsIds.void_stone]: 100 } },
			{
				recipe: {
					[itemsIds.thorny_root]: 50,
					[itemsIds.thorny_vine]: 50,
					[itemsIds.tangle_root]: 50,
					[itemsIds.bone]: 200,
				},
			},
		],
	},
	[itemsIds.bronze_bar]: {
		smithing: { [itemsIds.heat]: 1, [itemsIds.copper_ore]: 1, [itemsIds.tin_ore]: 1 },
	},
	[itemsIds.iron_bar]: {
		smithing: { [itemsIds.heat]: 5, [itemsIds.iron_ore]: 3 },
	},
	[itemsIds.gold_bar]: {
		smithing: { [itemsIds.heat]: 20, [itemsIds.gold_ore]: 5 },
	},
	[itemsIds.mithril_bar]: {
		smithing: { [itemsIds.heat]: 50, [itemsIds.mithril_ore]: 5 },
	},
	[itemsIds.adamantite_bar]: {
		smithing: { [itemsIds.heat]: 100, [itemsIds.adamantite_ore]: 10 },
	},
	[itemsIds.runite_bar]: {
		smithing: { [itemsIds.heat]: 200, [itemsIds.runite_ore]: 12 },
	},
	[itemsIds.stygian_bar]: {
		smithing: { [itemsIds.heat]: 500, [itemsIds.stygian_ore]: 15, [itemsIds.ichor]: 5 },
	},
	[itemsIds.chaotic_crystal]: {
		smithing: { [itemsIds.heat]: 500, [itemsIds.void_stone]: 20 },
	},
	[itemsIds.chitin]: {
		crafting: [{ recipe: { [itemsIds.heavy_chitin]: 1 } }],
	},
	[itemsIds.scale]: {
		crafting: [{ recipe: { [itemsIds.heavy_scale]: 1 } }],
	},
	[itemsIds.log]: {
		crafting: [{ recipe: { [itemsIds.branch]: 20 } }],
	},
	[itemsIds.air_talisman]: {
		crafting: [{ recipe: { [itemsIds.air_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.earth_talisman]: {
		crafting: [{ recipe: { [itemsIds.earth_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.fire_talisman]: {
		crafting: [{ recipe: { [itemsIds.fire_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.water_talisman]: {
		crafting: [{ recipe: { [itemsIds.water_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.blood_talisman]: {
		crafting: [{ recipe: { [itemsIds.blood_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.death_talisman]: {
		crafting: [{ recipe: { [itemsIds.death_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.chaos_talisman]: {
		crafting: [{ recipe: { [itemsIds.chaos_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.nature_talisman]: {
		crafting: [{ recipe: { [itemsIds.nature_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.mind_talisman]: {
		crafting: [{ recipe: { [itemsIds.mind_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.cosmic_talisman]: {
		crafting: [{ recipe: { [itemsIds.cosmic_essence]: 50000, [itemsIds.unstable_talisman]: 1 } }],
	},
	[itemsIds.air_rune]: {
		runecrafting: { [itemsIds.air_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.earth_rune]: {
		runecrafting: { [itemsIds.earth_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.fire_rune]: {
		runecrafting: { [itemsIds.fire_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.water_rune]: {
		runecrafting: { [itemsIds.water_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.blood_rune]: {
		runecrafting: { [itemsIds.blood_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.death_rune]: {
		runecrafting: { [itemsIds.death_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.chaos_rune]: {
		runecrafting: { [itemsIds.chaos_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.nature_rune]: {
		runecrafting: { [itemsIds.nature_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.mind_rune]: {
		runecrafting: { [itemsIds.mind_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.cosmic_rune]: {
		runecrafting: { [itemsIds.cosmic_essence]: 400, [itemsIds.rune_slate]: 1 },
	},
	[itemsIds.bronze_pickaxe]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 40, [itemsIds.log]: 20 } }],
		augmenting: { [itemsIds.bronze_bar]: 4, [itemsIds.log]: 2 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_pickaxe]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75, [itemsIds.oak_log]: 35 } }],
		augmenting: { [itemsIds.iron_bar]: 8, [itemsIds.oak_log]: 4 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.mithril_pickaxe]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 100, [itemsIds.willow_log]: 50 } }],
		augmenting: { [itemsIds.mithril_bar]: 13, [itemsIds.willow_log]: 7 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_pickaxe]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 100, [itemsIds.maple_log]: 50 } }],
		augmenting: { [itemsIds.adamantite_bar]: 20, [itemsIds.maple_log]: 10 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_pickaxe]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 100, [itemsIds.yew_log]: 50 } }],
		augmenting: { [itemsIds.runite_bar]: 27, [itemsIds.yew_log]: 14 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_pickaxe]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 100, [itemsIds.ichor]: 50 } }],
		augmenting: { [itemsIds.stygian_bar]: 35, [itemsIds.ichor]: 20 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.dwarven_rocksmasher]: {
		crafting: [
			{
				recipe: {
					[itemsIds.stygian_bar]: 200,
					[itemsIds.chaotic_crystal]: 100,
					[itemsIds.dwarven_alloy]: 1,
					[itemsIds.dwarven_ingot]: 1,
					[itemsIds.dwarven_mold]: 1,
				},
			},
		],
		augmenting: {
			[itemsIds.stygian_ore]: 750,
			[itemsIds.void_stone]: 250,
			[itemsIds.dwarven_alloy]: 3,
			[itemsIds.dwarven_ingot]: 3,
			[itemsIds.dwarven_mold]: 3,
		},
		scrapping: { [itemsIds.mining_shard]: 30, [itemsIds.stygian_bar]: 1, [itemsIds.stone]: 3 },
	},
	[itemsIds.bronze_hatchet]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 40, [itemsIds.log]: 20 } }],
		augmenting: { [itemsIds.bronze_bar]: 4, [itemsIds.log]: 2 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_hatchet]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75, [itemsIds.oak_log]: 35 } }],
		augmenting: { [itemsIds.iron_bar]: 8, [itemsIds.oak_log]: 4 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.mithril_hatchet]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 100, [itemsIds.willow_log]: 50 } }],
		augmenting: { [itemsIds.mithril_bar]: 13, [itemsIds.willow_log]: 7 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_hatchet]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 100, [itemsIds.maple_log]: 50 } }],
		augmenting: { [itemsIds.adamantite_bar]: 20, [itemsIds.maple_log]: 10 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_hatchet]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 100, [itemsIds.yew_log]: 50 } }],
		augmenting: { [itemsIds.runite_bar]: 27, [itemsIds.yew_log]: 14 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_hatchet]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 100, [itemsIds.ichor]: 50 } }],
		augmenting: { [itemsIds.stygian_bar]: 35, [itemsIds.ichor]: 20 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.the_grovekeeper]: {
		crafting: [
			{
				recipe: {
					[itemsIds.yew_log]: 1000,
					[itemsIds.elder_log]: 500,
					[itemsIds.very_mysterious_seedling]: 1,
					[itemsIds.elder_sap]: 1,
					[itemsIds.world_tree_branch]: 1,
				},
			},
		],
		augmenting: {
			[itemsIds.yew_log]: 2500,
			[itemsIds.elder_log]: 1000,
			[itemsIds.very_mysterious_seedling]: 3,
			[itemsIds.elder_sap]: 3,
			[itemsIds.world_tree_branch]: 3,
		},
		scrapping: { [itemsIds.foraging_shard]: 30, [itemsIds.elder_log]: 1, [itemsIds.yew_log]: 3 },
	},
	[itemsIds.sapphire_ring]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.sapphire]: 1, [itemsIds.gold_ring]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.sapphire]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.runic_etchant]: 10 },
	},
	[itemsIds.sapphire_necklace]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.sapphire]: 1, [itemsIds.gold_necklace]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.sapphire]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.runic_etchant]: 10 },
	},
	[itemsIds.emerald_ring]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.emerald]: 1, [itemsIds.gold_ring]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.emerald]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.corrupted_substrate]: 10 },
	},
	[itemsIds.emerald_necklace]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.emerald]: 1, [itemsIds.gold_necklace]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.emerald]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.corrupted_substrate]: 10 },
	},
	[itemsIds.ruby_ring]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.ruby]: 1, [itemsIds.gold_ring]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.ruby]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.reactive_powder]: 10 },
	},
	[itemsIds.ruby_necklace]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.ruby]: 1, [itemsIds.gold_necklace]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.ruby]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.reactive_powder]: 10 },
	},
	[itemsIds.diamond_ring]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.diamond]: 1, [itemsIds.gold_ring]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.diamond]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.polishing_paste]: 10 },
	},
	[itemsIds.diamond_necklace]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.diamond]: 1, [itemsIds.gold_necklace]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.diamond]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.polishing_paste]: 10 },
	},
	[itemsIds.black_opal_ring]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.black_opal]: 1, [itemsIds.gold_ring]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.black_opal]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.sanguine_infusion]: 10 },
	},
	[itemsIds.black_opal_necklace]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 50, [itemsIds.black_opal]: 1, [itemsIds.gold_necklace]: 1 } }],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.black_opal]: 1 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.sanguine_infusion]: 10 },
	},
	[itemsIds.prismatic_necklace]: {
		crafting: [
			{
				recipe: {
					[itemsIds.sapphire_necklace]: 150,
					[itemsIds.emerald_necklace]: 120,
					[itemsIds.ruby_necklace]: 90,
					[itemsIds.diamond_necklace]: 60,
					[itemsIds.prismatic_dust]: 650,
				},
			},
		],
		augmenting: {
			[itemsIds.gold_bar]: 250,
			[itemsIds.sapphire]: 32,
			[itemsIds.emerald]: 24,
			[itemsIds.ruby]: 12,
			[itemsIds.diamond]: 6,
			[itemsIds.black_opal]: 3,
		},
		scrapping: {
			[itemsIds.gold_bar]: 25,
			[itemsIds.runic_etchant]: 320,
			[itemsIds.corrupted_substrate]: 240,
			[itemsIds.reactive_powder]: 120,
			[itemsIds.polishing_paste]: 60,
			[itemsIds.sanguine_infusion]: 30,
		},
	},
	[itemsIds.prismatic_ring]: {
		crafting: [
			{
				recipe: {
					[itemsIds.sapphire_ring]: 150,
					[itemsIds.emerald_ring]: 120,
					[itemsIds.ruby_ring]: 90,
					[itemsIds.diamond_ring]: 60,
					[itemsIds.prismatic_dust]: 650,
				},
			},
		],
		augmenting: {
			[itemsIds.gold_bar]: 250,
			[itemsIds.sapphire]: 32,
			[itemsIds.emerald]: 24,
			[itemsIds.ruby]: 12,
			[itemsIds.diamond]: 6,
			[itemsIds.black_opal]: 3,
		},
		scrapping: {
			[itemsIds.gold_bar]: 25,
			[itemsIds.runic_etchant]: 320,
			[itemsIds.corrupted_substrate]: 240,
			[itemsIds.reactive_powder]: 120,
			[itemsIds.polishing_paste]: 60,
			[itemsIds.sanguine_infusion]: 30,
		},
	},
	[itemsIds.gold_ring]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 100 } }],
	},
	[itemsIds.gold_necklace]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 100 } }],
	},
	[itemsIds.corrupted_ring]: {
		crafting: [
			{
				recipe: {
					[itemsIds.gold_bar]: 300,
					[itemsIds.emerald]: 10,
					[itemsIds.black_opal]: 2,
					[itemsIds.nagas_bracelet]: 1,
				},
			},
		],
		augmenting: { [itemsIds.gold_bar]: 50, [itemsIds.emerald]: 5 },
		scrapping: { [itemsIds.gold_bar]: 5, [itemsIds.corrupted_substrate]: 50 },
	},
	[itemsIds.runic_etchant]: {
		crafting: [
			{
				recipe: {
					[itemsIds.sapphire]: 1,
					[itemsIds.water_rune]: 10,
					[itemsIds.seaweed]: 100,
				},
			},
		],
	},
	[itemsIds.corrupted_substrate]: {
		crafting: [
			{
				recipe: {
					[itemsIds.emerald]: 1,
					[itemsIds.nature_rune]: 10,
					[itemsIds.sour_essence]: 100,
				},
			},
		],
	},
	[itemsIds.reactive_powder]: {
		crafting: [
			{
				recipe: {
					[itemsIds.ruby]: 1,
					[itemsIds.fire_rune]: 10,
					[itemsIds.ashes]: 100,
				},
			},
			{
				recipe: {
					[itemsIds.ruby]: 1,
					[itemsIds.fire_rune]: 10,
					[itemsIds.burnt_food]: 100,
				},
			},
			{
				recipe: {
					[itemsIds.ruby]: 1,
					[itemsIds.fire_rune]: 10,
					[itemsIds.burnt_fish]: 100,
				},
			},
		],
	},
	[itemsIds.polishing_paste]: {
		crafting: [
			{
				recipe: {
					[itemsIds.diamond]: 1,
					[itemsIds.air_rune]: 10,
					[itemsIds.fish_oil]: 10,
				},
			},
		],
	},
	[itemsIds.sanguine_infusion]: {
		crafting: [
			{
				recipe: {
					[itemsIds.black_opal]: 1,
					[itemsIds.blood_rune]: 10,
					[itemsIds.ichor]: 100,
				},
			},
		],
	},
	[itemsIds.ancient_nature_amulet]: {
		augmenting: {
			[itemsIds.nature_essence]: 5000,
			[itemsIds.nature_talisman]: 1,
			[itemsIds.nature_rune]: 50,
		},
		scrapping: {
			[itemsIds.nature_rune]: 100,
		},
	},
	[itemsIds.ancient_fire_amulet]: {
		augmenting: { [itemsIds.fire_essence]: 5000, [itemsIds.fire_talisman]: 1, [itemsIds.fire_rune]: 50 },
		scrapping: {
			[itemsIds.fire_rune]: 100,
		},
	},
	[itemsIds.ancient_water_amulet]: {
		augmenting: { [itemsIds.water_essence]: 5000, [itemsIds.water_talisman]: 1, [itemsIds.water_rune]: 50 },
		scrapping: {
			[itemsIds.water_rune]: 100,
		},
	},
	[itemsIds.ancient_blood_amulet]: {
		augmenting: { [itemsIds.blood_essence]: 5000, [itemsIds.blood_talisman]: 1, [itemsIds.blood_rune]: 50 },
		scrapping: {
			[itemsIds.blood_rune]: 100,
		},
	},
	[itemsIds.ancient_air_amulet]: {
		augmenting: { [itemsIds.air_essence]: 5000, [itemsIds.air_talisman]: 1, [itemsIds.air_rune]: 50 },
		scrapping: {
			[itemsIds.air_rune]: 100,
		},
	},
	[itemsIds.bronze_hoe]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 40, [itemsIds.log]: 20 } }],
		augmenting: { [itemsIds.bronze_bar]: 4, [itemsIds.log]: 2 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_hoe]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75, [itemsIds.oak_log]: 35 } }],
		augmenting: { [itemsIds.iron_bar]: 8, [itemsIds.oak_log]: 4 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.mithril_hoe]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 100, [itemsIds.willow_log]: 50 } }],
		augmenting: { [itemsIds.mithril_bar]: 13, [itemsIds.willow_log]: 7 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_hoe]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 100, [itemsIds.maple_log]: 50 } }],
		augmenting: { [itemsIds.adamantite_bar]: 20, [itemsIds.maple_log]: 10 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_hoe]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 100, [itemsIds.yew_log]: 50 } }],
		augmenting: { [itemsIds.runite_bar]: 27, [itemsIds.yew_log]: 14 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_hoe]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 100, [itemsIds.ichor]: 50 } }],
		augmenting: { [itemsIds.stygian_bar]: 35, [itemsIds.ichor]: 20 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.bronze_tongs]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 40, [itemsIds.log]: 20 } }],
		augmenting: { [itemsIds.bronze_bar]: 4, [itemsIds.log]: 2 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_tongs]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75, [itemsIds.oak_log]: 35 } }],
		augmenting: { [itemsIds.iron_bar]: 8, [itemsIds.oak_log]: 4 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.mithril_tongs]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 100, [itemsIds.willow_log]: 50 } }],
		augmenting: { [itemsIds.mithril_bar]: 13, [itemsIds.willow_log]: 7 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_tongs]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 100, [itemsIds.maple_log]: 50 } }],
		augmenting: { [itemsIds.adamantite_bar]: 20, [itemsIds.maple_log]: 10 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_tongs]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 100, [itemsIds.yew_log]: 50 } }],
		augmenting: { [itemsIds.runite_bar]: 27, [itemsIds.yew_log]: 14 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_tongs]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 100, [itemsIds.ichor]: 50 } }],
		augmenting: { [itemsIds.stygian_bar]: 35, [itemsIds.ichor]: 20 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.lesser_magic_tome]: {
		crafting: [{ recipe: { [itemsIds.air_talisman]: 1, [itemsIds.scroll]: 5, [itemsIds.lesser_cloth]: 100 } }],
	},
	[itemsIds.major_magic_tome]: {
		crafting: [
			{
				recipe: {
					[itemsIds.earth_talisman]: 1,
					[itemsIds.fire_talisman]: 1,
					[itemsIds.lesser_magic_tome]: 1,
					[itemsIds.scroll]: 10,
					[itemsIds.imbued_cloth]: 200,
				},
			},
		],
	},
	[itemsIds.greater_magic_tome]: {
		crafting: [
			{
				recipe: {
					[itemsIds.water_talisman]: 1,
					[itemsIds.blood_talisman]: 1,
					[itemsIds.major_magic_tome]: 1,
					[itemsIds.scroll]: 20,
					[itemsIds.major_cloth]: 300,
				},
			},
		],
	},
	[itemsIds.elder_magic_tome]: {
		crafting: [
			{
				recipe: {
					[itemsIds.death_talisman]: 1,
					[itemsIds.chaos_talisman]: 1,
					[itemsIds.greater_magic_tome]: 1,
					[itemsIds.scroll]: 40,
					[itemsIds.greater_cloth]: 400,
				},
			},
		],
	},
	[itemsIds.ancient_magic_tome]: {
		crafting: [
			{
				recipe: {
					[itemsIds.nature_talisman]: 1,
					[itemsIds.mind_talisman]: 1,
					[itemsIds.cosmic_talisman]: 1,
					[itemsIds.elder_magic_tome]: 1,
					[itemsIds.scroll]: 80,
					[itemsIds.elder_cloth]: 500,
				},
			},
		],
	},
	[itemsIds.apprentice_runic_chisel]: {
		crafting: [{ recipe: { [itemsIds.rune_slate]: 1000, [itemsIds.sapphire]: 3, [itemsIds.emerald]: 1 } }],
		augmenting: { [itemsIds.rune_slate]: 50, [itemsIds.sapphire]: 1 },
		scrapping: { [itemsIds.rune_slate]: 5, [itemsIds.runic_etchant]: 10 },
	},
	[itemsIds.journeyman_runic_chisel]: {
		crafting: [
			{
				recipe: {
					[itemsIds.rune_slate]: 1000,
					[itemsIds.sapphire]: 15,
					[itemsIds.emerald]: 12,
					[itemsIds.ruby]: 9,
					[itemsIds.diamond]: 6,
				},
			},
		],
		augmenting: {
			[itemsIds.rune_slate]: 100,
			[itemsIds.sapphire]: 1,
			[itemsIds.emerald]: 1,
			[itemsIds.ruby]: 1,
		},
		scrapping: {
			[itemsIds.rune_slate]: 10,
			[itemsIds.runic_etchant]: 10,
			[itemsIds.corrupted_substrate]: 10,
			[itemsIds.reactive_powder]: 10,
		},
	},
	[itemsIds.master_runic_chisel]: {
		crafting: [
			{
				recipe: {
					[itemsIds.rune_slate]: 1000,
					[itemsIds.sapphire]: 30,
					[itemsIds.emerald]: 24,
					[itemsIds.ruby]: 18,
					[itemsIds.diamond]: 12,
					[itemsIds.black_opal]: 6,
				},
			},
		],
		augmenting: {
			[itemsIds.rune_slate]: 100,
			[itemsIds.emerald]: 1,
			[itemsIds.ruby]: 1,
			[itemsIds.diamond]: 1,
		},
		scrapping: {
			[itemsIds.rune_slate]: 10,
			[itemsIds.runic_etchant]: 10,
			[itemsIds.corrupted_substrate]: 10,
			[itemsIds.reactive_powder]: 10,
			[itemsIds.polishing_paste]: 10,
		},
	},
	[itemsIds.ancient_runic_chisel]: {
		crafting: [
			{
				recipe: {
					[itemsIds.rune_slate]: 1000,
					[itemsIds.sapphire]: 50,
					[itemsIds.emerald]: 40,
					[itemsIds.ruby]: 30,
					[itemsIds.diamond]: 20,
					[itemsIds.black_opal]: 10,
					[itemsIds.runecrafting_shard]: 3000,
				},
			},
		],
		augmenting: {
			[itemsIds.rune_slate]: 100,
			[itemsIds.emerald]: 1,
			[itemsIds.ruby]: 1,
			[itemsIds.diamond]: 1,
			[itemsIds.runecrafting_shard]: 300,
		},
		scrapping: {
			[itemsIds.rune_slate]: 10,
			[itemsIds.runic_etchant]: 5,
			[itemsIds.corrupted_substrate]: 5,
			[itemsIds.reactive_powder]: 5,
			[itemsIds.polishing_paste]: 5,
			[itemsIds.runecrafting_shard]: 30,
		},
	},
	[itemsIds.fishing_net]: {
		crafting: [{ recipe: { [itemsIds.branch]: 150 } }],
	},
	[itemsIds.fly_fishing_rod]: {
		crafting: [{ recipe: { [itemsIds.branch]: 200, [itemsIds.log]: 100 } }],
	},
	[itemsIds.cage]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 50, [itemsIds.oak_log]: 200 } }],
	},
	[itemsIds.harpoon]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 25, [itemsIds.willow_log]: 200 } }],
	},
	[itemsIds.pyre_log]: {
		crafting: [{ recipe: { [itemsIds.log]: 10, [itemsIds.fish_oil]: 1 } }],
	},
	[itemsIds.pyre_oak_log]: {
		crafting: [{ recipe: { [itemsIds.oak_log]: 10, [itemsIds.fish_oil]: 2 } }],
	},
	[itemsIds.pyre_willow_log]: {
		crafting: [{ recipe: { [itemsIds.willow_log]: 10, [itemsIds.fish_oil]: 3 } }],
	},
	[itemsIds.pyre_maple_log]: {
		crafting: [{ recipe: { [itemsIds.maple_log]: 10, [itemsIds.fish_oil]: 4 } }],
	},
	[itemsIds.pyre_yew_log]: {
		crafting: [{ recipe: { [itemsIds.yew_log]: 10, [itemsIds.fish_oil]: 5 } }],
	},
	[itemsIds.pyre_elder_log]: {
		crafting: [{ recipe: { [itemsIds.elder_log]: 10, [itemsIds.fish_oil]: 6 } }],
	},
	[itemsIds.charged_heat_stone]: {
		crafting: [{ recipe: { [itemsIds.heat]: 50000, [itemsIds.fire_rune]: 50, [itemsIds.inert_heat_stone]: 1 } }],
	},
	[itemsIds.bronze_scimitar]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 50, [itemsIds.log]: 10 } }],
		augmenting: { [itemsIds.bronze_bar]: 4, [itemsIds.log]: 1 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.bronze_battleaxe]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 50, [itemsIds.log]: 10 } }],
		augmenting: { [itemsIds.bronze_bar]: 6, [itemsIds.log]: 2 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.bronze_daggers]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 50, [itemsIds.log]: 10 } }],
		augmenting: { [itemsIds.bronze_bar]: 2, [itemsIds.log]: 1 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.bronze_greatsword]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 75, [itemsIds.log]: 15 } }],
		augmenting: { [itemsIds.bronze_bar]: 8, [itemsIds.log]: 2 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_scimitar]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75, [itemsIds.oak_log]: 15 } }],
		augmenting: { [itemsIds.iron_bar]: 8, [itemsIds.oak_log]: 2 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.iron_battleaxe]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75, [itemsIds.oak_log]: 30 } }],
		augmenting: { [itemsIds.iron_bar]: 10, [itemsIds.oak_log]: 3 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.iron_daggers]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75, [itemsIds.oak_log]: 10 } }],
		augmenting: { [itemsIds.iron_bar]: 5, [itemsIds.oak_log]: 1 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.iron_greatsword]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 100, [itemsIds.oak_log]: 25 } }],
		augmenting: { [itemsIds.iron_bar]: 13, [itemsIds.oak_log]: 3 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.obsidian_scimitar]: {
		augmenting: {
			[itemsIds.iron_bar]: 6,
			[itemsIds.mithril_bar]: 6,
			[itemsIds.obsidian_glass]: 1,
			[itemsIds.oak_log]: 2,
			[itemsIds.willow_log]: 2,
		},
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.obsidian_battleaxe]: {
		augmenting: {
			[itemsIds.iron_bar]: 8,
			[itemsIds.mithril_bar]: 8,
			[itemsIds.obsidian_glass]: 1,
			[itemsIds.oak_log]: 4,
			[itemsIds.willow_log]: 4,
		},
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.obsidian_daggers]: {
		augmenting: {
			[itemsIds.iron_bar]: 5,
			[itemsIds.mithril_bar]: 5,
			[itemsIds.obsidian_glass]: 1,
			[itemsIds.oak_log]: 2,
			[itemsIds.willow_log]: 2,
		},
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.obsidian_greatsword]: {
		augmenting: {
			[itemsIds.iron_bar]: 10,
			[itemsIds.mithril_bar]: 10,
			[itemsIds.obsidian_glass]: 1,
			[itemsIds.oak_log]: 3,
			[itemsIds.willow_log]: 3,
		},
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_scimitar]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 100, [itemsIds.willow_log]: 50 } }],
		augmenting: { [itemsIds.mithril_bar]: 13, [itemsIds.willow_log]: 3 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_battleaxe]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 100, [itemsIds.willow_log]: 50 } }],
		augmenting: { [itemsIds.mithril_bar]: 16, [itemsIds.willow_log]: 5 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_daggers]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 100, [itemsIds.willow_log]: 50 } }],
		augmenting: { [itemsIds.mithril_bar]: 10, [itemsIds.willow_log]: 3 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_greatsword]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 150, [itemsIds.willow_log]: 50 } }],
		augmenting: { [itemsIds.mithril_bar]: 20, [itemsIds.willow_log]: 4 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_scimitar]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 100, [itemsIds.maple_log]: 50 } }],
		augmenting: { [itemsIds.adamantite_bar]: 13, [itemsIds.maple_log]: 3 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.adamantite_battleaxe]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 100, [itemsIds.maple_log]: 50 } }],
		augmenting: { [itemsIds.adamantite_bar]: 16, [itemsIds.maple_log]: 5 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.adamantite_daggers]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 100, [itemsIds.maple_log]: 50 } }],
		augmenting: { [itemsIds.adamantite_bar]: 10, [itemsIds.maple_log]: 3 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.adamantite_greatsword]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 150, [itemsIds.maple_log]: 50 } }],
		augmenting: { [itemsIds.adamantite_bar]: 20, [itemsIds.maple_log]: 4 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_scimitar]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 100, [itemsIds.yew_log]: 50 } }],
		augmenting: { [itemsIds.runite_bar]: 13, [itemsIds.yew_log]: 3 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.runite_battleaxe]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 100, [itemsIds.yew_log]: 50 } }],
		augmenting: { [itemsIds.runite_bar]: 16, [itemsIds.yew_log]: 5 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.runite_daggers]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 100, [itemsIds.yew_log]: 50 } }],
		augmenting: { [itemsIds.runite_bar]: 10, [itemsIds.yew_log]: 3 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.runite_greatsword]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 150, [itemsIds.yew_log]: 50 } }],
		augmenting: { [itemsIds.runite_bar]: 20, [itemsIds.yew_log]: 4 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_scimitar]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 100, [itemsIds.ichor]: 50 } }],
		augmenting: { [itemsIds.stygian_bar]: 35, [itemsIds.ichor]: 7 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.stygian_battleaxe]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 100, [itemsIds.ichor]: 50 } }],
		augmenting: { [itemsIds.stygian_bar]: 40, [itemsIds.ichor]: 12 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.stygian_daggers]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 100, [itemsIds.ichor]: 50 } }],
		augmenting: { [itemsIds.stygian_bar]: 30, [itemsIds.ichor]: 6 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.stygian_greatsword]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 150, [itemsIds.ichor]: 50 } }],
		augmenting: { [itemsIds.stygian_bar]: 45, [itemsIds.ichor]: 9 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.bronze_helm]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 20 } }],
		augmenting: { [itemsIds.bronze_bar]: 2 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_helm]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 50 } }],
		augmenting: { [itemsIds.iron_bar]: 5 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.obsidian_helm]: {
		augmenting: { [itemsIds.iron_bar]: 5, [itemsIds.mithril_bar]: 5, [itemsIds.obsidian_glass]: 1 },
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_helm]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 100 } }],
		augmenting: { [itemsIds.mithril_bar]: 10 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_helm]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 100 } }],
		augmenting: { [itemsIds.adamantite_bar]: 16 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_helm]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 100 } }],
		augmenting: { [itemsIds.runite_bar]: 23 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_helm]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 100, [itemsIds.ichor]: 50 } }],
		augmenting: { [itemsIds.stygian_bar]: 30, [itemsIds.ichor]: 6 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.bronze_full_helm]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 50 } }],
		augmenting: { [itemsIds.bronze_bar]: 4 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_full_helm]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75 } }],
		augmenting: { [itemsIds.iron_bar]: 8 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.obsidian_full_helm]: {
		augmenting: { [itemsIds.iron_bar]: 6, [itemsIds.mithril_bar]: 6, [itemsIds.obsidian_glass]: 1 },
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_full_helm]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 120 } }],
		augmenting: { [itemsIds.mithril_bar]: 13 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_full_helm]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 120 } }],
		augmenting: { [itemsIds.adamantite_bar]: 20 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_full_helm]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 120 } }],
		augmenting: { [itemsIds.runite_bar]: 27 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_full_helm]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 120, [itemsIds.ichor]: 60 } }],
		augmenting: { [itemsIds.stygian_bar]: 35, [itemsIds.ichor]: 7 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.bronze_shield]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 50 } }],
		augmenting: { [itemsIds.bronze_bar]: 6 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_shield]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75 } }],
		augmenting: { [itemsIds.iron_bar]: 10 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.obsidian_shield]: {
		augmenting: { [itemsIds.iron_bar]: 8, [itemsIds.mithril_bar]: 8, [itemsIds.obsidian_glass]: 1 },
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_shield]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 120 } }],
		augmenting: { [itemsIds.mithril_bar]: 16 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_shield]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 120 } }],
		augmenting: { [itemsIds.adamantite_bar]: 24 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_shield]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 120 } }],
		augmenting: { [itemsIds.runite_bar]: 32 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_shield]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 120, [itemsIds.ichor]: 60 } }],
		augmenting: { [itemsIds.stygian_bar]: 40, [itemsIds.ichor]: 8 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.castle_door]: {
		augmenting: { [itemsIds.stygian_bar]: 40, [itemsIds.ichor]: 8 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.bronze_breastplate]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 100 } }],
		augmenting: { [itemsIds.bronze_bar]: 10 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.bronze_chainmail]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 60 } }],
		augmenting: { [itemsIds.bronze_bar]: 6 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_breastplate]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 150 } }],
		augmenting: { [itemsIds.iron_bar]: 15 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.iron_chainmail]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 100 } }],
		augmenting: { [itemsIds.iron_bar]: 10 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.obsidian_breastplate]: {
		augmenting: { [itemsIds.iron_bar]: 11, [itemsIds.mithril_bar]: 11, [itemsIds.obsidian_glass]: 1 },
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.obsidian_chainmail]: {
		augmenting: { [itemsIds.iron_bar]: 8, [itemsIds.mithril_bar]: 8, [itemsIds.obsidian_glass]: 1 },
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_breastplate]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 200 } }],
		augmenting: { [itemsIds.mithril_bar]: 23 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_chainmail]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 150 } }],
		augmenting: { [itemsIds.mithril_bar]: 16 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_breastplate]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 300 } }],
		augmenting: { [itemsIds.adamantite_bar]: 32 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.adamantite_chainmail]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 200 } }],
		augmenting: { [itemsIds.adamantite_bar]: 24 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_breastplate]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 300 } }],
		augmenting: { [itemsIds.runite_bar]: 41 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.runite_chainmail]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 200 } }],
		augmenting: { [itemsIds.runite_bar]: 32 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_breastplate]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 300, [itemsIds.ichor]: 150 } }],
		augmenting: { [itemsIds.stygian_bar]: 50, [itemsIds.ichor]: 10 },
		scrapping: { [itemsIds.stygian_bar]: 5, [itemsIds.ichor]: 1 },
	},
	[itemsIds.stygian_chainmail]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 200, [itemsIds.ichor]: 100 } }],
		augmenting: { [itemsIds.stygian_bar]: 40, [itemsIds.ichor]: 8 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.bronze_plate_leggings]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 40 } }],
		augmenting: { [itemsIds.bronze_bar]: 4 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_plate_leggings]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 75 } }],
		augmenting: { [itemsIds.iron_bar]: 8 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.obsidian_plate_leggings]: {
		augmenting: { [itemsIds.iron_bar]: 6, [itemsIds.mithril_bar]: 6, [itemsIds.obsidian_glass]: 1 },
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_plate_leggings]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 130 } }],
		augmenting: { [itemsIds.mithril_bar]: 13 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_plate_leggings]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 200 } }],
		augmenting: { [itemsIds.adamantite_bar]: 20 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_plate_leggings]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 200 } }],
		augmenting: { [itemsIds.runite_bar]: 27 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_plate_leggings]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 200, [itemsIds.ichor]: 100 } }],
		augmenting: { [itemsIds.stygian_bar]: 35, [itemsIds.ichor]: 7 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.bronze_boots]: {
		augmenting: { [itemsIds.bronze_bar]: 4 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_boots]: {
		augmenting: { [itemsIds.iron_bar]: 8 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.obsidian_boots]: {
		augmenting: { [itemsIds.iron_bar]: 6, [itemsIds.mithril_bar]: 6, [itemsIds.obsidian_glass]: 1 },
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_boots]: {
		augmenting: { [itemsIds.mithril_bar]: 13 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_boots]: {
		augmenting: { [itemsIds.adamantite_bar]: 20 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_boots]: {
		augmenting: { [itemsIds.runite_bar]: 28 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_boots]: {
		augmenting: { [itemsIds.stygian_bar]: 35, [itemsIds.ichor]: 5 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.bronze_gloves]: {
		augmenting: { [itemsIds.bronze_bar]: 8 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.iron_gloves]: {
		augmenting: { [itemsIds.iron_bar]: 13 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.obsidian_gloves]: {
		augmenting: { [itemsIds.iron_bar]: 10, [itemsIds.mithril_bar]: 10, [itemsIds.obsidian_glass]: 1 },
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.mithril_gloves]: {
		augmenting: { [itemsIds.mithril_bar]: 20 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.adamantite_gloves]: {
		augmenting: { [itemsIds.adamantite_bar]: 28 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.runite_gloves]: {
		augmenting: { [itemsIds.runite_bar]: 36 },
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.stygian_gloves]: {
		augmenting: { [itemsIds.stygian_bar]: 70, [itemsIds.ichor]: 10 },
		scrapping: { [itemsIds.stygian_bar]: 1, [itemsIds.ichor]: 1 },
	},
	[itemsIds.hide_body]: {
		crafting: [{ recipe: { [itemsIds.hide]: 60 } }],
		augmenting: { [itemsIds.hide]: 8 },
		scrapping: { [itemsIds.hide]: 1 },
	},
	[itemsIds.fur_body]: {
		crafting: [{ recipe: { [itemsIds.fur]: 100 } }],
		augmenting: { [itemsIds.fur]: 8 },
		scrapping: { [itemsIds.fur]: 1 },
	},
	[itemsIds.thick_hide_body]: {
		crafting: [{ recipe: { [itemsIds.fur]: 100, [itemsIds.rough_hide]: 25 } }],
		augmenting: { [itemsIds.fur]: 10, [itemsIds.rough_hide]: 3 },
		scrapping: { [itemsIds.fur]: 1 },
	},
	[itemsIds.leather_body]: {
		crafting: [{ recipe: { [itemsIds.leather]: 150 } }],
		augmenting: { [itemsIds.leather]: 18 },
		scrapping: { [itemsIds.leather]: 1 },
	},
	[itemsIds.reinforced_leather_body]: {
		crafting: [{ recipe: { [itemsIds.thick_fur]: 100, [itemsIds.leather]: 100 } }],
		augmenting: { [itemsIds.thick_fur]: 3, [itemsIds.leather]: 23 },
		scrapping: { [itemsIds.thick_fur]: 1 },
	},
	[itemsIds.chitin_body]: {
		crafting: [{ recipe: { [itemsIds.chitin]: 200 } }],
		augmenting: { [itemsIds.chitin]: 15 },
		scrapping: { [itemsIds.chitin]: 1 },
	},
	[itemsIds.scale_body]: {
		crafting: [{ recipe: { [itemsIds.scale]: 200 } }],
		augmenting: { [itemsIds.scale]: 20 },
		scrapping: { [itemsIds.scale]: 1 },
	},
	[itemsIds.hide_coif]: {
		crafting: [{ recipe: { [itemsIds.hide]: 30 } }],
		augmenting: { [itemsIds.hide]: 3 },
		scrapping: { [itemsIds.hide]: 1 },
	},
	[itemsIds.fur_coif]: {
		crafting: [{ recipe: { [itemsIds.fur]: 50 } }],
		augmenting: { [itemsIds.fur]: 3 },
		scrapping: { [itemsIds.fur]: 2 },
	},
	[itemsIds.thick_hide_coif]: {
		crafting: [{ recipe: { [itemsIds.fur]: 75, [itemsIds.rough_hide]: 15 } }],
		augmenting: { [itemsIds.fur]: 4, [itemsIds.rough_hide]: 1 },
		scrapping: { [itemsIds.fur]: 2 },
	},
	[itemsIds.leather_coif]: {
		crafting: [{ recipe: { [itemsIds.leather]: 100 } }],
		augmenting: { [itemsIds.leather]: 5 },
		scrapping: { [itemsIds.leather]: 2 },
	},
	[itemsIds.reinforced_leather_coif]: {
		crafting: [{ recipe: { [itemsIds.thick_fur]: 50, [itemsIds.leather]: 50 } }],
		augmenting: { [itemsIds.thick_fur]: 1, [itemsIds.leather]: 7 },
		scrapping: { [itemsIds.thick_fur]: 1 },
	},
	[itemsIds.chitin_coif]: {
		crafting: [{ recipe: { [itemsIds.chitin]: 100 } }],
		augmenting: { [itemsIds.chitin]: 5 },
		scrapping: { [itemsIds.chitin]: 1 },
	},
	[itemsIds.scale_coif]: {
		crafting: [{ recipe: { [itemsIds.scale]: 100 } }],
		augmenting: { [itemsIds.scale]: 4 },
		scrapping: { [itemsIds.scale]: 1 },
	},
	[itemsIds.hide_trousers]: {
		crafting: [{ recipe: { [itemsIds.hide]: 60 } }],
		augmenting: { [itemsIds.hide]: 8 },
		scrapping: { [itemsIds.hide]: 1 },
	},
	[itemsIds.fur_trousers]: {
		crafting: [{ recipe: { [itemsIds.fur]: 100 } }],
		augmenting: { [itemsIds.fur]: 8 },
		scrapping: { [itemsIds.fur]: 1 },
	},
	[itemsIds.thick_hide_trousers]: {
		crafting: [{ recipe: { [itemsIds.fur]: 100, [itemsIds.rough_hide]: 25 } }],
		augmenting: { [itemsIds.fur]: 10, [itemsIds.rough_hide]: 3 },
		scrapping: { [itemsIds.fur]: 1 },
	},
	[itemsIds.leather_trousers]: {
		crafting: [{ recipe: { [itemsIds.leather]: 150 } }],
		augmenting: { [itemsIds.leather]: 18 },
		scrapping: { [itemsIds.leather]: 1 },
	},
	[itemsIds.reinforced_leather_trousers]: {
		crafting: [{ recipe: { [itemsIds.thick_fur]: 100, [itemsIds.leather]: 100 } }],
		augmenting: { [itemsIds.thick_fur]: 3, [itemsIds.leather]: 23 },
		scrapping: { [itemsIds.thick_fur]: 1 },
	},
	[itemsIds.chitin_trousers]: {
		crafting: [{ recipe: { [itemsIds.chitin]: 200 } }],
		augmenting: { [itemsIds.chitin]: 15 },
		scrapping: { [itemsIds.chitin]: 1 },
	},
	[itemsIds.scale_trousers]: {
		crafting: [{ recipe: { [itemsIds.scale]: 200 } }],
		augmenting: { [itemsIds.scale]: 20 },
		scrapping: { [itemsIds.scale]: 1 },
	},
	[itemsIds.robe_top]: {
		crafting: [{ recipe: { [itemsIds.rough_cloth]: 120 } }],
		augmenting: { [itemsIds.rough_cloth]: 24 },
		scrapping: { [itemsIds.rough_cloth]: 2 },
	},
	[itemsIds.practitioner_robe_top]: {
		crafting: [{ recipe: { [itemsIds.restored_cloth]: 200 } }],
		augmenting: { [itemsIds.restored_cloth]: 48 },
		scrapping: { [itemsIds.restored_cloth]: 2 },
	},
	[itemsIds.academy_robe_top]: {
		crafting: [{ recipe: { [itemsIds.lesser_cloth]: 300 } }],
		augmenting: { [itemsIds.lesser_cloth]: 60 },
		scrapping: { [itemsIds.lesser_cloth]: 2 },
	},
	[itemsIds.imbued_robe_top]: {
		crafting: [{ recipe: { [itemsIds.imbued_cloth]: 400 } }],
		augmenting: { [itemsIds.imbued_cloth]: 72 },
		scrapping: { [itemsIds.imbued_cloth]: 2 },
	},
	[itemsIds.major_imbued_robe_top]: {
		crafting: [{ recipe: { [itemsIds.major_cloth]: 400 } }],
		augmenting: { [itemsIds.major_cloth]: 84 },
		scrapping: { [itemsIds.major_cloth]: 2 },
	},
	[itemsIds.greater_imbued_robe_top]: {
		crafting: [{ recipe: { [itemsIds.greater_cloth]: 400 } }],
		augmenting: { [itemsIds.greater_cloth]: 96 },
		scrapping: { [itemsIds.greater_cloth]: 2 },
	},
	[itemsIds.elder_robe_top]: {
		crafting: [{ recipe: { [itemsIds.elder_cloth]: 400, [itemsIds.aquamarine]: 30 } }],
		augmenting: { [itemsIds.elder_cloth]: 108, [itemsIds.aquamarine]: 3 },
		scrapping: { [itemsIds.elder_cloth]: 2 },
	},
	[itemsIds.robe_hood]: {
		crafting: [{ recipe: { [itemsIds.rough_cloth]: 60 } }],
		augmenting: { [itemsIds.rough_cloth]: 12 },
		scrapping: { [itemsIds.rough_cloth]: 2 },
	},
	[itemsIds.practitioner_hood]: {
		crafting: [{ recipe: { [itemsIds.restored_cloth]: 100 } }],
		augmenting: { [itemsIds.restored_cloth]: 18 },
		scrapping: { [itemsIds.restored_cloth]: 2 },
	},
	[itemsIds.academy_hood]: {
		crafting: [{ recipe: { [itemsIds.lesser_cloth]: 150 } }],
		augmenting: { [itemsIds.lesser_cloth]: 24 },
		scrapping: { [itemsIds.lesser_cloth]: 2 },
	},
	[itemsIds.imbued_hood]: {
		crafting: [{ recipe: { [itemsIds.imbued_cloth]: 200 } }],
		augmenting: { [itemsIds.imbued_cloth]: 24 },
		scrapping: { [itemsIds.imbued_cloth]: 2 },
	},
	[itemsIds.major_imbued_hood]: {
		crafting: [{ recipe: { [itemsIds.major_cloth]: 200 } }],
		augmenting: { [itemsIds.major_cloth]: 30 },
		scrapping: { [itemsIds.major_cloth]: 2 },
	},
	[itemsIds.greater_imbued_hood]: {
		crafting: [{ recipe: { [itemsIds.greater_cloth]: 200 } }],
		augmenting: { [itemsIds.greater_cloth]: 36 },
		scrapping: { [itemsIds.greater_cloth]: 2 },
	},
	[itemsIds.elder_hood]: {
		crafting: [{ recipe: { [itemsIds.elder_cloth]: 200, [itemsIds.aquamarine]: 30 } }],
		augmenting: { [itemsIds.elder_cloth]: 48, [itemsIds.aquamarine]: 3 },
		scrapping: { [itemsIds.elder_cloth]: 2 },
	},
	[itemsIds.robe_bottom]: {
		crafting: [{ recipe: { [itemsIds.rough_cloth]: 120 } }],
		augmenting: { [itemsIds.rough_cloth]: 24 },
		scrapping: { [itemsIds.rough_cloth]: 2 },
	},
	[itemsIds.practitioner_robe_bottom]: {
		crafting: [{ recipe: { [itemsIds.restored_cloth]: 200 } }],
		augmenting: { [itemsIds.restored_cloth]: 48 },
		scrapping: { [itemsIds.restored_cloth]: 2 },
	},
	[itemsIds.academy_robe_bottom]: {
		crafting: [{ recipe: { [itemsIds.lesser_cloth]: 300 } }],
		augmenting: { [itemsIds.lesser_cloth]: 60 },
		scrapping: { [itemsIds.lesser_cloth]: 2 },
	},
	[itemsIds.imbued_robe_bottom]: {
		crafting: [{ recipe: { [itemsIds.imbued_cloth]: 400 } }],
		augmenting: { [itemsIds.imbued_cloth]: 72 },
		scrapping: { [itemsIds.imbued_cloth]: 2 },
	},
	[itemsIds.major_imbued_robe_bottom]: {
		crafting: [{ recipe: { [itemsIds.major_cloth]: 400 } }],
		augmenting: { [itemsIds.major_cloth]: 84 },
		scrapping: { [itemsIds.major_cloth]: 2 },
	},
	[itemsIds.greater_imbued_robe_bottom]: {
		crafting: [{ recipe: { [itemsIds.greater_cloth]: 400 } }],
		augmenting: { [itemsIds.greater_cloth]: 96 },
		scrapping: { [itemsIds.greater_cloth]: 2 },
	},
	[itemsIds.elder_robe_bottom]: {
		crafting: [{ recipe: { [itemsIds.elder_cloth]: 400, [itemsIds.aquamarine]: 30 } }],
		augmenting: { [itemsIds.elder_cloth]: 108, [itemsIds.aquamarine]: 3 },
		scrapping: { [itemsIds.elder_cloth]: 2 },
	},
	[itemsIds.flimsy_recurve_bow]: {
		crafting: [{ recipe: { [itemsIds.log]: 500, [itemsIds.shoddy_ammo]: 300 } }],
		augmenting: { [itemsIds.log]: 10, [itemsIds.shoddy_ammo]: 100 },
		scrapping: { [itemsIds.log]: 10 },
	},
	[itemsIds.sturdy_recurve_bow]: {
		crafting: [{ recipe: { [itemsIds.log]: 750, [itemsIds.amateur_ammo]: 300 } }],
		augmenting: { [itemsIds.log]: 20, [itemsIds.amateur_ammo]: 100 },
		scrapping: { [itemsIds.log]: 10 },
	},
	[itemsIds.oak_recurve_bow]: {
		crafting: [{ recipe: { [itemsIds.oak_log]: 750, [itemsIds.apprentice_ammo]: 300 } }],
		augmenting: { [itemsIds.oak_log]: 25, [itemsIds.apprentice_ammo]: 100 },
		scrapping: { [itemsIds.oak_log]: 10 },
	},
	[itemsIds.willow_recurve_bow]: {
		crafting: [{ recipe: { [itemsIds.willow_log]: 1000, [itemsIds.journeyman_ammo]: 300 } }],
		augmenting: { [itemsIds.willow_log]: 40, [itemsIds.journeyman_ammo]: 100 },
		scrapping: { [itemsIds.willow_log]: 10 },
	},
	[itemsIds.maple_recurve_bow]: {
		crafting: [{ recipe: { [itemsIds.maple_log]: 1000, [itemsIds.professional_ammo]: 300 } }],
		augmenting: { [itemsIds.maple_log]: 55, [itemsIds.professional_ammo]: 100 },
		scrapping: { [itemsIds.maple_log]: 10 },
	},
	[itemsIds.yew_recurve_bow]: {
		crafting: [{ recipe: { [itemsIds.yew_log]: 1000, [itemsIds.master_ammo]: 300 } }],
		augmenting: { [itemsIds.yew_log]: 75, [itemsIds.master_ammo]: 100 },
		scrapping: { [itemsIds.yew_log]: 10 },
	},
	[itemsIds.elder_recurve_bow]: {
		crafting: [{ recipe: { [itemsIds.elder_log]: 1000, [itemsIds.experimental_ammo]: 300 } }],
		augmenting: { [itemsIds.elder_log]: 105, [itemsIds.experimental_ammo]: 100 },
		scrapping: { [itemsIds.elder_log]: 10 },
	},
	[itemsIds.flimsy_longbow]: {
		crafting: [{ recipe: { [itemsIds.log]: 750, [itemsIds.shoddy_ammo]: 300 } }],
		augmenting: { [itemsIds.log]: 15, [itemsIds.shoddy_ammo]: 100 },
		scrapping: { [itemsIds.log]: 10 },
	},
	[itemsIds.sturdy_longbow]: {
		crafting: [{ recipe: { [itemsIds.log]: 1000, [itemsIds.amateur_ammo]: 300 } }],
		augmenting: { [itemsIds.log]: 30, [itemsIds.amateur_ammo]: 100 },
		scrapping: { [itemsIds.log]: 10 },
	},
	[itemsIds.oak_longbow]: {
		crafting: [{ recipe: { [itemsIds.oak_log]: 1000, [itemsIds.apprentice_ammo]: 300 } }],
		augmenting: { [itemsIds.oak_log]: 45, [itemsIds.apprentice_ammo]: 100 },
		scrapping: { [itemsIds.oak_log]: 10 },
	},
	[itemsIds.willow_longbow]: {
		crafting: [{ recipe: { [itemsIds.willow_log]: 1500, [itemsIds.journeyman_ammo]: 300 } }],
		augmenting: { [itemsIds.willow_log]: 70, [itemsIds.journeyman_ammo]: 100 },
		scrapping: { [itemsIds.willow_log]: 10 },
	},
	[itemsIds.maple_longbow]: {
		crafting: [{ recipe: { [itemsIds.maple_log]: 1500, [itemsIds.professional_ammo]: 300 } }],
		augmenting: { [itemsIds.maple_log]: 100, [itemsIds.professional_ammo]: 100 },
		scrapping: { [itemsIds.maple_log]: 10 },
	},
	[itemsIds.yew_longbow]: {
		crafting: [{ recipe: { [itemsIds.yew_log]: 1500, [itemsIds.master_ammo]: 300 } }],
		augmenting: { [itemsIds.yew_log]: 145, [itemsIds.master_ammo]: 100 },
		scrapping: { [itemsIds.yew_log]: 10 },
	},
	[itemsIds.elder_longbow]: {
		crafting: [{ recipe: { [itemsIds.elder_log]: 1500, [itemsIds.experimental_ammo]: 300 } }],
		augmenting: { [itemsIds.elder_log]: 215, [itemsIds.experimental_ammo]: 100 },
		scrapping: { [itemsIds.elder_log]: 10 },
	},
	[itemsIds.flimsy_crossbow]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 5, [itemsIds.log]: 750, [itemsIds.shoddy_ammo]: 300 } }],
		augmenting: { [itemsIds.bronze_bar]: 1, [itemsIds.log]: 15, [itemsIds.shoddy_ammo]: 100 },
		scrapping: { [itemsIds.log]: 10 },
	},
	[itemsIds.light_crossbow]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 5, [itemsIds.log]: 1000, [itemsIds.amateur_ammo]: 300 } }],
		augmenting: { [itemsIds.bronze_bar]: 1, [itemsIds.log]: 25, [itemsIds.amateur_ammo]: 100 },
		scrapping: { [itemsIds.log]: 10 },
	},
	[itemsIds.bone_crossbow]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 6, [itemsIds.oak_log]: 1000, [itemsIds.apprentice_ammo]: 300 } }],
		augmenting: { [itemsIds.iron_bar]: 1, [itemsIds.oak_log]: 40, [itemsIds.apprentice_ammo]: 100 },
		scrapping: { [itemsIds.oak_log]: 10 },
	},
	[itemsIds.heavy_crossbow]: {
		crafting: [
			{ recipe: { [itemsIds.mithril_bar]: 7, [itemsIds.willow_log]: 1500, [itemsIds.journeyman_ammo]: 300 } },
		],
		augmenting: { [itemsIds.mithril_bar]: 1, [itemsIds.willow_log]: 55, [itemsIds.journeyman_ammo]: 100 },
		scrapping: { [itemsIds.willow_log]: 10 },
	},
	[itemsIds.reinforced_crossbow]: {
		crafting: [
			{ recipe: { [itemsIds.adamantite_bar]: 9, [itemsIds.maple_log]: 1500, [itemsIds.professional_ammo]: 300 } },
		],
		augmenting: { [itemsIds.adamantite_bar]: 1, [itemsIds.maple_log]: 80, [itemsIds.professional_ammo]: 100 },
		scrapping: { [itemsIds.maple_log]: 10 },
	},
	[itemsIds.arbalest]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 10, [itemsIds.yew_log]: 1500, [itemsIds.master_ammo]: 300 } }],
		augmenting: { [itemsIds.runite_bar]: 1, [itemsIds.yew_log]: 115, [itemsIds.master_ammo]: 100 },
		scrapping: { [itemsIds.yew_log]: 10 },
	},
	[itemsIds.elder_crossbow]: {
		crafting: [
			{ recipe: { [itemsIds.stygian_bar]: 12, [itemsIds.elder_log]: 1500, [itemsIds.experimental_ammo]: 300 } },
		],
		augmenting: { [itemsIds.stygian_bar]: 2, [itemsIds.elder_log]: 165, [itemsIds.experimental_ammo]: 100 },
		scrapping: { [itemsIds.elder_log]: 10 },
	},
	[itemsIds.rough_throwing_knife]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 5, [itemsIds.log]: 500, [itemsIds.shoddy_ammo]: 300 } }],
		augmenting: { [itemsIds.bronze_bar]: 1, [itemsIds.log]: 5, [itemsIds.shoddy_ammo]: 100 },
		scrapping: { [itemsIds.log]: 10 },
	},
	[itemsIds.throwing_knife]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 5, [itemsIds.log]: 750, [itemsIds.amateur_ammo]: 300 } }],
		augmenting: { [itemsIds.bronze_bar]: 1, [itemsIds.log]: 15, [itemsIds.amateur_ammo]: 100 },
		scrapping: { [itemsIds.log]: 10 },
	},
	[itemsIds.light_throwing_knife]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 6, [itemsIds.oak_log]: 750, [itemsIds.apprentice_ammo]: 300 } }],
		augmenting: { [itemsIds.iron_bar]: 1, [itemsIds.oak_log]: 20, [itemsIds.apprentice_ammo]: 100 },
		scrapping: { [itemsIds.oak_log]: 10 },
	},
	[itemsIds.refined_throwing_knife]: {
		crafting: [
			{ recipe: { [itemsIds.mithril_bar]: 7, [itemsIds.willow_log]: 1000, [itemsIds.journeyman_ammo]: 300 } },
		],
		augmenting: { [itemsIds.mithril_bar]: 1, [itemsIds.willow_log]: 25, [itemsIds.journeyman_ammo]: 100 },
		scrapping: { [itemsIds.willow_log]: 10 },
	},
	[itemsIds.throwing_axe]: {
		crafting: [
			{ recipe: { [itemsIds.adamantite_bar]: 9, [itemsIds.maple_log]: 1000, [itemsIds.professional_ammo]: 300 } },
		],
		augmenting: { [itemsIds.adamantite_bar]: 1, [itemsIds.maple_log]: 35, [itemsIds.professional_ammo]: 100 },
		scrapping: { [itemsIds.maple_log]: 10 },
	},
	[itemsIds.throwing_javelin]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 10, [itemsIds.yew_log]: 1000, [itemsIds.master_ammo]: 300 } }],
		augmenting: { [itemsIds.runite_bar]: 1, [itemsIds.yew_log]: 50, [itemsIds.master_ammo]: 100 },
		scrapping: { [itemsIds.yew_log]: 10 },
	},
	[itemsIds.throwing_spinner]: {
		crafting: [
			{ recipe: { [itemsIds.stygian_bar]: 12, [itemsIds.elder_log]: 1000, [itemsIds.experimental_ammo]: 300 } },
		],
		augmenting: { [itemsIds.stygian_bar]: 2, [itemsIds.elder_log]: 65, [itemsIds.experimental_ammo]: 100 },
		scrapping: { [itemsIds.elder_log]: 10 },
	},
	[itemsIds.driftwood_wand]: {
		crafting: [{ recipe: { [itemsIds.air_rune]: 1, [itemsIds.log]: 6, [itemsIds.rotten_driftwood]: 50 } }],
		augmenting: { [itemsIds.air_essence]: 10, [itemsIds.log]: 5, [itemsIds.rotten_driftwood]: 1 },
		scrapping: { [itemsIds.rotten_driftwood]: 2 },
	},
	[itemsIds.sturdy_wand]: {
		crafting: [{ recipe: { [itemsIds.earth_rune]: 10, [itemsIds.log]: 6, [itemsIds.rotten_driftwood]: 100 } }],
		augmenting: { [itemsIds.earth_essence]: 100, [itemsIds.log]: 5, [itemsIds.rotten_driftwood]: 3 },
		scrapping: { [itemsIds.rotten_driftwood]: 2 },
	},
	[itemsIds.academy_wand]: {
		crafting: [
			{
				recipe: {
					[itemsIds.air_rune]: 10,
					[itemsIds.earth_rune]: 10,
					[itemsIds.oak_log]: 8,
					[itemsIds.sturdy_driftwood]: 150,
				},
			},
		],
		augmenting: {
			[itemsIds.air_essence]: 100,
			[itemsIds.earth_essence]: 100,
			[itemsIds.oak_log]: 5,
			[itemsIds.sturdy_driftwood]: 4,
		},
		scrapping: { [itemsIds.sturdy_driftwood]: 2 },
	},
	[itemsIds.imbued_wand]: {
		crafting: [
			{ recipe: { [itemsIds.fire_rune]: 25, [itemsIds.willow_log]: 9, [itemsIds.sturdy_driftwood]: 150 } },
		],
		augmenting: { [itemsIds.fire_essence]: 250, [itemsIds.willow_log]: 5, [itemsIds.sturdy_driftwood]: 5 },
		scrapping: { [itemsIds.sturdy_driftwood]: 2 },
	},
	[itemsIds.major_imbued_wand]: {
		crafting: [
			{
				recipe: {
					[itemsIds.fire_rune]: 25,
					[itemsIds.water_rune]: 25,
					[itemsIds.maple_log]: 100,
					[itemsIds.mystical_driftwood]: 200,
				},
			},
		],
		augmenting: {
			[itemsIds.fire_essence]: 250,
			[itemsIds.water_essence]: 250,
			[itemsIds.maple_log]: 5,
			[itemsIds.mystical_driftwood]: 7,
		},
		scrapping: { [itemsIds.mystical_driftwood]: 2 },
	},
	[itemsIds.greater_imbued_wand]: {
		crafting: [
			{
				recipe: {
					[itemsIds.blood_rune]: 50,
					[itemsIds.death_rune]: 50,
					[itemsIds.yew_log]: 100,
					[itemsIds.mystical_driftwood]: 200,
				},
			},
		],
		augmenting: {
			[itemsIds.blood_essence]: 500,
			[itemsIds.death_essence]: 500,
			[itemsIds.yew_log]: 5,
			[itemsIds.mystical_driftwood]: 10,
		},
		scrapping: { [itemsIds.mystical_driftwood]: 2 },
	},
	[itemsIds.elder_wand]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaos_rune]: 250,
					[itemsIds.nature_rune]: 250,
					[itemsIds.mind_rune]: 250,
					[itemsIds.cosmic_rune]: 250,
					[itemsIds.elder_log]: 50,
					[itemsIds.aquamarine]: 15,
					[itemsIds.mystical_driftwood]: 200,
				},
			},
		],
		augmenting: {
			[itemsIds.chaos_essence]: 5000,
			[itemsIds.nature_essence]: 5000,
			[itemsIds.mind_essence]: 5000,
			[itemsIds.cosmic_essence]: 5000,
			[itemsIds.elder_log]: 10,
			[itemsIds.aquamarine]: 2,
			[itemsIds.mystical_driftwood]: 13,
		},
		scrapping: { [itemsIds.mystical_driftwood]: 1, [itemsIds.elder_log]: 5 },
	},
	[itemsIds.driftwood_staff]: {
		crafting: [{ recipe: { [itemsIds.air_rune]: 1, [itemsIds.log]: 19, [itemsIds.rotten_driftwood]: 50 } }],
		augmenting: { [itemsIds.air_essence]: 10, [itemsIds.log]: 10, [itemsIds.rotten_driftwood]: 4 },
		scrapping: { [itemsIds.rotten_driftwood]: 2 },
	},
	[itemsIds.sturdy_staff]: {
		crafting: [{ recipe: { [itemsIds.earth_rune]: 10, [itemsIds.log]: 19, [itemsIds.rotten_driftwood]: 100 } }],
		augmenting: { [itemsIds.earth_essence]: 100, [itemsIds.log]: 10, [itemsIds.rotten_driftwood]: 4 },
		scrapping: { [itemsIds.rotten_driftwood]: 2 },
	},
	[itemsIds.academy_staff]: {
		crafting: [
			{
				recipe: {
					[itemsIds.air_rune]: 10,
					[itemsIds.earth_rune]: 10,
					[itemsIds.oak_log]: 25,
					[itemsIds.sturdy_driftwood]: 150,
				},
			},
		],
		augmenting: {
			[itemsIds.air_essence]: 100,
			[itemsIds.earth_essence]: 100,
			[itemsIds.oak_log]: 10,
			[itemsIds.sturdy_driftwood]: 5,
		},
		scrapping: { [itemsIds.sturdy_driftwood]: 2 },
	},
	[itemsIds.imbued_staff]: {
		crafting: [
			{ recipe: { [itemsIds.fire_rune]: 25, [itemsIds.willow_log]: 33, [itemsIds.sturdy_driftwood]: 150 } },
		],
		augmenting: { [itemsIds.fire_essence]: 250, [itemsIds.willow_log]: 15, [itemsIds.sturdy_driftwood]: 8 },
		scrapping: { [itemsIds.sturdy_driftwood]: 2 },
	},
	[itemsIds.major_imbued_staff]: {
		crafting: [
			{
				recipe: {
					[itemsIds.fire_rune]: 25,
					[itemsIds.water_rune]: 25,
					[itemsIds.maple_log]: 200,
					[itemsIds.mystical_driftwood]: 200,
				},
			},
		],
		augmenting: {
			[itemsIds.fire_essence]: 250,
			[itemsIds.water_essence]: 250,
			[itemsIds.maple_log]: 20,
			[itemsIds.mystical_driftwood]: 11,
		},
		scrapping: { [itemsIds.mystical_driftwood]: 2 },
	},
	[itemsIds.greater_imbued_staff]: {
		crafting: [
			{
				recipe: {
					[itemsIds.blood_rune]: 50,
					[itemsIds.death_rune]: 50,
					[itemsIds.yew_log]: 200,
					[itemsIds.mystical_driftwood]: 200,
				},
			},
		],
		augmenting: {
			[itemsIds.blood_essence]: 500,
			[itemsIds.death_essence]: 500,
			[itemsIds.yew_log]: 25,
			[itemsIds.mystical_driftwood]: 15,
		},
		scrapping: { [itemsIds.mystical_driftwood]: 2 },
	},
	[itemsIds.elder_staff]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaos_rune]: 250,
					[itemsIds.nature_rune]: 250,
					[itemsIds.mind_rune]: 250,
					[itemsIds.cosmic_rune]: 250,
					[itemsIds.elder_log]: 100,
					[itemsIds.aquamarine]: 30,
					[itemsIds.mystical_driftwood]: 200,
				},
			},
		],
		augmenting: {
			[itemsIds.chaos_essence]: 5000,
			[itemsIds.nature_essence]: 5000,
			[itemsIds.mind_essence]: 5000,
			[itemsIds.cosmic_essence]: 5000,
			[itemsIds.elder_log]: 30,
			[itemsIds.aquamarine]: 3,
			[itemsIds.mystical_driftwood]: 21,
		},
		scrapping: { [itemsIds.mystical_driftwood]: 1, [itemsIds.elder_log]: 5 },
	},
	[itemsIds.arcane_notes]: {
		crafting: [{ recipe: { [itemsIds.air_rune]: 1, [itemsIds.rough_cloth]: 60, [itemsIds.rotten_driftwood]: 10 } }],
		augmenting: { [itemsIds.air_essence]: 10, [itemsIds.rough_cloth]: 24, [itemsIds.rotten_driftwood]: 1 },
		scrapping: { [itemsIds.rough_cloth]: 2 },
	},
	[itemsIds.simple_arcane_focus]: {
		crafting: [
			{ recipe: { [itemsIds.earth_rune]: 10, [itemsIds.restored_cloth]: 100, [itemsIds.rotten_driftwood]: 10 } },
		],
		augmenting: {
			[itemsIds.earth_essence]: 100,
			[itemsIds.restored_cloth]: 24,
			[itemsIds.rotten_driftwood]: 1,
		},
		scrapping: { [itemsIds.restored_cloth]: 2 },
	},
	[itemsIds.academy_focus]: {
		crafting: [
			{
				recipe: {
					[itemsIds.air_rune]: 10,
					[itemsIds.earth_rune]: 10,
					[itemsIds.lesser_cloth]: 150,
					[itemsIds.sturdy_driftwood]: 15,
				},
			},
		],
		augmenting: {
			[itemsIds.air_essence]: 100,
			[itemsIds.earth_essence]: 100,
			[itemsIds.lesser_cloth]: 30,
			[itemsIds.sturdy_driftwood]: 2,
		},
		scrapping: { [itemsIds.lesser_cloth]: 2 },
	},
	[itemsIds.imbued_focus]: {
		crafting: [
			{ recipe: { [itemsIds.fire_rune]: 25, [itemsIds.imbued_cloth]: 200, [itemsIds.sturdy_driftwood]: 16 } },
		],
		augmenting: { [itemsIds.fire_essence]: 250, [itemsIds.imbued_cloth]: 48, [itemsIds.sturdy_driftwood]: 2 },
		scrapping: { [itemsIds.imbued_cloth]: 2 },
	},
	[itemsIds.major_imbued_focus]: {
		crafting: [
			{
				recipe: {
					[itemsIds.fire_rune]: 25,
					[itemsIds.water_rune]: 25,
					[itemsIds.major_cloth]: 200,
					[itemsIds.mystical_driftwood]: 20,
				},
			},
		],
		augmenting: {
			[itemsIds.fire_essence]: 250,
			[itemsIds.water_essence]: 250,
			[itemsIds.major_cloth]: 66,
			[itemsIds.mystical_driftwood]: 2,
		},
		scrapping: { [itemsIds.major_cloth]: 2 },
	},
	[itemsIds.greater_imbued_focus]: {
		crafting: [
			{
				recipe: {
					[itemsIds.blood_rune]: 50,
					[itemsIds.death_rune]: 50,
					[itemsIds.greater_cloth]: 200,
					[itemsIds.mystical_driftwood]: 26,
				},
			},
		],
		augmenting: {
			[itemsIds.blood_essence]: 500,
			[itemsIds.death_essence]: 500,
			[itemsIds.greater_cloth]: 90,
			[itemsIds.mystical_driftwood]: 3,
		},
		scrapping: { [itemsIds.greater_cloth]: 2 },
	},
	[itemsIds.elder_focus]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaos_rune]: 250,
					[itemsIds.nature_rune]: 250,
					[itemsIds.mind_rune]: 250,
					[itemsIds.cosmic_rune]: 250,
					[itemsIds.elder_cloth]: 200,
					[itemsIds.aquamarine]: 15,
					[itemsIds.mystical_driftwood]: 30,
				},
			},
		],
		augmenting: {
			[itemsIds.chaos_essence]: 5000,
			[itemsIds.nature_essence]: 5000,
			[itemsIds.mind_essence]: 5000,
			[itemsIds.cosmic_essence]: 5000,
			[itemsIds.elder_cloth]: 126,
			[itemsIds.aquamarine]: 2,
			[itemsIds.mystical_driftwood]: 4,
		},
		scrapping: { [itemsIds.elder_cloth]: 2 },
	},
	[itemsIds.shoddy_ammo]: {
		crafting: [{ recipe: { [itemsIds.stone]: 1, [itemsIds.branch]: 1, [itemsIds.feather]: 8 } }],
	},
	[itemsIds.amateur_ammo]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 1, [itemsIds.log]: 5, [itemsIds.feather]: 8 } }],
	},
	[itemsIds.apprentice_ammo]: {
		crafting: [{ recipe: { [itemsIds.iron_bar]: 1, [itemsIds.oak_log]: 5, [itemsIds.feather]: 8 } }],
	},
	[itemsIds.journeyman_ammo]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 1, [itemsIds.willow_log]: 10, [itemsIds.feather]: 8 } }],
	},
	[itemsIds.professional_ammo]: {
		crafting: [{ recipe: { [itemsIds.mithril_bar]: 1, [itemsIds.maple_log]: 10, [itemsIds.feather]: 8 } }],
	},
	[itemsIds.master_ammo]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 1, [itemsIds.yew_log]: 10, [itemsIds.feather]: 8 } }],
	},
	[itemsIds.experimental_ammo]: {
		crafting: [{ recipe: { [itemsIds.runite_bar]: 1, [itemsIds.yew_log]: 15, [itemsIds.feather]: 8 } }],
	},
	[itemsIds.void_ammo]: {
		crafting: [{ recipe: { [itemsIds.stygian_bar]: 1, [itemsIds.elder_log]: 5, [itemsIds.feather]: 8 } }],
	},
	[itemsIds.lowgrade_whetstone]: {
		crafting: [
			{ recipe: { [itemsIds.stone]: 8, [itemsIds.bronze_bar]: 1, [itemsIds.iron_bar]: 1 } },
			{ recipe: { [itemsIds.stone]: 8, [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 1 }, multiplier: 48 },
		],
	},
	[itemsIds.midgrade_whetstone]: {
		crafting: [
			{ recipe: { [itemsIds.stone]: 16, [itemsIds.mithril_bar]: 2, [itemsIds.adamantite_bar]: 2 } },
			{
				recipe: { [itemsIds.stone]: 16, [itemsIds.adamantite_bar]: 2, [itemsIds.runite_bar]: 2 },
				multiplier: 120,
			},
		],
	},
	[itemsIds.highgrade_whetstone]: {
		crafting: [{ recipe: { [itemsIds.stone]: 24, [itemsIds.runite_bar]: 2, [itemsIds.stygian_bar]: 2 } }],
	},
	[itemsIds.lesser_imbued_charm]: {
		crafting: [
			{
				recipe: {
					[itemsIds.silver]: 1,
					[itemsIds.chaos_rune]: 1,
					[itemsIds.rough_cloth]: 1,
					[itemsIds.lesser_cloth]: 1,
				},
			},
			{
				recipe: {
					[itemsIds.silver]: 1,
					[itemsIds.chaos_rune]: 1,
					[itemsIds.lesser_cloth]: 1,
					[itemsIds.imbued_cloth]: 1,
				},
				multiplier: 108,
			},
		],
	},
	[itemsIds.major_imbued_charm]: {
		crafting: [
			{
				recipe: {
					[itemsIds.silver]: 2,
					[itemsIds.blood_rune]: 2,
					[itemsIds.imbued_cloth]: 1,
					[itemsIds.major_cloth]: 1,
				},
			},
			{
				recipe: {
					[itemsIds.silver]: 2,
					[itemsIds.blood_rune]: 2,
					[itemsIds.major_cloth]: 1,
					[itemsIds.greater_cloth]: 1,
				},
				multiplier: 108,
			},
		],
	},
	[itemsIds.greater_imbued_charm]: {
		crafting: [
			{
				recipe: {
					[itemsIds.silver]: 4,
					[itemsIds.death_rune]: 2,
					[itemsIds.mind_rune]: 2,
					[itemsIds.greater_cloth]: 1,
					[itemsIds.elder_cloth]: 1,
				},
			},
		],
	},
	[itemsIds.santa_hat]: {
		scrapping: { [itemsIds.coal]: 100 },
		augmenting: { [itemsIds.coal]: 1000 },
	},
	[itemsIds.snowman_head]: {
		scrapping: { [itemsIds.coal]: 100 },
		augmenting: { [itemsIds.coal]: 1000 },
	},
	[itemsIds.tophat]: {
		scrapping: { [itemsIds.coal]: 100 },
		augmenting: { [itemsIds.coal]: 1000 },
	},
	[itemsIds.lesser_ladle]: {
		augmenting: { [itemsIds.burnt_fish]: 200, [itemsIds.ruby]: 1 },
		scrapping: { [itemsIds.reactive_powder]: 1 },
	},
	[itemsIds.ladle]: {
		augmenting: { [itemsIds.burnt_fish]: 200, [itemsIds.ruby]: 1 },
		scrapping: { [itemsIds.reactive_powder]: 1 },
	},
	[itemsIds.greater_ladle]: {
		augmenting: { [itemsIds.burnt_fish]: 200, [itemsIds.ruby]: 1 },
		scrapping: { [itemsIds.reactive_powder]: 1 },
	},
	[itemsIds.moss_maul]: {
		crafting: [
			{
				recipe: {
					[itemsIds.stone]: 5000,
					[itemsIds.adamantite_bar]: 750,
					[itemsIds.runed_stone]: 1,
					[itemsIds.yew_log]: 1000,
				},
			},
		],
		augmenting: { [itemsIds.yew_log]: 1000, [itemsIds.emerald]: 3 },
		scrapping: { [itemsIds.yew_log]: 20, [itemsIds.corrupted_substrate]: 20 },
	},
	[itemsIds.modified_diving_gloves]: {
		scrapping: { [itemsIds.water_rune]: 5, [itemsIds.fish_oil]: 2 },
		augmenting: { [itemsIds.water_rune]: 50, [itemsIds.fish_oil]: 20 },
	},
	[itemsIds.kings_crown]: {
		crafting: [
			{
				recipe: {
					[itemsIds.mithril_bar]: 1000,
					[itemsIds.runite_bar]: 1000,
					[itemsIds.royal_ice_shard]: 1,
					[itemsIds.water_rune]: 1000,
				},
			},
		],
		augmenting: { [itemsIds.diamond]: 2, [itemsIds.water_rune]: 300 },
		scrapping: { [itemsIds.polishing_paste]: 10, [itemsIds.water_rune]: 30 },
	},
	[itemsIds.fire_orb]: {
		crafting: [{ recipe: { [itemsIds.magma_core]: 1, [itemsIds.fire_talisman]: 1, [itemsIds.fire_rune]: 2500 } }],
		augmenting: { [itemsIds.ruby]: 4, [itemsIds.fire_rune]: 100 },
		scrapping: { [itemsIds.reactive_powder]: 5 },
	},
	[itemsIds.shard_of_kalanahmatti]: {
		scrapping: { [itemsIds.runite_bar]: 10, [itemsIds.yew_log]: 50 },
		crafting: [{ recipe: { [itemsIds.runite_bar]: 200, [itemsIds.yew_log]: 1000, [itemsIds.kalanahmatti]: 1 } }],
		augmenting: { [itemsIds.runite_bar]: 100, [itemsIds.yew_log]: 500 },
	},
	[itemsIds.zero_edge]: {
		scrapping: { [itemsIds.runite_bar]: 5 },
		augmenting: { [itemsIds.runite_bar]: 50 },
	},
	[itemsIds.chaos_crest]: {
		augmenting: { [itemsIds.coal]: 200 },
		scrapping: {
			[itemsIds.smithing_shard]: 1,
			[itemsIds.reactive_powder]: 5,
			[itemsIds.coal]: 20,
		},
	},
	[itemsIds.forgotten_sadness]: {
		scrapping: { [itemsIds.death_rune]: 10, [itemsIds.ichor]: 5 },
		augmenting: { [itemsIds.death_rune]: 100, [itemsIds.ichor]: 50 },
		crafting: [
			{ recipe: { [itemsIds.forgotten_anger]: 1, [itemsIds.death_rune]: 100, [itemsIds.ichor]: 50 } },
			{ recipe: { [itemsIds.forgotten_madness]: 1, [itemsIds.death_rune]: 100, [itemsIds.ichor]: 50 } },
		],
	},
	[itemsIds.forgotten_madness]: {
		scrapping: { [itemsIds.death_rune]: 10, [itemsIds.ichor]: 5 },
		augmenting: { [itemsIds.death_rune]: 100, [itemsIds.ichor]: 50 },
		crafting: [
			{ recipe: { [itemsIds.forgotten_sadness]: 1, [itemsIds.death_rune]: 100, [itemsIds.ichor]: 50 } },
			{ recipe: { [itemsIds.forgotten_anger]: 1, [itemsIds.death_rune]: 100, [itemsIds.ichor]: 50 } },
		],
	},
	[itemsIds.forgotten_anger]: {
		scrapping: { [itemsIds.death_rune]: 10, [itemsIds.ichor]: 5 },
		augmenting: { [itemsIds.death_rune]: 100, [itemsIds.ichor]: 50 },
		crafting: [
			{ recipe: { [itemsIds.forgotten_madness]: 1, [itemsIds.death_rune]: 100, [itemsIds.ichor]: 50 } },
			{ recipe: { [itemsIds.forgotten_sadness]: 1, [itemsIds.death_rune]: 100, [itemsIds.ichor]: 50 } },
		],
	},
	[itemsIds.gargoyle_chainmail]: {
		augmenting: { [itemsIds.iron_bar]: 100, [itemsIds.mithril_bar]: 100, [itemsIds.stygian_bar]: 10 },
		scrapping: { [itemsIds.obsidian_glass]: 1, [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.gargoyle_legs]: {
		augmenting: { [itemsIds.iron_bar]: 80, [itemsIds.mithril_bar]: 80, [itemsIds.stygian_bar]: 10 },
		scrapping: { [itemsIds.obsidian_glass]: 1, [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.gargoyle_boots]: {
		augmenting: { [itemsIds.iron_bar]: 80, [itemsIds.mithril_bar]: 80, [itemsIds.stygian_bar]: 10 },
		scrapping: { [itemsIds.obsidian_glass]: 1, [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.gargoyle_helm]: {
		augmenting: { [itemsIds.iron_bar]: 60, [itemsIds.mithril_bar]: 60, [itemsIds.stygian_bar]: 10 },
		scrapping: { [itemsIds.obsidian_glass]: 1, [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.gargoyle_shield]: {
		augmenting: { [itemsIds.iron_bar]: 100, [itemsIds.mithril_bar]: 100, [itemsIds.stygian_bar]: 10 },
		scrapping: { [itemsIds.obsidian_glass]: 1, [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.gargoyle_falchion]: {
		augmenting: {
			[itemsIds.iron_bar]: 80,
			[itemsIds.mithril_bar]: 80,
			[itemsIds.stygian_bar]: 10,
			[itemsIds.oak_log]: 20,
			[itemsIds.willow_log]: 20,
		},
		scrapping: { [itemsIds.obsidian_glass]: 1, [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.gargoyle_daggers]: {
		augmenting: {
			[itemsIds.iron_bar]: 60,
			[itemsIds.mithril_bar]: 60,
			[itemsIds.stygian_bar]: 10,
			[itemsIds.oak_log]: 15,
			[itemsIds.willow_log]: 15,
		},
		scrapping: { [itemsIds.obsidian_glass]: 1, [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.gargoyle_glaive]: {
		augmenting: {
			[itemsIds.iron_bar]: 120,
			[itemsIds.mithril_bar]: 120,
			[itemsIds.stygian_bar]: 10,
			[itemsIds.oak_log]: 30,
			[itemsIds.willow_log]: 30,
		},
		scrapping: { [itemsIds.obsidian_glass]: 1, [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.infernal_lance]: {
		crafting: [
			{
				recipe: {
					[itemsIds.iron_bar]: 1500,
					[itemsIds.mithril_bar]: 1500,
					[itemsIds.obsidian_greatsword]: 25,
					[itemsIds.infernal_dust]: 10000,
					[itemsIds.obsidian_glass]: 10000,
				},
			},
		],
		augmenting: { [itemsIds.coal]: 100, [itemsIds.stygian_bar]: 50, [itemsIds.fire_rune]: 100 },
		scrapping: {
			[itemsIds.stygian_bar]: 5,
			[itemsIds.fire_rune]: 10,
			[itemsIds.obsidian_glass]: 10,
		},
	},
	[itemsIds.black_knight_great_helm]: {
		scrapping: { [itemsIds.iron_bar]: 6, [itemsIds.mithril_bar]: 6 },
		augmenting: { [itemsIds.iron_bar]: 60, [itemsIds.mithril_bar]: 60 },
	},
	[itemsIds.chorus_of_souls]: {
		scrapping: { [itemsIds.death_rune]: 20, [itemsIds.ichor]: 10 },
		augmenting: { [itemsIds.death_rune]: 200, [itemsIds.ichor]: 100 },
	},
	[itemsIds.symphony_of_spirits]: {
		scrapping: {
			[itemsIds.stygian_bar]: 2,
			[itemsIds.death_rune]: 50,
			[itemsIds.ichor]: 10,
		},
		augmenting: { [itemsIds.death_rune]: 500, [itemsIds.ichor]: 100, [itemsIds.stygian_bar]: 20 },
	},
	[itemsIds.decayed_cloak]: {
		scrapping: { [itemsIds.death_rune]: 50, [itemsIds.ichor]: 5 },
		augmenting: { [itemsIds.death_rune]: 500, [itemsIds.ichor]: 50 },
	},
	[itemsIds.mysterious_man]: {
		scrapping: { [itemsIds.ichor]: 1000 },
		augmenting: { [itemsIds.ichor]: 10000 },
	},
	[itemsIds.corny_joke]: {
		scrapping: { [itemsIds.ichor]: 1000 },
		augmenting: { [itemsIds.ichor]: 10000 },
	},
	[itemsIds.death_metal_wig]: {
		scrapping: { [itemsIds.ichor]: 1000 },
		augmenting: { [itemsIds.ichor]: 10000 },
	},
	[itemsIds.shrimp_carapace]: {
		augmenting: { [itemsIds.shrimp_bauble]: 1 },
		scrapping: { [itemsIds.raw_shrimp]: 10 },
	},
	[itemsIds.shrimp_greaves]: {
		augmenting: { [itemsIds.shrimp_bauble]: 1 },
		scrapping: { [itemsIds.raw_shrimp]: 10 },
	},
	[itemsIds.shrimp_helm]: {
		augmenting: { [itemsIds.shrimp_bauble]: 1 },
		scrapping: { [itemsIds.raw_shrimp]: 10 },
	},
	[itemsIds.shrimp_shell]: {
		augmenting: { [itemsIds.shrimp_bauble]: 1 },
		scrapping: { [itemsIds.raw_shrimp]: 10 },
	},
	[itemsIds.titanic_gauntlet]: {
		crafting: [{ recipe: { [itemsIds.giant_scraps]: 400 } }],
		augmenting: {
			[itemsIds.sapphire]: 15,
			[itemsIds.emerald]: 12,
			[itemsIds.ruby]: 9,
			[itemsIds.diamond]: 6,
			[itemsIds.black_opal]: 3,
			[itemsIds.giant_kings_jewel]: 1,
		},
		scrapping: {
			[itemsIds.sapphire]: 30,
			[itemsIds.emerald]: 25,
			[itemsIds.ruby]: 20,
			[itemsIds.diamond]: 15,
			[itemsIds.black_opal]: 10,
			[itemsIds.giant_kings_jewel]: 5,
		},
	},
	[itemsIds.awakened_world_shaper]: {
		augmenting: {
			[itemsIds.sapphire]: 15,
			[itemsIds.emerald]: 12,
			[itemsIds.ruby]: 9,
			[itemsIds.diamond]: 6,
			[itemsIds.black_opal]: 3,
			[itemsIds.giant_kings_jewel]: 1,
		},
		scrapping: {
			[itemsIds.runic_etchant]: 200,
			[itemsIds.corrupted_substrate]: 150,
			[itemsIds.reactive_powder]: 100,
			[itemsIds.polishing_paste]: 50,
			[itemsIds.sanguine_infusion]: 10,
		},
	},
	[itemsIds.shapeless_scythe]: {
		crafting: [
			{ recipe: { [itemsIds.stygian_bar]: 350, [itemsIds.chaotic_crystal]: 200, [itemsIds.chaos_stone]: 1 } },
		],
		augmenting: { [itemsIds.giant_kings_jewel]: 1, [itemsIds.giant_scraps]: 40 },
		scrapping: { [itemsIds.giant_scraps]: 10, [itemsIds.chaotic_crystal]: 5 },
	},
	[itemsIds.giant_kings_jewel]: {
		crafting: [{ recipe: { [itemsIds.giant_scraps]: 250 } }],
	},
	[itemsIds.world_walkers]: {
		crafting: [{ recipe: { [itemsIds.giant_scraps]: 400 } }],
		augmenting: { [itemsIds.giant_scraps]: 50 },
		scrapping: { [itemsIds.giant_scraps]: 5 },
	},
	[itemsIds.crystal_mace]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaotic_crystal]: 150,
					[itemsIds.heavy_chaos_shard]: 1,
					[itemsIds.chaos_rune]: 2500,
				},
			},
		],
		augmenting: {
			[itemsIds.chaotic_crystal]: 30,
			[itemsIds.willow_log]: 750,
			[itemsIds.elder_log]: 250,
			[itemsIds.diamond]: 4,
			[itemsIds.chaos_rune]: 5000,
		},
		scrapping: {
			[itemsIds.chaotic_crystal]: 3,
			[itemsIds.willow_log]: 75,
			[itemsIds.elder_log]: 25,
			[itemsIds.polishing_paste]: 40,
			[itemsIds.chaos_rune]: 100,
		},
	},
	[itemsIds.signet_ring]: {
		augmenting: {
			[itemsIds.heat]: 50000,
			[itemsIds.gold_bar]: 100,
			[itemsIds.chaotic_crystal]: 10,
			[itemsIds.black_opal]: 1,
			[itemsIds.chaos_rune]: 2500,
		},
		scrapping: {
			[itemsIds.gold_bar]: 10,
			[itemsIds.chaotic_crystal]: 1,
			[itemsIds.sanguine_infusion]: 5,
			[itemsIds.chaos_rune]: 100,
		},
	},
	[itemsIds.mantle_of_flame]: {
		crafting: [{ recipe: { [itemsIds.feather]: 5000, [itemsIds.phoenixs_feather]: 36 } }],
		augmenting: {
			[itemsIds.heat]: 15000,
			[itemsIds.fire_rune]: 1250,
			[itemsIds.feather]: 500,
			[itemsIds.phoenixs_feather]: 1,
			[itemsIds.molten_ashes]: 1,
		},
		scrapping: {
			[itemsIds.heat]: 1500,
			[itemsIds.fire_rune]: 200,
			[itemsIds.feather]: 50,
		},
	},
	[itemsIds.bestial_shawl]: {
		scrapping: {
			[itemsIds.fur]: 3,
			[itemsIds.thick_fur]: 1,
			[itemsIds.rough_hide]: 1,
			[itemsIds.leather]: 3,
		},
		augmenting: {
			[itemsIds.fur]: 30,
			[itemsIds.thick_fur]: 10,
			[itemsIds.rough_hide]: 10,
			[itemsIds.leather]: 30,
		},
	},
	[itemsIds.hands_of_khaast]: {
		scrapping: { [itemsIds.fire_rune]: 10, [itemsIds.stygian_bar]: 1 },
		crafting: [
			{ recipe: { [itemsIds.stygian_bar]: 100, [itemsIds.fire_rune]: 1000, [itemsIds.phoenixs_feather]: 36 } },
		],
	},
	[itemsIds.black_knight_titans_helm]: {
		scrapping: {
			[itemsIds.gold_bar]: 15,
			[itemsIds.adamantite_bar]: 15,
			[itemsIds.obsidian_glass]: 15,
		},
		augmenting: { [itemsIds.gold_bar]: 150, [itemsIds.adamantite_bar]: 150, [itemsIds.obsidian_glass]: 150 },
	},
	[itemsIds.elven_hood]: {
		crafting: [
			{ recipe: { [itemsIds.crystal_scale]: 12, [itemsIds.elder_cloth]: 100, [itemsIds.aquamarine]: 30 } },
		],
		augmenting: {
			[itemsIds.scale]: 10,
			[itemsIds.crystal_scale]: 5,
			[itemsIds.leather]: 10,
		},
		scrapping: {
			[itemsIds.scale]: 1,
			[itemsIds.leather]: 1,
		},
	},
	[itemsIds.elven_heavy_armor]: {
		crafting: [
			{ recipe: { [itemsIds.adamantite_bar]: 250, [itemsIds.stygian_bar]: 500, [itemsIds.crystal_scale]: 12 } },
		],
		augmenting: {
			[itemsIds.adamantite_bar]: 10,
			[itemsIds.stygian_bar]: 10,
			[itemsIds.crystal_scale]: 5,
			[itemsIds.leather]: 10,
		},
		scrapping: {
			[itemsIds.adamantite_bar]: 1,
			[itemsIds.stygian_bar]: 1,
			[itemsIds.leather]: 1,
		},
	},
	[itemsIds.elven_leggings]: {
		crafting: [{ recipe: { [itemsIds.scale]: 250, [itemsIds.crystal_scale]: 12 } }],
		augmenting: {
			[itemsIds.scale]: 10,
			[itemsIds.crystal_scale]: 5,
			[itemsIds.leather]: 10,
		},
		scrapping: {
			[itemsIds.scale]: 1,
			[itemsIds.leather]: 1,
		},
	},
	[itemsIds.elven_boots]: {
		crafting: [{ recipe: { [itemsIds.stygian_boots]: 3, [itemsIds.crystal_scale]: 24 } }],
		augmenting: {
			[itemsIds.scale]: 10,
			[itemsIds.crystal_scale]: 5,
			[itemsIds.leather]: 10,
		},
		scrapping: {
			[itemsIds.scale]: 1,
			[itemsIds.leather]: 1,
		},
	},
	[itemsIds.searing_ladle]: {
		augmenting: {
			[itemsIds.fire_rune]: 150,
			[itemsIds.burnt_fish]: 1000,
			[itemsIds.burnt_food]: 100,
			[itemsIds.ruby]: 1,
			[itemsIds.diamond]: 1,
		},
		scrapping: {
			[itemsIds.reactive_powder]: 1,
			[itemsIds.polishing_paste]: 1,
		},
	},
	[itemsIds.black_knights_gauntlets]: {
		scrapping: { [itemsIds.adamantite_bar]: 10, [itemsIds.stygian_bar]: 4 },
		augmenting: { [itemsIds.adamantite_bar]: 100, [itemsIds.stygian_bar]: 35 },
	},
	[itemsIds.bestial_dagger]: {
		augmenting: { [itemsIds.bone_plate]: 10, [itemsIds.chitin]: 10, [itemsIds.scale]: 10 },
		scrapping: { [itemsIds.chitin]: 1 },
	},
	[itemsIds.empowered_bestial_dagger]: {
		augmenting: { [itemsIds.bone_plate]: 20, [itemsIds.chitin]: 20, [itemsIds.scale]: 20 },
		scrapping: { [itemsIds.scale]: 1 },
	},
	[itemsIds.bestial_defender]: {
		augmenting: { [itemsIds.bone_plate]: 10, [itemsIds.chitin]: 10, [itemsIds.scale]: 10 },
		scrapping: { [itemsIds.chitin]: 1 },
	},
	[itemsIds.empowered_bestial_defender]: {
		augmenting: { [itemsIds.bone_plate]: 20, [itemsIds.chitin]: 20, [itemsIds.scale]: 20 },
		scrapping: { [itemsIds.scale]: 1 },
	},
	[itemsIds.elven_kings_longbow]: {
		crafting: [{ recipe: { [itemsIds.living_shard]: 2, [itemsIds.crystal_scale]: 48 } }],
		augmenting: { [itemsIds.elder_log]: 250, [itemsIds.void_ammo]: 200, [itemsIds.crystal_scale]: 9 },
		scrapping: { [itemsIds.elder_log]: 25, [itemsIds.void_ammo]: 20, [itemsIds.crystal_scale]: 1 },
	},
	[itemsIds.ancient_trident]: {
		crafting: [
			{
				recipe: {
					[itemsIds.worn_ancient_splinter]: 3,
					[itemsIds.elder_log]: 2000,
					[itemsIds.aquamarine]: 200,
					[itemsIds.mystical_driftwood]: 500,
				},
			},
		],
		augmenting: { [itemsIds.aqueous_chunks]: 16, [itemsIds.frost_shard]: 1, [itemsIds.aquamarine]: 30 },
		scrapping: { [itemsIds.aqueous_chunks]: 10, [itemsIds.frost_shard]: 1, [itemsIds.aquamarine]: 3 },
	},
	[itemsIds.fishtail]: {
		augmenting: { [itemsIds.runite_bar]: 33, [itemsIds.aquamarine]: 1 },
		scrapping: { [itemsIds.runite_bar]: 2 },
	},
	[itemsIds.razorfin]: {
		augmenting: { [itemsIds.runite_bar]: 25, [itemsIds.stygian_bar]: 4, [itemsIds.aquamarine]: 1 },
		scrapping: { [itemsIds.runite_bar]: 2, [itemsIds.stygian_bar]: 1 },
	},
	[itemsIds.elven_quiver]: {
		augmenting: { [itemsIds.elder_log]: 75, [itemsIds.crystal_scale]: 15, [itemsIds.elder_stone]: 1 },
		scrapping: { [itemsIds.elder_log]: 7, [itemsIds.crystal_scale]: 1, [itemsIds.elder_stone]: 1 },
	},
	[itemsIds.magic_quiver]: {
		augmenting: { [itemsIds.thick_fur]: 8, [itemsIds.leather]: 20 },
		scrapping: { [itemsIds.thick_fur]: 1, [itemsIds.leather]: 1 },
	},
	[itemsIds.defenders_band]: {
		augmenting: {
			[itemsIds.bronze_bar]: 100,
			[itemsIds.iron_bar]: 100,
			[itemsIds.gold_bar]: 200,
			[itemsIds.mithril_bar]: 100,
			[itemsIds.adamantite_bar]: 100,
			[itemsIds.runite_bar]: 100,
			[itemsIds.black_opal]: 1,
		},
		scrapping: {
			[itemsIds.bronze_bar]: 1,
			[itemsIds.iron_bar]: 1,
			[itemsIds.gold_bar]: 2,
			[itemsIds.mithril_bar]: 1,
			[itemsIds.adamantite_bar]: 1,
			[itemsIds.runite_bar]: 1,
		},
	},
	[itemsIds.defenders_pendant]: {
		augmenting: {
			[itemsIds.bronze_bar]: 100,
			[itemsIds.iron_bar]: 100,
			[itemsIds.gold_bar]: 200,
			[itemsIds.mithril_bar]: 100,
			[itemsIds.adamantite_bar]: 100,
			[itemsIds.runite_bar]: 100,
			[itemsIds.black_opal]: 1,
		},
		scrapping: {
			[itemsIds.bronze_bar]: 1,
			[itemsIds.iron_bar]: 1,
			[itemsIds.gold_bar]: 2,
			[itemsIds.mithril_bar]: 1,
			[itemsIds.adamantite_bar]: 1,
			[itemsIds.runite_bar]: 1,
		},
	},
	[itemsIds.scaled_circuit]: {
		augmenting: { [itemsIds.gold_bar]: 200, [itemsIds.black_opal]: 1, [itemsIds.scale]: 35 },
		scrapping: { [itemsIds.gold_bar]: 10, [itemsIds.corrupted_substrate]: 10, [itemsIds.scale]: 2 },
	},
	[itemsIds.devourers_band]: {
		augmenting: { [itemsIds.frost_shard]: 1, [itemsIds.elder_stone]: 1, [itemsIds.ancient_stone]: 1 },
		scrapping: { [itemsIds.frost_shard]: 1, [itemsIds.elder_stone]: 1 },
	},
	[itemsIds.slayers_pendant]: {
		augmenting: {
			[itemsIds.bronze_bar]: 100,
			[itemsIds.iron_bar]: 100,
			[itemsIds.gold_bar]: 200,
			[itemsIds.mithril_bar]: 100,
			[itemsIds.adamantite_bar]: 100,
			[itemsIds.runite_bar]: 100,
			[itemsIds.black_opal]: 1,
		},
		scrapping: { [itemsIds.gold_bar]: 10, [itemsIds.sanguine_infusion]: 10, [itemsIds.stygian_bar]: 2 },
	},
	[itemsIds.alacrity_pendant]: {
		augmenting: { [itemsIds.air_rune]: 450, [itemsIds.diamond]: 6 },
		scrapping: { [itemsIds.air_rune]: 100, [itemsIds.polishing_paste]: 200 },
	},
	[itemsIds.ring_of_taxes]: {
		augmenting: {
			[itemsIds.gold_bar]: 500,
			[itemsIds.sapphire]: 5,
			[itemsIds.emerald]: 4,
			[itemsIds.ruby]: 3,
			[itemsIds.diamond]: 2,
			[itemsIds.black_opal]: 1,
		},
		scrapping: { [itemsIds.gold_bar]: 10, [itemsIds.polishing_paste]: 50 },
	},
	[itemsIds.wrath_and_war]: {
		scrapping: { [itemsIds.stygian_bar]: 4, [itemsIds.ichor]: 2 },
		augmenting: { [itemsIds.stygian_bar]: 40, [itemsIds.ichor]: 12 },
	},
	[itemsIds.shrimp_scepter]: {
		crafting: [
			{
				recipe: {
					[itemsIds.voidblessed_shrimp]: 1,
					[itemsIds.shrimp_eye]: 1,
					[itemsIds.chaotic_crystal]: 300,
					[itemsIds.shrimp_scraps]: 150,
				},
			},
		],
		augmenting: { [itemsIds.chaotic_crystal]: 33, [itemsIds.shrimp_scraps]: 30, [itemsIds.shrimp_eye]: 1 },
		scrapping: { [itemsIds.chaotic_crystal]: 3, [itemsIds.shrimp_scraps]: 1 },
	},
	[itemsIds.shrimp_stinger]: {
		crafting: [
			{
				recipe: {
					[itemsIds.voidblessed_shrimp]: 1,
					[itemsIds.shrimp_eye]: 1,
					[itemsIds.chaotic_crystal]: 300,
					[itemsIds.shrimp_scraps]: 150,
				},
			},
		],
		augmenting: { [itemsIds.chaotic_crystal]: 33, [itemsIds.shrimp_scraps]: 30, [itemsIds.shrimp_eye]: 1 },
		scrapping: { [itemsIds.chaotic_crystal]: 3, [itemsIds.shrimp_scraps]: 1 },
	},
	[itemsIds.shrimp_tail]: {
		crafting: [
			{
				recipe: {
					[itemsIds.voidblessed_shrimp]: 1,
					[itemsIds.shrimp_eye]: 1,
					[itemsIds.chaotic_crystal]: 300,
					[itemsIds.shrimp_scraps]: 150,
				},
			},
		],
		augmenting: { [itemsIds.chaotic_crystal]: 33, [itemsIds.shrimp_scraps]: 30, [itemsIds.shrimp_eye]: 1 },
		scrapping: { [itemsIds.chaotic_crystal]: 3, [itemsIds.shrimp_scraps]: 1 },
	},
	[itemsIds.hunters_band]: {
		scrapping: { [itemsIds.bone]: 25, [itemsIds.bone_plate]: 10 },
		augmenting: { [itemsIds.bone]: 250, [itemsIds.bone_plate]: 100 },
	},
	[itemsIds.dwarven_hammer_necklace]: {
		scrapping: {
			[itemsIds.stone]: 30,
			[itemsIds.mithril_bar]: 15,
			[itemsIds.adamantite_bar]: 15,
			[itemsIds.runite_bar]: 15,
		},
		augmenting: {
			[itemsIds.stone]: 300,
			[itemsIds.mithril_bar]: 150,
			[itemsIds.adamantite_bar]: 150,
			[itemsIds.runite_bar]: 150,
		},
	},
	[itemsIds.ancient_token]: {
		augmenting: { [itemsIds.aqueous_chunks]: 4, [itemsIds.frost_shard]: 1, [itemsIds.aquamarine]: 30 },
		scrapping: { [itemsIds.aqueous_chunks]: 4, [itemsIds.frost_shard]: 1, [itemsIds.aquamarine]: 3 },
	},
	[itemsIds.ancient_ring]: {
		augmenting: { [itemsIds.aqueous_chunks]: 4, [itemsIds.frost_shard]: 1, [itemsIds.aquamarine]: 30 },
		scrapping: { [itemsIds.aqueous_chunks]: 4, [itemsIds.frost_shard]: 1, [itemsIds.aquamarine]: 3 },
	},
	[itemsIds.golem_ring]: {
		augmenting: { [itemsIds.gold_bar]: 100, [itemsIds.obsidian_glass]: 1, [itemsIds.golem_marrow]: 1 },
		scrapping: { [itemsIds.gold_bar]: 10, [itemsIds.golem_marrow]: 1 },
	},
	[itemsIds.elven_light_armor]: {
		crafting: [{ recipe: { [itemsIds.elven_heavy_armor]: 1, [itemsIds.crystal_scale]: 12 } }],
		augmenting: {
			[itemsIds.scale]: 10,
			[itemsIds.crystal_scale]: 5,
			[itemsIds.leather]: 10,
		},
		scrapping: { [itemsIds.scale]: 1, [itemsIds.leather]: 1 },
	},
	[itemsIds.elven_hat]: {
		crafting: [{ recipe: { [itemsIds.elven_hood]: 1, [itemsIds.crystal_scale]: 12 } }],
		augmenting: {
			[itemsIds.scale]: 10,
			[itemsIds.crystal_scale]: 5,
			[itemsIds.leather]: 10,
		},
		scrapping: { [itemsIds.scale]: 1, [itemsIds.leather]: 1 },
	},
	[itemsIds.sylvan_staff]: {
		scrapping: {
			[itemsIds.elder_log]: 5,
			[itemsIds.nature_rune]: 30,
			[itemsIds.mystical_driftwood]: 5,
		},
		augmenting: { [itemsIds.elder_log]: 50, [itemsIds.nature_rune]: 300, [itemsIds.mystical_driftwood]: 50 },
	},
	[itemsIds.master_parrying_gauntlet]: {
		crafting: [
			{
				recipe: {
					[itemsIds.adamantite_bar]: 150,
					[itemsIds.runite_bar]: 300,
					[itemsIds.stygian_bar]: 150,
					[itemsIds.defenders_band]: 1,
				},
			},
			{
				recipe: {
					[itemsIds.adamantite_bar]: 150,
					[itemsIds.runite_bar]: 300,
					[itemsIds.stygian_bar]: 150,
					[itemsIds.defenders_pendant]: 1,
				},
			},
		],
		augmenting: { [itemsIds.adamantite_bar]: 8, [itemsIds.runite_bar]: 15, [itemsIds.stygian_bar]: 8 },
		scrapping: { [itemsIds.adamantite_bar]: 1, [itemsIds.runite_bar]: 1, [itemsIds.stygian_bar]: 1 },
	},
	[itemsIds.expert_parrying_gauntlet]: {
		crafting: [
			{ recipe: { [itemsIds.iron_bar]: 150, [itemsIds.mithril_bar]: 300, [itemsIds.adamantite_bar]: 150 } },
		],
		augmenting: { [itemsIds.iron_bar]: 15, [itemsIds.mithril_bar]: 30, [itemsIds.adamantite_bar]: 15 },
		scrapping: { [itemsIds.iron_bar]: 1, [itemsIds.mithril_bar]: 3, [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.beginner_parrying_gauntlet]: {
		crafting: [{ recipe: { [itemsIds.bronze_bar]: 300, [itemsIds.iron_bar]: 300 } }],
		augmenting: { [itemsIds.bronze_bar]: 30, [itemsIds.iron_bar]: 30 },
		scrapping: { [itemsIds.bronze_bar]: 3, [itemsIds.iron_bar]: 3 },
	},
	[itemsIds.scroll_of_embers]: {
		scrollcrafting: {
			[itemsIds.silver]: 700,
			[itemsIds.fire_rune]: 70,
			[itemsIds.nature_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_the_prospector]: {
		scrollcrafting: {
			[itemsIds.silver]: 300,
			[itemsIds.air_rune]: 40,
			[itemsIds.fire_rune]: 40,
			[itemsIds.cosmic_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_the_scholar]: {
		scrollcrafting: {
			[itemsIds.silver]: 1300,
			[itemsIds.chaos_rune]: 110,
			[itemsIds.mind_rune]: 110,
			[itemsIds.cosmic_rune]: 110,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_gathering]: {
		scrollcrafting: {
			[itemsIds.silver]: 800,
			[itemsIds.earth_rune]: 70,
			[itemsIds.fire_rune]: 70,
			[itemsIds.water_rune]: 70,
			[itemsIds.mind_rune]: 70,
			[itemsIds.cosmic_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_superheating]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.earth_rune]: 80,
			[itemsIds.fire_rune]: 80,
			[itemsIds.cosmic_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_pyromancy]: {
		scrollcrafting: {
			[itemsIds.silver]: 100,
			[itemsIds.fire_rune]: 30,
			[itemsIds.water_rune]: 30,
			[itemsIds.nature_rune]: 30,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_wealth]: {
		scrollcrafting: {
			[itemsIds.silver]: 500,
			[itemsIds.nature_rune]: 50,
			[itemsIds.mind_rune]: 50,
			[itemsIds.cosmic_rune]: 50,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_haste]: {
		scrollcrafting: {
			[itemsIds.silver]: 2000,
			[itemsIds.air_rune]: 200,
			[itemsIds.chaos_rune]: 200,
			[itemsIds.mind_rune]: 200,
			[itemsIds.cosmic_rune]: 200,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_naturalist]: {
		scrollcrafting: {
			[itemsIds.silver]: 600,
			[itemsIds.air_rune]: 60,
			[itemsIds.water_rune]: 60,
			[itemsIds.nature_rune]: 60,
			[itemsIds.cosmic_rune]: 60,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_acrobatics]: {
		scrollcrafting: { [itemsIds.gold]: 1 },
	},
	[itemsIds.scroll_of_critical_strike]: {
		scrollcrafting: {
			[itemsIds.silver]: 600,
			[itemsIds.blood_rune]: 60,
			[itemsIds.death_rune]: 60,
			[itemsIds.chaos_rune]: 60,
			[itemsIds.cosmic_rune]: 60,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_poisoning]: {
		scrollcrafting: { [itemsIds.gold]: 1 },
	},
	[itemsIds.scroll_of_accuracy]: {
		scrollcrafting: {
			[itemsIds.silver]: 400,
			[itemsIds.blood_rune]: 40,
			[itemsIds.death_rune]: 40,
			[itemsIds.mind_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_deflect]: {
		scrollcrafting: { [itemsIds.gold]: 1 },
	},
	[itemsIds.scroll_of_force]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.blood_rune]: 80,
			[itemsIds.death_rune]: 80,
			[itemsIds.chaos_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_convergence]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.blood_rune]: 80,
			[itemsIds.death_rune]: 80,
			[itemsIds.chaos_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_finesse]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.blood_rune]: 80,
			[itemsIds.death_rune]: 80,
			[itemsIds.chaos_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_healing]: {
		scrollcrafting: {
			[itemsIds.silver]: 200,
			[itemsIds.blood_rune]: 30,
			[itemsIds.nature_rune]: 30,
			[itemsIds.cosmic_rune]: 30,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_weakening]: {
		scrollcrafting: {
			[itemsIds.silver]: 100,
			[itemsIds.air_rune]: 20,
			[itemsIds.fire_rune]: 20,
			[itemsIds.mind_rune]: 20,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_reinforcement]: {
		scrollcrafting: {
			[itemsIds.silver]: 200,
			[itemsIds.death_rune]: 30,
			[itemsIds.chaos_rune]: 30,
			[itemsIds.mind_rune]: 30,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_protection]: {
		scrollcrafting: {
			[itemsIds.silver]: 400,
			[itemsIds.air_rune]: 40,
			[itemsIds.earth_rune]: 40,
			[itemsIds.cosmic_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_parry]: {
		scrollcrafting: { [itemsIds.gold]: 1 },
	},
	[itemsIds.scroll_of_false_equilibrium]: {
		scrollcrafting: {
			[itemsIds.silver]: 1000,
			[itemsIds.blood_rune]: 80,
			[itemsIds.death_rune]: 80,
			[itemsIds.chaos_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_efficiency]: {
		scrollcrafting: {
			[itemsIds.silver]: 1500,
			[itemsIds.air_rune]: 130,
			[itemsIds.earth_rune]: 130,
			[itemsIds.fire_rune]: 130,
			[itemsIds.water_rune]: 130,
			[itemsIds.nature_rune]: 130,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_fishing]: {
		scrollcrafting: {
			[itemsIds.silver]: 700,
			[itemsIds.earth_rune]: 60,
			[itemsIds.water_rune]: 60,
			[itemsIds.mind_rune]: 60,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_cooking]: {
		scrollcrafting: {
			[itemsIds.silver]: 500,
			[itemsIds.earth_rune]: 50,
			[itemsIds.fire_rune]: 50,
			[itemsIds.water_rune]: 50,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_leftovers]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.fire_rune]: 80,
			[itemsIds.chaos_rune]: 80,
			[itemsIds.mind_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_blitzing]: {
		scrollcrafting: {
			[itemsIds.silver]: 400,
			[itemsIds.water_rune]: 40,
			[itemsIds.blood_rune]: 40,
			[itemsIds.air_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_crafting]: {
		scrollcrafting: {
			[itemsIds.silver]: 800,
			[itemsIds.air_rune]: 70,
			[itemsIds.nature_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_refining]: {
		scrollcrafting: {
			[itemsIds.silver]: 400,
			[itemsIds.air_rune]: 40,
			[itemsIds.earth_rune]: 40,
			[itemsIds.fire_rune]: 40,
			[itemsIds.cosmic_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_runecrafting]: {
		scrollcrafting: {
			[itemsIds.silver]: 500,
			[itemsIds.nature_rune]: 50,
			[itemsIds.mind_rune]: 50,
			[itemsIds.cosmic_rune]: 50,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_chances]: {
		scrollcrafting: {
			[itemsIds.silver]: 800,
			[itemsIds.nature_rune]: 70,
			[itemsIds.mind_rune]: 70,
			[itemsIds.cosmic_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_the_shrimp_lord]: {
		scrollcrafting: {
			[itemsIds.silver]: 100,
			[itemsIds.air_rune]: 10,
			[itemsIds.water_rune]: 10,
			[itemsIds.chaos_rune]: 10,
			[itemsIds.nature_rune]: 10,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_enlightenment]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.air_rune]: 70,
			[itemsIds.water_rune]: 70,
			[itemsIds.chaos_rune]: 70,
			[itemsIds.mind_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_prolonging]: {
		scrollcrafting: {
			[itemsIds.silver]: 700,
			[itemsIds.nature_rune]: 60,
			[itemsIds.mind_rune]: 60,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_the_treasure_hunter]: {
		scrollcrafting: {
			[itemsIds.silver]: 1200,
			[itemsIds.chaos_rune]: 100,
			[itemsIds.nature_rune]: 100,
			[itemsIds.cosmic_rune]: 100,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_inferno]: {
		scrollcrafting: {
			[itemsIds.silver]: 600,
			[itemsIds.earth_rune]: 50,
			[itemsIds.fire_rune]: 50,
			[itemsIds.chaos_rune]: 50,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_destructive_testing]: {
		scrollcrafting: {
			[itemsIds.silver]: 200,
			[itemsIds.earth_rune]: 30,
			[itemsIds.fire_rune]: 30,
			[itemsIds.chaos_rune]: 30,
			[itemsIds.mind_rune]: 30,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_nature]: {
		scrollcrafting: {
			[itemsIds.silver]: 300,
			[itemsIds.earth_rune]: 30,
			[itemsIds.water_rune]: 30,
			[itemsIds.nature_rune]: 30,
			[itemsIds.cosmic_rune]: 30,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_root_digging]: {
		scrollcrafting: {
			[itemsIds.silver]: 300,
			[itemsIds.earth_rune]: 30,
			[itemsIds.nature_rune]: 30,
			[itemsIds.cosmic_rune]: 30,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_patience]: {
		scrollcrafting: {
			[itemsIds.silver]: 1000,
			[itemsIds.air_rune]: 80,
			[itemsIds.water_rune]: 80,
			[itemsIds.death_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_overhealing]: {
		scrollcrafting: {
			[itemsIds.silver]: 200,
			[itemsIds.air_rune]: 30,
			[itemsIds.water_rune]: 30,
			[itemsIds.death_rune]: 30,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_farming]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.earth_rune]: 70,
			[itemsIds.water_rune]: 70,
			[itemsIds.nature_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_the_gold_digger]: {
		scrollcrafting: {
			[itemsIds.silver]: 500,
			[itemsIds.earth_rune]: 50,
			[itemsIds.nature_rune]: 50,
			[itemsIds.cosmic_rune]: 50,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_fertilizing]: {
		scrollcrafting: {
			[itemsIds.silver]: 1400,
			[itemsIds.air_rune]: 120,
			[itemsIds.earth_rune]: 120,
			[itemsIds.water_rune]: 120,
			[itemsIds.nature_rune]: 120,
			[itemsIds.cosmic_rune]: 120,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_cultivation]: {
		scrollcrafting: {
			[itemsIds.silver]: 200,
			[itemsIds.air_rune]: 30,
			[itemsIds.water_rune]: 30,
			[itemsIds.blood_rune]: 30,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_fortitude]: {
		scrollcrafting: {
			[itemsIds.silver]: 800,
			[itemsIds.blood_rune]: 70,
			[itemsIds.chaos_rune]: 70,
			[itemsIds.nature_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_pungent_bait]: {
		scrollcrafting: {
			[itemsIds.silver]: 200,
			[itemsIds.water_rune]: 30,
			[itemsIds.nature_rune]: 30,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_reinforced_line]: {
		scrollcrafting: {
			[itemsIds.silver]: 300,
			[itemsIds.earth_rune]: 40,
			[itemsIds.water_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_fishing_magnetism]: {
		scrollcrafting: {
			[itemsIds.silver]: 400,
			[itemsIds.earth_rune]: 40,
			[itemsIds.water_rune]: 40,
			[itemsIds.cosmic_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_bait_preservation]: {
		scrollcrafting: {
			[itemsIds.silver]: 600,
			[itemsIds.earth_rune]: 60,
			[itemsIds.water_rune]: 60,
			[itemsIds.chaos_rune]: 60,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_oil_spill]: {
		scrollcrafting: {
			[itemsIds.silver]: 800,
			[itemsIds.earth_rune]: 70,
			[itemsIds.water_rune]: 70,
			[itemsIds.death_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_deadliest_catch]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.earth_rune]: 70,
			[itemsIds.water_rune]: 70,
			[itemsIds.blood_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_the_master_chef]: {
		scrollcrafting: {
			[itemsIds.silver]: 1000,
			[itemsIds.earth_rune]: 90,
			[itemsIds.fire_rune]: 90,
			[itemsIds.water_rune]: 90,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_the_herbalist]: {
		scrollcrafting: {
			[itemsIds.silver]: 500,
			[itemsIds.fire_rune]: 50,
			[itemsIds.death_rune]: 50,
			[itemsIds.nature_rune]: 50,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_seed_harvesting]: {
		scrollcrafting: {
			[itemsIds.silver]: 400,
			[itemsIds.earth_rune]: 40,
			[itemsIds.blood_rune]: 40,
			[itemsIds.nature_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_metallurgy]: {
		scrollcrafting: {
			[itemsIds.silver]: 500,
			[itemsIds.air_rune]: 40,
			[itemsIds.fire_rune]: 40,
			[itemsIds.mind_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_pure_metals]: {
		scrollcrafting: {
			[itemsIds.silver]: 1200,
			[itemsIds.earth_rune]: 100,
			[itemsIds.fire_rune]: 100,
			[itemsIds.mind_rune]: 100,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_essence_concentration]: {
		scrollcrafting: {
			[itemsIds.silver]: 700,
			[itemsIds.chaos_rune]: 70,
			[itemsIds.mind_rune]: 70,
			[itemsIds.cosmic_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_the_fiber_finder]: {
		scrollcrafting: {
			[itemsIds.silver]: 600,
			[itemsIds.earth_rune]: 60,
			[itemsIds.water_rune]: 60,
			[itemsIds.mind_rune]: 60,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_archaeology]: {
		scrollcrafting: {
			[itemsIds.silver]: 500,
			[itemsIds.earth_rune]: 50,
			[itemsIds.water_rune]: 50,
			[itemsIds.nature_rune]: 50,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_endless_ammo]: {
		scrollcrafting: {
			[itemsIds.silver]: 400,
			[itemsIds.chaos_rune]: 40,
			[itemsIds.nature_rune]: 40,
			[itemsIds.mind_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_rune_reserves]: {
		scrollcrafting: {
			[itemsIds.silver]: 500,
			[itemsIds.water_rune]: 50,
			[itemsIds.chaos_rune]: 50,
			[itemsIds.mind_rune]: 50,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_the_soul_wisdom]: {
		scrollcrafting: {
			[itemsIds.silver]: 600,
			[itemsIds.chaos_rune]: 60,
			[itemsIds.mind_rune]: 60,
			[itemsIds.cosmic_rune]: 60,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_overly_well_done]: {
		scrollcrafting: {
			[itemsIds.silver]: 100,
			[itemsIds.earth_rune]: 10,
			[itemsIds.fire_rune]: 10,
			[itemsIds.water_rune]: 10,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_recklessness]: {
		scrollcrafting: {
			[itemsIds.silver]: 1000,
			[itemsIds.blood_rune]: 80,
			[itemsIds.death_rune]: 80,
			[itemsIds.chaos_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_engraving]: {
		scrollcrafting: {
			[itemsIds.silver]: 500,
			[itemsIds.nature_rune]: 50,
			[itemsIds.mind_rune]: 50,
			[itemsIds.cosmic_rune]: 50,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_resistance]: {
		scrollcrafting: {
			[itemsIds.silver]: 400,
			[itemsIds.air_rune]: 40,
			[itemsIds.fire_rune]: 40,
			[itemsIds.cosmic_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_supercooling]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.earth_rune]: 80,
			[itemsIds.water_rune]: 80,
			[itemsIds.cosmic_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_dwarven_engineering]: {
		scrollcrafting: {
			[itemsIds.silver]: 1000,
			[itemsIds.air_rune]: 90,
			[itemsIds.nature_rune]: 90,
			[itemsIds.mind_rune]: 90,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_taunting]: {
		scrollcrafting: {
			[itemsIds.silver]: 400,
			[itemsIds.blood_rune]: 40,
			[itemsIds.chaos_rune]: 40,
			[itemsIds.earth_rune]: 40,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.ashes]: {
		crafting: [
			{ recipe: { [itemsIds.heat]: 100, [itemsIds.log]: 30 } },
			{ recipe: { [itemsIds.heat]: 100, [itemsIds.oak_log]: 25 } },
			{ recipe: { [itemsIds.heat]: 100, [itemsIds.willow_log]: 20 } },
			{ recipe: { [itemsIds.heat]: 100, [itemsIds.maple_log]: 15 } },
			{ recipe: { [itemsIds.heat]: 100, [itemsIds.yew_log]: 10 } },
		],
	},
	[itemsIds.butter]: {
		crafting: [{ recipe: { [itemsIds.milk]: 5 } }],
	},
	[itemsIds.water_imbued_dust]: {
		crafting: [{ recipe: { [itemsIds.water_rune]: 32, [itemsIds.ashes]: 12 } }],
	},
	[itemsIds.empty_vial]: {
		crafting: [
			{ recipe: { [itemsIds.heat]: 20, [itemsIds.sand]: 5 } },
			{ recipe: { [itemsIds.heat]: 20, [itemsIds.empty_large_vial]: 60 } },
			{ recipe: { [itemsIds.heat]: 20, [itemsIds.empty_reinforced_vial]: 2 } },
		],
	},
	[itemsIds.empty_large_vial]: {
		// crafting: [
		// 	{ recipe: { [itemsIds.heat]: 20, [itemsIds.sand]: 3 } },
		// 	{ recipe: { [itemsIds.heat]: 20, [itemsIds.empty_vial]: 10 } },
		// 	{ recipe: { [itemsIds.heat]: 20, [itemsIds.empty_reinforced_vial]: 2 } },
		// ],
	},
	[itemsIds.empty_reinforced_vial]: {
		// crafting: [{ recipe: { [itemsIds.heat]: 100, [itemsIds.sand]: 3 } }],
	},
	[itemsIds.leather]: {
		crafting: [
			{ recipe: { [itemsIds.salt]: 1, [itemsIds.rough_hide]: 2 } },
			{ recipe: { [itemsIds.salt]: 1, [itemsIds.fur]: 4 } },
			{ recipe: { [itemsIds.salt]: 1, [itemsIds.hide]: 8 } },
		],
	},
	[itemsIds.rough_cloth]: {
		runecrafting: { [itemsIds.aqueous_grass]: 5 },
	},
	[itemsIds.restored_cloth]: {
		runecrafting: { [itemsIds.aqueous_grass]: 10 },
	},
	[itemsIds.lesser_cloth]: {
		runecrafting: { [itemsIds.water_weeds]: 5 },
	},
	[itemsIds.imbued_cloth]: {
		runecrafting: { [itemsIds.river_vines]: 5 },
	},
	[itemsIds.major_cloth]: {
		runecrafting: { [itemsIds.violet_ribbons]: 5 },
	},
	[itemsIds.greater_cloth]: {
		runecrafting: { [itemsIds.white_reeds]: 5 },
	},
	[itemsIds.elder_cloth]: {
		runecrafting: { [itemsIds.ancient_kelp]: 5 },
	},
	[itemsIds.aqueous_chunks]: {
		crafting: [{ recipe: { [itemsIds.frost_shard]: 1 } }],
	},
	[itemsIds.frost_shard]: {
		crafting: [{ recipe: { [itemsIds.aqueous_chunks]: 6 } }],
	},
	[itemsIds.box_trap]: {
		crafting: [
			{ recipe: { [itemsIds.log]: 100 } },
			{ recipe: { [itemsIds.oak_log]: 90 } },
			{ recipe: { [itemsIds.willow_log]: 80 } },
		],
	},
	[itemsIds.pitfall_trap]: {
		crafting: [
			{ recipe: { [itemsIds.oak_log]: 100 } },
			{ recipe: { [itemsIds.willow_log]: 90 } },
			{ recipe: { [itemsIds.maple_log]: 80 } },
		],
	},
	[itemsIds.bird_trap]: {
		crafting: [
			{ recipe: { [itemsIds.willow_log]: 100 } },
			{ recipe: { [itemsIds.maple_log]: 90 } },
			{ recipe: { [itemsIds.yew_log]: 80 } },
		],
	},
	[itemsIds.net_trap]: {
		crafting: [
			{ recipe: { [itemsIds.maple_log]: 100 } },
			{ recipe: { [itemsIds.yew_log]: 90 } },
			{ recipe: { [itemsIds.elder_log]: 80 } },
		],
	},
	[itemsIds.bear_trap]: {
		crafting: [{ recipe: { [itemsIds.yew_log]: 100 } }, { recipe: { [itemsIds.elder_log]: 90 } }],
	},
	[itemsIds.monster_trap]: {
		crafting: [{ recipe: { [itemsIds.elder_log]: 100 } }],
	},
	[itemsIds.water_totem]: {
		crafting: [{ recipe: { [itemsIds.water_rune]: 5000, [itemsIds.aquamarine]: 10 } }],
	},
	[itemsIds.nature_totem]: {
		crafting: [{ recipe: { [itemsIds.stone]: 1000, [itemsIds.nature_rune]: 5000 } }],
	},
	[itemsIds.earth_totem]: {
		crafting: [{ recipe: { [itemsIds.stone]: 1000, [itemsIds.earth_rune]: 5000 } }],
	},
	[itemsIds.coalescing_totem]: {
		crafting: [{ recipe: { [itemsIds.stone]: 1000, [itemsIds.chaos_rune]: 3000, [itemsIds.nature_rune]: 3000 } }],
	},
	[itemsIds.shrimp_bauble]: {
		scrapping: { [itemsIds.chaos_essence]: 100 },
		augmenting: { [itemsIds.chaos_essence]: 1000 },
	},
	[itemsIds.shrimp]: {
		augmenting: { [itemsIds.raw_shrimp]: 1 },
		scrapping: { [itemsIds.raw_shrimp]: 1 },
	},
	[itemsIds.shrimp_ascendant]: {
		augmenting: { [itemsIds.raw_shrimp]: 1 },
		scrapping: { [itemsIds.raw_shrimp]: 1 },
	},
	[itemsIds.snowball]: {
		crafting: [{ recipe: { [itemsIds.snow]: 100 } }],
	},
	[itemsIds.magical_snowball]: {
		scrapping: { [itemsIds.cosmic_rune]: 50 },
		augmenting: { [itemsIds.cosmic_rune]: 500 },
	},
	[itemsIds.candy_cane_daggers]: {
		scrapping: { [itemsIds.sugar]: 10 },
		augmenting: { [itemsIds.sugar]: 100 },
	},
	[itemsIds.festive_shroud]: {
		scrapping: { [itemsIds.sugar]: 10 },
		augmenting: { [itemsIds.sugar]: 100 },
	},
	[itemsIds.magic_ice_bar]: {
		smithing: { [itemsIds.heat]: 100, [itemsIds.magic_ice]: 2 },
	},
	[itemsIds.winter_rune]: {
		runecrafting: { [itemsIds.rune_slate]: 1, [itemsIds.winter_essence]: 400 },
	},
	[itemsIds.scroll_of_candy_canes]: {
		scrollcrafting: {
			[itemsIds.chaos_rune]: 10,
			[itemsIds.mind_rune]: 10,
			[itemsIds.cosmic_rune]: 10,
			[itemsIds.wrapping_paper]: 1,
			[itemsIds.winter_rune]: 20,
		},
	},
	// Use augmentOverride in the masterItemList for base item cost
	[itemsIds.black_knights_trial]: { augmenting: {} },
	[itemsIds.giant_scroll]: { augmenting: {} },
	[itemsIds.corroded_scroll]: { augmenting: {} },
	[itemsIds.stew_stained_page]: { augmenting: {} },
	[itemsIds.clustercluck]: { augmenting: {} },
	[itemsIds.demonic_trial]: { augmenting: {} },
	[itemsIds.taproot_scroll]: { augmenting: {} },
	[itemsIds.the_final_test]: { augmenting: {} },
	[itemsIds.shapeless_scroll]: { augmenting: {} },
	[itemsIds.stormy_sea_scroll]: { augmenting: {} },
	[itemsIds.enchanted_armory_scroll]: { augmenting: {} },
	[itemsIds.fallen_academy_scroll]: { augmenting: {} },
	[itemsIds.overgrown_woods_scroll]: { augmenting: {} },
	[itemsIds.elven_encampment_scroll]: { augmenting: {} },
	[itemsIds.giants_keep_key]: {
		crafting: [
			{
				recipe: {
					[itemsIds.fire_rune]: 3000,
					[itemsIds.water_rune]: 3000,
					[itemsIds.blood_rune]: 3000,
					[itemsIds.chaos_rune]: 3000,
					[itemsIds.nature_rune]: 3000,
					[itemsIds.nature_fragment]: 1,
					[itemsIds.ice_fragment]: 1,
					[itemsIds.fire_fragment]: 1,
					[itemsIds.corrupted_fragment]: 1,
					[itemsIds.chaos_fragment]: 1,
				},
			},
			{
				recipe: {
					[itemsIds.nature_fragment]: 3,
					[itemsIds.ice_fragment]: 3,
					[itemsIds.fire_fragment]: 3,
					[itemsIds.corrupted_fragment]: 3,
					[itemsIds.chaos_fragment]: 3,
				},
			},
		],
		augmenting: {
			[itemsIds.fire_rune]: 250,
			[itemsIds.water_rune]: 250,
			[itemsIds.blood_rune]: 250,
			[itemsIds.chaos_rune]: 250,
			[itemsIds.nature_rune]: 250,
		},
	},
	[itemsIds.nature_fragment]: {
		crafting: [{ recipe: { [itemsIds.nature_rune]: 500, [itemsIds.fire_fragment]: 1 } }],
	},
	[itemsIds.ice_fragment]: {
		crafting: [{ recipe: { [itemsIds.water_rune]: 500, [itemsIds.nature_fragment]: 1 } }],
	},
	[itemsIds.fire_fragment]: {
		crafting: [{ recipe: { [itemsIds.fire_rune]: 500, [itemsIds.ice_fragment]: 1 } }],
	},
	[itemsIds.torn_goblin_settlement_map]: {
		crafting: [
			{
				recipe: {
					[itemsIds.burnt_fish]: 75,
					[itemsIds.fire_rune]: 10,
					[itemsIds.goblin_brain]: 75,
					[itemsIds.scrap_of_stained_parchment]: 5,
				},
			},
		],
		augmenting: { [itemsIds.burnt_fish]: 15, [itemsIds.burnt_food]: 15, [itemsIds.goblin_brain]: 15 },
	},
	[itemsIds.dark_fortress_key]: {
		crafting: [
			{
				recipe: {
					[itemsIds.iron_bar]: 500,
					[itemsIds.mithril_bar]: 100,
					[itemsIds.blood_rune]: 1000,
					[itemsIds.death_rune]: 1000,
					[itemsIds.chaos_rune]: 1000,
					[itemsIds.dark_key_piece]: 4,
				},
			},
			{
				recipe: {
					[itemsIds.blood_rune]: 1000,
					[itemsIds.death_rune]: 1000,
					[itemsIds.chaos_rune]: 1000,
					[itemsIds.dark_key_piece]: 13,
				},
			},
		],
		augmenting: {
			[itemsIds.iron_bar]: 15,
			[itemsIds.mithril_bar]: 15,
			[itemsIds.blood_rune]: 50,
			[itemsIds.death_rune]: 50,
			[itemsIds.chaos_rune]: 50,
		},
	},
	[itemsIds.cursed_spade]: {
		crafting: [
			{
				recipe: {
					[itemsIds.iron_bar]: 1,
					[itemsIds.log]: 1,
					[itemsIds.ichor]: 300,
					[itemsIds.bone]: 1,
					[itemsIds.cursed_spade_head]: 1,
				},
			},
		],
		augmenting: { [itemsIds.ichor]: 30, [itemsIds.bone]: 10 },
	},
	[itemsIds.burning_feather]: {
		crafting: [
			{
				recipe: {
					[itemsIds.heat]: 200000,
					[itemsIds.fire_rune]: 4000,
					[itemsIds.feather]: 1,
					[itemsIds.molten_ashes]: 2,
					[itemsIds.infernal_flame]: 2,
				},
			},
			{
				recipe: {
					[itemsIds.fire_rune]: 1000,
					[itemsIds.feather]: 1,
					[itemsIds.molten_ashes]: 6,
					[itemsIds.infernal_flame]: 6,
				},
			},
		],
		augmenting: { [itemsIds.heat]: 10000, [itemsIds.fire_rune]: 300, [itemsIds.feather]: 50 },
	},
	[itemsIds.krampus_key]: {
		crafting: [{ recipe: { [itemsIds.winter_essence]: 150000 } }],
	},
	[itemsIds.infernal_flame]: {
		crafting: [{ recipe: { [itemsIds.heat]: 25000, [itemsIds.molten_ashes]: 2 } }],
	},
	[itemsIds.elder_lockstone]: {
		crafting: [
			{
				recipe: {
					[itemsIds.stone]: 1000,
					[itemsIds.elder_log]: 100,
					[itemsIds.fairy_dust]: 500,
					[itemsIds.elder_gem]: 1,
				},
			},
			{ recipe: { [itemsIds.stone]: 1000, [itemsIds.elder_gem]: 3 } },
		],
		augmenting: {
			[itemsIds.nature_rune]: 30,
			[itemsIds.stone]: 1000,
			[itemsIds.elder_log]: 10,
			[itemsIds.fairy_dust]: 50,
		},
	},
	[itemsIds.castle_map]: {
		crafting: [
			{
				recipe: {
					[itemsIds.silver]: 1000,
					[itemsIds.scroll]: 1,
					[itemsIds.scale]: 80,
					[itemsIds.partial_map]: 6,
				},
			},
			{ recipe: { [itemsIds.scroll]: 1, [itemsIds.partial_map]: 20 } },
		],
		augmenting: { [itemsIds.stone]: 50, [itemsIds.scroll]: 3 },
	},
	[itemsIds.ancient_pyramid]: {
		crafting: [
			{
				recipe: {
					[itemsIds.water_rune]: 3000,
					[itemsIds.ancient_stone]: 4,
					[itemsIds.mystical_driftwood]: 200,
				},
			},
			{ recipe: { [itemsIds.ancient_stone]: 13 } },
		],
		augmenting: { [itemsIds.raw_shrimp]: 250, [itemsIds.water_rune]: 250, [itemsIds.aquamarine]: 5 },
	},
	[itemsIds.ancient_maelstrom_orb]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaotic_crystal]: 50,
					[itemsIds.elder_log]: 1500,
					[itemsIds.ancient_crystal]: 4,
					[itemsIds.aquamarine]: 50,
				},
			},
			{ recipe: { [itemsIds.ancient_crystal]: 13 } },
		],
		augmenting: { [itemsIds.chaotic_crystal]: 2, [itemsIds.water_rune]: 250, [itemsIds.chaos_rune]: 500 },
	},
	[itemsIds.dwarven_research_key]: {
		crafting: [{ recipe: { [itemsIds.core_ingot]: 5 } }],
	},
	[itemsIds.abandoned_academy_scroll]: {
		crafting: [{ recipe: { [itemsIds.ancient_cloth_fragment]: 5 } }],
	},
	[itemsIds.core_ingot]: {
		smithing: { [itemsIds.heat]: 100000, [itemsIds.core_fragment]: 1 },
	},
	[itemsIds.dark_vault_key]: {
		crafting: [
			{
				recipe: {
					[itemsIds.adamantite_bar]: 300,
					[itemsIds.mithril_bar]: 300,
					[itemsIds.iron_bar]: 100,
					[itemsIds.ichor]: 300,
					[itemsIds.dark_vault_identification_crystal]: 4,
				},
			},
			{ recipe: { [itemsIds.dark_vault_identification_crystal]: 13 } },
		],
		augmenting: {
			[itemsIds.iron_bar]: 30,
			[itemsIds.mithril_bar]: 30,
			[itemsIds.obsidian_glass]: 10,
			[itemsIds.ichor]: 10,
		},
	},
	[itemsIds.novice_tacklebox]: {
		crafting: [
			{
				recipe: {
					[itemsIds.bronze_bar]: 20,
					[itemsIds.log]: 40,
					[itemsIds.fishing_net]: 3,
					[itemsIds.rotten_driftwood]: 3,
				},
			},
		],
		augmenting: { [itemsIds.bronze_bar]: 2, [itemsIds.log]: 4, [itemsIds.rotten_driftwood]: 1 },
		scrapping: { [itemsIds.bronze_bar]: 1 },
	},
	[itemsIds.basic_tacklebox]: {
		crafting: [
			{
				recipe: {
					[itemsIds.iron_bar]: 35,
					[itemsIds.oak_log]: 75,
					[itemsIds.fishing_net]: 6,
					[itemsIds.fly_fishing_rod]: 3,
					[itemsIds.rotten_driftwood]: 15,
				},
			},
		],
		augmenting: { [itemsIds.iron_bar]: 4, [itemsIds.oak_log]: 8, [itemsIds.rotten_driftwood]: 5 },
		scrapping: { [itemsIds.iron_bar]: 1 },
	},
	[itemsIds.advanced_tacklebox]: {
		crafting: [
			{
				recipe: {
					[itemsIds.mithril_bar]: 65,
					[itemsIds.willow_log]: 135,
					[itemsIds.fishing_net]: 8,
					[itemsIds.fly_fishing_rod]: 8,
					[itemsIds.cage]: 8,
					[itemsIds.sturdy_driftwood]: 15,
				},
			},
		],
		augmenting: { [itemsIds.mithril_bar]: 7, [itemsIds.willow_log]: 13, [itemsIds.sturdy_driftwood]: 2 },
		scrapping: { [itemsIds.mithril_bar]: 1 },
	},
	[itemsIds.pro_tacklebox]: {
		crafting: [
			{
				recipe: {
					[itemsIds.adamantite_bar]: 100,
					[itemsIds.maple_log]: 200,
					[itemsIds.fishing_net]: 8,
					[itemsIds.fly_fishing_rod]: 8,
					[itemsIds.cage]: 8,
					[itemsIds.harpoon]: 8,
					[itemsIds.sturdy_driftwood]: 50,
				},
			},
		],
		augmenting: { [itemsIds.adamantite_bar]: 10, [itemsIds.maple_log]: 20, [itemsIds.sturdy_driftwood]: 5 },
		scrapping: { [itemsIds.adamantite_bar]: 1 },
	},
	[itemsIds.supreme_tacklebox]: {
		crafting: [
			{
				recipe: {
					[itemsIds.runite_bar]: 135,
					[itemsIds.yew_log]: 270,
					[itemsIds.fishing_net]: 8,
					[itemsIds.fly_fishing_rod]: 8,
					[itemsIds.cage]: 8,
					[itemsIds.harpoon]: 8,
					[itemsIds.sturdy_driftwood]: 160,
					[itemsIds.aquamarine]: 10,
					[itemsIds.mystical_driftwood]: 30,
				},
			},
		],
		augmenting: {
			[itemsIds.runite_bar]: 14,
			[itemsIds.yew_log]: 27,
			[itemsIds.sturdy_driftwood]: 16,
			[itemsIds.aquamarine]: 1,
			[itemsIds.mystical_driftwood]: 3,
		},
		scrapping: { [itemsIds.runite_bar]: 1 },
	},
	[itemsIds.oceans_embrace]: {
		crafting: [
			{
				recipe: {
					[itemsIds.aquamarine]: 50,
					[itemsIds.ancient_fishing_rod]: 1,
					[itemsIds.ancient_harpoon]: 1,
					[itemsIds.ancient_fishing_net]: 1,
					[itemsIds.mystical_driftwood]: 150,
				},
			},
		],
		augmenting: {
			[itemsIds.aquamarine]: 300,
			[itemsIds.ancient_fishing_rod]: 3,
			[itemsIds.ancient_harpoon]: 3,
			[itemsIds.ancient_fishing_net]: 3,
			[itemsIds.mystical_driftwood]: 300,
		},
		scrapping: { [itemsIds.fishing_shard]: 30, [itemsIds.aquamarine]: 1, [itemsIds.mystical_driftwood]: 2 },
	},
	[itemsIds.shrimp_tail_bait]: {
		crafting: [{ recipe: { [itemsIds.raw_shrimp]: 3 } }],
	},
	[itemsIds.fish_guts_bait]: {
		crafting: [
			{ recipe: { [itemsIds.raw_anchovy]: 9 } },
			{ recipe: { [itemsIds.raw_trout]: 6 } },
			{ recipe: { [itemsIds.raw_salmon]: 3 } },
		],
	},
	[itemsIds.hardened_hook_bait]: {
		crafting: [{ recipe: { [itemsIds.adamantite_bar]: 2 } }],
	},
	[itemsIds.alluring_lure_bait]: {
		crafting: [{ recipe: { [itemsIds.gold_bar]: 2, [itemsIds.adamantite_bar]: 1 } }],
	},
	[itemsIds.writhing_tentacle_bait]: {
		crafting: [{ recipe: { [itemsIds.raw_tentacle_meat]: 1 } }],
	},
	[itemsIds.ancient_fishing_rod]: {
		crafting: [
			{ recipe: { [itemsIds.fishing_shard]: 900 } },
			{
				recipe: { [itemsIds.aquamarine]: 50, [itemsIds.ancient_harpoon]: 1, [itemsIds.mystical_driftwood]: 50 },
				experience: 4500,
			},
			{
				recipe: {
					[itemsIds.aquamarine]: 50,
					[itemsIds.ancient_fishing_net]: 1,
					[itemsIds.mystical_driftwood]: 50,
				},
				experience: 4500,
			},
		],
	},
	[itemsIds.ancient_harpoon]: {
		crafting: [
			{ recipe: { [itemsIds.fishing_shard]: 900 } },
			{
				recipe: {
					[itemsIds.aquamarine]: 50,
					[itemsIds.ancient_fishing_rod]: 1,
					[itemsIds.mystical_driftwood]: 50,
				},
				experience: 4500,
			},
			{
				recipe: {
					[itemsIds.aquamarine]: 50,
					[itemsIds.ancient_fishing_net]: 1,
					[itemsIds.mystical_driftwood]: 50,
				},
				experience: 4500,
			},
		],
	},
	[itemsIds.ancient_fishing_net]: {
		crafting: [
			{ recipe: { [itemsIds.fishing_shard]: 900 } },
			{
				recipe: {
					[itemsIds.aquamarine]: 50,
					[itemsIds.ancient_fishing_rod]: 1,
					[itemsIds.mystical_driftwood]: 50,
				},
				experience: 4500,
			},
			{
				recipe: { [itemsIds.aquamarine]: 50, [itemsIds.ancient_harpoon]: 1, [itemsIds.mystical_driftwood]: 50 },
				experience: 4500,
			},
		],
	},
	[itemsIds.dwarven_alloy]: {
		crafting: [
			{ recipe: { [itemsIds.mining_shard]: 900 } },
			{
				recipe: { [itemsIds.stygian_ore]: 150, [itemsIds.void_stone]: 50, [itemsIds.dwarven_ingot]: 1 },
				experience: 4500,
			},
			{
				recipe: { [itemsIds.stygian_ore]: 150, [itemsIds.void_stone]: 50, [itemsIds.dwarven_mold]: 1 },
				experience: 4500,
			},
		],
	},
	[itemsIds.dwarven_ingot]: {
		crafting: [
			{ recipe: { [itemsIds.mining_shard]: 900 } },
			{
				recipe: { [itemsIds.stygian_ore]: 150, [itemsIds.void_stone]: 50, [itemsIds.dwarven_alloy]: 1 },
				experience: 4500,
			},
			{
				recipe: { [itemsIds.stygian_ore]: 150, [itemsIds.void_stone]: 50, [itemsIds.dwarven_mold]: 1 },
				experience: 4500,
			},
		],
	},
	[itemsIds.dwarven_mold]: {
		crafting: [
			{ recipe: { [itemsIds.mining_shard]: 900 } },
			{
				recipe: { [itemsIds.stygian_ore]: 150, [itemsIds.void_stone]: 50, [itemsIds.dwarven_alloy]: 1 },
				experience: 4500,
			},
			{
				recipe: { [itemsIds.stygian_ore]: 150, [itemsIds.void_stone]: 50, [itemsIds.dwarven_ingot]: 1 },
				experience: 4500,
			},
		],
	},
	[itemsIds.very_mysterious_seedling]: {
		crafting: [
			{ recipe: { [itemsIds.foraging_shard]: 900 } },
			{
				recipe: { [itemsIds.yew_log]: 500, [itemsIds.elder_log]: 150, [itemsIds.elder_sap]: 1 },
				experience: 4500,
			},
			{
				recipe: { [itemsIds.yew_log]: 500, [itemsIds.elder_log]: 150, [itemsIds.world_tree_branch]: 1 },
				experience: 4500,
			},
		],
	},
	[itemsIds.elder_sap]: {
		crafting: [
			{ recipe: { [itemsIds.foraging_shard]: 900 } },
			{
				recipe: { [itemsIds.yew_log]: 500, [itemsIds.elder_log]: 150, [itemsIds.very_mysterious_seedling]: 1 },
				experience: 4500,
			},
			{
				recipe: { [itemsIds.yew_log]: 500, [itemsIds.elder_log]: 150, [itemsIds.world_tree_branch]: 1 },
				experience: 4500,
			},
		],
	},
	[itemsIds.world_tree_branch]: {
		crafting: [
			{ recipe: { [itemsIds.foraging_shard]: 900 } },
			{
				recipe: { [itemsIds.yew_log]: 500, [itemsIds.elder_log]: 150, [itemsIds.very_mysterious_seedling]: 1 },
				experience: 4500,
			},
			{
				recipe: { [itemsIds.yew_log]: 500, [itemsIds.elder_log]: 150, [itemsIds.elder_sap]: 1 },
				experience: 4500,
			},
		],
	},
	[itemsIds.fishermans_smock]: {
		crafting: [{ recipe: { [itemsIds.fishing_shard]: 450 } }],
		scrapping: {
			[itemsIds.fishing_shard]: 1,
			[itemsIds.aqueous_grass]: 6,
			[itemsIds.water_weeds]: 5,
			[itemsIds.river_vines]: 4,
			[itemsIds.violet_ribbons]: 3,
			[itemsIds.white_reeds]: 2,
			[itemsIds.ancient_kelp]: 1,
		},
	},
	[itemsIds.fishermans_bucket_hat]: {
		crafting: [{ recipe: { [itemsIds.fishing_shard]: 450 } }],
		scrapping: {
			[itemsIds.fishing_shard]: 1,
			[itemsIds.aqueous_grass]: 6,
			[itemsIds.water_weeds]: 5,
			[itemsIds.river_vines]: 4,
			[itemsIds.violet_ribbons]: 3,
			[itemsIds.white_reeds]: 2,
			[itemsIds.ancient_kelp]: 1,
		},
	},
	[itemsIds.fishermans_waders]: {
		crafting: [{ recipe: { [itemsIds.fishing_shard]: 450 } }],
		scrapping: {
			[itemsIds.fishing_shard]: 1,
			[itemsIds.aqueous_grass]: 6,
			[itemsIds.water_weeds]: 5,
			[itemsIds.river_vines]: 4,
			[itemsIds.violet_ribbons]: 3,
			[itemsIds.white_reeds]: 2,
			[itemsIds.ancient_kelp]: 1,
		},
	},
	[itemsIds.miners_smock]: {
		crafting: [{ recipe: { [itemsIds.mining_shard]: 450 } }],
		scrapping: {
			[itemsIds.mining_shard]: 1,
			[itemsIds.tin_ore]: 6,
			[itemsIds.copper_ore]: 6,
			[itemsIds.iron_ore]: 5,
			[itemsIds.gold_ore]: 4,
			[itemsIds.mithril_ore]: 3,
			[itemsIds.adamantite_ore]: 2,
			[itemsIds.runite_ore]: 2,
			[itemsIds.stygian_ore]: 1,
		},
	},
	[itemsIds.miners_lantern_helmet]: {
		crafting: [{ recipe: { [itemsIds.mining_shard]: 450 } }],
		scrapping: {
			[itemsIds.mining_shard]: 1,
			[itemsIds.tin_ore]: 6,
			[itemsIds.copper_ore]: 6,
			[itemsIds.iron_ore]: 5,
			[itemsIds.gold_ore]: 4,
			[itemsIds.mithril_ore]: 3,
			[itemsIds.adamantite_ore]: 2,
			[itemsIds.runite_ore]: 2,
			[itemsIds.stygian_ore]: 1,
		},
	},
	[itemsIds.miners_trousers]: {
		crafting: [{ recipe: { [itemsIds.mining_shard]: 450 } }],
		scrapping: {
			[itemsIds.mining_shard]: 1,
			[itemsIds.tin_ore]: 6,
			[itemsIds.copper_ore]: 6,
			[itemsIds.iron_ore]: 5,
			[itemsIds.gold_ore]: 4,
			[itemsIds.mithril_ore]: 3,
			[itemsIds.adamantite_ore]: 2,
			[itemsIds.runite_ore]: 2,
			[itemsIds.stygian_ore]: 1,
		},
	},
	[itemsIds.lumberjacks_vest]: {
		crafting: [{ recipe: { [itemsIds.foraging_shard]: 450 } }],
		scrapping: {
			[itemsIds.foraging_shard]: 1,
			[itemsIds.branch]: 7,
			[itemsIds.log]: 6,
			[itemsIds.oak_log]: 5,
			[itemsIds.willow_log]: 4,
			[itemsIds.maple_log]: 3,
			[itemsIds.yew_log]: 2,
			[itemsIds.elder_log]: 1,
		},
	},
	[itemsIds.lumberjacks_hat]: {
		crafting: [{ recipe: { [itemsIds.foraging_shard]: 450 } }],
		scrapping: {
			[itemsIds.foraging_shard]: 1,
			[itemsIds.branch]: 7,
			[itemsIds.log]: 6,
			[itemsIds.oak_log]: 5,
			[itemsIds.willow_log]: 4,
			[itemsIds.maple_log]: 3,
			[itemsIds.yew_log]: 2,
			[itemsIds.elder_log]: 1,
		},
	},
	[itemsIds.lumberjacks_trousers]: {
		crafting: [{ recipe: { [itemsIds.foraging_shard]: 450 } }],
		scrapping: {
			[itemsIds.foraging_shard]: 1,
			[itemsIds.branch]: 7,
			[itemsIds.log]: 6,
			[itemsIds.oak_log]: 5,
			[itemsIds.willow_log]: 4,
			[itemsIds.maple_log]: 3,
			[itemsIds.yew_log]: 2,
			[itemsIds.elder_log]: 1,
		},
	},
	[itemsIds.chefs_hat]: {
		augmenting: { [itemsIds.burnt_fish]: 100, [itemsIds.burnt_food]: 100 },
		scrapping: {
			[itemsIds.cooking_shard]: 1,
			[itemsIds.reactive_powder]: 5,
			[itemsIds.fire_rune]: 5,
		},
	},
	[itemsIds.golden_spoon]: {
		crafting: [
			{
				recipe: {
					[itemsIds.gold_bar]: 500,
					[itemsIds.ruby]: 10,
					[itemsIds.diamond]: 10,
					[itemsIds.black_opal]: 5,
					[itemsIds.cooking_shard]: 3000,
				},
			},
		],
		augmenting: {
			[itemsIds.fire_rune]: 200,
			[itemsIds.burnt_fish]: 1000,
			[itemsIds.burnt_food]: 1000,
			[itemsIds.ruby]: 1,
			[itemsIds.diamond]: 1,
			[itemsIds.black_opal]: 1,
			[itemsIds.cooking_shard]: 300,
		},
		scrapping: {
			[itemsIds.fire_rune]: 10,
			[itemsIds.reactive_powder]: 10,
			[itemsIds.polishing_paste]: 10,
			[itemsIds.cooking_shard]: 30,
		},
	},
	[itemsIds.camo_cloak]: {
		crafting: [
			{
				recipe: {
					[itemsIds.rough_cloth]: 300,
					[itemsIds.restored_cloth]: 250,
					[itemsIds.lesser_cloth]: 200,
					[itemsIds.imbued_cloth]: 150,
					[itemsIds.major_cloth]: 100,
					[itemsIds.greater_cloth]: 50,
				},
			},
		],
		augmenting: {
			[itemsIds.rough_cloth]: 30,
			[itemsIds.restored_cloth]: 25,
			[itemsIds.lesser_cloth]: 20,
			[itemsIds.imbued_cloth]: 15,
			[itemsIds.major_cloth]: 10,
			[itemsIds.greater_cloth]: 5,
		},
		scrapping: {
			[itemsIds.rough_cloth]: 1,
			[itemsIds.restored_cloth]: 1,
			[itemsIds.lesser_cloth]: 1,
			[itemsIds.imbued_cloth]: 1,
			[itemsIds.major_cloth]: 1,
			[itemsIds.greater_cloth]: 1,
		},
	},
	[itemsIds.flamboyant_cape]: {
		crafting: [
			{
				recipe: {
					[itemsIds.rough_cloth]: 300,
					[itemsIds.restored_cloth]: 250,
					[itemsIds.lesser_cloth]: 200,
					[itemsIds.imbued_cloth]: 150,
					[itemsIds.major_cloth]: 100,
					[itemsIds.greater_cloth]: 50,
				},
			},
		],
		augmenting: {
			[itemsIds.rough_cloth]: 30,
			[itemsIds.restored_cloth]: 25,
			[itemsIds.lesser_cloth]: 20,
			[itemsIds.imbued_cloth]: 15,
			[itemsIds.major_cloth]: 10,
			[itemsIds.greater_cloth]: 5,
		},
		scrapping: {
			[itemsIds.rough_cloth]: 1,
			[itemsIds.restored_cloth]: 1,
			[itemsIds.lesser_cloth]: 1,
			[itemsIds.imbued_cloth]: 1,
			[itemsIds.major_cloth]: 1,
			[itemsIds.greater_cloth]: 1,
		},
	},
	[itemsIds.small_bag_of_holding]: {
		crafting: [{ recipe: { [itemsIds.leather]: 300 } }],
		augmenting: { [itemsIds.leather]: 30 },
		scrapping: { [itemsIds.leather]: 1 },
	},
	[itemsIds.bag_of_holding]: {
		augmenting: { [itemsIds.chitin]: 150 },
		scrapping: { [itemsIds.chitin]: 1, [itemsIds.leather]: 1 },
	},
	[itemsIds.large_bag_of_holding]: {
		augmenting: { [itemsIds.scale]: 300 },
		scrapping: { [itemsIds.scale]: 1, [itemsIds.leather]: 1 },
	},
	[itemsIds.newbie_adventurers_backpack]: {
		scrapping: { [itemsIds.leather]: 3 },
		crafting: [{ recipe: { [itemsIds.leather]: 300 } }],
		augmenting: { [itemsIds.leather]: 30 },
	},
	[itemsIds.dwarven_adventurers_backpack]: {
		scrapping: { [itemsIds.stygian_bar]: 3, [itemsIds.scale]: 25 },
		crafting: [
			{
				recipe: {
					[itemsIds.chitin]: 300,
					[itemsIds.scale]: 300,
					[itemsIds.leather]: 300,
					[itemsIds.defunct_golem_core]: 1,
				},
			},
		],
		augmenting: { [itemsIds.stygian_bar]: 25, [itemsIds.scale]: 250 },
	},
	[itemsIds.cloak_of_many_pockets]: {
		crafting: [
			{
				recipe: {
					[itemsIds.rough_cloth]: 300,
					[itemsIds.restored_cloth]: 250,
					[itemsIds.lesser_cloth]: 200,
					[itemsIds.imbued_cloth]: 150,
					[itemsIds.major_cloth]: 100,
					[itemsIds.greater_cloth]: 50,
				},
			},
		],
		augmenting: {
			[itemsIds.rough_cloth]: 30,
			[itemsIds.restored_cloth]: 25,
			[itemsIds.lesser_cloth]: 20,
			[itemsIds.imbued_cloth]: 15,
			[itemsIds.major_cloth]: 10,
			[itemsIds.greater_cloth]: 5,
		},
		scrapping: {
			[itemsIds.rough_cloth]: 1,
			[itemsIds.lesser_cloth]: 1,
			[itemsIds.imbued_cloth]: 1,
			[itemsIds.major_cloth]: 1,
			[itemsIds.greater_cloth]: 1,
		},
	},
	[itemsIds.cloak_of_the_void]: {
		crafting: [
			{
				recipe: {
					[itemsIds.rough_cloth]: 300,
					[itemsIds.restored_cloth]: 250,
					[itemsIds.lesser_cloth]: 200,
					[itemsIds.imbued_cloth]: 150,
					[itemsIds.major_cloth]: 100,
					[itemsIds.greater_cloth]: 50,
					[itemsIds.void_portal_anchor]: 1,
				},
			},
		],
		augmenting: {
			[itemsIds.rough_cloth]: 30,
			[itemsIds.restored_cloth]: 25,
			[itemsIds.lesser_cloth]: 20,
			[itemsIds.imbued_cloth]: 15,
			[itemsIds.major_cloth]: 10,
			[itemsIds.greater_cloth]: 5,
		},
		scrapping: {
			[itemsIds.rough_cloth]: 3,
			[itemsIds.restored_cloth]: 2,
			[itemsIds.lesser_cloth]: 2,
			[itemsIds.imbued_cloth]: 2,
			[itemsIds.major_cloth]: 1,
			[itemsIds.greater_cloth]: 1,
		},
	},
	[itemsIds.dwarven_manufacturing_pendant]: {
		augmenting: {
			[itemsIds.nature_rune]: 50,
			[itemsIds.nature_talisman]: 1,
			[itemsIds.nature_rune]: 50,
		},
		scrapping: {
			[itemsIds.nature_rune]: 100,
		},
	},
	[itemsIds.elven_logistics_pendant]: {
		augmenting: {
			[itemsIds.nature_rune]: 50,
			[itemsIds.nature_talisman]: 1,
			[itemsIds.nature_rune]: 50,
		},
		scrapping: {
			[itemsIds.nature_rune]: 100,
		},
	},
	[itemsIds.dwarven_tongs]: {
		crafting: [
			{
				recipe: {
					[itemsIds.stygian_bar]: 200,
					[itemsIds.chaotic_crystal]: 100,
					[itemsIds.smithing_shard]: 3000,
				},
			},
		],
		augmenting: { [itemsIds.smithing_shard]: 300 },
		scrapping: { [itemsIds.smithing_shard]: 30, [itemsIds.chaotic_crystal]: 1 },
	},
	[itemsIds.elvish_hoe]: {
		crafting: [
			{
				recipe: {
					[itemsIds.stygian_bar]: 350,
					[itemsIds.diamond]: 1,
					[itemsIds.ichor]: 175,
					[itemsIds.farming_shard]: 8000,
				},
			},
		],
		augmenting: { [itemsIds.diamond]: 10, [itemsIds.farming_shard]: 900 },
		scrapping: {
			[itemsIds.polishing_paste]: 10,
			[itemsIds.farming_shard]: 80,
			[itemsIds.ichor]: 1,
			[itemsIds.stygian_bar]: 1,
		},
	},
	[itemsIds.blademasters_shroud]: {
		crafting: [
			{
				recipe: {
					[itemsIds.blademasters_memory]: 8,
					[itemsIds.leather]: 150,
					[itemsIds.stygian_bar]: 50,
					[itemsIds.adamantite_bar]: 300,
				},
			},
		],
		augmenting: {
			[itemsIds.blademasters_memory]: 1,
			[itemsIds.leather]: 50,
			[itemsIds.stygian_bar]: 10,
			[itemsIds.adamantite_bar]: 50,
		},
		scrapping: {
			[itemsIds.leather]: 5,
			[itemsIds.stygian_bar]: 1,
			[itemsIds.adamantite_bar]: 5,
		},
	},
	[itemsIds.royal_banner]: {
		scrapping: {
			[itemsIds.mithril_bar]: 2,
			[itemsIds.adamantite_bar]: 2,
			[itemsIds.runite_bar]: 2,
			[itemsIds.maple_log]: 2,
			[itemsIds.yew_log]: 2,
			[itemsIds.rough_cloth]: 1,
			[itemsIds.restored_cloth]: 1,
		},
		crafting: [
			{
				recipe: {
					[itemsIds.torn_banner]: 1,
					[itemsIds.rough_cloth]: 50,
					[itemsIds.restored_cloth]: 50,
					[itemsIds.mithril_bar]: 100,
					[itemsIds.adamantite_bar]: 100,
					[itemsIds.runite_bar]: 100,
					[itemsIds.yew_log]: 100,
					[itemsIds.maple_log]: 100,
				},
			},
		],
		augmenting: {
			[itemsIds.rough_cloth]: 10,
			[itemsIds.restored_cloth]: 10,
			[itemsIds.mithril_bar]: 20,
			[itemsIds.adamantite_bar]: 20,
			[itemsIds.runite_bar]: 20,
			[itemsIds.yew_log]: 20,
			[itemsIds.maple_log]: 20,
		},
	},
	[itemsIds.necromancers_cowl]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaos_rune]: 1000,
					[itemsIds.blood_rune]: 1000,
					[itemsIds.death_rune]: 1000,
					[itemsIds.elder_cloth]: 200,
					[itemsIds.aquamarine]: 30,
					[itemsIds.necromancers_remains]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.chaos_rune]: 100,
			[itemsIds.blood_rune]: 100,
			[itemsIds.death_rune]: 100,
			[itemsIds.elder_cloth]: 75,
			[itemsIds.aquamarine]: 5,
			[itemsIds.necromancers_remains]: 1,
		},
		scrapping: { [itemsIds.elder_cloth]: 6, [itemsIds.aquamarine]: 5 },
	},
	[itemsIds.necromancers_robe_top]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaos_rune]: 1000,
					[itemsIds.blood_rune]: 1000,
					[itemsIds.death_rune]: 1000,
					[itemsIds.elder_cloth]: 400,
					[itemsIds.aquamarine]: 30,
					[itemsIds.necromancers_remains]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.chaos_rune]: 100,
			[itemsIds.blood_rune]: 100,
			[itemsIds.death_rune]: 100,
			[itemsIds.elder_cloth]: 125,
			[itemsIds.aquamarine]: 5,
			[itemsIds.necromancers_remains]: 1,
		},
		scrapping: { [itemsIds.elder_cloth]: 6, [itemsIds.aquamarine]: 5 },
	},
	[itemsIds.necromancers_robe_bottoms]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaos_rune]: 1000,
					[itemsIds.blood_rune]: 1000,
					[itemsIds.death_rune]: 1000,
					[itemsIds.elder_cloth]: 400,
					[itemsIds.aquamarine]: 30,
					[itemsIds.necromancers_remains]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.chaos_rune]: 100,
			[itemsIds.blood_rune]: 100,
			[itemsIds.death_rune]: 100,
			[itemsIds.elder_cloth]: 100,
			[itemsIds.aquamarine]: 5,
			[itemsIds.necromancers_remains]: 1,
		},
		scrapping: { [itemsIds.elder_cloth]: 6, [itemsIds.aquamarine]: 5 },
	},
	[itemsIds.necromancers_wand]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaos_rune]: 1000,
					[itemsIds.blood_rune]: 1000,
					[itemsIds.death_rune]: 1000,
					[itemsIds.elder_log]: 50,
					[itemsIds.aquamarine]: 15,
					[itemsIds.mystical_driftwood]: 200,
					[itemsIds.necromancers_remains]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.chaos_rune]: 100,
			[itemsIds.blood_rune]: 100,
			[itemsIds.death_rune]: 100,
			[itemsIds.elder_log]: 15,
			[itemsIds.aquamarine]: 5,
			[itemsIds.mystical_driftwood]: 15,
			[itemsIds.necromancers_remains]: 1,
		},
		scrapping: { [itemsIds.mystical_driftwood]: 50, [itemsIds.aquamarine]: 5 },
	},
	[itemsIds.book_of_death]: {
		crafting: [
			{
				recipe: {
					[itemsIds.chaos_rune]: 1000,
					[itemsIds.blood_rune]: 1000,
					[itemsIds.death_rune]: 1000,
					[itemsIds.elder_cloth]: 200,
					[itemsIds.aquamarine]: 15,
					[itemsIds.mystical_driftwood]: 50,
					[itemsIds.necromancers_remains]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.chaos_rune]: 100,
			[itemsIds.blood_rune]: 100,
			[itemsIds.death_rune]: 100,
			[itemsIds.elder_cloth]: 150,
			[itemsIds.aquamarine]: 5,
			[itemsIds.mystical_driftwood]: 10,
			[itemsIds.necromancers_remains]: 1,
		},
		scrapping: { [itemsIds.elder_cloth]: 6, [itemsIds.aquamarine]: 5 },
	},
	[itemsIds.basic_runic_dust]: {
		crafting: [
			{ recipe: { [itemsIds.bronze_bar]: 8 } },
			{ recipe: { [itemsIds.rough_cloth]: 16 } },
			{ recipe: { [itemsIds.hide]: 4, [itemsIds.log]: 16 } },
			{ recipe: { [itemsIds.unusual_runic_dust]: 2 }, multiplier: 1 },
		],
	},
	[itemsIds.unusual_runic_dust]: {
		crafting: [
			{ recipe: { [itemsIds.bronze_bar]: 8, [itemsIds.iron_bar]: 8 } },
			{ recipe: { [itemsIds.rough_cloth]: 16, [itemsIds.restored_cloth]: 16 } },
			{ recipe: { [itemsIds.fur]: 4, [itemsIds.oak_log]: 16 } },
			{ recipe: { [itemsIds.basic_runic_dust]: 10 }, multiplier: 1 },
			{ recipe: { [itemsIds.singular_runic_dust]: 2 }, multiplier: 1 },
		],
	},
	[itemsIds.singular_runic_dust]: {
		crafting: [
			{ recipe: { [itemsIds.iron_bar]: 8, [itemsIds.mithril_bar]: 8 } },
			{ recipe: { [itemsIds.lesser_cloth]: 16, [itemsIds.imbued_cloth]: 16 } },
			{ recipe: { [itemsIds.thick_fur]: 4, [itemsIds.rough_hide]: 4, [itemsIds.maple_log]: 16 } },
			{ recipe: { [itemsIds.unusual_runic_dust]: 10 }, multiplier: 1 },
			{ recipe: { [itemsIds.prime_runic_dust]: 2 }, multiplier: 1 },
		],
	},
	[itemsIds.prime_runic_dust]: {
		crafting: [
			{ recipe: { [itemsIds.mithril_bar]: 8, [itemsIds.adamantite_bar]: 8 } },
			{ recipe: { [itemsIds.runite_bar]: 4, [itemsIds.adamantite_bar]: 8 } },
			{ recipe: { [itemsIds.major_cloth]: 16, [itemsIds.greater_cloth]: 16 } },
			{ recipe: { [itemsIds.chitin]: 4, [itemsIds.yew_log]: 16 } },
			{ recipe: { [itemsIds.singular_runic_dust]: 10 }, multiplier: 1 },
			{ recipe: { [itemsIds.mythical_runic_dust]: 2 }, multiplier: 1 },
		],
	},
	[itemsIds.mythical_runic_dust]: {
		crafting: [
			{ recipe: { [itemsIds.runite_bar]: 8, [itemsIds.stygian_bar]: 8 } },
			{ recipe: { [itemsIds.greater_cloth]: 16, [itemsIds.elder_cloth]: 16 } },
			{ recipe: { [itemsIds.scale]: 4, [itemsIds.elder_log]: 16 } },
			{ recipe: { [itemsIds.prime_runic_dust]: 10 }, multiplier: 1 },
		],
	},
	[itemsIds.elven_royal_leggings]: {
		crafting: [
			{
				recipe: {
					[itemsIds.crystal_scale]: 100,
					[itemsIds.scale]: 300,
					[itemsIds.leather]: 500,
					[itemsIds.royal_elven_remnants]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.crystal_scale]: 3,
			[itemsIds.scale]: 30,
			[itemsIds.leather]: 50,
			[itemsIds.royal_elven_remnants]: 1,
		},
		scrapping: {
			[itemsIds.scale]: 3,
			[itemsIds.leather]: 5,
		},
	},
	[itemsIds.elven_royal_tunic]: {
		crafting: [
			{
				recipe: {
					[itemsIds.crystal_scale]: 100,
					[itemsIds.scale]: 500,
					[itemsIds.leather]: 750,
					[itemsIds.royal_elven_remnants]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.crystal_scale]: 3,
			[itemsIds.scale]: 50,
			[itemsIds.leather]: 75,
			[itemsIds.royal_elven_remnants]: 1,
		},
		scrapping: {
			[itemsIds.scale]: 3,
			[itemsIds.leather]: 5,
		},
	},
	[itemsIds.elven_royal_hat]: {
		crafting: [
			{
				recipe: {
					[itemsIds.crystal_scale]: 100,
					[itemsIds.scale]: 200,
					[itemsIds.leather]: 300,
					[itemsIds.royal_elven_remnants]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.crystal_scale]: 3,
			[itemsIds.scale]: 20,
			[itemsIds.leather]: 30,
			[itemsIds.royal_elven_remnants]: 1,
		},
		scrapping: {
			[itemsIds.scale]: 3,
			[itemsIds.leather]: 5,
		},
	},
	[itemsIds.infernal_chainmail]: {
		crafting: [
			{
				recipe: {
					[itemsIds.iron_bar]: 500,
					[itemsIds.mithril_bar]: 500,
					[itemsIds.adamantite_bar]: 500,
					[itemsIds.stygian_bar]: 300,
					[itemsIds.obsidian_glass]: 300,
					[itemsIds.gargoyle_shards]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.iron_bar]: 50,
			[itemsIds.mithril_bar]: 50,
			[itemsIds.adamantite_bar]: 50,
			[itemsIds.stygian_bar]: 30,
			[itemsIds.obsidian_glass]: 30,
			[itemsIds.gargoyle_shards]: 1,
		},
		scrapping: {
			[itemsIds.obsidian_glass]: 1,
			[itemsIds.stygian_bar]: 3,
			[itemsIds.adamantite_bar]: 5,
		},
	},
	[itemsIds.infernal_legs]: {
		crafting: [
			{
				recipe: {
					[itemsIds.iron_bar]: 400,
					[itemsIds.mithril_bar]: 400,
					[itemsIds.adamantite_bar]: 400,
					[itemsIds.stygian_bar]: 250,
					[itemsIds.obsidian_glass]: 250,
					[itemsIds.gargoyle_shards]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.iron_bar]: 40,
			[itemsIds.mithril_bar]: 40,
			[itemsIds.adamantite_bar]: 40,
			[itemsIds.stygian_bar]: 25,
			[itemsIds.obsidian_glass]: 25,
			[itemsIds.gargoyle_shards]: 1,
		},
		scrapping: {
			[itemsIds.obsidian_glass]: 1,
			[itemsIds.stygian_bar]: 3,
			[itemsIds.adamantite_bar]: 5,
		},
	},
	[itemsIds.infernal_helm]: {
		crafting: [
			{
				recipe: {
					[itemsIds.iron_bar]: 300,
					[itemsIds.mithril_bar]: 300,
					[itemsIds.adamantite_bar]: 300,
					[itemsIds.stygian_bar]: 200,
					[itemsIds.obsidian_glass]: 200,
					[itemsIds.gargoyle_shards]: 4,
				},
			},
		],
		augmenting: {
			[itemsIds.iron_bar]: 30,
			[itemsIds.mithril_bar]: 30,
			[itemsIds.adamantite_bar]: 30,
			[itemsIds.stygian_bar]: 20,
			[itemsIds.obsidian_glass]: 20,
			[itemsIds.gargoyle_shards]: 1,
		},
		scrapping: {
			[itemsIds.obsidian_glass]: 1,
			[itemsIds.stygian_bar]: 3,
			[itemsIds.adamantite_bar]: 5,
		},
	},
	[itemsIds.reinforced_cloak]: {
		crafting: [
			{
				recipe: {
					[itemsIds.leather]: 100,
					[itemsIds.adamantite_bar]: 40,
					[itemsIds.imbued_cloth]: 100,
				},
			},
		],
		augmenting: {
			[itemsIds.leather]: 10,
			[itemsIds.adamantite_bar]: 4,
			[itemsIds.imbued_cloth]: 10,
		},
		scrapping: {
			[itemsIds.leather]: 1,
			[itemsIds.adamantite_bar]: 1,
			[itemsIds.imbued_cloth]: 1,
		},
	},
	[itemsIds.scroll_of_fibrous_fabrication]: {
		scrollcrafting: {
			[itemsIds.silver]: 800,
			[itemsIds.air_rune]: 80,
			[itemsIds.mind_rune]: 80,
			[itemsIds.cosmic_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_crucible]: {
		scrollcrafting: {
			[itemsIds.silver]: 700,
			[itemsIds.earth_rune]: 70,
			[itemsIds.fire_rune]: 70,
			[itemsIds.mind_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_forge_enhancement]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.water_rune]: 90,
			[itemsIds.fire_rune]: 90,
			[itemsIds.cosmic_rune]: 90,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_forge_maintenance]: {
		scrollcrafting: {
			[itemsIds.silver]: 900,
			[itemsIds.nature_rune]: 90,
			[itemsIds.fire_rune]: 90,
			[itemsIds.cosmic_rune]: 90,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.smiths_golem_torso]: {
		crafting: [{ recipe: { [itemsIds.lava_heart]: 1, [itemsIds.smithing_shard]: 450 } }],
		scrapping: {
			[itemsIds.smithing_shard]: 1,
			[itemsIds.bronze_bar]: 7,
			[itemsIds.iron_bar]: 6,
			[itemsIds.gold_bar]: 5,
			[itemsIds.mithril_bar]: 4,
			[itemsIds.adamantite_bar]: 3,
			[itemsIds.runite_bar]: 2,
			[itemsIds.stygian_bar]: 1,
			[itemsIds.chaotic_crystal]: 1,
		},
	},
	[itemsIds.smiths_golem_legs]: {
		crafting: [{ recipe: { [itemsIds.lava_heart]: 1, [itemsIds.smithing_shard]: 450 } }],
		scrapping: {
			[itemsIds.smithing_shard]: 1,
			[itemsIds.bronze_bar]: 7,
			[itemsIds.iron_bar]: 6,
			[itemsIds.gold_bar]: 5,
			[itemsIds.mithril_bar]: 4,
			[itemsIds.adamantite_bar]: 3,
			[itemsIds.runite_bar]: 2,
			[itemsIds.stygian_bar]: 1,
			[itemsIds.chaotic_crystal]: 1,
		},
	},
	[itemsIds.chefs_coat]: {
		scrapping: {
			[itemsIds.cooking_shard]: 1,
			[itemsIds.reactive_powder]: 5,
			[itemsIds.fire_rune]: 5,
		},
		crafting: [{ recipe: { [itemsIds.bleached_cloth]: 1, [itemsIds.cooking_shard]: 450 } }],
		augmenting: { [itemsIds.cooking_shard]: 30 },
	},
	[itemsIds.chefs_pants]: {
		scrapping: {
			[itemsIds.cooking_shard]: 1,
			[itemsIds.reactive_powder]: 5,
			[itemsIds.fire_rune]: 5,
		},
		crafting: [{ recipe: { [itemsIds.bleached_cloth]: 1, [itemsIds.cooking_shard]: 450 } }],
		augmenting: { [itemsIds.cooking_shard]: 30 },
	},
	[itemsIds.runecrafters_robe_top]: {
		crafting: [{ recipe: { [itemsIds.academy_wards]: 1, [itemsIds.runecrafting_shard]: 450 } }],
		scrapping: {
			[itemsIds.runecrafting_shard]: 1,
			[itemsIds.air_rune]: 1,
			[itemsIds.earth_rune]: 1,
			[itemsIds.fire_rune]: 1,
			[itemsIds.water_rune]: 1,
			[itemsIds.blood_rune]: 1,
			[itemsIds.death_rune]: 1,
			[itemsIds.chaos_rune]: 1,
			[itemsIds.nature_rune]: 1,
			[itemsIds.mind_rune]: 1,
			[itemsIds.cosmic_rune]: 1,
		},
	},
	[itemsIds.runecrafters_robe_bottoms]: {
		crafting: [{ recipe: { [itemsIds.academy_wards]: 1, [itemsIds.runecrafting_shard]: 450 } }],
		scrapping: {
			[itemsIds.runecrafting_shard]: 1,
			[itemsIds.air_rune]: 1,
			[itemsIds.earth_rune]: 1,
			[itemsIds.fire_rune]: 1,
			[itemsIds.water_rune]: 1,
			[itemsIds.blood_rune]: 1,
			[itemsIds.death_rune]: 1,
			[itemsIds.chaos_rune]: 1,
			[itemsIds.nature_rune]: 1,
			[itemsIds.mind_rune]: 1,
			[itemsIds.cosmic_rune]: 1,
		},
	},
	[itemsIds.runecrafters_robe_hood]: {
		crafting: [{ recipe: { [itemsIds.academy_wards]: 1, [itemsIds.runecrafting_shard]: 450 } }],
		scrapping: {
			[itemsIds.runecrafting_shard]: 1,
			[itemsIds.air_rune]: 1,
			[itemsIds.earth_rune]: 1,
			[itemsIds.fire_rune]: 1,
			[itemsIds.water_rune]: 1,
			[itemsIds.blood_rune]: 1,
			[itemsIds.death_rune]: 1,
			[itemsIds.chaos_rune]: 1,
			[itemsIds.nature_rune]: 1,
			[itemsIds.mind_rune]: 1,
			[itemsIds.cosmic_rune]: 1,
		},
	},
	[itemsIds.crown_of_spikes]: {
		augmenting: {
			[itemsIds.gold_bar]: 100,
			[itemsIds.iron_bar]: 30,
			[itemsIds.mithril_bar]: 30,
			[itemsIds.golem_marrow]: 1,
		},
		scrapping: {
			[itemsIds.gold_bar]: 10,
			[itemsIds.iron_bar]: 3,
			[itemsIds.golem_marrow]: 1,
		},
	},
	[itemsIds.dark_ring]: {
		augmenting: {
			[itemsIds.gold_bar]: 100,
			[itemsIds.iron_bar]: 30,
			[itemsIds.mithril_bar]: 30,
			[itemsIds.obsidian_glass]: 1,
		},
		scrapping: {
			[itemsIds.gold_bar]: 10,
			[itemsIds.iron_bar]: 3,
			[itemsIds.mithril_bar]: 3,
		},
	},
	[itemsIds.pendant_of_virulence]: {
		scrapping: {
			[itemsIds.iron_bar]: 3,
			[itemsIds.gold_bar]: 10,
			[itemsIds.mithril_bar]: 3,
			[itemsIds.ichor]: 3,
		},
		augmenting: {
			[itemsIds.gold_bar]: 100,
			[itemsIds.iron_bar]: 30,
			[itemsIds.mithril_bar]: 30,
			[itemsIds.ichor]: 30,
		},
	},
	[itemsIds.scroll_of_duster]: {
		scrollcrafting: {
			[itemsIds.silver]: 1000,
			[itemsIds.nature_rune]: 80,
			[itemsIds.mind_rune]: 80,
			[itemsIds.cosmic_rune]: 80,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_scrapper]: {
		scrollcrafting: {
			[itemsIds.silver]: 600,
			[itemsIds.nature_rune]: 60,
			[itemsIds.mind_rune]: 60,
			[itemsIds.cosmic_rune]: 60,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.scroll_of_immunity]: {
		scrollcrafting: {
			[itemsIds.silver]: 700,
			[itemsIds.earth_rune]: 70,
			[itemsIds.death_rune]: 70,
			[itemsIds.cosmic_rune]: 70,
			[itemsIds.scroll]: 1,
		},
	},
	[itemsIds.combat_manual_volume_1]: {
		scrapping: {
			[itemsIds.earth_rune]: 100,
		},
	},
	[itemsIds.combat_manual_volume_2]: {
		scrapping: {
			[itemsIds.earth_rune]: 100,
		},
	},
	[itemsIds.combat_manual_volume_3]: {
		scrapping: {
			[itemsIds.earth_rune]: 100,
		},
	},
	[itemsIds.spell_tome_fire_ball]: {
		scrapping: {
			[itemsIds.fire_rune]: 100,
		},
	},
	[itemsIds.spell_tome_flamethrower]: {
		scrapping: {
			[itemsIds.fire_rune]: 300,
		},
	},
	// ballistics 101
	[itemsIds.ballistics_101]: {
		scrapping: {
			[itemsIds.air_rune]: 100,
		},
	},
	// projectile based diplomacy i
	[itemsIds.projectile_based_diplomacy_i]: {
		scrapping: {
			[itemsIds.air_rune]: 100,
		},
	},
	// projectile based diplomacy ii
	[itemsIds.projectile_based_diplomacy_ii]: {
		scrapping: {
			[itemsIds.air_rune]: 200,
		},
	},
	// projectile based diplomacy iii
	[itemsIds.projectile_based_diplomacy_iii]: {
		scrapping: {
			[itemsIds.air_rune]: 300,
		},
	},
	// apocalyptic tome
	[itemsIds.apocalyptic_tome]: {
		scrapping: {
			[itemsIds.fire_rune]: 500,
		},
	},
	// intermediate combat manual volume i
	[itemsIds.intermediate_combat_manual_volume_1]: {
		scrapping: {
			[itemsIds.air_rune]: 200,
		},
	},
	// city decree
	[itemsIds.city_decree]: {
		scrapping: {
			[itemsIds.air_rune]: 100,
		},
	},
	// how to first aid
	[itemsIds.how_to_first_aid]: {
		scrapping: {
			[itemsIds.nature_rune]: 100,
		},
	},
	// defending the weak an excerpt
	[itemsIds.defending_the_weak_an_excerpt]: {
		scrapping: {
			[itemsIds.nature_rune]: 200,
		},
	},
	// combat manual volume 4
	[itemsIds.combat_manual_volume_4]: {
		scrapping: {
			[itemsIds.air_rune]: 100,
		},
	},
	// attention getting tactics
	[itemsIds.attention_getting_tactics]: {
		scrapping: {
			[itemsIds.chaos_rune]: 200,
		},
	},
	// smoke and mirrors
	[itemsIds.smoke_and_mirrors]: {
		scrapping: {
			[itemsIds.chaos_rune]: 300,
		},
	},
	// all ghillied up
	[itemsIds.all_ghillied_up]: {
		scrapping: {
			[itemsIds.nature_rune]: 300,
		},
	},
	// ice ice ice
	[itemsIds.ice_ice_ice]: {
		scrapping: {
			[itemsIds.water_rune]: 400,
		},
	},
	// trappers guide
	[itemsIds.trappers_guide]: {
		scrapping: {
			[itemsIds.nature_rune]: 200,
		},
	},
	// spell tome ice shard
	[itemsIds.spell_tome_ice_shard]: {
		scrapping: {
			[itemsIds.water_rune]: 100,
		},
	},
	// notes on acid i
	[itemsIds.notes_on_acid_i]: {
		scrapping: {
			[itemsIds.death_rune]: 300,
		},
	},
	// notes on acid ii
	[itemsIds.notes_on_acid_ii]: {
		scrapping: {
			[itemsIds.death_rune]: 300,
		},
	},
	// armor and its weaknesses
	[itemsIds.armor_and_its_weaknesses]: {
		scrapping: {
			[itemsIds.death_rune]: 300,
		},
	},
	// cultist notes i
	[itemsIds.cultist_notes_i]: {
		scrapping: {
			[itemsIds.blood_rune]: 300,
		},
	},
	// cultist notes ii
	[itemsIds.cultist_notes_ii]: {
		scrapping: {
			[itemsIds.blood_rune]: 400,
		},
	},
	[itemsIds.mantis_shrimp_carapace]: {
		crafting: [
			{
				recipe: {
					[itemsIds.voidblessed_shrimp]: 1,
					[itemsIds.shrimp_eye]: 1,
					[itemsIds.chaotic_crystal]: 300,
					[itemsIds.shrimp_scraps]: 300,
				},
			},
		],
		augmenting: { [itemsIds.chaotic_crystal]: 33, [itemsIds.shrimp_scraps]: 30, [itemsIds.shrimp_eye]: 1 },
		scrapping: { [itemsIds.chaotic_crystal]: 3, [itemsIds.shrimp_scraps]: 3 },
	},
	[itemsIds.mantis_shrimp_gauntlets]: {
		crafting: [
			{
				recipe: {
					[itemsIds.voidblessed_shrimp]: 1,
					[itemsIds.shrimp_eye]: 1,
					[itemsIds.chaotic_crystal]: 300,
					[itemsIds.shrimp_scraps]: 120,
				},
			},
		],
		augmenting: { [itemsIds.chaotic_crystal]: 33, [itemsIds.shrimp_scraps]: 30, [itemsIds.shrimp_eye]: 1 },
		scrapping: { [itemsIds.chaotic_crystal]: 3, [itemsIds.shrimp_scraps]: 1 },
	},
	[itemsIds.mantis_shrimp_greaves]: {
		crafting: [
			{
				recipe: {
					[itemsIds.voidblessed_shrimp]: 1,
					[itemsIds.shrimp_eye]: 1,
					[itemsIds.chaotic_crystal]: 300,
					[itemsIds.shrimp_scraps]: 240,
				},
			},
		],
		augmenting: { [itemsIds.chaotic_crystal]: 33, [itemsIds.shrimp_scraps]: 30, [itemsIds.shrimp_eye]: 1 },
		scrapping: { [itemsIds.chaotic_crystal]: 3, [itemsIds.shrimp_scraps]: 2 },
	},
	[itemsIds.mantis_shrimp_helm]: {
		crafting: [
			{
				recipe: {
					[itemsIds.voidblessed_shrimp]: 1,
					[itemsIds.shrimp_eye]: 1,
					[itemsIds.chaotic_crystal]: 300,
					[itemsIds.shrimp_scraps]: 180,
				},
			},
		],
		augmenting: { [itemsIds.chaotic_crystal]: 33, [itemsIds.shrimp_scraps]: 30, [itemsIds.shrimp_eye]: 1 },
		scrapping: { [itemsIds.chaotic_crystal]: 3, [itemsIds.shrimp_scraps]: 1 },
	},
	[itemsIds.mantis_shrimp_shell]: {
		crafting: [
			{
				recipe: {
					[itemsIds.voidblessed_shrimp]: 1,
					[itemsIds.shrimp_eye]: 1,
					[itemsIds.chaotic_crystal]: 300,
					[itemsIds.shrimp_scraps]: 160,
				},
			},
		],
		augmenting: { [itemsIds.chaotic_crystal]: 33, [itemsIds.shrimp_scraps]: 30, [itemsIds.shrimp_eye]: 1 },
		scrapping: { [itemsIds.chaotic_crystal]: 3, [itemsIds.shrimp_scraps]: 2 },
	},
	[itemsIds.awakened_shrimp_eye]: {
		crafting: [
			{
				recipe: {
					[itemsIds.shrimp_eye]: 1,
					[itemsIds.ancient_crystal]: 10,
					[itemsIds.aquamarine]: 100,
					[itemsIds.elder_log]: 100,
					[itemsIds.chaotic_crystal]: 200,
					[itemsIds.shrimp_scraps]: 200,
				},
			},
		],
		augmenting: { [itemsIds.chaotic_crystal]: 15, [itemsIds.shrimp_scraps]: 15 },
	},
	[itemsIds.ancient_chaos_amulet]: {
		augmenting: {
			[itemsIds.chaos_essence]: 5000,
			[itemsIds.chaos_talisman]: 1,
			[itemsIds.chaos_rune]: 50,
			[itemsIds.chaotic_crystal]: 1,
		},
		scrapping: {
			[itemsIds.chaos_rune]: 100,
			[itemsIds.chaotic_crystal]: 1,
		},
	},
};

import { Flex, Grid, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import Checkbox from '@material-ui/core/Checkbox';
import { IdlescapeButton, IdlescapeContainer, IdlescapeInput, IdlescapePill, IdlescapeTooltip } from '@idlescape/ui';
import React from 'react';
import { socket } from '../../../../services/socket.service';
import { getTimeString } from '../../../../utils/helperFunctions';
import { FormControlLabel } from '@material-ui/core';
import { EnchantmentData } from '../../EnchantmentData';
import { MINIMUM_BUFF_MINUTES, PLATINUM_PER_MINUTE, VALID_PLATINUM_BUFFS } from '../../../../utils/constantsCollection';
import { clone } from 'lodash';

const buffIds = clone(VALID_PLATINUM_BUFFS);

export default function PlatinumShopGlobalBuffs() {
	const [selectedBuffId, setSelectedBuffId] = React.useState(buffIds[0]);
	const [selectedTime, setSelectedTime] = React.useState(60);
	const [isAnonymous, setIsAnonymous] = React.useState(false);

	function renderBuff(buffId: number) {
		const enchantmentData = EnchantmentData.findEnchantmentByID(buffId);
		if (!enchantmentData) return null;

		return (
			<IdlescapePill
				key={buffId}
				height={'30px'}
				padding={'2px 0'}
				onClick={() => setSelectedBuffId(enchantmentData.id)}
				image={enchantmentData.buffIcon}
				fontSize={'12px'}
				lineHeight={'24px'}
				alignSelf={'center'}
				cursor={'pointer'}
				variant={enchantmentData.id === selectedBuffId ? 'light' : 'primary'}
				filter={'drop-shadow(2px 2px 3px rgba(0, 225, 255, 0.7))'}
			>
				{enchantmentData.name}
				<IdlescapeTooltip>
					<Text
						textAlign={'center'}
						fontSize={'1.5rem'}
						fontWeight={'bold'}
						color={'rgb(201, 30, 207)'}
						margin={0}
					>
						{enchantmentData.name + ' ' + 2}
					</Text>
					<Text textAlign={'center'}>{enchantmentData.getTooltip(2, enchantmentData.strengthPerLevel)}</Text>
				</IdlescapeTooltip>
			</IdlescapePill>
		);
	}

	function buyBuff() {
		socket.emit('platinum-shop:global-buff', {
			enchantmentId: selectedBuffId,
			platinumSpend: selectedTime * PLATINUM_PER_MINUTE,
			isAnonymous,
		});
	}

	const selectedBuffData = EnchantmentData.findEnchantmentByID(selectedBuffId);
	return (
		<IdlescapeContainer variant='platinum'>
			<VStack>
				<Heading
					fontSize={'48px'}
					fontWeight={'unset'}
					textShadow={
						'0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgb(54 188 250 / 80%), 0 0 40px rgb(54 188 250 / 80%), 0 0 50px rgb(54 188 250 / 80%), 0 0 60px rgb(54 188 250 / 80%), 0 0 70px #e60073'
					}
					textAlign={'center'}
					marginTop={'0'}
				>
					Select a Buff
				</Heading>
				<Grid
					mt={'10px'}
					width={'100%'}
					gridTemplateColumns={'repeat(auto-fit, minmax(140px, 1fr))'}
					gap={'10px'}
				>
					{buffIds.map((buffId) => {
						return renderBuff(buffId);
					})}
				</Grid>
				<Heading
					fontSize={'48px'}
					fontWeight={'unset'}
					textShadow={
						'0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgb(54 188 250 / 80%), 0 0 40px rgb(54 188 250 / 80%), 0 0 50px rgb(54 188 250 / 80%), 0 0 60px rgb(54 188 250 / 80%), 0 0 70px #e60073'
					}
					textAlign={'center'}
					marginTop={'0'}
				>
					Duration
				</Heading>

				<HStack mt={'10px'}>
					<IdlescapeInput type='number' setValue={setSelectedTime} value={selectedTime} min={0} />
					<Text>minutes</Text>
				</HStack>
				<Heading
					fontSize={'48px'}
					fontWeight={'unset'}
					textShadow={
						'0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgb(54 188 250 / 80%), 0 0 40px rgb(54 188 250 / 80%), 0 0 50px rgb(54 188 250 / 80%), 0 0 60px rgb(54 188 250 / 80%), 0 0 70px #e60073'
					}
					textAlign={'center'}
					marginTop={'0'}
				>
					Summary
				</Heading>
				<Flex gap={'5px'} width={'100%'} justifyContent={'center'} flexWrap={'wrap'}>
					<VStack>
						<Text mb={'0'}>Cost</Text>
						<IdlescapePill
							mt={'0 !important'}
							width={'100%'}
							variant='light'
							image='/images/premium/platinum_icon.png'
						>
							{(selectedTime * PLATINUM_PER_MINUTE).toLocaleString('en-us')}
						</IdlescapePill>
					</VStack>
					<VStack>
						<Text mb={'0'}>Buff</Text>
						<IdlescapePill
							mt={'0 !important'}
							pr={'10px'}
							width={'100%'}
							variant='light'
							image={selectedBuffData.buffIcon}
							color={'rgb(201, 30, 207)'}
							fontWeight={'bold'}
						>
							{selectedBuffData.name} 2
							<IdlescapeTooltip>
								<Text
									textAlign={'center'}
									fontSize={'1.5rem'}
									fontWeight={'bold'}
									color={'rgb(201, 30, 207)'}
								>
									{selectedBuffData.name + ' ' + 2}
								</Text>
								{selectedBuffData.getTooltip(2, selectedBuffData.strengthPerLevel)}
							</IdlescapeTooltip>
						</IdlescapePill>
					</VStack>
					<VStack>
						<Text mb={'0'}>Buff Time</Text>
						<IdlescapePill
							mt={'0 !important'}
							pr={'10px'}
							width={'100%'}
							variant='light'
							image='/images/pontification/skill_waiting_icon.png'
						>
							{getTimeString(selectedTime * 60)}
						</IdlescapePill>
					</VStack>
				</Flex>
				<FormControlLabel
					style={{ marginTop: '10px' }}
					control={
						<Checkbox
							checked={isAnonymous}
							onChange={() => setIsAnonymous(!isAnonymous)}
							name='anonymous'
							color='primary'
							style={{ padding: '3px' }}
						/>
					}
					label='Anonymous Purchase'
				/>
				<IdlescapeButton
					onClick={buyBuff}
					variant={selectedTime < MINIMUM_BUFF_MINUTES ? 'disabled' : 'blue'}
					size='large'
					textAlign='center'
				>
					{selectedTime < MINIMUM_BUFF_MINUTES
						? `Minimum ${MINIMUM_BUFF_MINUTES} minutes`
						: 'Purchase Global Buff'}
				</IdlescapeButton>
			</VStack>
		</IdlescapeContainer>
	);
}

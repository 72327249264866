// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { IFarmingLoot } from '../../../game-server/src/modules/skills/farming/Farming.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const farmingLootTable: { [key in number]: IFarmingLoot[] } = {
	[itemsIds.carrot_seed]: [
		{ id: itemsIds.carrot, chance: 1, min: 1, max: 5 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.peppercorn_seed]: [
		{ id: itemsIds.pepper, chance: 1, min: 5, max: 10 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.sugarcane_seed]: [
		{ id: itemsIds.sugar, chance: 1, min: 5, max: 10 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.wheat_seed]: [
		{ id: itemsIds.wheat, chance: 1, min: 1, max: 5 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.potato_seed]: [
		{ id: itemsIds.potato, chance: 1, min: 1, max: 5 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.rice_seed]: [
		{ id: itemsIds.rice, chance: 1, min: 1, max: 5 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.tomato_seed]: [
		{ id: itemsIds.tomato, chance: 1, min: 1, max: 5 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.wildberry_bush_seed]: [
		{ id: itemsIds.wildberry, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.chili_pepper_seed]: [
		{ id: itemsIds.chili_pepper, chance: 1, min: 1, max: 5 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.pumpkin_seed]: [
		{ id: itemsIds.pumpkin, chance: 1, min: 1, max: 4 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.mushroom_spore]: [
		{ id: itemsIds.mushroom, chance: 1, min: 1, max: 2 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 600, min: 1, max: 16 },
	],
	[itemsIds.tree_seed]: [
		{ id: itemsIds.log, chance: 1, min: 20, max: 50 },
		{ id: itemsIds.branch, chance: 1, min: 2, max: 20 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 300, min: 1, max: 16 },
	],
	[itemsIds.oak_tree_seed]: [
		{ id: itemsIds.oak_log, chance: 1, min: 20, max: 50 },
		{ id: itemsIds.branch, chance: 1, min: 2, max: 20 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 300, min: 1, max: 16 },
	],
	[itemsIds.willow_tree_seed]: [
		{ id: itemsIds.willow_log, chance: 1, min: 20, max: 50 },
		{ id: itemsIds.branch, chance: 1, min: 2, max: 20 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 300, min: 1, max: 16 },
	],
	[itemsIds.maple_tree_seed]: [
		{ id: itemsIds.maple_log, chance: 1, min: 20, max: 50 },
		{ id: itemsIds.branch, chance: 1, min: 2, max: 20 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 300, min: 1, max: 16 },
	],
	[itemsIds.yew_tree_seed]: [
		{ id: itemsIds.yew_log, chance: 1, min: 20, max: 50 },
		{ id: itemsIds.branch, chance: 1, min: 2, max: 20 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 300, min: 1, max: 16 },
	],
	[itemsIds.banana_tree_seed]: [
		{ id: itemsIds.banana, chance: 1, min: 5, max: 10 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 250, min: 1, max: 16 },
	],
	[itemsIds.apple_tree_seed]: [
		{ id: itemsIds.apple, chance: 1, min: 10, max: 20 },
		{ id: itemsIds.branch, chance: 1, min: 2, max: 20 },
		{ id: itemsIds.log, chance: 1, min: 20, max: 40 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 250, min: 1, max: 16 },
	],
	[itemsIds.elder_tree_seed]: [
		{ id: itemsIds.branch, chance: 1 / 6, min: 20, max: 40 },
		{ id: itemsIds.log, chance: 1 / 6, min: 40, max: 80 },
		{ id: itemsIds.oak_log, chance: 1 / 6, min: 40, max: 80 },
		{ id: itemsIds.willow_log, chance: 1 / 6, min: 40, max: 80 },
		{ id: itemsIds.maple_log, chance: 1 / 6, min: 40, max: 80 },
		{ id: itemsIds.yew_log, chance: 1 / 6, min: 40, max: 80 },
		{ id: itemsIds.elder_log, chance: 1, min: 50, max: 100 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 100, min: 1, max: 16 },
	],
	[itemsIds.sageberry_bush_seed]: [
		{ id: itemsIds.sageberry, chance: 1, min: 1, max: 4 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 300, min: 1, max: 16 },
	],
	[itemsIds.mysterious_seed]: [
		{ id: itemsIds.mysterious_seed, chance: 1 / 10, min: 1, max: 16 }, // myst seed
		{ id: itemsIds.mysterious_bait, chance: 2 / 15, min: 3, max: 9 }, // myst bait
		{ id: itemsIds.earthworm_bait, chance: 1 / 6, min: 4, max: 12 }, // earthworm bait

		{ id: itemsIds.geode, chance: 1 / 25, min: 1, max: 1 }, // scroll
		{ id: itemsIds.ichor, chance: 1 / 25, min: 2, max: 10 }, // ichor

		{ id: itemsIds.stone, chance: 2 / 2, min: 2, max: 25 }, // Stone
		{ id: itemsIds.fertilizer, chance: 2 / 5, min: 1, max: 10 }, // fertilizer
		{ id: itemsIds.clay, chance: 2 / 15, min: 2, max: 25 }, // clay
		{ id: itemsIds.sand, chance: 2 / 15, min: 2, max: 25 }, // sand
		{ id: itemsIds.rune_slate, chance: 2 / 15, min: 10, max: 50 }, // rune slate
		{ id: itemsIds.coal, chance: 1 / 5, min: 10, max: 50 }, // coal

		{ id: itemsIds.copper_ore, chance: 1 / 5, min: 10, max: 50 }, // copper ore
		{ id: itemsIds.tin_ore, chance: 1 / 5, min: 10, max: 50 }, // tin ore
		{ id: itemsIds.iron_ore, chance: 2 / 15, min: 10, max: 50 }, // iron ore
		{ id: itemsIds.gold_ore, chance: 1 / 10, min: 10, max: 50 }, // gold ore
		{ id: itemsIds.silver, chance: 2 / 25, min: 10, max: 50 }, // silver
		{ id: itemsIds.mithril_ore, chance: 1 / 15, min: 10, max: 50 }, // mithril ore
		{ id: itemsIds.adamantite_ore, chance: 2 / 35, min: 10, max: 50 }, // adamantite ore
		{ id: itemsIds.runite_ore, chance: 1 / 20, min: 10, max: 50 }, // runite ore
		{ id: itemsIds.stygian_ore, chance: 1 / 30, min: 1, max: 10 }, // stygian ore
		{ id: itemsIds.void_stone, chance: 1 / 40, min: 1, max: 5 }, // stygian ore

		{ id: itemsIds.bronze_bar, chance: 1 / 10, min: 5, max: 25 }, // bronze bar
		{ id: itemsIds.iron_bar, chance: 1 / 15, min: 5, max: 25 }, // iron bar
		{ id: itemsIds.gold_bar, chance: 1 / 20, min: 5, max: 25 }, // gold bar
		{ id: itemsIds.mithril_bar, chance: 1 / 30, min: 5, max: 25 }, // mithril bar
		{ id: itemsIds.adamantite_bar, chance: 1 / 35, min: 5, max: 25 }, // adamantite bar
		{ id: itemsIds.runite_bar, chance: 1 / 40, min: 5, max: 25 }, // runite bar
		{ id: itemsIds.stygian_bar, chance: 1 / 60, min: 1, max: 10 }, // stygian bar
		{ id: itemsIds.chaotic_crystal, chance: 1 / 80, min: 1, max: 5 }, // chaotic crystal

		{ id: itemsIds.sapphire, chance: 1 / 25, min: 1, max: 3 }, // sapphire
		{ id: itemsIds.emerald, chance: 2 / 75, min: 1, max: 2 }, // emerald
		{ id: itemsIds.ruby, chance: 1 / 50, min: 1, max: 1 }, // ruby
		{ id: itemsIds.diamond, chance: 2 / 125, min: 1, max: 1 }, // diamond
		{ id: itemsIds.black_opal, chance: 1 / 75, min: 1, max: 1 }, // black opal

		{ id: itemsIds.carrot_seed, chance: 2 / 3, min: 1, max: 30 }, // carrot seed
		{ id: itemsIds.potato_seed, chance: 2 / 3, min: 1, max: 30 }, // potato seed
		{ id: itemsIds.wheat_seed, chance: 1 / 2, min: 1, max: 30 }, // wheat seed
		{ id: itemsIds.tomato_seed, chance: 1 / 2, min: 1, max: 30 }, // tomato seed
		{ id: itemsIds.mushroom_spore, chance: 2 / 5, min: 1, max: 30 }, // mushroom spore
		{ id: itemsIds.sugarcane_seed, chance: 2 / 5, min: 1, max: 30 }, // sugarcane seed
		{ id: itemsIds.chili_pepper_seed, chance: 1 / 3, min: 1, max: 30 }, // chili pepper seed
		{ id: itemsIds.rice_seed, chance: 1 / 3, min: 1, max: 30 }, // rice seed
		{ id: itemsIds.pumpkin_seed, chance: 2 / 7, min: 1, max: 30 }, // pumpkin seed
		{ id: itemsIds.peppercorn_seed, chance: 2 / 7, min: 1, max: 30 }, // peppercorn seed
		{ id: itemsIds.wildberry_bush_seed, chance: 2 / 3, min: 1, max: 30 }, // wildberry bush seed
		{ id: itemsIds.sageberry_bush_seed, chance: 1 / 5, min: 1, max: 5 }, // sageberry bush seed

		{ id: itemsIds.tree_seed, chance: 1 / 2, min: 1, max: 5 }, // tree seed
		{ id: itemsIds.oak_tree_seed, chance: 1 / 4, min: 1, max: 5 }, // oak tree seed
		{ id: itemsIds.willow_tree_seed, chance: 1 / 6, min: 1, max: 5 }, // willow tree seed
		{ id: itemsIds.maple_tree_seed, chance: 1 / 8, min: 1, max: 5 }, // maple tree seed
		{ id: itemsIds.yew_tree_seed, chance: 1 / 10, min: 1, max: 5 }, // yew tree seed
		{ id: itemsIds.banana_tree_seed, chance: 1 / 8, min: 1, max: 5 }, // banana tree seed
		{ id: itemsIds.apple_tree_seed, chance: 1 / 8, min: 1, max: 5 }, // apple tree seed
		{ id: itemsIds.elder_tree_seed, chance: 1 / 15, min: 1, max: 3 }, // elder tree seed

		{ id: itemsIds.water_totem, chance: 1 / 50, min: 1, max: 1 }, // water totem
		{ id: itemsIds.nature_totem, chance: 1 / 50, min: 1, max: 1 }, // nature totem
		{ id: itemsIds.earth_totem, chance: 1 / 50, min: 1, max: 1 }, // earth totem
		{ id: itemsIds.coalescing_totem, chance: 1 / 50, min: 1, max: 1 }, // coalescing totem

		{ id: itemsIds.watermelon, chance: 1 / 15, min: 1, max: 3 }, // water melon
		{ id: itemsIds.durian, chance: 1 / 15, min: 1, max: 3 }, // durian
		{ id: itemsIds.onion, chance: 1 / 15, min: 1, max: 3 }, // onion
		{ id: itemsIds.mana_berry, chance: 1 / 15, min: 1, max: 3 }, // mana berry
		{ id: itemsIds.thorny_root, chance: 1 / 15, min: 1, max: 3 }, // thorny root
		{ id: itemsIds.thorny_vine, chance: 1 / 15, min: 1, max: 3 }, // thorny vine
		{ id: itemsIds.tangle_root, chance: 1 / 15, min: 1, max: 3 }, // tangle root
		{ id: itemsIds.mandrake, chance: 1 / 15, min: 1, max: 3 }, // mandrake
		{ id: itemsIds.frost_flowers, chance: 1 / 15, min: 1, max: 3 }, // frost flower
		{ id: itemsIds.black_berry, chance: 1 / 15, min: 1, max: 3 }, // black berry
		{ id: itemsIds.fairy_dust, chance: 1 / 15, min: 1, max: 3 }, // fairy dust
		{ id: itemsIds.lotus_flower, chance: 1 / 15, min: 1, max: 3 }, // lotus flower
		{ id: itemsIds.fourleaf_clover, chance: 1 / 15, min: 1, max: 3 }, // four-leaf clover
		{ id: itemsIds.glow_spore_shroom, chance: 1 / 15, min: 1, max: 3 }, // glow spore shroom
		{ id: itemsIds.pineapple, chance: 1 / 15, min: 1, max: 3 }, // pineapple
		{ id: itemsIds.ghost_shrooms, chance: 1 / 15, min: 1, max: 3 }, // ghost shrooms
		{ id: itemsIds.yellow_pepper, chance: 1 / 15, min: 1, max: 3 }, // yellow pepper

		{ id: itemsIds.geode, chance: 1 / 20, min: 1, max: 1 }, // geode
		{ id: itemsIds.birds_nest, chance: 1 / 10, min: 1, max: 1 }, // bird's nest
		{ id: itemsIds.sunken_treasure, chance: 1 / 20, min: 1, max: 1 }, // sunken treasure

		{ id: itemsIds.branch, chance: 1 / 10, min: 5, max: 25 }, // branch
		{ id: itemsIds.log, chance: 1 / 15, min: 5, max: 25 }, // log
		{ id: itemsIds.oak_log, chance: 1 / 20, min: 5, max: 25 }, // oak log
		{ id: itemsIds.willow_log, chance: 1 / 30, min: 5, max: 25 }, // willow log
		{ id: itemsIds.maple_log, chance: 1 / 35, min: 5, max: 25 }, // maple log
		{ id: itemsIds.yew_log, chance: 1 / 40, min: 5, max: 25 }, // yew log
		{ id: itemsIds.elder_log, chance: 1 / 60, min: 1, max: 10 }, // elder log

		{ id: itemsIds.bone, chance: 1 / 25, min: 1, max: 5 }, // bone
		{ id: itemsIds.bone_plate, chance: 1 / 25, min: 1, max: 5 }, // bone plate
		{ id: itemsIds.chitin, chance: 1 / 25, min: 1, max: 5 }, // chitin
		{ id: itemsIds.fur, chance: 1 / 25, min: 1, max: 5 }, // fur
		{ id: itemsIds.thick_fur, chance: 1 / 25, min: 1, max: 5 }, // thick fur
		{ id: itemsIds.wool, chance: 1 / 25, min: 1, max: 5 }, // wool
		{ id: itemsIds.hide, chance: 1 / 25, min: 1, max: 5 }, // hide
		{ id: itemsIds.rough_hide, chance: 1 / 25, min: 1, max: 5 }, // rough hide
		{ id: itemsIds.scale, chance: 1 / 25, min: 1, max: 5 }, // scale
		{ id: itemsIds.crystal_scale, chance: 1 / 25, min: 1, max: 1 }, // crystal scale

		{ id: itemsIds.cookie_dough, chance: 1 / 300, min: 1, max: 30 }, // cookie dough
	],
	[itemsIds.box_trap]: [
		{ id: itemsIds.bone, chance: 1, min: 2, max: 8 },
		{ id: itemsIds.hide, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.fur, chance: 2 / 3, min: 1, max: 5 },
		{ id: itemsIds.raw_chicken, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.feather, chance: 1 / 2, min: 50, max: 150 },
	],
	[itemsIds.pitfall_trap]: [
		{ id: itemsIds.bone, chance: 1, min: 2, max: 8 },
		{ id: itemsIds.fur, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.rough_hide, chance: 2 / 3, min: 1, max: 5 },
		{ id: itemsIds.raw_beef, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.milk, chance: 1 / 2, min: 10, max: 30 },
	],
	[itemsIds.bird_trap]: [
		{ id: itemsIds.raw_chicken, chance: 1, min: 25, max: 75 },
		{ id: itemsIds.feather, chance: 1 / 2, min: 500, max: 1500 },
		{ id: itemsIds.phoenixs_feather, chance: 1 / 100, min: 1, max: 1 },
		{ id: itemsIds.mysterious_seed, chance: 1 / 2, min: 5, max: 15 },
		{ id: itemsIds.birds_nest, chance: 1 / 10, min: 1, max: 1 },
	],
	[itemsIds.net_trap]: [
		{ id: itemsIds.bone, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.bone_plate, chance: 1/4, min: 2, max: 6 },
		{ id: itemsIds.thick_fur, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.rough_hide, chance: 1, min: 10, max: 30 },
		{ id: itemsIds.chitin, chance: 2 / 3, min: 1, max: 5 },
		{ id: itemsIds.raw_beef, chance: 1, min: 5, max: 35 },
	],
	[itemsIds.bear_trap]: [
		{ id: itemsIds.bone, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.bone_plate, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.chitin, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.scale, chance: 2 / 3, min: 1, max: 5 },
		{ id: itemsIds.raw_beef, chance: 1, min: 40, max: 50 },
	],
	[itemsIds.monster_trap]: [
		{ id: itemsIds.bone_plate, chance: 1, min: 10, max: 30 },
		{ id: itemsIds.scale, chance: 1, min: 5, max: 15 },
		{ id: itemsIds.spider_legs, chance: 1/2, min: 30, max: 50 },
		{ id: itemsIds.goblin_brain, chance: 1/4, min: 30, max: 50 },
		{ id: itemsIds.satchel, chance: 1/20, min: 1, max: 1 },
	],
	[itemsIds.water_totem]: [],
	[itemsIds.nature_totem]: [],
	[itemsIds.earth_totem]: [],
	[itemsIds.coalescing_totem]: [],
	[itemsIds.ancient_oak_seed]: [
		{ id: itemsIds.heart_of_the_oak, chance: 1, min: 1, max: 1 },
		// This is just elder tree loot too
		{ id: itemsIds.branch, chance: 1 / 6, min: 20, max: 400 },
		{ id: itemsIds.log, chance: 1 / 6, min: 40, max: 800 },
		{ id: itemsIds.oak_log, chance: 1 / 6, min: 40, max: 800 },
		{ id: itemsIds.willow_log, chance: 1 / 6, min: 40, max: 800 },
		{ id: itemsIds.maple_log, chance: 1 / 6, min: 40, max: 800 },
		{ id: itemsIds.yew_log, chance: 1 / 6, min: 40, max: 800 },
		{ id: itemsIds.elder_log, chance: 1, min: 50, max: 200 },
	],
};

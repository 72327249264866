import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { IdlescapeFrameBox, IdlescapeInput } from '@idlescape/ui';
import _ from 'lodash';
import React, { useState } from 'react';
import { IAffixes, TAffixesPaths } from '../../../../../../../game-server/src/modules/affixes/affixes.interface';
import { IItem, TItemRarity } from '../../../../../../../game-server/src/modules/items/items.interface';
import { getAffixCost, getApplicableAffixTier } from '../../../../../utils/affixFunctions';
import { affixList } from '../../../../../utils/affixList';
import {
	AFFIX_COMBAT_SLOTS,
	AFFIX_COSTS,
	AFFIX_DUST_PER_RARITY,
	AFFIX_JEWELRY_SLOTS,
	AFFIX_RARITY_OPTIONS,
	AFFIX_TOOL_SLOTS,
} from '../../../../../utils/constantsCollection';
import { itemList } from '../../../../../utils/itemList';
import { stringCapitalize } from '../../../../../utils/StringFormatter';
import { TooltipLiterals } from '../../../Tooltips/Data/TooltipLiterals';
import AffixTooltip from '../AffixTooltip';

// For sorting below
const rarityOrder = {
	common: 1,
	uncommon: 2,
	rare: 3,
	epic: 4,
	legendary: 5,
};

export default function AffixList({
	selectedItem,
	selectedAffixes,
	handleAffixSelect,
}: {
	selectedItem: IItem | undefined;
	selectedAffixes: TAffixesPaths[];
	handleAffixSelect: (affix: TAffixesPaths) => void;
}) {
	const [sortAffix, setSortAffix] = useState('');

	const affixCost = getAffixCost(
		selectedItem ? itemList[selectedItem.itemID] : undefined,
		selectedAffixes.map((affix) => affixList[affix])
	);

	function showAffix(affixData: IAffixes, affixPath: TAffixesPaths) {
		if (!selectedItem) {
			return true;
		}
		if (!affixData) {
			return false;
		}
		if (affixData.inactive) {
			return false;
		}
		const itemData = itemList[selectedItem.itemID];
		if (!itemData || !itemData.equipmentStats) {
			return false;
		}

		const affixTierData = getApplicableAffixTier(affixData, itemData);
		if (!affixTierData) return false;

		if (affixData.slots || affixData.reducedEffectSlots) {
			const allSlots = [...(affixData.slots ?? []), ...(affixData.reducedEffectSlots ?? [])];
			if (!allSlots.includes(itemData.equipmentStats.slot)) return false;
		}
		if (
			(affixData.skillingAffix || affixData.combatAffix) &&
			!AFFIX_JEWELRY_SLOTS.includes(itemData.equipmentStats.slot)
		) {
			if (affixData.skillingAffix && AFFIX_COMBAT_SLOTS.includes(itemData.equipmentStats.slot)) {
				return false;
			}
			if (affixData.combatAffix && AFFIX_TOOL_SLOTS.includes(itemData.equipmentStats.slot)) {
				return false;
			}
		}
		if (sortAffix.length === 0) {
			return true;
		}
		const affixName = affixData.name.toLowerCase();
		if (affixName.includes(sortAffix.toLowerCase())) {
			return true;
		}
		if (TooltipLiterals.affixesLiterals[affixPath].toLowerCase().includes(sortAffix.toLowerCase())) {
			return true;
		}
		if (affixData.statBuffs) {
			for (const stat of affixData.statBuffs) {
				if (stat.path.toLowerCase().includes(sortAffix.toLowerCase())) {
					return true;
				}
			}
		}
		return false;
	}

	// Get the total frequency of all the valid affixes
	const totalFrequency = Object.keys(affixList)
		.filter((affix) => showAffix(affixList[affix as TAffixesPaths], affix as TAffixesPaths))
		.reduce((acc, affix) => {
			const affixData = affixList[affix as TAffixesPaths];
			const rarity = affixData.rarity ?? 'common';
			const frequency = AFFIX_RARITY_OPTIONS[rarity].frequency;
			return acc + frequency;
		}, 0);

	function renderAffixButton(affixData: IAffixes, affixPath: TAffixesPaths) {
		const name = affixData.name.replace('of ', '').replace('the ', '');
		let filter = 'drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.7))';
		if (selectedAffixes.includes(affixPath)) {
			filter = 'drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.7)) drop-shadow(0 0 10px rgba(0, 255, 0, 0.9))';
		}
		const dustID =
			affixCost.dust.id === -1 ? AFFIX_DUST_PER_RARITY[affixData.rarity ?? 'common'] : affixCost.dust.id;
		if (!selectedItem) return <></>;
		const itemData = itemList[selectedItem.itemID];
		return (
			<IdlescapeFrameBox
				onClick={() => handleAffixSelect(affixPath)}
				as={Flex}
				flexDirection={'column'}
				variant='primary'
				paddingBottom={'15px'}
				filter={filter}
				cursor={'pointer'}
				height={'100px'}
				width={'100px'}
				key={affixPath}
			>
				<Text
					background={
						'linear-gradient(to right, rgb(0, 0, 0) 0, rgba(0, 40, 60, 0.8) 80%, rgba(0, 225, 255, 0) 100%);'
					}
					maxWidth={'90%'}
					borderTopLeftRadius={'10px'}
					filter={'drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'}
					margin={'3px 0 0 3px'}
					textAlign={'left'}
					paddingLeft={'2px'}
					fontWeight={'bold'}
					fontSize={'10px'}
					className={`rarity-${affixData.rarity}`}
				>
					{name}
				</Text>
				<Box
					className={`affix_runes ${affixData.imageSheetCSS} ${affixData.imageCSS}`}
					width='53px'
					height='53px'
					alignSelf='center'
				/>
				<Box position={'absolute'} bottom={'7.5px'} width={'100%'}>
					<Text as='span' verticalAlign='middle'>
						{AFFIX_COSTS[affixData.rarity ?? 'common']}
					</Text>
					<Image
						src={itemList[dustID].itemImage}
						alt={'dust cost'}
						verticalAlign='middle'
						height='16px'
						width='16px'
						marginLeft='5px'
					/>
				</Box>
				<AffixTooltip
					affixData={affixData}
					affixPath={affixPath}
					totalFrequency={totalFrequency}
					itemData={itemData}
				/>
			</IdlescapeFrameBox>
		);
	}

	function renderAffixList() {
		const affixesByCategories: Record<string, TAffixesPaths[]> = _.groupBy(
			Object.keys(affixList) as TAffixesPaths[],
			(affixPath: string) => affixPath.split('.')[0]
		);
		// Need to split by rarity
		const affixes: React.ReactElement[] = [];
		for (const [category, affixPaths] of Object.entries(affixesByCategories)) {
			const thisCategory: React.ReactElement[] = [];
			const rarity: Partial<Record<TItemRarity, React.ReactElement[]>> = {};
			for (const affixPath of affixPaths) {
				const affixData = affixList[affixPath];
				if (!showAffix(affixData, affixPath)) {
					continue;
				}
				const rarityKey = affixData.rarity ?? 'common';
				if (!rarity[rarityKey]) {
					rarity[rarityKey] = [];
				}
				const current = rarity[rarityKey];
				current && current.push(renderAffixButton(affixData, affixPath));
			}

			const rarityKeys = (Object.keys(rarity) as (keyof typeof rarityOrder)[]).sort((a, b) => {
				return rarityOrder[a] - rarityOrder[b];
			});
			for (const rarityKey of rarityKeys) {
				const rarityItems = rarity[rarityKey];
				const items = rarityItems as React.ReactElement[];
				if (items.length === 0) continue;
				thisCategory.push(
					<div key={`affix-category-${category}-${rarityKey}-title`} className='affix-rarity-name'>
						{stringCapitalize(rarityKey)} :{' '}
						{(
							((AFFIX_RARITY_OPTIONS[rarityKey]?.frequency ?? 1) / totalFrequency) *
							100 *
							items.length
						).toFixed(2)}
						%
					</div>
				);
				thisCategory.push(
					<div key={`affix-category-${category}-${rarityKey}-items`}>
						<Grid gridTemplateColumns={'repeat(auto-fit, 100px);'}>{items}</Grid>
						<br />
					</div>
				);
			}
			// Dont show empty categories
			if (thisCategory.length === 0) continue;

			affixes.push(
				<div key={`affix-category-${category}`}>
					{affixes.length > 0 && <hr />}
					<h2>{stringCapitalize(category)}</h2>
					{thisCategory}
				</div>
			);
		}
		return affixes;
	}
	return (
		<div className='enchanting-items-container'>
			<h4 className='enchanting-title'>Affixes</h4>
			<div className='enchanting-title-border'></div>
			<IdlescapeInput
				variant='search'
				setValue={setSortAffix}
				value={sortAffix}
				type='search'
				className={'anchor-enchanting-inventory-filter'}
				placeholder='Affix Filter'
			/>
			<Box className='enchanting-items' overflow='hidden'>
				{renderAffixList()}
			</Box>
		</div>
	);
}

// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { TQuestListClient } from '../../../game-server/src/modules/quest/Quest.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import { locationsIds } from '../utils/lookup-dictionaries/lookupLocationList';
import { monstersIds } from '../utils/lookup-dictionaries/lookupMonsterList';
import { questsIds } from '../utils/lookup-dictionaries/lookupQuestList';
import { talentsIds } from '../utils/lookup-dictionaries/lookupTalentList';
/**
 * Try not to change the targetAmounts for steps that are already accessible in live, it can mess things up
 */
export const questList: TQuestListClient = {
	// 1 - 99 are reserved for system / meta quests
	1: {
		id: 1,
		name: 'Getting Started - Verify Email',
		description: "Verify your account's email to increase security and unlock potential perks.",
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Verify Your Email',
				description:
					'You can verify your email in the Security section of your Settings page. This will increase your account security and give you access to the Marketplace if your gamemode allows it.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Verify Email',
						targetID: -1,
					},
				],
				customButton: {
					name: 'Check Status',
					emit: 'quest:custom:checkVerifiedEmail',
				},
				targetAmount: 1,
				link: {
					page: 'settings',
				},
			},
		],
		questIcon: '/images/misc/notepad_icon.png',
	},
	2: {
		id: 2,
		name: 'Who is that Guy?',
		description:
			"Inspect another player's profile by clicking on their name or using the /inspect command. This shows their preferred or current cosmetics, their profile, their stats, and any other customizations they have applied to their profile page.",
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Inspect a Player',
				description:
					"Click on a player's name in chat and select inspect or use the command '/inspect [player name]'.",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Inspect',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'chat',
				},
			},
			{
				stepID: 2,
				name: 'Setting Cosmetics',
				description:
					"You can set your current gear and cosmetics to display on your profile character by using the command '/profile cosmetics'. You can change this whenever you want, you just need to re-use the command!",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Set Cosmetics',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'chat',
				},
			},
			{
				stepID: 3,
				name: 'Setting Icons',
				description:
					"You can display up to 3 icons on your profile page. For this step you need to run the '/profile icons add' command; if you don't have an icon that's fine, just keep this in mind for later!",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Add Icon',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'chat',
				},
			},
			// Item Showcase, when implemented
			// Hiscore Showcase, when implemented
		],
		questIcon: '/images/ui/social_icon.png',
	},
	3: {
		id: 3,
		name: 'Climbing the Ranks',
		description: 'Check any hiscores board.',
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Check Hiscores',
				description: 'Check any hiscore board.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Hiscores',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'social',
				},
			},
		],
		questIcon: '/images/ui/social_icon.png',
	},
	4: {
		id: 4,
		name: 'Socializing',
		description: 'Learn to use the chat social features.',
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Add a Friend',
				description:
					"Add anyone to your friend list. You can do this by clicking on their name in chat and selecting 'Add Friend'. They will now appear in the Social tab under the Friends list and can be clicked on to access the chat features for them. They do not need to accept a friend request to complete this step, and you can remove them afterwards if you really want to :(",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Add Friend',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'chat',
				},
			},
			{
				stepID: 2,
				name: 'Blocking and Unblocking',
				description:
					"Blocking someone will prevent them from sending you any messages and is a good way to avoid harassment and spam. To block someone click on their name in chat and select 'Block'. Once they are blocked you can unblock them by going to your Blocked list in the Social tab and removing them from it there. To complete this step, successfully unblock someone.",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Unblock Someone',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'social',
				},
			},
		],
		questIcon: '/images/ui/social_icon.png',
	},
	5: {
		id: 5,
		name: 'Le Toucan Has Arrived',
		description: 'Learn to use the Shrine.',
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Donate to the Shrine',
				description:
					'Donate any amount of gold to the Shrine. ' +
					'Open the Shrine page and select any of the buff options to donate towards. ' +
					'Buff options, or altars, are categories of buffs that you can specifically donate towards to unlock their effects. ' +
					'Each altar has their own set of effects, but there is also the Time shrine that determines the length of all shrine buffs. ' +
					'There are several personal milestones that you can reach by donating to the shrine to benefit more from a shrine, and the global donations by all players that increases the overall ' +
					'potential buffs available. Basically, you have to donate to get buffs, the more you donate the more everyone benefits!',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Donate',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'shrine',
				},
			},
		],
		questIcon: '/images/misc/shrine_icon.png',
	},
	6: {
		id: 6,
		name: 'Hiring Mercenaries',
		description: 'Learn how to use mercenaries in combat.',
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Equipping Your Merc',
				description:
					'Mercenaries are non-player characters that use your loadouts to join you in group combat. ' +
					'You must supply them with their own set of equipment and food inventories to use them to their full potential. ' +
					'Unlike players, they eat their entire inventory at once, resulting in massive initial health pools, and they do not benefit from your Talents. Besides this, they are exactly like players in combat. ' +
					'It is generally recommended that your mercenaries fill roles that you or your party do not, such as supporting if you are focused on damage, or vice versa. ' +
					'To complete this step, mark one of your loadouts as being for a mercenary.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Mark Loadout',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Entering Combat',
				description:
					'Mercenaries will attempt to fill any unfilled slot that is available in a party when Group Combat begins. ' +
					'They join based on the order that you have marked a loadout for them, with the first loadout being the first mercenary to join. ' +
					'To complete this step, successfully enter a Group Combat encounter with a mercenary in your party, such as the duo combat zones.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Merc Combat',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questIcon: '/images/combat/shared_icon.png',
	},
	7: {
		id: 7,
		name: 'The Action Queue',
		description: 'Learn how to use the Action Queue.',
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Adding to the Queue',
				description:
					'The Action Queue lets you queue up a list of actions to complete as well as how many times to complete them. ' +
					'This is an advanced way to make your character accomplish tasks without you needing to login and change your actions every time. ' +
					'To complete this step, add an action to your queue. If you do not have IS+ or any claimed Queue tokens you may only have one queue slot available. ' +
					'If you have multiple queue slots you may simply repeat the process multiple times to create a more complex queue. ' +
					'Your character will automatically attempt to use assigned loadouts at the locations you have selected, and skip any actions that are not possible. ' +
					'You can also specify if the queue should only run once or repeat indefinitely. ' +
					'You can access the Action Queue through its own page in the side-bar, or enable Action Queue related buttons in your Settings.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Add to Queue',
						targetID: -1,
					},
				],
				link: {
					page: 'action-queue',
				},
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Starting the Queue',
				description:
					'Once you have a valid queue, simply press the Start button to begin it! Start a queue to complete this step.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Starting the Queue',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questIcon: '/images/pontification/skill_waiting_icon.png',
	},

	// 100 - 1999 are reserved for regular quests, order doesn't matter just don't change the IDs after they're set
	// #region 100-199 are reserved for "storyline" quests, meaning they must be completable by all gamemodes
	// currently that means making sure no gathers and ironmen can reasonable complete them
	// All main quests should have at least 1 sidequest, preferably one for both skilling and combat, doesn't have to be same difficulty though!
	// Farm Quest
	100: {
		id: 100,
		name: 'Helping Hand',
		description:
			'The farms make up most of the fringes of human civilization; not only do they act as a sort of buffer zone between The City and the wilderness, ' +
			'they provide a majority of the food and resource necessary for humanity to survive. ' +
			'Many settlements out there have been built, many have been destroyed, and even more will be constructed in the future, if humanity survives that long. ' +
			'Although The City Guard is unable to provide support to the many farms and homesteads, rewards are available for the fledgling adventurer who is willing to lend a hand to the farmers and their families.',
		difficulty: 'Easy',
		questIcon: '/images/combat/monsters/chicken.png',
		questSteps: [
			{
				stepID: 1,
				name: 'Broken Fences and Empty Stomachs',
				description:
					'Raiding parties of goblins, too skittish to confront even mere farmers, have been stealing livestock and pillaging lone farmsteads. ' +
					'Before you go out to face them in glorious combat, the farmers require some supplies to help them recover from the losses they have already suffered. ' +
					'They could use some logs to repair their fences, or foodstocks to feed their remaining animals or their families.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.log,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.wildberry,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.raw_anchovy,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.raw_beef,
					},
				],
				targetAmount: 100,
			},
			{
				stepID: 2,
				name: 'Clearing Land',
				description:
					'It is simply a fact of life that some farmsteads are razed by raiders or abandoned due to lack of support. Given time, these lands are reclaimed by the wilderness and will require some effort to make usable as farms once more. ' +
					'Many of these lost lands devolve into Clay Pits or Grasslands; there is a small bounty in materials to be made here if you wish to help clear them. ' +
					'Alternatively, you could try hunting the goblin scouts or dealing with rats whilst the farmers do their work.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.clay_pit,
					},
					{
						trigger: 'location',
						targetID: locationsIds.grasslands,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.goblin,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.rat,
					},
				], // Added the slay targets for NGIM
				targetAmount: 100,
			},
			{
				stepID: 3,
				name: 'Strength of Stone! ...or Clay, or Sand...',
				description:
					'The weak shelters that most farmsteads construct of logs and sticks will not last the test of many raids. A more permanent solution is required, even if it does not truly solve the problem. ' +
					'Stone, Clay, and Sand are all useful materials for construction, and the farmers will be able to use them to build more defensible structures.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.stone,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.clay,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.sand,
					},
				],
				targetAmount: 100,
			},
			{
				stepID: 4,
				name: 'Pest Control',
				description:
					'Goblins can never truly be exterminated, but wiping out their scouts will at least deincentivize them from raiding this particular settlement. Hopefully the others have someone as helpful as you to assist them. ' +
					'There are rumors that deep within some of the many caves there are passages to darker, more sinister places. Perhaps the goblins are not the only ones scouting the area...',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.goblin,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.greater_imp,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			questPoints: 1,
			experience: {
				foraging: 100,
				fishing: 100,
				mining: 100,
				attack: 100,
				defense: 100,
				constitution: 100,
			},
			items: [
				{ id: itemsIds.milk, amount: 100 },
				{ id: itemsIds.raw_beef, amount: 100 },
				{ id: itemsIds.potato, amount: 100 },
				{ id: itemsIds.carrot, amount: 100 },
				{ id: itemsIds.wheat, amount: 100 },
				{ id: itemsIds.potato_seed, amount: 16 },
				{ id: itemsIds.carrot_seed, amount: 16 },
				{ id: itemsIds.wheat_seed, amount: 16 },
			],
			achievementsToAdd: [questsIds.farm_fiend],
		},
	},
	// Cave Quest, explore caves, verdant valley / city outskirts mine
	101: {
		id: 101,
		name: 'Caves and Caverns',
		description:
			'Although your chosen settlement may be safe, the surrounding wilderness is not. The farmers need your help to clear out the caves and caverns that hide goblins and their kind. ' +
			' Once again, the City Guard are unable to provide meaningful support, but they will reward you for your efforts once more in the form of basic materials and training.',
		difficulty: 'Easy',
		questIcon: '/images/combat/monsters/goblin.png',
		questRequirements: {
			requiredQuests: [questsIds.helping_hand],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Supplies for Exploration',
				description:
					'An experienced adventurer could easily head into the Caves with no preparation, but you may not yet be there. ' +
					'The farmers you have already helped have offered insist on fashioning you a cart or longlasting rations for your journey, but you will still need to provide the materials.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.oak_log,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.iron_ore,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.watermelon,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.honey,
					},
				],
				targetAmount: 200,
			},
			{
				stepID: 2,
				name: 'Tunnels or Hideouts',
				description:
					'The City Outskirts is home to a massive mining operation and the Verdant Valley lays just outside the City; both would be a prime location for a tunnel or hideout for raiders seeking to exploit the farms. ' +
					'Moving through the City Outskirts Mine or the Verdant Valley may reveal some of these hidden places, or you may find better luck in finding them while hunting Goblins and Greater Imps.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.city_outskirts_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.verdant_valley,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.goblin,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.greater_imp,
					},
				], // Added the slay targets for NGIM
				targetAmount: 200,
			},
			{
				stepID: 3,
				name: 'Convincing the Guard',
				description:
					'Without your protection you fear the farms would be vulnerable to Goblin attacks, but the City Guard refuses to station anyone at the settlement. ' +
					'With a resupply of Bronze and Iron Bars, the Guard may be convinced to outfit and train some of these farmers to defend themselves.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.bronze_bar,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.iron_bar,
					},
				],
				targetAmount: 200,
			},
			{
				stepID: 4,
				name: 'Spelunking',
				description:
					'Goblins, like any other pest, cannot ever truly be eliminated. No matter how many you slay here, they will always return, but with enough slain now the settlement ' +
					'will be safe long enough for the farmers to prepare themselves for the raids. With luck, you will have saved this settlement from the fate of so many others, ' +
					' though there is no guarantee that any other adventurer would be so responsible with the settlement they have been tasked to defend.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.goblin,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.greater_imp,
					},
				],
				targetAmount: 1000,
			},
		],
		questRewards: {
			questPoints: 1,
			experience: {
				foraging: 1000,
				fishing: 1000,
				mining: 1000,
				attack: 1000,
				defense: 1000,
				constitution: 1000,
			},
			items: [
				{ id: itemsIds.oak_log, amount: 100 },
				{ id: itemsIds.log, amount: 100 },
				{ id: itemsIds.iron_ore, amount: 100 },
				{ id: itemsIds.tin_ore, amount: 100 },
				{ id: itemsIds.copper_ore, amount: 100 },
				{ id: itemsIds.watermelon, amount: 100 },
				{ id: itemsIds.onion, amount: 100 },
			],
			achievementsToAdd: [questsIds.cavern_crusader],
		},
	},
	// City Quest, introduction to the city, do work for the guards + citizens, village quarry / fungal grotto / lazy river
	102: {
		id: 102,
		name: 'City Services',
		description:
			'With that settlement no longer needing your help, the City Guard has decided that you may be of use to them. ' +
			'The City Guard is comprised almost solely of enforcers and soldiers, with little time or patience for the needs of the people. ' +
			'Originally, The City was once many different smaller towns and cities, but as threats from the wilderness grew, so too did the need for a centralized defense. ' +
			'With the armies and militias of these smaller polities absorbed, the binds of bureaucracy have drastically limited the helpfulness of the Guard as they maintain ' +
			'a massive force with little training or supply available.',
		difficulty: 'Easy',
		questIcon: '/images/combat/monsters/guard.png',
		questRequirements: {
			requiredQuests: [questsIds.caves_and_caverns],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Food for the Hungry',
				description:
					'From the rooftop of any tall place one can see The City stretching out to the horizon in all directions. Hidden by the grand palaces and sprawling infrastructure, ' +
					'many of the people suffer in silence. The City Guard has tasked you with gathering food for the hungry, as the Guard is unable to provide for them outside of an almost draconian ' +
					'enforcement of the law.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.apple,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.banana,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.mushroom,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.raw_trout,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.raw_salmon,
					},
				],
				targetAmount: 500,
			},
			{
				stepID: 2,
				name: 'Training Session',
				description:
					'The skill level of most city dwellers is unforgiveably low; decades of peace within the City and reliance on outer settlements have made them unable to properly care for themselves, both within and outside of the Guard. ' +
					'With your experience in the farms and caves, you may be able to show these people how to provide for themselves. ' +
					'Show them how to forage, fish, mine out in the wilds, or spar with the Guard to improve their combat skills.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.fungal_grotto,
					},
					{
						trigger: 'location',
						targetID: locationsIds.lazy_river,
					},
					{
						trigger: 'location',
						targetID: locationsIds.village_quarry,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.guard,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.militia_crossbowman,
					},
				],
				targetAmount: 500,
			},
			{
				stepID: 3,
				name: 'Heat for the Forges',
				description:
					'Once a town of its own, The City Forges are both massive and renowned. As expected, this massive sprawling complex of forges and smelters requires a massive amount of fuel to keep running. ' +
					'Your task is to gather a portion of the materials required to keep the forges running. In return, the Guard will provide you with one of their uniforms, random confiscated goods in Satchels, ' +
					'and various resources you may find useful.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.coal,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.willow_log,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.sturdy_driftwood,
					},
				],
				targetAmount: 500,
			},
		],
		questRewards: {
			questPoints: 1,
			experience: {
				foraging: 2000,
				fishing: 2000,
				mining: 2000,
				smithing: 2000,
			},
			items: [
				{ id: itemsIds.apple_tree_seed, amount: 32 },
				{ id: itemsIds.willow_tree_seed, amount: 32 },
				{ id: itemsIds.iron_bar, amount: 100 },
				{ id: itemsIds.fur_trousers, amount: 1 },
				{ id: itemsIds.iron_chainmail, amount: 1 },
				{ id: itemsIds.iron_helm, amount: 1 },
				{ id: itemsIds.satchel, amount: 2 },
			],
			achievementsToAdd: [questsIds.internal_auditor],
		},
	},
	// City Quest, do some work for the black knights, same locations as above
	103: {
		id: 103,
		name: 'City Secrets',
		description:
			'Your efforts have not gone unnoticed by the Guard and their superiors. The City council, a group of wizards that have been in power since the fall of the Academy, have some tasks that someone of your capabilities should be able to handle. ' +
			'The City Guard is known to be woefully incapable of handling true threats, so they are hoping to introduce new tools that can more adequately protect the City: ' +
			'the Black Knights, golems of obsidian armor that are controlled solely by the wizards.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/black_knight.png',
		questRequirements: {
			requiredQuests: [questsIds.city_services],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Alloys and Reagents',
				description:
					"The Council's research requires a massive amount of mithril ores and various alchemy reagents. With these the wizards are capable of manipulating the essence of metals and potentially create new alloys. " +
					'Your task is to gather the necessary materials for the latest experiments.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.raw_tigerfish,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.raw_magnetic_minnow,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.glow_spore_shroom,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.ghost_shrooms,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.mithril_ore,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Testing Data',
				description:
					'The Black Knights are ready to see some combat and have been integrated into the City Guard as a form of higher level shock troop. ' +
					'Due to the unskilled nature of the guards and militia, you have been tasked with providing the Black Knights with some combat data ' +
					'or providing mithril bars to the wizards to help them create more Black Knights. ' +
					'One of the wizards has even promised you some of their created obsidian and a helmet for you to study or use, if you so desire. ' +
					'Perhaps there is more latent magic in the obsidian than they let on...',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.black_knight,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.mithril_bar,
					},
				],
				targetAmount: 500,
			},
		],
		questRewards: {
			questPoints: 2,
			experience: {
				foraging: 2500,
				fishing: 2500,
				mining: 2500,
				smithing: 5000,
				crafting: 5000,
				defense: 5000,
			},
			items: [
				{ id: itemsIds.mysterious_seed, amount: 64 },
				{ id: itemsIds.satchel, amount: 4 },
				{ id: itemsIds.obsidian_glass, amount: 8 },
				{ id: itemsIds.obsidian_helm, amount: 1 },
			],
			// achievementsToAdd: [questsIds.cavern_crusader],
		},
	},
	// City Quest, black knights are the baddies?? same locations as above - medium
	104: {
		id: 104,
		name: 'Secrets Unveiled',
		description:
			'The mass production of Black Knights has been a boon to the City Guard, but the wizards have been increasing overstepping their boundaries. ' +
			'Some within the Guard fear that the wizards may seek to take even more control over the City and use the Black Knights to enforce their will. ' +
			'Although you were just one of many adventurers involved in the creation of the Black Knights, you should still probably do something about this.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/black_knight.png',
		questRequirements: {
			requiredQuests: [questsIds.city_secrets],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Re-equipping the Guard',
				description:
					'The City Guard has been overly reliant on the Black Knights and have let their equipment fall into disrepair. ' +
					'Providing them with new stocks of ingots should be enough for them to get their gear back in fighting shape. ' +
					'Some among the Guard warn you that there will still be groups within the Guard that will remain under the control of the wizards.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.iron_bar,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.mithril_bar,
					},
				],
				targetAmount: 1500,
			},
			{
				stepID: 2,
				name: 'Oppressing Oppressors',
				description:
					'Some districts of The City have fallen completely under the control of the more zealous wizards. ' +
					'You can liberate these areas by scrapping the Black Knights and scattering their support, or provide ' +
					'more higher quality materials to the loyal among the City Guard to help them fight back against these golems. ' +
					'Regardless of the route you take, the wizards will not be pleased with your actions; the Guard is prepared to offer you ' +
					'some of the more valuable materials from the Black Knights in return for your help.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.black_knight,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.mithril_bar,
					},
				],
				targetAmount: 1000,
			},
		],
		questRewards: {
			questPoints: 2,
			experience: {
				smithing: 15000,
				attack: 15000,
			},
			items: [
				{ id: itemsIds.satchel, amount: 6 },
				{ id: itemsIds.obsidian_glass, amount: 24 },
				{ id: itemsIds.obsidian_chainmail, amount: 1 },
				{ id: itemsIds.obsidian_greatsword, amount: 1 },
			],
			achievementsToAdd: [questsIds.obsidian_obliterator],
		},
	},
	// Fallen Academy, introduction of magic lore in IS, desert mine / tangle / lazy river - medium
	105: {
		id: 105,
		name: 'The Fallen Academy',
		description:
			'Decades have passed since the fall of the Academy, yet it still remains a center of power and knowledge, of a sort. ' +
			'Countless towers of all shapes, sizes, and constructions dot the landscape surrounding this once magnificent place ' +
			'with many of them still housing the remnants of wizards that have strayed too far in their path to power. ' +
			'Ironically, the Academy proper is the safest place to explore, as the wizards that remain here are more interested in their dark rites and research than in the outside world. ' +
			'You will be tasked with exploring and recovering some of the lost knowledge and materials from the Academy at the behest of the City. Hopefully it is put to good use.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/dark_mage.png',
		questRequirements: {
			requiredQuests: [questsIds.city_secrets],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Travelling to the Academy',
				description:
					'Located well outside of the City, the Academy requires traversing quite a distance to reach. ' +
					'The most common routes to take are either through the Desert, the Tangle, or meandering down the Lazy River. ' +
					'It would be wise to stock up on supplies or material during your journey there, as the wizards are not known for their hospitality.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.gold_ore,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.thorny_root,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.thorny_vine,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.raw_tigerfish,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.raw_salmon,
					},
					{
						trigger: 'location',
						targetID: locationsIds.desert_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.the_tangle,
					},
					{
						trigger: 'location',
						targetID: locationsIds.lazy_river,
					},
				],
				targetAmount: 5000,
			},
			{
				stepID: 2,
				name: 'Braving the Academy',
				description:
					'The campus of the Academy is a sprawling complex of towers, libraries, and laboratories. ' +
					'Some of them have been abandoned for decades, while others remain mostly functional even now. ' +
					'The safer buildings have already been scoured clean by other adventurers, ' +
					'so you will need to delve into the more dangerous areas to find anything of value.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.corpse_eater,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.dark_mage,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.lesser_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.greater_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.possessed_mage,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.pactbound_sorcerer,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.exiled_preceptor,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 3,
				name: 'Essence Offering',
				description:
					'During your exploration, you managed to find an essence imbuing chamber. ' +
					'Essence is the raw, magical energy that makes up all of reality, and when properly harnessed can be used to create powerful artifacts. ' +
					'There is much debate regarding the origins and nature of the essences, but for practical purposes they are simply a resource to be gathered and used. ' +
					'A set of uncommon unstable talismans have been left here as well as the notes on how to imbue them; follow the instructions and in the future you will be able to more easily create your own. ' +
					'Talismans are capable of enhancing your own essence collecting abilities, so they are a common tool for any fledgling mage to use. ' +
					'Unstable talismans are not particularly rare, but may cost some gold to purchase in the City. ' +
					'There are tales of talismans that were created by others not within the Academy, but those are probably only myths...',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.air_essence,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.earth_essence,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.fire_essence,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.water_essence,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.blood_essence,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.death_essence,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.chaos_essence,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.nature_essence,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.mind_essence,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.cosmic_essence,
					},
				],
				targetAmount: 50000,
			},
		],
		questRewards: {
			questPoints: 2,
			experience: {
				mining: 7500,
				foraging: 7500,
				fishing: 7500,
				runecrafting: 15000,
				constitution: 10000,
				defense: 10000,
			},
			items: [
				{ id: itemsIds.unstable_talisman, amount: 2 },
				{ id: itemsIds.air_talisman, amount: 1 },
				{ id: itemsIds.air_essence, amount: 25000 },
				{ id: itemsIds.earth_essence, amount: 25000 },
				{ id: itemsIds.fire_essence, amount: 25000 },
				{ id: itemsIds.water_essence, amount: 25000 },
			],
			achievementsToAdd: [questsIds.cult_crusher, questsIds.demon_destroyer],
		},
	},
	// Lava Maze, continuation of magic lore (runeslates), underground mine / misty marsh / still lake - hard
	106: {
		id: 106,
		name: 'Runes of the Lava Maze',
		description:
			'The Lava Maze was a site of ancient and powerful magic. It once hosted a sanctum of runecrafters, with an order of mages who specialized in creating powerful rune slates-magical tablets inscribed with runes that could manipulate reality itself' +
			'These runes were used to protect the realm, imbue items with immense power, and even control the elements. This power would be sought after by demons and adventurers alike.' +
			'When the demons emerged from the fiery depths many mages were forced to abandon the sanctum. The maze, formed by volcanic lava over centuries, would become a battleground with adventurers and demons vying for power.' +
			'Over time it became a dangerous and enigmatic place guarded by the demons and creatures, now making it their home.',
		difficulty: 'Hard',
		questIcon: '/images/combat/monsters/greater_demon.png',
		questRequirements: {
			requiredQuests: [questsIds.secrets_unveiled],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'The Runic Path',
				description:
					'The Slate Spires is a cold, forbidding region known for its towering darkish slate formations that jut out from the frozen ground like the jagged teeth of an ancient beast.' +
					'The air is crisp and frigid, with icy winds howling through the narrow valleys between the mountains of slate. Snow covers the ground in thick layers, and patches of ice make travel unforgiving.' +
					'Despite the harsh conditions, the Slate Spires are a valuable resource, rich in the high-quality slate coveted by runecrafters and merchants alike. It would be wise to gather slate for runecrafting as you journey to the lava maze.' +
					'Gathering from the Slate Spires counts as double progress for this step.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.slate_spires,
						progressMult: 2,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.rune_slate,
					},
				],
				targetAmount: 5000,
			},
			{
				stepID: 2,
				name: 'Harvesting the Last Bastion',
				description:
					'The Misty Marsh is a sprawling expanse of wetlands shrouded in a perpetual eerie fog.' +
					'The thick mist hangs low over the landscape, obscurring vision and muting sounds, creating an atmosphere of mystery and foreboding.' +
					'The ground is a mix of soggy earth with water channels winding through the area. Willow and Oak trees are in abundance in the area along with some seeds and vegetables.' +
					'It would be a good idea to gather some flora during your trek through this area. The Still Lake is a far-reaching tranquil body of water surrounded by dense, ancient forests.' +
					'The lakes surface is perfectly calm and mirror like, reflecting the sky and surrounding trees with uncanny clarity.' +
					'Despite its serene appearance, the lake holds a myriad of aquatic wildlife, and magic infused flora, perfect for adventurers and anglers alike.' +
					'The Underground Mine is a vast network of tunnels and caverns beneath the earth, filled with rich veins of precious mithril, gold, and some runeslate from the Slate Spires far above.' +
					'The mine is a dark and dangerous place, with winding passageways that can easily disorient and trap the unwary.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.misty_marsh,
					},
					{
						trigger: 'location',
						targetID: locationsIds.still_lake,
					},
					{
						trigger: 'location',
						targetID: locationsIds.underground_mine,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.silver,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.raw_slippery_eel,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.river_vines,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.onion,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.pumpkin,
					},
				],
				targetAmount: 5000,
			},
			{
				stepID: 3,
				name: "Inferno's Labyrinth",
				description:
					'The Lava Maze is a perilous network of fiery corridors, treacherous paths, and molten rivers, all set within a massive volcanic obsidian landscape.' +
					'It is a place of ancient power and danger, where the very ground pulses with heat and the air shimmers with intense energy.' +
					'Make your way to the focal point of the labyrinth in order to reach the inner sanctum and produce powerful runes like the mages of old had once done.' +
					'Demonic inhabitants have since overrun the area and settled in the ruins and throughout the maze, you will need to dispatch them before you can reach your goal.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.lesser_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.greater_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.deadly_spider,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.deadly_red_spider,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.overseer_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.vassal_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.blood_spider,
					},
				],
				targetAmount: 1500,
			},
			{
				stepID: 4,
				name: 'The Art of Runecrafting',
				description:
					'Once inside the sanctum you can finally start procuring your own runes using the methods long lost at this ancient site.' +
					'You search through shelves and aged research tables filled with scrolls and tomes detailing the history, theory, and practice of runecrafting.' +
					'At the heart of the sanctum lies a massive runic altar, a raised stone platform inscribed with concentric circles of runes. Surrounding the altar are towering runic pillars, each covered in detailed inscriptions and diagrams.' +
					'These pillars channel the latent magical energies of the sanctum. In finality you will need to craft runes using your magical essence, runic inscriptions, and infusing it into the runeslates.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.air_rune,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.earth_rune,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.fire_rune,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.water_rune,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.blood_rune,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.death_rune,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.chaos_rune,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.nature_rune,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.mind_rune,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.cosmic_rune,
					},
				],
				targetAmount: 10000,
			},
		],
		questRewards: {
			questPoints: 3,
			experience: {
				mining: 10000,
				fishing: 10000,
				foraging: 10000,
				runecrafting: 20000,
				constitution: 10000,
				attack: 10000,
				defense: 10000,
			},
			items: [
				{ id: itemsIds.rune_slate, amount: 5000 },
				{ id: itemsIds.pumpkin_seed, amount: 128 },
				{ id: itemsIds.demonic_statue, amount: 20 },
				{ id: itemsIds.ichor, amount: 1000 },
			],
			achievementsToAdd: [questsIds.runic_spires],
		},
	},
	// Back to the City, tie magic lore to black knights, mix of all locations above, combat sidequest gives more lore and background in enchanted armory - hard
	// Corrupted Lands, find out where the Advisor is from, hidden mine / haunted woods / no fishing - elite
	// Valley of Giants, going out further and older, slate spires / frozen tundra / open ocean - elite
	// Chaos Wastes, one source of malice, deep pit / living forest / stormy seas - master

	// #endregion
	// #region 200-299 skill specific quests
	// Augmenting tomes
	200: {
		id: 200,
		name: 'The Path to Enhancement',
		description: 'Begin the journey of at least 30 augmentations and unlock the Lesser Magic Tome recipe.',
		difficulty: 'Easy',
		questRequirements: {
			skillRequirements: {
				enchanting: 20,
			},
			questPoints: 1,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment 30 times.',
				description: 'Augment anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.the_academy_of_augmentation,
					},
				],
				targetAmount: 30,
			},
		],
		questIcon: '/images/enchanting/Lesser_Magic_Tome_embossed.png',
	},
	201: {
		id: 201,
		name: 'Realizing Your Enchanting Potential',
		description: 'Continue to increase your knowledge and unlock the Major Magic Tome recipe.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [questsIds.the_path_to_enhancement],
			skillRequirements: {
				enchanting: 40,
			},
			questPoints: 2,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment 100 times.',
				description: 'Augment anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.the_academy_of_augmentation,
					},
				],
				targetAmount: 100,
			},
		],
		questIcon: '/images/enchanting/Major_Magic_Tome.png',
	},
	202: {
		id: 202,
		name: 'Going Beyond the Academy',
		description:
			'As your abilities grow, so does your need for new materials and experience. Unlocks the Greater Magic Tome recipe.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [questsIds.realizing_your_enchanting_potential],
			skillRequirements: {
				enchanting: 55,
			},
			questPoints: 3,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment 500 times.',
				description: 'Augment anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.the_academy_of_augmentation,
					},
				],
				targetAmount: 500,
			},
		],
		questIcon: '/images/enchanting/Greater_Magic_Tome.png',
	},
	203: {
		id: 203,
		name: 'Unearthing Elder Knowledge',
		description:
			'The civilizations of the past hold many secrets. Follow in their footsteps and unlock the Elder Magic Tome recipe.',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [questsIds.going_beyond_the_academy],
			skillRequirements: {
				enchanting: 70,
			},
			questPoints: 4,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment 1000 times.',
				description: 'Augment anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.the_academy_of_augmentation,
					},
				],
				targetAmount: 1000,
			},
		],
		questIcon: '/images/enchanting/Elder_Magic_Tome.png',
		questRewards: {
			questPoints: 1,
		},
	},
	204: {
		id: 204,
		name: 'Unleashing Ancient Knowledge',
		description:
			'Older, darker pasts haunt even the oldest of known history. True mastery lies there, waiting to be unlocked. Unlocks the Ancient Magic Tome recipe',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [questsIds.unearthing_elder_knowledge],
			skillRequirements: {
				enchanting: 85,
			},
			questPoints: 5,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment 2500 times.',
				description: 'Augment anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.the_academy_of_augmentation,
					},
				],
				targetAmount: 2500,
			},
		],
		questIcon: '/images/enchanting/Ancient_Magic_Tome.png',
		questRewards: {
			questPoints: 1,
		},
	},
	// Crafting enchantment upgrades
	205: {
		id: 205,
		name: 'Crafting Tools I',
		description: 'Your crafting stations are shoddy and low quality. Perhaps you should look into improving them?',
		difficulty: 'Easy',
		questRequirements: {
			skillRequirements: {
				crafting: 20,
			},
			requiredQuests: [questsIds.processing_tutorial],
			questPoints: 1,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Basic Metalworking and Carpentry',
				description:
					'Practice making some basic tools and equipment to hone your abilities. You may not need it all, but the Vendor will be happy to take any extras off your hands!',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.mithril_pickaxe,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.iron_pickaxe,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.mithril_hatchet,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.iron_hatchet,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.advanced_tacklebox,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.basic_tacklebox,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.apprentice_runic_chisel,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.mithril_tongs,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.iron_tongs,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.mithril_hoe,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.iron_hoe,
					},
				],
				targetAmount: 20,
			},
		],
		questRewards: {
			experience: {
				crafting: 10000,
			},
			relatedTalent: [talentsIds.novice_crafter],
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	206: {
		id: 206,
		name: 'Crafting Tools II',
		description:
			'Your tools are of passable quality now. Perhaps you can draw some inspirations on efficiency by studying combat equipment?',
		difficulty: 'Medium',
		questRequirements: {
			skillRequirements: {
				crafting: 40,
			},
			requiredQuests: [questsIds.crafting_tools_i],
			questPoints: 2,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Combat Equipment Study',
				description:
					'Making some decent combat equipment will help you understand the intricacies of crafting. Try making some!',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.adamantite_daggers,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.adamantite_plate_leggings,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.adamantite_chainmail,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.adamantite_helm,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.chitin_coif,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.chitin_trousers,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.chitin_body,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.yew_longbow,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.greater_imbued_hood,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.greater_imbued_robe_bottom,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.greater_imbued_robe_top,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.greater_imbued_staff,
					},
				],
				targetAmount: 20,
			},
		],
		questRewards: {
			experience: {
				crafting: 25000,
			},
			relatedTalent: [talentsIds.apprentice_crafter],
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	207: {
		id: 207,
		name: 'Crafting Tools III',
		description: 'You can make equipment, but how is your precision?',
		difficulty: 'Hard',
		questRequirements: {
			skillRequirements: {
				crafting: 60,
			},
			requiredQuests: [questsIds.crafting_tools_ii],
			questPoints: 3,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Precision Engineering',
				description:
					'Black Opal is a notoriously hard gem to work with. Make a few sets of rings and necklaces to prove your skill!',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.black_opal_necklace,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.black_opal_ring,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.corrupted_ring,
					},
				],
				targetAmount: 10,
			},
		],
		questRewards: {
			experience: {
				crafting: 50000,
			},
			relatedTalent: [talentsIds.journeyman_crafter],
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	208: {
		id: 208,
		name: 'Crafting Tools IV',
		description:
			'Tools, basic equipment, and jewelry are far too mundane to further your skills now. You need a new challenge- how about some reverse engineering?',
		difficulty: 'Elite',
		questRequirements: {
			skillRequirements: {
				crafting: 80,
			},
			requiredQuests: [questsIds.crafting_tools_iii],
			questPoints: 4,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Dungeons and Artifacts',
				description: 'Reverse engineer some artifacts and rebuild some keys to expand your skillset.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.dark_fortress_key,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.cursed_spade,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.burning_feather,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.castle_map,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.elder_lockstone,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.ancient_pyramid,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.giants_keep_key,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.dwarven_research_key,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.abandoned_academy_scroll,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.dark_vault_key,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.ancient_maelstrom_orb,
					},
				],
				targetAmount: 20,
			},
		],
		questRewards: {
			experience: {
				crafting: 75000,
			},
			relatedTalent: [talentsIds.master_crafter],
			questPoints: 1,
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	209: {
		id: 209,
		name: 'Crafting Tools V',
		description: 'All that is left for you to master is Legendary equipment...',
		difficulty: 'Master',
		questRequirements: {
			skillRequirements: {
				crafting: 99,
			},
			requiredQuests: [questsIds.crafting_tools_iv],
			questPoints: 5,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Restoration or Crude Imitation?',
				description: 'Create some legendary equipment to prove your mastery.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.shapeless_scythe,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.ancient_trident,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.elven_kings_longbow,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.prismatic_necklace,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.prismatic_ring,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.golden_spoon,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.ancient_runic_chisel,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.dwarven_tongs,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.elvish_hoe,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.oceans_embrace,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.the_grovekeeper,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.dwarven_rocksmasher,
					},
				],
				targetAmount: 9,
			},
		],
		questRewards: {
			experience: {
				crafting: 150000,
			},
			relatedTalent: [talentsIds.grandmaster_crafter],
			questPoints: 1,
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	// Skilling Mastery quests - first one is doing like 1k of a skill and gives shards for their base set for gathering to unlock their set recipes, second is crafting the dungeon key and doing it this unlocks legendary recipe
	// The mastery quest will involve turning in 3000 of the skill's shard to unlock the recipe for the set after doing the first quest
	// 210: mining set quest
	210: {
		id: 210,
		name: 'Mining Equipment',
		description:
			'You could improve your mining experience by investing in a set of protective equipment that specializes in keeping you safe from bumping your head or dropping rocks on your toes!',
		difficulty: 'Medium',
		questIcon: '/images/mining/iron_pickaxe.png',
		questRequirements: {
			skillRequirements: {
				mining: 60,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Research the Mining Set',
				description: 'Mine 10,000 times to figure out what kind of equipment you need.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.clay_pit,
					},
					{
						trigger: 'location',
						targetID: locationsIds.city_outskirts_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.village_quarry,
					},
					{
						trigger: 'location',
						targetID: locationsIds.desert_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.underground_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.hidden_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.volcano_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.slate_spires,
					},
				],
				targetAmount: 10000,
			},
		],
		questRewards: {
			experience: {
				mining: 100000,
			},
			items: [{ id: itemsIds.mining_shard, amount: 1000 }],
			questPoints: 1,
		},
	},
	// 211: mining mastery quest
	211: {
		id: 211,
		name: 'Legendary Pickaxe',
		description:
			"Legends tell of the unquestionable prowess of the Dwarves' mining capabilities. Deep in the Wilderness there are various Dwarven ruins that may hold the key to recreating their favored tools.",
		difficulty: 'Master',
		questIcon: '/images/mining/dwarven_pickaxe.png',
		questRequirements: {
			requiredQuests: [questsIds.mining_equipment],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Dwarven Research Station',
				description:
					'There are many of these stations scattered throughout the wilderness; clear one of them to get examples of their equipment, or forego the rewards and have contract another to do it for you by turning in your key.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.dwarven_research_station,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.dwarven_research_key,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Miner could hope to reproduce Dwarven tools; consume 9,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.mining_shard,
					},
				],
				targetAmount: 9000,
			},
		],
		questRewards: {
			experience: {
				mining: 500000,
			},
			items: [{ id: itemsIds.dwarven_rocksmasher, amount: 1 }],
			questPoints: 1,
		},
	},
	// 212: foraging set quest
	212: {
		id: 212,
		name: 'Foraging Equipment',
		description:
			'Creating some tailored equipment for foraging should make the whole process more comfortable and efficient.',
		difficulty: 'Medium',
		questIcon: '/images/foraging/iron_hatchet.png',
		questRequirements: {
			skillRequirements: {
				foraging: 60,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Research the Foraging Set',
				description: 'Forage 10,000 times to figure out what kind of equipment you need.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.grasslands,
					},
					{
						trigger: 'location',
						targetID: locationsIds.verdant_valley,
					},
					{
						trigger: 'location',
						targetID: locationsIds.fungal_grotto,
					},
					{
						trigger: 'location',
						targetID: locationsIds.the_tangle,
					},
					{
						trigger: 'location',
						targetID: locationsIds.misty_marsh,
					},
					{
						trigger: 'location',
						targetID: locationsIds.frozen_tundra,
					},
					{
						trigger: 'location',
						targetID: locationsIds.haunted_woods,
					},
					{
						trigger: 'location',
						targetID: locationsIds.living_forest,
					},
				],
				targetAmount: 10000,
			},
		],
		questRewards: {
			experience: {
				foraging: 100000,
			},
			items: [{ id: itemsIds.foraging_shard, amount: 1000 }],
			questPoints: 1,
		},
	},
	// 213: foraging mastery quest
	213: {
		id: 213,
		name: 'Legendary Hatchet',
		description: 'The ruins of the elves may reveal secrets.',
		difficulty: 'Master',
		questIcon: '/images/foraging/grovekeeper.png',
		questRequirements: {
			requiredQuests: [questsIds.foraging_equipment],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Nature Atheneum',
				description:
					'The history and technology of the Elves are buried deep within the forest, find these hidden libraries their lost knowledge, or forego the rewards and have contract another to do it for you by turning in your key',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.nature_atheneum,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.heart_of_the_oak,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Forager could hope to reproduce Elvish tools; consume 9,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.foraging_shard,
					},
				],
				targetAmount: 9000,
			},
		],
		questRewards: {
			experience: {
				foraging: 500000,
			},
			items: [{ id: itemsIds.the_grovekeeper, amount: 1 }],
			questPoints: 1,
		},
	},
	// 214: fishing set quest
	214: {
		id: 214,
		name: 'Fishing Set Quest',
		description: 'A full tacklebox is a good starting point, but a proper angler needs more than that.',
		difficulty: 'Medium',
		questIcon: '/images/fishing/Advanced_Tacklebox.png',
		questRequirements: {
			skillRequirements: {
				fishing: 60,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Master the Art of Fishing',
				description: 'Fish 10,000 times to figure out what kind of equipment you need.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.shallow_pond,
					},
					{
						trigger: 'location',
						targetID: locationsIds.lazy_river,
					},
					{
						trigger: 'location',
						targetID: locationsIds.still_lake,
					},
					{
						trigger: 'location',
						targetID: locationsIds.open_ocean,
					},
					{
						trigger: 'location',
						targetID: locationsIds.stormy_seas,
					},
				],
				targetAmount: 10000,
			},
		],
		questRewards: {
			experience: {
				fishing: 100000,
			},
			items: [{ id: itemsIds.fishing_shard, amount: 1000 }],
			questPoints: 1,
		},
	},
	// 215: fishing mastery quest, ocean's embrace
	215: {
		id: 215,
		name: 'Legendary Tacklebox',
		description:
			'Researchers have been attempting to plumb the depths of the ocean for centuries, find their research and use it for more practical purposes.',
		difficulty: 'Master',
		questIcon: '/images/fishing/Master_Tacklebox.png',
		questRequirements: {
			requiredQuests: [questsIds.fishing_set_quest],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Abandoned Academy Base',
				description:
					"Before the Academy's fall, it had many research bases on the fringes of civilization; some say it holds occult knowledge based on ancient technology and magicks.",
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.abandoned_academy_base,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.abandoned_academy_scroll,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Fisher could hope to produce legendary fishing tools; consume 9,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.fishing_shard,
					},
				],
				targetAmount: 9000,
			},
		],
		questRewards: {
			experience: {
				fishing: 500000,
			},
			items: [{ id: itemsIds.oceans_embrace, amount: 1 }],
			questPoints: 1,
		},
	},
	// 216: runecrafting set quest
	216: {
		id: 216,
		name: 'Runecrafting Set Quest',
		description:
			"Runecrafting is a specialized subset of magic and would benefit from a specialist's set of robes.",
		difficulty: 'Medium',
		questIcon: '/images/runecrafting/air_rune.png',
		questRequirements: {
			skillRequirements: {
				runecrafting: 60,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Runecrafting Equipment',
				description: 'Do 3000 runecrafting actions to prove your mastery of the skill.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.the_runecrafting_shrines,
					},
				],
				targetAmount: 3000,
			},
		],
		questRewards: {
			experience: {
				runecrafting: 100000,
			},
			items: [{ id: itemsIds.runecrafting_shard, amount: 1000 }],
			questPoints: 1,
		},
	},
	// 217: runecrafting mastery quest
	217: {
		id: 217,
		name: 'Legendary Chisel',
		description:
			'Researchers have been attempting to plumb the depths of the ocean for centuries, find their research and use it for more practical purposes.',
		difficulty: 'Master',
		questIcon: '/images/runecrafting/chisel_2.png',
		questRequirements: {
			requiredQuests: [questsIds.runecrafting_set_quest],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Abandoned Academy Base',
				description:
					"Before the Academy's fall, it had many research bases on the fringes of civilization; some say it holds occult knowledge based on ancient technology and magicks.",
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.abandoned_academy_base,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.abandoned_academy_scroll,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Runecrafter could hope to produce a legendary chisel; consume 9,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.runecrafting_shard,
					},
				],
				targetAmount: 9000,
			},
		],
		questRewards: {
			experience: {
				runecrafting: 500000,
			},
			items: [{ id: itemsIds.ancient_runic_chisel, amount: 1 }],
			questPoints: 1,
		},
	},
	// 218: smithing set quest
	218: {
		id: 218,
		name: 'Smithing Equipment',
		description: 'Smelting metals requires more than a solid pair of tongs, find out what else you can use here.',
		difficulty: 'Medium',
		questIcon: '/images/smithing/Iron_Tongs.png',
		questRequirements: {
			skillRequirements: {
				smithing: 60,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Master the Art of Smithing',
				description: 'Smith 3000 times to prove your mastery of the skill.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.the_forges,
					},
				],
				targetAmount: 3000,
			},
		],
		questRewards: {
			experience: {
				smithing: 100000,
			},
			items: [{ id: itemsIds.smithing_shard, amount: 1000 }],
			questPoints: 1,
		},
	},
	// 219: smithing mastery quest
	219: {
		id: 219,
		name: 'Legendary Tongs',
		description: 'Master the art of smithing and unlock the recipe for the Dwarven Tongs.',
		difficulty: 'Master',
		questIcon: '/images/smithing/dwarven_tongs.png',
		questRequirements: {
			requiredQuests: [questsIds.smithing_equipment],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Learn the Legendary Recipe',
				description: 'Research the recipe by gathering 9,000 skilling shards.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.smithing_shard,
					},
				],
				targetAmount: 9000,
			},
		],
		questRewards: {
			experience: {
				smithing: 500000,
			},
			items: [{ id: itemsIds.dwarven_tongs, amount: 1 }],
			questPoints: 1,
		},
	},
	// 220: cooking set quest
	220: {
		id: 220,
		name: 'Cooking Set Quest',
		description: 'Master the art of cooking and unlock the recipe for the Cooking Set.',
		difficulty: 'Medium',
		questIcon: '/images/cooking/raw_chicken.png',
		questRequirements: {
			skillRequirements: {
				cooking: 60,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'The Art of Cooking',
				description: 'Cook or prepare 3000 times to prove your mastery of the skill.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.the_cooking_guild,
					},
					{
						trigger: 'location',
						targetID: locationsIds.the_preparation_halls,
					},
				],
				targetAmount: 3000,
			},
		],
		questRewards: {
			experience: {
				cooking: 100000,
			},
			items: [{ id: itemsIds.cooking_shard, amount: 1000 }],
			questPoints: 1,
		},
	},
	// 221: cooking mastery quest
	221: {
		id: 221,
		name: 'Legendary Ladle',
		description: 'Master the art of cooking and unlock the recipe for the Golden Spoon.',
		difficulty: 'Master',
		questIcon: '/images/cooking/golden_spoon.png',
		questRequirements: {
			requiredQuests: [questsIds.cooking_set_quest],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Learn the Legendary Recipe',
				description: 'Research the recipe by gathering 9,000 skilling shards.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.cooking_shard,
					},
				],
				targetAmount: 9000,
			},
		],
		questRewards: {
			experience: {
				cooking: 500000,
			},
			items: [{ id: itemsIds.golden_spoon, amount: 1 }],
			questPoints: 1,
		},
	},
	// 222: farming mastery quest (no set)
	222: {
		id: 222,
		name: 'Legendary Hoe',
		description: 'Master the art of farming and unlock the recipe for the Elvish Hoe.',
		difficulty: 'Master',
		questIcon: '/images/farming/elven_hoe.png',
		questRequirements: {
			skillRequirements: {
				farming: 60,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Nature Atheneum',
				description:
					'The history and technology of the Elves are buried deep within the forest, find these hidden libraries their lost knowledge.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.nature_atheneum,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.heart_of_the_oak,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Farmer could hope to reproduce Elvish tools; consume 24,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.farming_shard,
					},
				],
				targetAmount: 24000,
			},
		],
		questRewards: {
			experience: {
				farming: 500000,
			},
			items: [{ id: itemsIds.elvish_hoe, amount: 1 }],
			questPoints: 1,
		},
	},
	// #endregion
	// #region 300-399 are reserved for combat quests
	// Caves
	300: {
		id: 300,
		name: 'Cave Cleanup',
		description:
			'The farms are secure, now it is time to take care of the source of the problem. Actually, there are many sources, these are goblins after all.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/goblin.png',
		questRequirements: {
			requiredQuests: [questsIds.caves_and_caverns],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Know Thy Enemy',
				description: `Various Goblin elites appear to be leading the incursions into human territory, find and defeat them where you can.
				They can be encounter at high difficulty waves in the Caves, the Caves elite scroll (Stew Stained Page) encounter, or the Caves dungeon (Torn Goblin Settlement Map).`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.goblin_chef,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.big_gobbo,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: 'Kitchen Chaos',
				description: `Where there are goblins there is a kitchen. Find and defeat one of their kitchens to disrupt their supply lines.`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.goblin_kitchen,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 3,
				name: 'Settlement Sabotage',
				description: `With enough intel you could bring a small group to take the fight to one of the Goblin Settlements. Destroy one of their settlements!`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.goblin_settlement,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				cooking: 10000,
			},
			items: [
				{ id: itemsIds.chefs_hat, amount: 1 },
				{ id: itemsIds.lesser_ladle, amount: 1 },
			],
			questPoints: 1,
		},
	},
	// Farm
	301: {
		id: 301,
		name: 'First Place Farm Fiasco',
		description:
			'With the external threats handled, it is time to look inward. There are reports of one farmer in particular winning far too many awards for his animals. Investigate if there is dark magic at play!',
		difficulty: 'Medium',
		questIcon: '/images/farming/farming_icon.png',
		questRequirements: {
			requiredQuests: [questsIds.helping_hand],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Magic Animal Feed Doping',
				description: `There is clearly dark magic influencing this farmer's animal feed, just look at the monstrosities that are being created here!`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.chocolate_cow,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.ot_tos_prized_hen,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.marshall_the_town_rat,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.marshall_the_town_rat_2,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: 'Clearing the Fields',
				description: `You're powerless to quell the influence of the doped animal feed, but you can at least clear the fields temporarily. No river rerouting necessary!`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.ot_tos_farm,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				farming: 10000,
				attack: 10000,
			},
			items: [
				{ id: itemsIds.choccy_milk, amount: 100 },
				{ id: itemsIds.milk, amount: 1000 },
				{ id: itemsIds.raw_beef, amount: 1000 },
				{ id: itemsIds.raw_chicken, amount: 1000 },
				{ id: itemsIds.feather, amount: 1000 },
			],
			questPoints: 1,
		},
	},
	// City
	302: {
		id: 302,
		name: 'Punishing the Punishers',
		description:
			'Some of the city guards have been taking their duties a bit too far. It is time to show them that the citizens are not to be trifled with.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/guard.png',
		questRequirements: {
			requiredQuests: [questsIds.city_secrets],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Enforcing the Law',
				description: `Destroy the golems that the guards have been using to enforce their will on the citizens.`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.imbued_black_knight,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.black_knight_titan,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: 'Vigilante Justice',
				description: `The armories these golems originate from are scattered throughout the city. Destroy one of them to send a message.`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.living_armory,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				smithing: 10000,
				attack: 10000,
			},
			items: [
				{ id: itemsIds.obsidian_glass, amount: 128 },
				{ id: itemsIds.dark_key_piece, amount: 6 },
				{ id: itemsIds.satchel, amount: 12 },
			],
			questPoints: 1,
		},
	},
	// Fallen Academy
	303: {
		id: 303,
		name: 'Purging the Cultists',
		description: 'The Academy used to be a bastion of knowledge but has been reduced to the hideout of a cult.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/dark_mage.png',
		questRequirements: {
			requiredQuests: [questsIds.city_services],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Enforcing the Law',
				description: `Destroy the golems that the guards have been using to enforce their will on the citizens.`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.possessed_mage,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.pactbound_sorcerer,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.exiled_preceptor,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: 'Tilting the Tower',
				description: `Clear out a tower of its denizens.`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.fallen_wizard_tower,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				magic: 20000,
				attack: 20000,
			},
			items: [
				{ id: itemsIds.unknown_rune, amount: 1 },
				{ id: itemsIds.rune_slate, amount: 2000 },
				{ id: itemsIds.cursed_spade_head, amount: 1 },
				{ id: itemsIds.sunken_treasure, amount: 12 },
			],
			questPoints: 1,
		},
	},
	// Lava Maze
	304: {
		id: 304,
		name: 'Exorcising the Pits',
		description:
			'The Lava Maze is a perilous and complex system of pathways, with molten rivers and towering, jagged obsidian walls that have been formed over time from volcanic activity. The air is thick with sulfur and heat, making it difficult to breathe. Glowing pools of lava cast an eerie light on the surroundings, and the echoes of monstrous demons roaring and clawing resonate through the maze. Certainly, outnumbered you must swiftly come up with a plan to rid yourself of the numerous demons and make your way deeper into the Pit. By completing these quests, adventurers not only prove their mettle but also pave the way for deeper exploration and discovery inside the secretive Lava Maze.',
		difficulty: 'Hard',
		questIcon: '/images/combat/monsters/elite/greater_demon_elite.png',
		questRequirements: {
			requiredQuests: [questsIds.runes_of_the_lava_maze],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Purge the Overseers',
				description: `You devise that you will need to weaken the demonic forces that guard the deeper secrets of the Lava Maze, by first culling the numbers of the Overseer and Vassal demons. These higher tier demons are not only stronger but also serve as commanders, orchestrating the chaos and enforcing the will of their infernal overlords. Slaying them will interfere with the demon hierarchy and create an opening for further expedition into the maze. It would seem that these monsters being fire oriented would suffer more from ice type attacks and possibly some slashing attacks as they are still fleshy beings, however the Overseers look like a real challenge and are more muscular. You will have to encounter them more to see what works best.`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.overseer_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.vassal_demon,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: 'The Pits Depths',
				description: `Having disrupted the demonic hierarchy, the path is now cleared to the elite challenge of one of the Pits. Here, some of the maze's most formidable guardians await, ready to unleash wave after wave of relentless assault. Demonic beings will attack you systematically, getting more intense as time passes. Demons and spiders alike emerge from the depths of hell, culminating in a final confrontation with the strongest champions. Smite the demons and emerge victorious from one of the many pits in the Lava Maze, and reap any rewards you find.`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.the_pit,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				magic: 30000,
				attack: 30000,
			},
			items: [
				{ id: itemsIds.ichor, amount: 2500 },
				{ id: itemsIds.molten_ashes, amount: 6 },
				{ id: itemsIds.infernal_flame, amount: 6 },
				{ id: itemsIds.demonic_statue, amount: 12 },
			],
			questPoints: 1,
		},
	},
	// Overgrown Woods
	305: {
		id: 305,
		name: 'Clearing the Undergrowth',
		description: 'This forest is deep and thick...',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/wolf.png',
		questRequirements: {
			requiredQuests: [questsIds.caves_and_caverns],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Into the Thicket',
				description: `You encounter some dangerous foes in the outskirts of the woods... You may need to bring an ally.`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.wolf,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.wild_boar,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.nox_wolf,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: 'Nox Woods',
				description: `One of the darkest parts of the woods, this area harbors only evil.`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.nox_woods,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				strength: 30000,
				attack: 30000,
			},
			items: [
				{ id: itemsIds.fur, amount: 2500 },
				{ id: itemsIds.thick_fur, amount: 1000 },
				{ id: itemsIds.partial_map, amount: 10 },
				{ id: itemsIds.mysterious_seed, amount: 32 },
			],
			questPoints: 1,
		},
	},
	// Enchanted Armory
	306: {
		id: 306,
		name: 'Entering the Armory',
		description: 'Strange magic has warped the suits of armor here into dangerous mechanical golems...',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/black_knight_magic.png',
		questRequirements: {
			requiredQuests: [questsIds.clearing_the_undergrowth],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Discovering Ancient Magic',
				description: `Suits of armor meet you at the entrance to the armory! These ones seem to be warped by magic...`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.glass_knight,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.glass_mage,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.glass_ranger,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: 'Obsidian Research Facility',
				description: `A section of the armory appears to be where the magic was unleashed. Conquer it in order to understand it's secrets!`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.obsidian_research_facility,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				magic: 30000,
				attack: 30000,
			},
			items: [
				{ id: itemsIds.gem_shards, amount: 5000 },
				{ id: itemsIds.obsidian_glass, amount: 50 },
				{ id: itemsIds.dark_vault_identification_crystal, amount: 12 },
				{ id: itemsIds.golem_marrow, amount: 500 },
			],
			questPoints: 1,
		},
	},

	// Corrupted Lands
	307: {
		id: 307,
		name: 'Conquering the Corruption',
		description: 'Poisonous substances have corrupted the creatures here!',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/bone_giant.png',
		questRequirements: {
			requiredQuests: [questsIds.exorcising_the_pits],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Cruel Corruption',
				description: `Toxic substances appear to be the cause for the vile creatures found in the corrupted lands.`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.cursed_naga,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.corrupted_tree,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.bone_giant,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: 'Corroded Battlefield',
				description: `Ruins of a long-forgotten battle appear in front of you. The monsters here are more corrupted than ever!`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.corroded_battlefield,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				strength: 30000,
				attack: 30000,
			},
			items: [
				{ id: itemsIds.corrupted_fragment, amount: 1 },
				{ id: itemsIds.yew_log, amount: 500 },
				{ id: itemsIds.ichor, amount: 500 },
				{ id: itemsIds.nagas_bracelet, amount: 1 },
				{ id: itemsIds.death_essence, amount: 30000 },
			],
			questPoints: 1,
		},
	},
	// Elven Encampment
	308: {
		id: 308,
		name: `Entering the Elves' Domain`,
		description: 'Nestled in a large clearing is a ring of tents. Elves mingle about the area.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/elite/elven_king.png',
		questRequirements: {
			requiredQuests: [questsIds.clearing_the_undergrowth],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Hostile Elves',
				description: `The elves appear to be hostile! Defend yourself!`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.elven_hunter,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.elven_rider,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.elven_warrior,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.elven_mage,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: 'Royal Encampment',
				description: `You come across a truly massive encampment of elves. This camp seems more prepared than you have ever seen...`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.royal_encampment,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				range: 30000,
				attack: 30000,
			},
			items: [
				{ id: itemsIds.elven_ammo, amount: 1000 },
				{ id: itemsIds.elder_stone, amount: 5 },
				{ id: itemsIds.elder_gem, amount: 1 },
				{ id: itemsIds.crystal_scale, amount: 12 },
			],
			questPoints: 1,
		},
	},

	// Valley of Giants
	309: {
		id: 309,
		name: `A Giant Valley`,
		description:
			'In the far north lie a civilization of giants. They wield weapons made with knowledge that has long been dead...',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/ice_giant.png',
		questRequirements: {
			requiredQuests: [questsIds.conquering_the_corruption],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Furious Foes',
				description: `The giant's don't want to be disturbed, but you need the weapons they wield!`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.ice_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.fire_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.moss_giant,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: `Giant's Hills`,
				description: `Futher into the valley you discover frozen hills, containing giants much older than the ones you have fought before...`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.giants_hills,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				strength: 30000,
				attack: 30000,
			},
			items: [
				{ id: itemsIds.water_rune, amount: 1000 },
				{ id: itemsIds.fire_rune, amount: 1000 },
				{ id: itemsIds.nature_rune, amount: 1000 },
				{ id: itemsIds.water_essence, amount: 30000 },
				{ id: itemsIds.fire_essence, amount: 30000 },
				{ id: itemsIds.nature_essence, amount: 30000 },
				{ id: itemsIds.water_talisman, amount: 1 },
				{ id: itemsIds.fire_talisman, amount: 1 },
				{ id: itemsIds.nature_talisman, amount: 1 },
				{ id: itemsIds.ice_fragment, amount: 1 },
				{ id: itemsIds.fire_fragment, amount: 1 },
				{ id: itemsIds.nature_fragment, amount: 1 },
			],
			questPoints: 1,
		},
	},

	// Chaos Wastes
	310: {
		id: 310,
		name: `The Chaotic Plane`,
		description:
			'Entering another plane of existence, you discover chaotic beings more powerful than anything in the overworld...',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/chaotic_abomination.png',
		questRequirements: {
			requiredQuests: [questsIds.entering_the_elves_domain],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Complete and Utter Chaos',
				description: `The enemies here have been warped by forces beyond your understanding.`,
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.chaotic_abomination,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.chaos_giant,
					},
				],
				targetAmount: 10,
			},
			{
				stepID: 2,
				name: `Chaotic Rift`,
				description: `A tear within reality itself spews forth nightmare-ish creatures.`,
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: locationsIds.chaotic_rift,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				magic: 30000,
				attack: 30000,
			},
			items: [
				{ id: itemsIds.chaos_talisman, amount: 1 },
				{ id: itemsIds.chaos_essence, amount: 30000 },
				{ id: itemsIds.chaos_fragment, amount: 1 },
				{ id: itemsIds.chaos_rune, amount: 1000 },
				{ id: itemsIds.ancient_crystal, amount: 4 },
			],
			questPoints: 1,
		},
	},

	// #endregion
	// #region 2000 - 2099 are reserved for tutorials
	2000: {
		id: 2000,
		name: 'Gathering Tutorial',
		description:
			'Learn how to gather! The Gathering skills are the primary produces of raw materials in Idlescape and have the lowest barrier to entry of all skills. By default every 5 minutes spent Gathering produces the relevant Gathering Shard for that skill that can eventually be used to craft very powerful gathering equipment.',
		difficulty: 'Tutorial',
		questSteps: [
			// Mining
			{
				stepID: 1,
				name: 'Mining',
				description:
					'Mining produces ores that can be processed by Smithing in order to craft weapons, armor, and tools. Pickaxes increase the speed at which you mine ores. Mining primarily feeds into the Melee combat style. If you are lucky then you may find a Geode or its Greater Geode sibling that can be opened for a random reward.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.clay_pit,
					},
				],
				targetAmount: 10,
				// image: {
				// 	image: '/images/tutorial/gathering_tut/mining.PNG',
				// 	size: 'medium',
				// },
				link: {
					page: 'mining',
				},
			},
			// Foraging
			{
				stepID: 2,
				name: 'Foraging',
				description:
					'Foraging produces logs, plants, and seeds. Logs can be used in crafting or burned for Heat. Plants can be used by Cooking to create various foods or potions. Seeds can be planted on your farm. Hatchets increase the speed at which you forage. Foraging primarily feeds into the Ranged combat style. If you are lucky then you may find a Birds Nest or its Greater Birds Nest sibling that can be opened for a random reward.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.grasslands,
					},
				],
				targetAmount: 10,
				// image: {
				// 	image: '/images/tutorial/gathering_tut/foraging.png',
				// 	size: 'medium',
				// },
				link: {
					page: 'foraging',
				},
			},
			// Fishing
			{
				stepID: 3,
				name: 'Fishing',
				description:
					'Fishing produces fish, driftwood, and plant fibers. Fish are used in cooking, driftwood can be burned or crafted with, and plant fibers can be weaved for crafting. Fishing uses stats from tackleboxes and various baits and is the most complex gathering skill. Fishing primarily feeds into the Magic combat style. If you are lucky then you may find a Sunken Treasure or its Ancient Treasure sibling that can be opened for a random reward.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.shallow_pond,
					},
				],
				targetAmount: 10,
				// image: {
				// 	image: '/images/tutorial/gathering_tut/fishing.png',
				// 	size: 'medium',
				// },
				link: {
					page: 'fishing',
				},
			},
		],
		questRewards: {
			experience: {
				mining: 100,
				foraging: 100,
				fishing: 100,
			},
			items: [
				{
					id: itemsIds.bronze_pickaxe,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Pickaxe',
					},
				},
				{
					id: itemsIds.bronze_hatchet,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Hatchet',
					},
				},
				{
					id: itemsIds.novice_tacklebox,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Tacklebox',
					},
				},
				// For farming tutorial if they don't have any other seeds for it
				{
					id: itemsIds.mysterious_seed,
					amount: 16,
				},
				// Chests
				{
					id: itemsIds.geode,
					amount: 1,
				},
				{
					id: itemsIds.birds_nest,
					amount: 1,
				},
				{
					id: itemsIds.sunken_treasure,
					amount: 1,
				},
				{
					id: itemsIds.rune_slate,
					amount: 100,
				},
			],
			achievementsToAdd: [
				questsIds.master_miner,
				questsIds.claymore,
				questsIds.castle,
				questsIds.master_of_the_shrimp,
				questsIds.its_definitely_spelled_mellon,
				questsIds.feeling_lucky,
				questsIds.destroying_history,
			],
		},
		questIcon: '/images/mining/iron_pickaxe.png',
	},
	2001: {
		id: 2001,
		name: 'Processing Tutorial',
		description:
			'Learn how to process! The Processing skills are used to convert raw materials into usable items and are the primary source of refined materials and finished products in Idlescape.',
		difficulty: 'Tutorial',
		questSteps: [
			// Crafting
			{
				stepID: 1,
				name: 'Crafting',
				description:
					'Crafting is used to create a wide variety of useful items and is the primary way for you to create finished products from various materials. For this tutorial you will need to craft a log. You can find the recipe in the Crafting menu under the General tab.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.log,
					},
				],
				image: {
					image: '/images/ui/crafting_icon.png',
					size: 'medium',
				},
				targetAmount: 1,
				link: {
					page: 'crafting',
				},
			},
			// RC Runes
			{
				stepID: 2,
				name: 'Runecrafting (Runes)',
				description:
					'Runes are used to power most magical effects in Idlescape, ranging from enchanted scrolls to powerful spells. Each rune type has a different essence that is produced by various activities that you can find by hovering over your essence count in the Runecrafting menu. For this tutorial you will need to produce some of the most basic runes: Air (passively earned), Earth (mining), Water (fishing), and Nature (foraging). Simply doing the activity will produce the essence, though essence generated can be boosted through absorbing the power of its respective Talisman or by using enchantments.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.air_rune,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.earth_rune,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.water_rune,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.nature_rune,
					},
				],
				targetAmount: 20,
				link: {
					page: 'runecrafting',
				},
			},
			// RC Cloth
			{
				stepID: 3,
				name: 'Runecrafting (Cloth)',
				description:
					'Cloth is used to craft magical items and is sourced entirely from fibers found while Fishing. For this tutorial you will need to either produce or find 10 rough cloth; you can find the requisite fiber, Aqueous Grass, in the Shallow Pond while Fishing.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.rough_cloth,
					},
				],
				targetAmount: 10,
				link: {
					page: 'runecrafting',
				},
			},
			// Smithing
			{
				stepID: 4,
				name: 'Smithing',
				description:
					'Metal bars have a wide variety of uses, but are primarily used in crafting Melee weapons and armor as well as produce several tools. For this tutorial you will need to either produce 10 bronze bars; you can find Tin and Copper Ore in the Clay Pit while Mining.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.bronze_bar,
					},
				],
				targetAmount: 10,
				link: {
					page: 'smithing',
				},
			},
			// Farming
			{
				stepID: 5,
				name: 'Farming',
				description:
					'Farming is a completely passive skill; plant a seed and wait for it to grow! For this tutorial you will need to plant and grow a Potato. You may use fertilizer to boost the output yield and experience of the crop, but it is not required.',
				validTargets: [
					{
						trigger: 'farm',
						targetID: itemsIds.potato_seed,
					},
				],
				targetAmount: 1,
				link: {
					page: 'farming',
				},
			},
		],
		questRewards: {
			experience: {
				crafting: 100,
				runecrafting: 100,
				smithing: 100,
				farming: 100,
			},
			items: [
				{
					id: itemsIds.bronze_hoe,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Hoe',
					},
				},
				{
					id: itemsIds.apprentice_runic_chisel,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Chisel',
					},
				},
				{
					id: itemsIds.bronze_tongs,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Bronze Tongs',
					},
				},
				{
					id: itemsIds.potato_seed,
					amount: 32,
				},
				{
					id: itemsIds.fertilizer,
					amount: 64,
				},
				{
					id: itemsIds.rune_slate,
					amount: 100,
				},
				{
					id: itemsIds.copper_ore,
					amount: 32,
				},
				{
					id: itemsIds.tin_ore,
					amount: 32,
				},
				{
					id: itemsIds.branch,
					amount: 100,
				},
				{
					id: itemsIds.log,
					amount: 32,
				},
				{
					id: itemsIds.heat,
					amount: 1000,
				},
			],
			achievementsToAdd: [questsIds.burning_with_enthusiasm, questsIds.burn_it_all],
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	2002: {
		id: 2002,
		name: 'Combat Tutorial',
		description:
			'Learn how to fight! Combat combines the efforts of its Gathering and Processing counterparts to challenge difficult opponents and unlock various content or rewards. Combat is the primary source of raw gold coins and is the most complex aspect of Idlescape. There are three combat styles: Melee, Ranged, and Magic. Each style has its own unique weapons and armor and perform differently based on the opponent faced.',
		difficulty: 'Tutorial',
		questSteps: [
			// equip
			{
				stepID: 1,
				name: 'Gearing Up',
				description:
					'Choose a combat style! Most styles have many weapons to choose from, but for simplicity you should focus on these specific ones for now.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.bronze_daggers,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.driftwood_staff,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.flimsy_recurve_bow,
					},
				],
				targetAmount: 1,
				link: {
					page: 'crafting',
				},
			},
			// fite
			{
				stepID: 2,
				name: 'Combat',
				description: 'Head to the Farms and slay 200 of its denizens!',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.cow,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.chicken,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.goblin,
					},
				],
				targetAmount: 200,
				link: {
					page: 'attack',
				},
			},
		],
		questRewards: {
			experience: {
				attack: 250,
				defense: 250,
			},
			achievementsToAdd: [questsIds.giant_slayer],
		},
		questIcon: '/images/combat/combat_level.png',
	},
	2003: {
		id: 2003,
		name: 'Item Improvement Tutorial',
		description:
			'There are several avenues you can take to improve your items with stacking effects and bonuses. This tutorial will cover the basics of Augmentations and Scrolled Enchantments in the Enchanting skill.',
		difficulty: 'Tutorial',
		questSteps: [
			// Scrollcrafting
			{
				stepID: 1,
				name: 'Scrollcrafting',
				description:
					'Enchanted Scrolls are the easiest way to improve an item. Items have a limited number of slots for enchantments and enchantments apply a variety of effects to an item. For this step you will need to craft a Scroll of the Shrimp Lord. You can find the recipe in the Scrollcrafting menu under the Enchanting tab. You can find silver through mining, scrolls in the General Shop, and runes from Runecrafting.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.scroll_of_the_shrimp_lord,
					},
				],
				targetAmount: 1,
				link: {
					page: 'enchanting',
				},
			},
			// Enchanting
			{
				stepID: 2,
				name: 'Enchanting',
				description:
					'Enchanted Scrolls have specific item slots they can be applied to. The Scroll of the Shrimp Lord can only be applied to Boots or Gloves which can be purchased from the General Shop. You can find the Enchanting menu under the Enchanting tab. Select the item to enchant and the scroll to enchant it with and click the Enchant button to complete this step.',
				validTargets: [
					{
						trigger: 'enchant-scroll',
						targetID: itemsIds.scroll_of_the_shrimp_lord,
					},
				],
				targetAmount: 1,
				link: {
					page: 'enchanting',
				},
			},
			// Augmenting
			{
				stepID: 3,
				name: 'Augmenting',
				description:
					'Augmenting improves the stats of items but requires a small amount of resources to do so. To complete this tutorial step you will need to attempt to augment any item. You can find the Augmenting menu under the Enchanting tab. Place the item to augment into the Augmenting Queue and confirm that you have the materials to augment and click the Start button to attempt augmentation.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'enchanting',
				},
			},
		],
		questRewards: {
			experience: {
				enchanting: 300,
			},
			items: [
				{
					id: itemsIds.scroll,
					amount: 3,
				},
				{
					id: itemsIds.silver,
					amount: 300,
				},
			],
			achievementsToAdd: [questsIds.prismatic_power, questsIds.pity_prismatic, questsIds.on_the_rainbow_road],
		},
		questIcon: '/images/enchanting/augmenting_logo.png',
	},
	2004: {
		id: 2004,
		name: 'Affixing Tutorial',
		description:
			'Affixes are a more advanced way to improve your equipment and enhance it for your personal niche.',
		difficulty: 'Tutorial',
		questRequirements: {
			requiredQuests: [questsIds.item_improvement_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Runic Dust',
				description:
					'Runic Dust is used to apply or reroll the affixes on an item. Each item has its own rarity with a corresponding tier of dust. Craft 30 or turn in any tier of dust, either from materials gathered or from monster drops.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.basic_runic_dust,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.basic_runic_dust,
					},

					{
						trigger: 'craft',
						targetID: itemsIds.unusual_runic_dust,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.unusual_runic_dust,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.singular_runic_dust,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.singular_runic_dust,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.prime_runic_dust,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.prime_runic_dust,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.mythical_runic_dust,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.mythical_runic_dust,
					},
				],
				targetAmount: 30,
				link: {
					page: 'crafting',
				},
			},
			{
				stepID: 2,
				name: 'Gear Scraps',
				description:
					'Gear Scraps are made by researching equipment until it breaks. The obtained scrap matches the rarity of the item. Gear Scraps are consumed on each reroll attempt of an affix. Turn in 1 of any tier of gear scraps.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: itemsIds.common_gear_scraps,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.uncommon_gear_scraps,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.rare_gear_scraps,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.epic_gear_scraps,
					},
					{
						trigger: 'inventory',
						targetID: itemsIds.legendary_gear_scraps,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 3,
				name: 'Time to Affix!',
				description:
					'You will need some dust to apply an affix to an item. The dust must be of the same rarity tier as the item. Selecting specific affixes increases the cost to affix massively depending on the rarity of the affixes selected. Simply affix anything to any item once to complete this step.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Affix Anything',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 4,
				name: 'Time to Reroll!',
				description:
					'You will need some dust and a gear scrap to reroll the affixes on an item. The dust and scraps must be of the same rarity tier as the item. Locking an affix causes it to not be rerolled; unlocked affixes will reroll its strength range but NOT replace it with another affix.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Reroll Anything',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				runecrafting: 500,
			},
			items: [
				{
					id: itemsIds.basic_runic_dust,
					amount: 300,
				},
				{
					id: itemsIds.unusual_runic_dust,
					amount: 150,
				},
				{
					id: itemsIds.common_gear_scraps,
					amount: 3,
				},
				{
					id: itemsIds.uncommon_gear_scraps,
					amount: 3,
				},
			],
			achievementsToAdd: [questsIds.legendary_roll, questsIds.legendary_rerolls],
		},
		questIcon: '/images/runecrafting/dust/runic_dust_legendary.png',
	},
	2005: {
		id: 2005,
		name: 'Talent Tutorial',
		description:
			'Talents are a mostly lategame specialization tool that will allow you to passively modify your character through unlocking different, highly unique perks/talents in a tree.',
		difficulty: 'Tutorial',
		// We need more quests with point rewards to enable this
		// questRequirements: {
		// 	questPoints: 5,
		// },
		questSteps: [
			{
				stepID: 1,
				name: 'Pick a Talent',
				description:
					'To complete this tutorial you simply have to select any one of the talents available. You are given a maximum talent allowance based on your mastery levels and quest/achievement points; most talents have a cost associated with them and you may select up to you allowance. Some Talents unlock other, stronger Talents to allow greater specialization. These are semi-permanent additions to your character and require the use of a Respec Token to reset your Talent Tree, so pick carefully!',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'talents',
				},
			},
		],
		questRewards: {
			items: [
				{
					id: itemsIds.talent_respec_token,
					amount: 1,
				},
			],
		},
		questIcon: '/images/total_level_mastery_icon.png',
	},
	// #endregion
	// #region 5000 - 9999 are reserved for achievements
	// Achievements ONLY rewards achievement points, nothing else
	// Easy = 1 pt, Medium = 2 pts, Hard = 3 pts, Elite = 4 pts, Master = 5 pts
	// Should ONLY have one step
	// #region 5000-6000 are reserved for combat achievements
	5000: {
		id: 5000,
		name: 'Farm Fiend',
		questIcon: '/images/combat/monsters/chicken.png',
		description: 'Terrorize the farms and kill ALL the farm animals!',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [questsIds.helping_hand],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 farm animals. You monster.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.chicken,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.cow,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.rat,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.ot_tos_prized_hen,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.chocolate_cow,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.marshall_the_town_rat,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.marshall_the_town_rat_2,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	5001: {
		id: 5001,
		name: 'Cavern Crusader',
		questIcon: '/images/combat/monsters/goblin.png',
		description: 'The only good goblin is a dead goblin.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [questsIds.caves_and_caverns],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 goblins.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.goblin,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.big_gobbo,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.goblin_chef,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.loot_goblin,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.hobgoblin,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	5002: {
		id: 5002,
		name: 'Internal Auditor',
		questIcon: '/images/combat/monsters/guard.png',
		description: 'It is time to determine who on the guard deserves that honor.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [questsIds.city_services],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 guards and militia. How exactly does this help the city?',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.guard,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.militia_crossbowman,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	5003: {
		id: 5003,
		name: 'Obsidian Obliterator',
		questIcon: '/images/combat/monsters/black_knight.png',
		description: 'These living golems are just man-made monsters.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [questsIds.secrets_unveiled],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 Black Knights.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.black_knight,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.imbued_black_knight,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.black_knight_titan,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	5004: {
		id: 5004,
		name: 'Giant Slayer',
		questIcon: '/images/combat/monsters/ice_giant.png',
		description: 'The bigger they are, the harder they fall.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [questsIds.combat_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 Giants.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.moss_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.fire_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.ice_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.bone_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.chaos_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.perennial_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.volcanic_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.glacial_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.ghastly_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.voidtouched_giant,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.black_knight_titan,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.giant_king,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.giant_king_2,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.the_advisor,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.corrupted_titan,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	5005: {
		id: 5005,
		name: 'Cult Crusher',
		questIcon: '/images/combat/monsters/dark_mage.png',
		description: 'Purge the cultists!',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [questsIds.the_fallen_academy],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 Mages.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.dark_mage,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.possessed_mage,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.pactbound_sorcerer,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.exiled_preceptor,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	5006: {
		id: 5006,
		name: 'Demon Destroyer',
		questIcon: '/images/combat/monsters/lesser_demon_no_highlight.png',
		description: 'Rip and Tear until it is done.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [questsIds.the_fallen_academy],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 Demons.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: monstersIds.greater_imp,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.lesser_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.greater_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.deep_pit_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.overseer_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.vassal_demon,
					},
					{
						trigger: 'slay',
						targetID: monstersIds.wrath,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	// Welcome to Combat - die once
	// #endregion
	// 6001-7000 are reserved for gathering achievements
	6001: {
		id: 6001,
		name: 'Master Miner',
		questIcon: '/images/mining/iron_pickaxe.svg',
		description: 'Complete one million mining actions.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [questsIds.gathering_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Mine 'em All!",
				description: 'Mine 1,000,000 times.',
				validTargets: [
					{
						trigger: 'location',
						targetID: locationsIds.clay_pit,
					},
					{
						trigger: 'location',
						targetID: locationsIds.city_outskirts_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.village_quarry,
					},
					{
						trigger: 'location',
						targetID: locationsIds.desert_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.underground_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.hidden_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.volcano_mine,
					},
					{
						trigger: 'location',
						targetID: locationsIds.slate_spires,
					},
					{
						trigger: 'location',
						targetID: locationsIds.deep_pit,
					},
				],
				targetAmount: 1000000,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	6002: {
		id: 6002,
		name: 'Castle',
		questIcon: '/images/mining/stone.png',
		description: 'Gather one million Stone.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [questsIds.gathering_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Castle',
				description: 'Gather one million Stone.',
				validTargets: [
					{
						trigger: 'gather',
						targetID: itemsIds.stone,
					},
				],
				targetAmount: 1000000,
			},
		],
		questRewards: {
			achievementPoints: 3,
		},
		achievement: true,
	},
	6003: {
		id: 6003,
		name: 'Master of the Shrimp',
		questIcon: '/images/fishing/raw_shrimp.png',
		description: 'Gather 100,000 Raw Shrimp to please the Shrimp God.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [questsIds.gathering_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Shrimp',
				description: 'SHRIMP',
				validTargets: [
					{
						trigger: 'gather',
						targetID: itemsIds.raw_shrimp,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	6004: {
		id: 6004,
		name: 'Clay-More',
		questIcon: '/images/mining/clay.png',
		description: 'Gather 1,000 Clay, preferably from the Clay Pit.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [questsIds.gathering_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Gather 1,000 Clay',
				description: 'The best place to get Clay is the Clay Pit, duh!',
				validTargets: [
					{
						trigger: 'gather',
						targetID: itemsIds.clay,
					},
				],
				targetAmount: 1000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	6005: {
		id: 6005,
		name: "It's Definitely Spelled 'Mellon'",
		questIcon: '/images/cooking/alchemy/ingredients/watermelon.png',
		description: 'Collect 100,000 watermelons.',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [questsIds.gathering_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'MELLONS',
				description: 'Collect 100,000 watermelons.',
				validTargets: [
					{
						trigger: 'gather',
						targetID: itemsIds.watermelon,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 4,
		},
		achievement: true,
	},
	6006: {
		id: 6006,
		name: 'Destroying History',
		questIcon: '/images/foraging/elder_log.png',
		description: 'Collect 100,000 Elder Logs from Foraging.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [questsIds.gathering_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Elder Logs',
				description: 'Collect 100,000 Elder Logs. They grow back!',
				validTargets: [
					{
						trigger: 'gather',
						targetID: itemsIds.elder_log,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	6007: {
		id: 6007,
		name: 'Feeling Lucky?',
		questIcon: '/images/cooking/alchemy/ingredients/lucky_clover.png',
		description: 'Collect 1,000 Four-leaf Clovers.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [questsIds.gathering_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Only the ones with four leaves!',
				description: 'Collect 1,000 Four-leaf Clovers.',
				validTargets: [
					{
						trigger: 'gather',
						targetID: itemsIds.fourleaf_clover,
					},
				],
				targetAmount: 1000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	6008: {
		id: 6008,
		name: 'Runic Spires',
		questIcon: '/images/runecrafting/rune_slate.png',
		description: 'Gather 1,000,000 Rune Slates, preferably from the Slate Spires.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [questsIds.runes_of_the_lava_maze],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Gather 1,000,000 Rune Slates',
				description: 'I wonder where the best place to get Rune Slates is...',
				validTargets: [
					{
						trigger: 'gather',
						targetID: itemsIds.rune_slate,
					},
				],
				targetAmount: 1000000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	// 7001-8000 are reserved for processing achievements
	7001: {
		id: 7001,
		name: 'Legendary Roll',
		questIcon: '/images/magic/buffs/chances-icon.png',
		description: 'Get within 2% of the maximum possible affix rolls on a Legendary rarity item on the first try.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [questsIds.affixing_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Perfect',
				description:
					'Get within 2% of perfect rolls for every affix slot on a Legendary item on the first try.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	7002: {
		id: 7002,
		name: 'Legendary Re-Rolls',
		questIcon: '/images/fishing/chance_icon.png',
		description: 'Get within 2% of the maximum possible affix rolls on a Legendary rarity item.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [questsIds.affixing_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Perfect',
				description: 'Get within 2% of perfect rolls for every affix slot on a Legendary item.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
		},
		achievement: true,
	},
	7003: {
		id: 7003,
		name: 'Prismatic Power',
		questIcon: '/images/jewellery/prismatic_necklace.png',
		description:
			'Transform two Prismatic jewelry pieces. I would recommend trying to get both a necklace and ring, but any combination will do.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [questsIds.item_improvement_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Prismatic Power',
				description: 'Transform two Prismatic jewelry pieces.',
				validTargets: [
					{
						trigger: 'transform',
						targetID: itemsIds.prismatic_necklace,
					},
					{
						trigger: 'transform',
						targetID: itemsIds.prismatic_ring,
					},
				],
				targetAmount: 2,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	7004: {
		id: 7004,
		name: 'Pity Prismatic',
		questIcon: '/images/jewellery/prismatic_ring.png',
		description: 'Craft a Prismatic jewelry piece from the pity dust.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [questsIds.item_improvement_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Pity Prismatic',
				description: 'Craft a Prismatic jewelry piece from pity dust.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: itemsIds.prismatic_necklace,
					},
					{
						trigger: 'craft',
						targetID: itemsIds.prismatic_ring,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
		},
		achievement: true,
	},
	7005: {
		id: 7005,
		name: 'On the Rainbow Road',
		questIcon: '/images/cooking/alchemy/rainbow_pot.png',
		description: 'Augment any item to +25.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [questsIds.item_improvement_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'On the Rainbow Road',
				description: 'Augment any item to +25.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	7006: {
		id: 7006,
		name: 'Burning with Enthusiasm',
		questIcon: '/images/heat_icon.png',
		description: 'In a single burn action, gain 1,000,000 heat.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [questsIds.processing_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Just a little warm',
				description: 'Gain 1,000,000 heat in one burn action from your inventory.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	7007: {
		id: 7007,
		name: 'BURN IT ALL',
		questIcon: '/images/heat_icon.png',
		description: 'In a single burn action, gain 1,000,000,000 heat.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [questsIds.processing_tutorial],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Way too warm',
				description: 'Gain 1,000,000,000 heat in one burn action from your inventory.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	// 8001-9000 are reserved for misc achievements
	// Idling Like a Champ - idle for 12 hours straight
	8001: {
		id: 8001,
		name: 'Idling Like a Champ',
		questIcon: '/images/pontification/skill_waiting_icon.png',
		description: 'Be offline for 12 hours straight.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Idling Like a Champ',
				description: 'Be offline for 12 hours straight.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
		achievementStandalone: true,
	},
	// #endregion
	// #region 10000+ are reserved for seasonal events or timed quests
	// #endregion
};

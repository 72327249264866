// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import _ from 'lodash';
import { IItem, IItemStats, TItemList, TPartialItemWithItemID } from '../../../game-server/src/modules/items/items.interface';
import { IItemData } from '../../../game-server/src/modules/items/items.interface';
import { IAffix } from '../../../game-server/src/modules/affixes/affixes.interface';

export function buffStacks(
	augLevel: number | undefined,
	itemMultiplier = 1,
	enchantmentMultiplier = 1,
	amountToEat = 1
): number {
	augLevel = augLevel ?? 0;
	itemMultiplier = itemMultiplier ?? 1;
	enchantmentMultiplier = enchantmentMultiplier ?? 1;
	return Math.max(1, Math.floor((1 + (augLevel ?? 0) * 2) * itemMultiplier * enchantmentMultiplier)) * amountToEat;
}

type THealingStats = 'healing' | 'healTick' | 'tickDelay' | 'totalTicks' | 'cooldown';

export function getHealingStats(item: IItem, itemResource: IItemData): Record<THealingStats, number> | undefined {
	if (!itemResource.healing) return undefined;

	const augments = item.augmentations ?? 0;
	const healingStats = {
		healing: itemResource.healing.hp * (1 + augments),
		healTick: Math.floor((itemResource.healing.perTick ?? 0) * Math.max(1, (1 + augments) / 2)),
		tickDelay: (itemResource.healing.tickDelay ?? 0) / 1000,
		totalTicks: itemResource.healing.totalTicks ?? 0,
		cooldown: (itemResource.healing.cooldown ?? 0) / 1000,
	};

	return healingStats;
}

export const getEnchantmentStrength = (item: TPartialItemWithItemID<IItem>, itemList: TItemList) => {
	const itemDetails = itemList[item.itemID];
	if (item.enchantmentID != undefined) {
		const isFood = itemList[item.itemID]?.tags?.includes('consumable');
		return isFood ? itemDetails.stackStrength ?? 2 : itemDetails.stackStrength ?? item.enchantmentStrength ?? 1;
	}
};

export function isTradeable(item: IItem, itemList: TItemList): boolean {
	return (itemList[item.itemID].tradeable ?? false) && !item.forceUntradeable;
}

const dummyItemStats: Required<IItemStats> = {
	name: '',
	price: 0,
	sourceInventory: '',
	enchantmentID: 0,
	augmentations: 0,
	enchantmentStrength: 0,
	affixes: [],
	storyID: 0,
	christmasSpirit: 0,
	forceUntradeable: false,
	augmentCounter: 0,
};

/**
 * This is for hard matches where everything must be the same
 */
export function compareItems(item1: IItem, item2: IItem) {
	if (item1.itemID !== item2.itemID) return false;
	for (const key in dummyItemStats) {
		if (item1[key as keyof IItemStats] !== item2[key as keyof IItemStats]) return false;
	}
	return true;
}

export function filterForBaseItems(stockpile: IItem[], itemIDs: number[], itemList: TItemList): IItem[] {
	return stockpile.filter((item) => {
		if (item.augmentations || item.forceUntradeable || item.affixes) return false;
		if (itemIDs.includes(item.itemID)) {
			const itemData = itemList[item.itemID];
			if ((itemData.forcedEnchant && item.enchantmentID === itemData.forcedEnchant) || !item.enchantmentID) {
				return true;
			}
		}
		return false;
	});
}

export function hasEnoughResources(
	stockpile: IItem[],
	resourceCosts: { id: number; amount: number }[],
	itemList: TItemList
): boolean {
	const idsToCheck = resourceCosts.map((cost) => cost.id);
	const filteredStockpile = filterForBaseItems(stockpile, idsToCheck, itemList);
	// Combine the base and disenchanted versions of the items
	const itemCounts: { [key: number]: number } = {};
	for (const item of filteredStockpile) {
		if (!itemCounts[item.itemID]) itemCounts[item.itemID] = 0;
		itemCounts[item.itemID] += item.stackSize;
	}
	for (const cost of resourceCosts) {
		if (!itemCounts[cost.id] || itemCounts[cost.id] < cost.amount) {
			return false;
		}
	}
	return true;
}

/**
 * Sorts the original affixes array object
 */
export function sortAffix(affixes?: IAffix[]) {
	if (!affixes) return;
	const affixTypeOrder = ['offense', 'defense', 'wealth', 'experience'];
	const orderedAffixes = affixes.sort((affixA, affixB) => {
		const affixATypeOrder = affixTypeOrder.indexOf(affixA.path.split('.')[0]);
		const affixBTypeOrder = affixTypeOrder.indexOf(affixB.path.split('.')[0]);
		if (affixATypeOrder !== affixBTypeOrder) return affixATypeOrder - affixBTypeOrder;

		return affixA.path > affixB.path ? 1 : affixA.path === affixB.path ? 0 : -1;
	});
	return orderedAffixes;
}

/**
 * This is for soft matches, like when using loadouts
 */
export function doItemsMatch(
	item1: TPartialItemWithItemID<IItem> | undefined,
	item2: TPartialItemWithItemID<IItem> | undefined
) {
	if (!item1 || !item2) return false;
	// Check itemID, augmentations, enchantmentStrength, enchantmentID, and affixes
	if (item1.itemID !== item2.itemID) return false;
	if (item1.augmentations !== item2.augmentations) return false;
	if (item1.enchantmentStrength !== item2.enchantmentStrength) return false;
	if (item1.enchantmentID !== item2.enchantmentID) return false;
	if (item1.affixes || item2.affixes) {
		if (!_.isEqual(item1.affixes, item2.affixes)) return false;
	}
	return true;
}

export function getEliteScrollWaves(item: IItem) {
	return (item.augmentations ?? 1) * 2;
}

export function getMonsterDifficulty(item: IItem) {
	return item.augmentations ?? 0;
}

export function getMonsterTreasureHunter(item: IItem) {
	return item.augmentations ?? 0;
}

export function getLocationTreasureHunter(item: IItem) {
	return (item.augmentations ?? 0) * 2;
}

export function dwarvenElvenHaste(dwarvenManufacturingStrength: number, elvenLogisticsStrength: number) {
	const logisticsStrength = (dwarvenManufacturingStrength != 0 ? 0.2 : 0) + (elvenLogisticsStrength != 0 ? -0.25 : 0);
	return logisticsStrength;
}

export function dwarvenElvenBonus(dwarvenManufacturingStrength: number, elvenLogisticsStrength: number) {
	const logisticsStrength = (dwarvenManufacturingStrength != 0 ? 1.25 : 1) * (elvenLogisticsStrength != 0 ? 0.8 : 1);
	return logisticsStrength;
}

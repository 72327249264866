import { Box, Grid, Image } from '@chakra-ui/react';
import React from 'react';
import {
	IMarketTransaction,
	IMarketTransactionDeanonymized,
} from '../../../../../game-server/src/repositories/MarketTransaction.repository';
import { paginate } from '../../../utils/helperFunctions';
import { itemList } from '../../../utils/itemList';
import { socket } from '../../../services/socket.service';
import { itemAffix, itemAugment, itemEnchant, itemImage } from '../../../utils/itemHelperFunctions';
import ItemTooltip from '../Tooltips/ItemTooltip';

function HistoryTable({
	totalListings,
	history,
	page,
	setPage,
	setFilter,
	modMode = false,
	lowerThreshold = 0.9,
	upperThreshold = 4,
}:
	| {
			totalListings: number;
			history: IMarketTransaction[];
			page: number;
			setPage: (page: number) => void;
			modMode?: false;
			lowerThreshold?: never;
			upperThreshold?: never;
			setFilter?: (filter: string) => void;
	  }
	| {
			totalListings: number;
			history: IMarketTransactionDeanonymized[];
			page: number;
			setPage: (page: number) => void;
			modMode?: true;
			lowerThreshold: number;
			upperThreshold: number;
			setFilter?: (filter: string) => void;
	  }) {
	function playerHistory() {
		if (totalListings === -1) {
			return <div className='marketplace-history-loading'>Loading...</div>;
		}
		if (history.length === 0) {
			return (
				<div className='marketplace-history-item'>
					<div className='marketplace-history-item-date'>No history found.</div>
				</div>
			);
		} else {
			const items: React.ReactElement[] = [];
			items.push(getHistoryHeader());
			for (let i = 0; i < history.length; i++) {
				items.push(formatHistoryItem(history[i], i));
			}
			return items;
		}
	}

	function getHistoryHeader() {
		return (
			<Box
				key={'header'}
				className='marketplace-history-header'
				gridTemplateColumns={modMode ? 'repeat(9, 1fr)' : 'repeat(5, 1fr)'}
			>
				{modMode && (
					<>
						<div>SELLER</div>
						<div>BUYER</div>
					</>
				)}
				<div>DATE</div>
				<div>ITEM</div>
				<div>QUANTITY</div>
				<div>PER ITEM</div>
				<div>TOTAL</div>
				{modMode && (
					<>
						<div>LOWEST</div>
						<div>SCORE</div>
					</>
				)}
			</Box>
		);
	}

	function formatHistoryItem(transaction: IMarketTransactionDeanonymized | IMarketTransaction, index: number) {
		const item = transaction.item;
		const itemData = itemList[item.itemID];
		const score = modMode ? transaction.price / (transaction as IMarketTransactionDeanonymized).lowestPrice : 1;
		const highlightTransaction = rowColor(score);
		return (
			<Grid
				className='marketplace-history-item'
				gridTemplateColumns={modMode ? 'repeat(9, 1fr)' : 'repeat(5, 1fr)'}
				textAlign='center'
				{...highlightTransaction}
				lineHeight='40px'
				textShadow='1px 1px 1px black'
				key={index}
			>
				{modMode && (
					<>
						<Box
							cursor='pointer'
							_hover={{ textDecoration: 'underline' }}
							onClick={() => details((transaction as IMarketTransactionDeanonymized).sellerName)}
						>
							{(transaction as IMarketTransactionDeanonymized).sellerName}
						</Box>
						<Box
							cursor='pointer'
							_hover={{ textDecoration: 'underline' }}
							onClick={() => details((transaction as IMarketTransactionDeanonymized).buyerName)}
						>
							{(transaction as IMarketTransactionDeanonymized).buyerName}
						</Box>
					</>
				)}
				<Box className='marketplace-history-item-date' lineHeight='20px'>
					<div className='marketplace-history-item-date-i'>
						{new Date(transaction.purchaseDate).toLocaleDateString()}
					</div>
					<div className='marketplace-history-item-date-i'>
						{new Date(transaction.purchaseDate).toLocaleTimeString()}
					</div>
				</Box>
				<Box
					textAlign='left'
					position='relative'
					width='100%'
					height='100%'
					onClick={() => setFilter?.(itemData.name)}
				>
					<Box position='relative' float='left' width='45px' height='45px'>
						{itemImage(itemData)}
						{itemEnchant(item)}
						{itemAugment(item)}
						{itemAffix(item)}
					</Box>
					{item.name}
					<ItemTooltip item={item} forceDefaultPositions={true} />
				</Box>
				<div className='marketplace-history-item-amount'>{item.stackSize.toLocaleString('en-us')}</div>
				<Box
					className={`marketplace-history-item-price ${transaction.isSeller ? 'sale' : 'purchase'}`}
					lineHeight={transaction.isSeller ? '20px' : '40px'}
				>
					{transaction.isSeller ? '+' : '-'}
					{transaction.price.toLocaleString('en-us')}
					<img
						className='marketplace-history-item-price-gold'
						src='images/gold_coin.png'
						alt='Price per Item'
					/>
					{transaction.isSeller ? (
						<div>
							~+{Math.floor(transaction.price * 0.95).toLocaleString('en-us')}
							<Image
								width='16px'
								height='16px'
								src='images/ui/marketplace_icon.png'
								alt='Tax Free Price per Item'
							/>
						</div>
					) : (
						''
					)}
				</Box>
				<Box
					className={`marketplace-history-item-price ${transaction.isSeller ? 'sale' : 'purchase'}`}
					lineHeight={transaction.isSeller ? '20px' : '40px'}
				>
					{transaction.isSeller ? '+' : '-'}
					{(transaction.price * item.stackSize).toLocaleString('en-us')}
					<img className='marketplace-history-item-price-gold' src='images/gold_coin.png' alt='Price Total' />
					{transaction.isSeller ? (
						<div>
							+{Math.floor(transaction.price * 0.95 * item.stackSize).toLocaleString('en-us')}
							<Image
								width='16px'
								height='16px'
								src='images/ui/marketplace_icon.png'
								alt='Tax Free Price Total'
							/>
						</div>
					) : (
						''
					)}
				</Box>
				{modMode && (
					<>
						<div className='marketplace-history-item-price'>
							{(transaction as IMarketTransactionDeanonymized).lowestPrice.toLocaleString('en-us')}
							<img
								className='marketplace-history-item-price-gold'
								src='images/gold_coin.png'
								alt='Lowest Price'
							/>
						</div>
						<div className='marketplace-history-item-score'>
							{score.toLocaleString('en-US', {
								maximumFractionDigits: 2,
							})}
						</div>
					</>
				)}
			</Grid>
		);
	}

	function rowColor(score: number) {
		if (score < lowerThreshold) {
			const opacity = 1 - score / lowerThreshold;
			return {
				backgroundColor: `rgba(255, 0, 0, ${opacity})`,
			};
		} else if (score > upperThreshold) {
			const opacity = Math.min(1, 0.1 * Math.log2(score / upperThreshold));
			return {
				backgroundColor: `rgba(0, 0, 255, ${opacity})`,
			};
		} else {
			return {
				background: 'rgb(59, 59, 59)',
				_odd: { background: 'rgb(73, 73, 73)' },
			};
		}
	}

	function details(username: string) {
		const command = '/details ' + username;

		const commandToSend = {
			channelId: 1,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);
	}

	function pageButtons() {
		if (totalListings <= 25) {
			return null;
		} else {
			const pagination = paginate(totalListings, page, 25, 5);
			const r: React.ReactElement[] = [];
			r.push(pageButton(1, '<<'));
			for (let i = pagination.startPage; i <= pagination.endPage; i++) {
				r.push(pageButton(i, i.toString()));
			}
			r.push(pageButton(pagination.totalPages, '>>'));
			return r;
		}
	}

	function pageButton(pageNumber: number, text: string) {
		return (
			<div
				key={text + pageNumber}
				className={'marketplace-history-page' + (page === pageNumber ? ' selected' : '')}
				onClick={() => setPage(pageNumber)}
			>
				{text}
			</div>
		);
	}

	return (
		<Box className='marketplace-history-container'>
			{modMode && <div className='marketplace-history-pages'>{pageButtons()}</div>}
			<div className={`marketplace-history ${modMode ? 'anchor-marketplace-history-modmode' : ''}`}>
				{playerHistory()}
			</div>
			<div className='marketplace-history-pages'>{pageButtons()}</div>
		</Box>
	);
}

export default HistoryTable;

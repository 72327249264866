import { Box, Grid } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React, { useState } from 'react';
import AbilityEditor from './Abilities/AbilityEditor';
import { CombatCharacterEquipment } from './CombatCharacterEquipment';
import CombatPlayerStats from './CombatPlayerStats';

export default function CombatGear() {
	const [equipmentGearContainer, setEquipmentGearContainer] = useState<'equipment' | 'cosmetics'>('equipment');

	return (
		<div className='combat-gear-container-wrapper'>
			<div className='combat-gear-container'>
				<Box gridRow={'span 2'}>
					<Grid
						gridTemplateColumns={'repeat(auto-fit, minmax(50px, 1fr))'}
						textAlign={'center'}
						columnGap={'10px'}
					>
						<IdlescapeButton
							onClick={() => setEquipmentGearContainer('equipment')}
							variant='green'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'end'}
						>
							Gear
						</IdlescapeButton>
						<IdlescapeButton
							onClick={() => setEquipmentGearContainer('cosmetics')}
							variant='blue'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'start'}
						>
							Cosmetics
						</IdlescapeButton>
					</Grid>
					<CombatCharacterEquipment view={equipmentGearContainer}></CombatCharacterEquipment>
				</Box>

				<CombatPlayerStats />

				<AbilityEditor />
			</div>
		</div>
	);
}

import React, { useEffect, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IChannelSettings } from '../../../../../game-server/src/modules/chat/Chat.interface';
import { activeChannelAtom } from '../../../atoms/activeChannelAtom';
import { messageAtom } from '../../../atoms/messageAtom';
import { socket } from '../../../services/socket.service';
import { produce } from 'immer';
import { changeFaviconToDefault } from '../../../utils/helperFunctions';

const ChatTab: React.FunctionComponent<{
	settings: IChannelSettings;
	index: number;
	unreadMessages: number;
	hasUnreadMention: boolean;
}> = ({ settings, index, unreadMessages, hasUnreadMention }) => {
	let channelIconPath = settings.channelIcon;
	if (settings.channelName === 'Activity') {
		channelIconPath = '/images/chaticons/chat-icon-activity-log.png';
	}
	if (settings.channelName === 'Group Chat') {
		channelIconPath = '/images/ui/group_icon.png';
	}
	const setMessages = useSetRecoilState(messageAtom);
	const channelIcon = getChannelIcon(channelIconPath, settings.channelName);
	const [activeChannel, setActiveChannel] = useRecoilState(activeChannelAtom);

	const amIActive = activeChannel === index;

	const amIWhisper = settings.channelId === null;
	const amIGroupChat = settings.channelId === -1;

	// Clear favicon notification if mention scrolls out of view
	const hadMention = useRef(hasUnreadMention);
	// Scroll to the tab with the mention
	const scrollToMention = useRef(null);
	useEffect(() => {
		if (hadMention.current && !hasUnreadMention) {
			changeFaviconToDefault();
		}
		hadMention.current = hasUnreadMention;
		if (hasUnreadMention && scrollToMention.current) {
			const divToScroll = scrollToMention.current as HTMLElement;
			divToScroll.scrollIntoView({ behavior: 'smooth' });
		}
	}, [hasUnreadMention]);

	function activateChannel() {
		setActiveChannel(index);
	}

	function leaveChannel(event: React.MouseEvent) {
		event.stopPropagation();
		// ! Make whisper, command and normal message their own functions and call from here
		if (amIWhisper) {
			setActiveChannel(0);
			setMessages(
				produce((draft) => {
					delete draft[settings.channelName];
				})
			);

			return;
		}

		const commandToSend = {
			channelId: settings.channelId,
			channelName: settings.channelName,
			commandString: '/channel leave ' + settings.channelName,
		};

		socket.emit('chat:command:send', commandToSend);
	}

	return (
		<div
			ref={scrollToMention}
			className={`chat-tab ${amIActive ? 'chat-tab-active' : ''} ${amIWhisper ? 'chat-tab-whisper' : ''} ${
				amIGroupChat ? 'chat-tab-group' : ''
			}`}
			onClick={activateChannel}
		>
			{channelIcon}
			<div className='chat-tab-name'>{settings.channelName}</div>
			{settings.channelName !== 'Activity' && settings.channelName !== 'Group Chat' && amIActive && (
				<a className='chat-tab-close-button' onClick={leaveChannel}></a>
			)}
			{!amIActive && unreadMessages > 0 && (
				<div className={`chat-tab-unread-messages ${hasUnreadMention ? 'chat-tab-unread-mention' : ''}`}>
					{Math.min(unreadMessages, 99)}
				</div>
			)}
		</div>
	);
};

const getChannelIcon = (path: string | undefined, alt: string) => {
	if (!path) return null;

	return <img className='chat-tab-icon' src={path} alt={alt} />;
};

export default ChatTab;

export const leaguesIds = {
	default: 1,
	ironman: 2,
	no_gather_ironman: 3,
	group_ironman: 4,
	preseason: 5,
	season_1: 6,
	season_1_ironman: 7,
	magic_catastrophe: 8,
	magic_catastrophe_ironman: 9
}
